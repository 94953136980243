import { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { generateMenuData } from "../../../utils/menu_data";
import { Link } from "react-router-dom";
const fadeInTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
  }
`;
const fadeOutBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const Container = styled.div`
  background-color: var(--background-color);
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media (min-width: 768px) {
    //max-width: var(--max-width-container);
  }
`;
const GridContainer = styled.div`
  //grid-template-rows: repeat(4, 1fr);

  min-width: 100%;
  overflow-x: hidden;
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;

const ButtonWrapper = styled.div`
  box-shadow: var(--shadow-small);
  border-top: 1px solid var(--transparent-border-color);
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  border-radius: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  font-size: var(--font-size-base);
  font-weight: 700;
  padding: 12px 0;

  &:hover {
    background-color: var(--background-color);
  }
  // min-width: 100%;
`;
const ButtonIcon = styled.span``;

const DropDown = styled.div`
  /* Samo za telefone, primenjuje se display na osnovu $isOpen */
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: none; /* Sakrij ako je otvoreno */
        `
      : css`
          display: block; /* Prikaži ako nije otvoreno */
        `}
`;

const Box = styled.div`
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${fadeOutBottom} 0.2s ease-in-out both;
        `
      : css`
          animation: ${fadeInTop} 0.3s ease-in-out both;
        `}
`;

const List = styled.ul`
  list-style: none; /* Remove default bullet points */
  padding: 0 8px;
  margin: 0;
  max-height: 40vh;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
`;

const ListItem = styled.li`
  font-size: var(--font-size-base);
  cursor: pointer;
  letter-spacing: 1.2px;
  text-transform: capitalize;
  font-size: var(--font-size-small);
  font-weight: 600;
`;
const StyledLink = styled(Link)`
  /* Dodajte vaše stilove ovde */
  color: var(--text-color); /* Primer: Postavljanje boje teksta na plavu */
  text-decoration: none; /* Primer: Uklanjanje podvlačenja */
  display: block;
  padding: 8px 0;
`;

const HamburgerMenuData = () => {
  const menu_data = generateMenuData();

  const [isOpenBox, setIsOpenBox] = useState(menu_data.map(() => true));
  const [isOpenContainer, setIsOpenContainer] = useState(
    menu_data.map(() => true)
  );
  const [isBoxAnimating, setIsBoxAnimating] = useState(
    menu_data.map(() => false)
  );

  const toggleBox = (index) => {
    if (isBoxAnimating[index]) {
      return;
    }

    const updatedIsOpenBox = [...isOpenBox];
    updatedIsOpenBox[index] = !updatedIsOpenBox[index];
    setIsOpenBox(updatedIsOpenBox);

    const updatedIsBoxAnimating = [...isBoxAnimating];
    updatedIsBoxAnimating[index] = true;
    setIsBoxAnimating(updatedIsBoxAnimating);

    setTimeout(
      () => {
        const updatedIsOpenContainer = [...isOpenContainer];
        updatedIsOpenContainer[index] = !updatedIsOpenContainer[index];
        setIsOpenContainer(updatedIsOpenContainer);

        updatedIsBoxAnimating[index] = false;
        setIsBoxAnimating(updatedIsBoxAnimating);
      },
      !isOpenContainer[index] ? 200 : 10
    );
  };

  return (
    <Container>
      <Wrapper>
        <GridContainer>
          {menu_data.map(
            (
              section,
              index // Mapiramo kroz sve sekcije
            ) => (
              <GridItems key={index}>
                <ButtonWrapper>
                  <Button onClick={() => toggleBox(index)}>
                    {section.name} {/* Prikažemo ime sekcije */}
                    {section.items.length ? (
                      <ButtonIcon role="presentation">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          className="svg-icon"
                          viewBox="0 0 28 16"
                          style={{ width: "12px", height: "12px" }}
                        >
                          <path
                            d="M1.57 1.59l12.76 12.77L27.1 1.59"
                            strokeWidth=""
                            stroke="var(--transparent-border-color)"
                            fill="none"
                          ></path>
                        </svg>
                      </ButtonIcon>
                    ) : null}
                  </Button>
                </ButtonWrapper>
                {section.items.length ? (
                  <DropDown $isOpen={isOpenContainer[index]}>
                    <Box $isOpen={isOpenBox[index]}>
                      {" "}
                      {/* Prikažemo sadržaj sekcije */}
                      <List>
                        {section.items.map(
                          (
                            item,
                            itemIndex // Mapiramo kroz stavke sekcije
                          ) => (
                            <ListItem key={itemIndex}>
                              <StyledLink to={item.link}>
                                {item.name}
                              </StyledLink>{" "}
                              {/* Prikažemo ime i link stavke */}
                            </ListItem>
                          )
                        )}
                      </List>
                    </Box>
                  </DropDown>
                ) : null}
              </GridItems>
            )
          )}
        </GridContainer>
      </Wrapper>
    </Container>
  );
};

export default HamburgerMenuData;
