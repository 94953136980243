import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useNavigation } from "../../../utils/navigation";
import { ProductContext } from "../../../context/ProductContext";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import { AuthUserContext } from "../../../context/AuthUserContext";
import axios from "axios";
import LanguageSelector from "../../../components/account/LanguageSelector";

const Container = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Wrapper = styled.div`
  width: 94%;
  //margin-top: 40px;
  @media (min-width: 767px) {
    max-width: var(--max-width-container);
  }
`;
const FlexContainer = styled.div`
  width: 100%;
  background-color: var(--background-color-hover-alt);
`;
const Flex = styled.div`
  margin: auto;
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: var(--font-size-small);
  padding: 12px;
  font-weight: 600;
  @media (min-width: 767px) {
    max-width: var(--max-width-container);
  }
`;
const Title = styled.h2`
  font-size: var(--font-size-large);
`;
const Description = styled.p`
  margin: 20px 0;
  font-size: var(--font-size-small);
  font-weight: 500;
`;

const Image = styled.img`
  width: 40px;
`;
const CloseButtonWrapper = styled.div`
  margin-top: 20px;
`;
const CloseButton = styled.button``;

const SectionContainer = styled.div`
  border-bottom: 1px solid var(--text-color);
  margin-bottom: 20px;
`;
const Select = styled.select`
  padding: 8px;
  margin-bottom: 20px;
`;

const ChangePreferences = () => {
  const { t, i18n } = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem("currency") || "USD"
  );
  const { lang } = useParams();
  const { goToHome } = useNavigation();
  const { location } = useContext(AuthUserContext);
  const { updateCurrencyTag } = useContext(ProductContext);
  const cartItems = useSelector((state) => state.cart.itemsList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    const storedCurrency = localStorage.getItem("currency");
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency);
    }
  }, []);
  const handleChangeCurrency = (event) => {
    const fromCurrency = selectedCurrency;
    const newCurrency = event.target.value;

    setSelectedCurrency(newCurrency);
    // Takođe, sačuvajte izabranu valutu u lokalnom skladištu
    localStorage.setItem("currency", newCurrency);
    window.location.reload();

    dispatch(
      cartActions.updateCurrency({
        fromCurrency: fromCurrency,
        toCurrency: newCurrency,
      })
    );
    updateCurrencyTag();
  };

  return (
    <Container>
      <FlexContainer>
        <Flex>
          <svg
            fill="var(--text-color)"
            width="24px"
            height="24px"
            viewBox="-4 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
          >
            <path d="M12.649,31.760 C12.463,31.919 12.231,31.999 12.000,31.999 C11.769,31.999 11.537,31.919 11.351,31.760 C10.887,31.365 0.000,21.976 0.000,11.964 C0.000,5.363 5.383,-0.006 12.000,-0.006 C18.617,-0.006 24.000,5.363 24.000,11.964 C24.000,21.976 13.113,31.365 12.649,31.760 ZM12.000,1.989 C6.486,1.989 2.000,6.464 2.000,11.964 C2.000,19.724 9.687,27.493 12.000,29.654 C14.312,27.493 22.000,19.724 22.000,11.964 C22.000,6.464 17.514,1.989 12.000,1.989 ZM12.000,17.991 C8.691,17.991 6.000,15.306 6.000,12.006 C6.000,8.705 8.691,6.021 12.000,6.021 C15.309,6.021 18.000,8.705 18.000,12.006 C18.000,15.306 15.309,17.991 12.000,17.991 ZM12.000,8.016 C9.794,8.016 8.000,9.805 8.000,12.006 C8.000,14.206 9.794,15.996 12.000,15.996 C14.206,15.996 16.000,14.206 16.000,12.006 C16.000,9.805 14.206,8.016 12.000,8.016 Z" />
          </svg>
          Deliver to {location && location.country}
        </Flex>
      </FlexContainer>
      <Wrapper>
        <SectionContainer>
          <Title>Language Settings</Title>
          <Description>
            Select the language you prefer for browsing, shopping, and
            communications.
          </Description>
          <LanguageSelector />
        </SectionContainer>
        <SectionContainer>
          <Title>Currency Settings</Title>
          <Description>Select the currency you want to shop with</Description>
          <Select onChange={handleChangeCurrency} value={selectedCurrency}>
            <option value="USD">$ - USD - US Dollar (Default)</option>
            <option value="EUR">€ - EUR - Euro</option>
          </Select>
        </SectionContainer>
      </Wrapper>
    </Container>
  );
};

export default ChangePreferences;
