import React, { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import AddressBookForm from "../account/AddressBookForm";
import { useTranslation } from "react-i18next";
const fadeIn = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const AddNewAddress = styled.div`
  display: flex;
  justify-content: flex-start;
  color: var(--primary-color);
  font-weight: 700;
  gap: var(--spacing-md);
  padding: var(--spacing-md) var(--spacing-md);
  margin-bottom: var(--spacing-md);
  background-color: var(--background-color);
  font-size: var(--font-size-base);
  font-weight: 300;
  font-family: "Larken";

  border-bottom: 1px solid #d7e0e7;
  cursor: pointer;
`;

const SelectAddressContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
`;
const SelectAddressWrapper = styled.div`
  position: relative;
  padding: var(--spacing-md) var(--spacing-md);
  margin: var(--spacing-xxs) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    padding: var(--spacing-xl);
  }
  border-bottom: 1px solid #d7e0e7;
`;
const AddressTitle = styled.div`
  font-weight: 300;
  font-size: var(--font-size-base);
  font-weight: normal;
  display: flex;
  align-items: center;
`;

const AddressType = styled.p`
  display: inline;
  font-size: var(--font-size-small);
  font-family: "Larken";
  margin-top: 0;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: 20px;
  text-transform: capitalize;
  color: var(--text-color);
  background-color: #d7e0e7;
  margin-left: 14px;
`;
const AdressInfo = styled.p`
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--p-color);
`;
const RadioButton = styled.div`
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 20px;
  border: 1px solid var(--text-color-hover);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
`;
const ConditionContainer = styled.span`
  display: ${(props) => (props.$newAddress ? "none" : "inline")};
`;
const ButtonsContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-xs);
  }
`;
const TrashSVG = styled.svg`
  padding: 11px var(--spacing-sm);
  border: 1px solid var(--text-color);
`;
const DotsSVG = styled.svg`
  @media (min-width: 768px) {
    display: none;
  }
`;
const Ul = styled.ul`
  padding: 0;
  border: 0;
  outline: none;
  list-style: none;
  position: absolute;
  right: 0%;
  top: calc(100% - 10px);

  width: 120px;
  box-shadow: var(--shadow-large);
  display: block;
  background-color: none;
  animation: ${fadeIn} 0.3s ease-out;
  z-index: 1;
  border: 1px solid #d7e0e7;
`;
const Li = styled.li`
  font-family: "Larken-Light";
  font-weight: 600;
  padding: 14px;
  font-size: var(--font-size-small);
  background: var(--background-color);
  border-bottom: 1px solid #d7e0e7;
  cursor: pointer;
  color: var(--text-color);
  &:hover {
    color: var(--background-color);
    background-color: var(--primary-color);
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TopTitle = styled.h2`
  margin-left: 12px;
  background-color: var(--background-color-hover);
  color: var(--text-color);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);
  font-size: var(--font-size-base);
  font-weight: 500;

  text-transform: uppercase;
  padding: 12px 0;
  opacity: 0.8;

  @media (min-width: 768px) {
    display: none;
  }
`;
const OnlyMobile = styled.span`
  display: ${(props) => (props.$newAddress ? "none" : "inline")};
  @media (min-width: 768px) {
    display: inline;
  }
`;
const ConfirmContainer = styled.div`
  margin-top: var(--spacing-xxs);
  background-color: var(--transparent-color);
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--zindex-default);
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: #1a2c38;
  background-color: var(--background-color);
  position: relative;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;

  padding: 60px 0;
  width: 94%;
  @media (min-width: 768px) {
    width: 500px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xl);
`;

const ButtonTransparent = styled.button`
  padding: var(--spacing-sm) var(--spacing-lg);
  color: var(--background-color);
  background-color: var(--text-color);
  &:hover {
    color: var(--text-color);
    background-color: var(--background-color);
  }
`;
const ButtonConfirm = styled(ButtonTransparent)`
  color: var(--error-color);
  background-color: var(--background-color);
  border-color: var(--error-color);
  &:hover {
    color: var(--background-color);
    background-color: var(--error-color);
  }
`;
const DefaultFlag = styled.div`
  position: absolute;
  top: 0;
  right: var(--spacing-md);
  padding: var(--spacing-xxs) var(--spacing-sm);
  color: var(--p-color);
  background-color: #fae0ce;
  font-size: var(--font-size-small);
`;
const ManageAddresses = ({ newAddress, setNewAddress }) => {
  const { t } = useTranslation();
  const [editAddress, setEditAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { userProfile, deleteAddressBook } = useContext(AuthUserContext);
  const [showDropdown, setShowDropdown] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  const toggleDropdown = (id) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDelete = async (e, addressId) => {
    e.preventDefault();

    try {
      await deleteAddressBook(addressId);
      setShowConfirm(false);
    } catch (error) {
      console.error("Greška prilikom brisanja adrese:", error); // Obradite grešku
    }
  };

  return (
    <>
      <OnlyMobile $newAddress={newAddress}>
        <AddNewAddress
          onClick={() => {
            if (!editAddress && newAddress) {
              setNewAddress(false);
            } else {
              setNewAddress(true);
            }
            setEditAddress(null);
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_693_9651)">
              <path
                d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                stroke="#5693BF"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 10H12.5"
                stroke="#5693BF"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 7.5V12.5"
                stroke="#5693BF"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_693_9651">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {t("ADDRESS_BOOK_FORM.ADD_NEW_ADDRESS")}
        </AddNewAddress>
        <TopTitle>
          {userProfile?.addresses.length === 1
            ? userProfile?.addresses.length + " " + t("PROFILE.SAVED_ADDRESS")
            : userProfile?.addresses.length +
              " " +
              t("PROFILE.SAVED_ADDRESSES")}
        </TopTitle>

        {userProfile &&
          userProfile?.addresses.map((address, index) => (
            <SelectAddressContainer key={index}>
              <SelectAddressWrapper>
                {address.is_primary && (
                  <DefaultFlag>{t("PROFILE.DEFAULT_FLAG")}</DefaultFlag>
                )}
                <FlexDiv onClick={() => setSelectedAddress(address)}>
                  <div>
                    <AddressTitle>
                      {address.country}, {address.id}{" "}
                      <AddressType>{address.type}</AddressType>
                    </AddressTitle>
                    <AdressInfo>
                      {address.street}, {address.city}, {address.postal_code} ..
                    </AdressInfo>
                  </div>
                </FlexDiv>
                <div style={{ position: "relative" }}>
                  <DotsSVG
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => toggleDropdown(address.id)}
                  >
                    <g clipPath="url(#clip0_931_10053)">
                      <path
                        d="M11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12Z"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19C13 18.7348 12.8946 18.4804 12.7071 18.2929C12.5196 18.1054 12.2652 18 12 18C11.7348 18 11.4804 18.1054 11.2929 18.2929C11.1054 18.4804 11 18.7348 11 19Z"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5Z"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_931_10053">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </DotsSVG>
                  {showDropdown[address.id] && (
                    <Ul>
                      <Li
                        key={0}
                        delay={0}
                        onClick={() => {
                          setNewAddress(true);
                          setEditAddress(address);
                          toggleDropdown(address.id);
                        }}
                      >
                        {t("BUTTONS.EDIT")}
                      </Li>
                      <Li
                        key={1}
                        delay={0.4}
                        onClick={() => setShowConfirm(address)}
                      >
                        {t("BUTTONS.REMOVE")}
                      </Li>
                    </Ul>
                  )}
                </div>
                <ButtonsContainer>
                  <TrashSVG
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setShowConfirm(address)}
                  >
                    <g clipPath="url(#clip0_303_7130)">
                      <path
                        d="M20.5008 6.5C20.7557 6.50028 21.0009 6.59788 21.1862 6.77285C21.3715 6.94782 21.4831 7.18695 21.498 7.44139C21.513 7.69584 21.4302 7.94638 21.2666 8.14183C21.103 8.33729 20.8709 8.4629 20.6178 8.493L20.5008 8.5H20.4198L19.5008 19.5C19.5009 20.2652 19.2085 21.0015 18.6836 21.5583C18.1586 22.115 17.4407 22.4501 16.6768 22.495L16.5008 22.5H8.50085C6.90285 22.5 5.59685 21.251 5.50885 19.75L5.50384 19.583L4.58085 8.5H4.50085C4.24597 8.49972 4.00081 8.40212 3.81548 8.22715C3.63014 8.05218 3.51861 7.81305 3.50367 7.55861C3.48874 7.30416 3.57152 7.05362 3.73511 6.85817C3.8987 6.66271 4.13075 6.5371 4.38385 6.507L4.50085 6.5H20.5008Z"
                        fill="black"
                      />
                      <path
                        d="M14.5 2.5C15.0304 2.5 15.5391 2.71071 15.9142 3.08579C16.2893 3.46086 16.5 3.96957 16.5 4.5C16.4997 4.75488 16.4021 5.00003 16.2272 5.18537C16.0522 5.3707 15.813 5.48223 15.5586 5.49717C15.3042 5.51211 15.0536 5.42933 14.8582 5.26574C14.6627 5.10214 14.5371 4.8701 14.507 4.617L14.5 4.5H10.5L10.493 4.617C10.4629 4.8701 10.3373 5.10214 10.1418 5.26574C9.94638 5.42933 9.69584 5.51211 9.44139 5.49717C9.18695 5.48223 8.94782 5.3707 8.77285 5.18537C8.59788 5.00003 8.50028 4.75488 8.5 4.5C8.49984 3.99542 8.69041 3.50943 9.0335 3.13945C9.37659 2.76947 9.84685 2.54284 10.35 2.505L10.5 2.5H14.5Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_303_7130">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.5 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </TrashSVG>

                  <button
                    className="button-transparent"
                    onClick={() => {
                      setNewAddress(true);
                      setEditAddress(address);
                    }}
                  >
                    {t("BUTTONS.EDIT")}
                  </button>
                </ButtonsContainer>
              </SelectAddressWrapper>
            </SelectAddressContainer>
          ))}
      </OnlyMobile>
      <OnlyMobile $newAddress={!newAddress}>
        <ConditionContainer $newAddress={!newAddress}>
          <AddressBookForm
            editAddress={editAddress}
            setSelectedAddress={setSelectedAddress}
            setNewAddress={setNewAddress}
            setEditAddress={setEditAddress}
          />
        </ConditionContainer>
      </OnlyMobile>
      {showConfirm && (
        <ConfirmContainer>
          <ConfirmWrapper>
            {t("PROFILE.DELETE_CONFIRMATION")}
            <ButtonContainer>
              <ButtonConfirm
                className="button-transparent"
                onClick={(e) => handleDelete(e, showConfirm.id)}
              >
                {t("BUTTONS.DELETE")}
              </ButtonConfirm>
              <ButtonTransparent
                className="button-transparent"
                onClick={(e) => setShowConfirm(false)}
              >
                {t("BUTTONS.CANCEL")}
              </ButtonTransparent>
            </ButtonContainer>
          </ConfirmWrapper>
        </ConfirmContainer>
      )}
    </>
  );
};

export default ManageAddresses;
