import styled from "styled-components";
import HearthA from "../../../assets/icons/search.png";
import { useContext } from "react";
import { AuthUserContext } from "../../../context/AuthUserContext";
import PageLoading from "../../../components/animations/PageLoading";

const Container = styled.main`
  margin-bottom: 40px;
`;
const ContainerWrapper = styled.div`
  @media (min-width: 767px) {
    display: flex;
    align-content: center;
    justify-content: center;
  }
`;
const Wrapper = styled.div`
  padding: 0 20px;
  @media (min-width: 767px) {
    width: 100%;
    max-width: 1400px;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;

  object-fit: cover;
  object-position: center;
  background-color: red;
  @media (min-width: 767px) {
    max-height: 50vh;
    width: auto;
  }
`;
const Wrapper3 = styled(Wrapper)`
  @media (min-width: 767px) {
    max-width: 940px;
  }
`;
const InnerWrapper1 = styled(Wrapper)`
  margin: 20px 0;
  @media (min-width: 767px) {
    margin: 40px 0;
    max-width: 500px;
  }
`;
const Wrapper2 = styled(Wrapper)`
  @media (min-width: 767px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
  }
`;
const Br = styled.br`
  @media (min-width: 767px) {
    display: none;
  }
`;
const Title = styled.h1`
  padding-top: 20px;
  font-size: var(--font-size-xlarge);
  font-weight: 400;
  text-align: center;
`;
const TitleDesc = styled.div`
  padding: 20px;
  font-size: var(--font-size-base);
  font-weight: 400;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  width: 100%;
  justify-content: center;
`;
const Button = styled.button`
  width: 96%;
  border-radius: 0;
  padding: 16px 0;
`;
const DisclamerText = styled.div`
  text-align: center;
  font-size: var(--font-size-small);
`;
const HowContainer = styled.div`
  background-color: var(--background-color-hover);
`;
const NumberContainer = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
  gap: 14px;
`;
const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-weight: bold;
  border-radius: 50%;
  background-color: var(--background-color);
  font-size: var(--font-size-large);
`;
const NumberText = styled.span`
  letter-spacing: 1.1px;
  font-weight: 300;
`;

const BenefitContainer = styled.div`
  padding: 20px;
  border-bottom: 2px solid var(--background-color-hover);
`;
const RefferalContainer = styled.div`
  @media (min-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;
const ReferralName = styled.h3`
  color: ${({ $isActive }) => (!$isActive ? "#9f9f9f" : "var(--text-color)")};
  font-size: var(--font-size-large);
  font-weight: bold;
`;
const RefferalNotFulfilled = styled.div`
  display: inline-block;
  background-color: var(--background-color-hover);
  color: #9f9f9f;
  font-weight: 400;
  margin-top: 14px;
  padding: 8px;
  border-radius: 20px;
  text-align: center;
  font-size: var(--font-size-small);
`;

const RefferalFulfilledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--accent-color);
  font-weight: bold;
`;
const RefferalFulfilled = styled(RefferalNotFulfilled)`
  display: flex;
  align-items: center;

  gap: 4px;
  color: var(--text-color);
`;

const SeeAllContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;
const SeeAll = styled.button`
  font-size: var(--font-size-base);
  border: none;
  border-radius: 0;
  color: var(--text-color);
  background-color: transparent;
  padding: 0;
  border-bottom: 1px solid var(--text-color);

  &:hover {
    background-color: transparent;
  }
`;

const TermsTitle = styled.h4`
  margin: 20px 0 10px 0;
  font-size: var(--font-size-small);
`;
const TermsText = styled.p`
  padding: 0;
  margin: 0;
  font-size: var(--font-size-small);
`;

const InviteFriend = () => {
  const { userProfile } = useContext(AuthUserContext);
  const handleShare = async () => {
    const shareData = {
      title: "Snus Website Invitation",
      text: "Discover curated collections from the world's most in-demand fashion labels. This invitation is only valid for 48 hours, so take advantage of the invite and enjoy a 10%-off welcome voucher today.",
      url: `https://www.nikotinskevrecice.com/?referral=${userProfile.referral_code}`,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // Alternativna logika za kopiranje u clipboard ili prikazivanje poruke da deljenje nije moguće
        console.log("Web Share API nije podržan.");
      }
    } catch (error) {
      console.error("Greška pri deljenju:", error);
    }
  };
  return (
    <Container>
      {userProfile ? (
        <>
          <ContainerWrapper>
            <Image src={HearthA} alt="cover-photo" />
            <InnerWrapper1>
              <Title>Invite a Friend</Title>
              <div>
                <TitleDesc>
                  You have <strong>7 invitations</strong> left. Give access to
                  family and friend and enjoy your benefits
                </TitleDesc>
              </div>
              <IconContainer>
                <IconWrapper>
                  <svg
                    fill="var(--text-color)"
                    height="40px"
                    width="40px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 482.978 482.978"
                    style={{ marginTop: "10px" }}
                  >
                    <g>
                      <path
                        d="M316.968,166.011c-4.101-4.101-10.749-4.101-14.85,0L166.011,302.117c-4.1,4.101-4.1,10.749,0,14.85
		c2.051,2.051,4.737,3.075,7.425,3.075c2.687,0,5.374-1.025,7.425-3.075L316.968,180.86
		C321.068,176.76,321.068,170.111,316.968,166.011z"
                      />
                      <path
                        d="M191.909,231.749c21.968,0,39.841-17.873,39.841-39.842c0-21.967-17.873-39.839-39.843-39.839
		c-21.967,0-39.838,17.872-39.838,39.839C152.069,213.876,169.941,231.749,191.909,231.749z M191.909,173.068
		c10.389,0,18.841,8.451,18.841,18.839c0,10.39-8.452,18.842-18.841,18.842c-10.388,0-18.84-8.452-18.84-18.842
		C173.069,181.52,181.52,173.068,191.909,173.068z"
                      />
                      <path
                        d="M291.069,251.229c-21.969,0-39.841,17.871-39.841,39.838c0,21.97,17.872,39.844,39.841,39.844
		c21.968,0,39.84-17.874,39.84-39.844C330.909,269.1,313.037,251.229,291.069,251.229z M291.069,309.91
		c-10.389,0-18.841-8.453-18.841-18.844c0-10.388,8.452-18.838,18.841-18.838c10.389,0,18.84,8.45,18.84,18.838
		C309.909,301.457,301.458,309.91,291.069,309.91z"
                      />
                      <path
                        d="M462.589,197.194c-6.095-8.077-11.853-15.707-14.199-22.932c-2.515-7.748-2.38-17.641-2.236-28.114
		c0.225-16.337,0.456-33.23-9.291-46.624c-9.846-13.528-26.071-18.525-41.762-23.358c-9.933-3.058-19.313-5.947-25.77-10.644
		c-6.323-4.602-11.891-12.556-17.785-20.976c-9.038-12.911-19.282-27.545-35.418-32.784c-4.064-1.32-8.481-1.989-13.128-1.989
		c-11.279,0-22.583,3.845-33.514,7.564C259.414,20.764,249.899,24,241.489,24c-8.411,0-17.924-3.236-27.997-6.663
		c-10.931-3.719-22.233-7.564-33.514-7.564c-4.646,0-9.063,0.669-13.128,1.989c-16.136,5.239-26.38,19.873-35.418,32.784
		c-5.895,8.42-11.462,16.374-17.785,20.975c-6.456,4.698-15.837,7.587-25.769,10.646c-15.691,4.832-31.917,9.828-41.763,23.358
		c-9.747,13.392-9.515,30.286-9.291,46.623c0.144,10.475,0.279,20.367-2.236,28.115c-2.346,7.226-8.104,14.855-14.199,22.933
		C10.832,209.859,0,224.213,0,241.489s10.832,31.63,20.39,44.295c6.096,8.077,11.853,15.706,14.199,22.933
		c2.516,7.747,2.38,17.641,2.236,28.114c-0.224,16.337-0.456,33.23,9.291,46.623c9.846,13.529,26.071,18.525,41.763,23.357
		c9.932,3.059,19.312,5.947,25.769,10.646c6.323,4.602,11.891,12.555,17.786,20.976c9.038,12.91,19.282,27.544,35.418,32.783
		c4.064,1.32,8.481,1.989,13.128,1.989c11.28,0,22.582-3.845,33.514-7.563c10.073-3.427,19.587-6.663,27.997-6.663
		c8.41,0,17.925,3.236,27.997,6.663c10.931,3.719,22.233,7.563,33.514,7.563c4.647,0,9.065-0.669,13.129-1.989
		c16.136-5.239,26.379-19.873,35.417-32.784c5.895-8.42,11.462-16.374,17.784-20.975c6.457-4.698,15.838-7.587,25.77-10.646
		c15.691-4.832,31.916-9.828,41.762-23.357c9.748-13.393,9.517-30.287,9.292-46.624c-0.143-10.475-0.278-20.367,2.236-28.114
		c2.347-7.226,8.105-14.855,14.199-22.933c9.558-12.664,20.39-27.018,20.39-44.294S472.147,209.858,462.589,197.194z
		 M445.827,273.134c-6.871,9.104-13.975,18.519-17.41,29.097c-3.588,11.05-3.422,23.168-3.262,34.887
		c0.185,13.494,0.359,26.24-5.273,33.979c-5.73,7.875-17.986,11.649-30.963,15.646c-11.14,3.43-22.657,6.977-31.945,13.735
		c-9.158,6.665-16.008,16.449-22.632,25.911c-7.842,11.204-15.25,21.786-24.699,24.855c-1.994,0.647-4.167,0.962-6.643,0.962
		c-7.806,0-17.008-3.13-26.75-6.444c-11.246-3.826-22.876-7.782-34.761-7.782c-11.884,0-23.513,3.956-34.76,7.782
		c-9.743,3.314-18.945,6.444-26.75,6.444c-2.475,0-4.648-0.314-6.643-0.962c-9.45-3.068-16.857-13.65-24.7-24.854
		c-6.624-9.463-13.474-19.247-22.633-25.913c-9.287-6.758-20.805-10.305-31.945-13.735c-12.976-3.995-25.233-7.77-30.963-15.645
		c-5.632-7.739-5.458-20.484-5.272-33.978c0.161-11.72,0.327-23.839-3.261-34.888c-3.435-10.578-10.54-19.992-17.411-29.098
		C28.846,262.127,21,251.73,21,241.489c0-10.241,7.846-20.638,16.152-31.643c6.871-9.105,13.976-18.52,17.411-29.098
		c3.588-11.05,3.421-23.169,3.261-34.889c-0.185-13.493-0.36-26.238,5.272-33.977c5.73-7.875,17.987-11.649,30.963-15.645
		c11.14-3.431,22.658-6.978,31.945-13.735c9.158-6.666,16.008-16.45,22.631-25.912c7.843-11.204,15.251-21.786,24.7-24.855
		c1.995-0.647,4.167-0.962,6.643-0.962c7.806,0,17.008,3.13,26.751,6.444C217.975,41.044,229.604,45,241.489,45
		c11.884,0,23.514-3.956,34.761-7.782c9.742-3.314,18.944-6.444,26.75-6.444c2.475,0,4.647,0.314,6.642,0.962
		c9.45,3.069,16.858,13.651,24.701,24.855c6.624,9.462,13.473,19.246,22.632,25.912c9.287,6.758,20.806,10.305,31.945,13.735
		c12.976,3.996,25.232,7.771,30.963,15.644c5.633,7.74,5.458,20.486,5.272,33.979c-0.16,11.72-0.326,23.838,3.262,34.888
		c3.435,10.576,10.539,19.991,17.409,29.096c8.307,11.007,16.152,21.403,16.152,31.645
		C461.978,251.73,454.133,262.127,445.827,273.134z"
                      />
                      <path
                        d="M404.536,188.513c-1.837-5.655-1.733-13.157-1.624-21.1c0.171-12.472,0.365-26.607-7.669-37.648
		c-8.112-11.146-21.688-15.326-33.665-19.015c-7.528-2.318-14.64-4.508-19.343-7.93c-4.599-3.348-8.817-9.374-13.284-15.754
		c-7.235-10.337-15.437-22.052-28.723-26.366c-3.393-1.103-7.07-1.661-10.928-1.661c-9.154,0-18.127,3.053-26.804,6.004
		c-7.641,2.6-14.858,5.055-21.008,5.055c-6.15,0-13.367-2.455-21.008-5.055c-8.676-2.951-17.648-6.004-26.803-6.004
		c-3.858,0-7.534,0.559-10.927,1.661c-13.287,4.314-21.488,16.029-28.724,26.364c-4.467,6.381-8.686,12.408-13.286,15.756
		c-4.703,3.423-11.814,5.612-19.343,7.931c-11.976,3.688-25.551,7.869-33.663,19.014c-8.034,11.039-7.84,25.176-7.669,37.648
		c0.109,7.942,0.212,15.445-1.624,21.1c-1.703,5.243-6.063,11.021-10.679,17.138c-7.65,10.138-16.321,21.627-16.321,35.839
		c0,14.213,8.671,25.702,16.321,35.84c4.617,6.116,8.977,11.895,10.679,17.138c1.836,5.654,1.733,13.157,1.624,21.1
		c-0.171,12.472-0.365,26.608,7.669,37.647c8.112,11.146,21.687,15.326,33.664,19.014c7.529,2.319,14.64,4.509,19.343,7.932
		c4.599,3.347,8.818,9.373,13.285,15.754c7.236,10.336,15.437,22.051,28.724,26.365c3.393,1.103,7.069,1.661,10.927,1.661
		c9.154,0,18.126-3.053,26.803-6.005c7.641-2.6,14.858-5.055,21.008-5.055c6.15,0,13.367,2.455,21.008,5.055
		c8.677,2.952,17.648,6.005,26.805,6.005c3.857,0,7.533-0.559,10.927-1.661c13.286-4.315,21.487-16.029,28.723-26.366
		c4.467-6.38,8.685-12.406,13.284-15.753c4.703-3.424,11.814-5.613,19.344-7.932c11.977-3.688,25.552-7.868,33.662-19.014
		c8.035-11.04,7.842-25.177,7.67-37.649c-0.108-7.942-0.211-15.444,1.624-21.099c1.703-5.243,6.063-11.021,10.68-17.138
		c7.649-10.137,16.32-21.627,16.32-35.839c0-14.213-8.671-25.703-16.321-35.84C410.598,199.532,406.238,193.755,404.536,188.513z
		 M398.452,264.679c-5.457,7.23-11.099,14.707-13.891,23.302c-2.907,8.956-2.775,18.572-2.648,27.872
		c0.139,10.089,0.27,19.618-3.651,25.004c-3.995,5.491-13.16,8.314-22.863,11.301c-8.843,2.724-17.986,5.539-25.521,11.022
		c-7.434,5.41-12.872,13.178-18.13,20.69c-5.865,8.378-11.405,16.292-18.006,18.436c-1.313,0.426-2.766,0.634-4.441,0.634
		c-5.681,0-12.655-2.373-20.04-4.886c-8.921-3.035-18.146-6.174-27.771-6.174c-9.624,0-18.85,3.139-27.772,6.174
		c-7.384,2.513-14.359,4.886-20.039,4.886c-1.675,0-3.127-0.208-4.441-0.634c-6.601-2.144-12.141-10.058-18.006-18.436
		c-5.259-7.513-10.697-15.28-18.132-20.691c-7.533-5.482-16.676-8.298-25.518-11.02c-9.703-2.988-18.869-5.811-22.865-11.302
		c-3.919-5.386-3.788-14.914-3.65-25.002c0.128-9.3,0.259-18.917-2.649-27.874c-2.791-8.594-8.434-16.071-13.89-23.302
		c-6.214-8.234-12.083-16.012-12.083-23.189c0-7.178,5.869-14.955,12.083-23.188c5.457-7.23,11.1-14.708,13.891-23.303
		c2.908-8.957,2.776-18.574,2.649-27.874c-0.138-10.088-0.269-19.616,3.65-25.002c3.996-5.49,13.161-8.313,22.864-11.301
		c8.842-2.723,17.986-5.539,25.519-11.021c7.435-5.411,12.873-13.179,18.132-20.691c5.865-8.378,11.405-16.292,18.006-18.436
		c1.314-0.427,2.766-0.634,4.441-0.634c5.681,0,12.655,2.373,20.04,4.885c8.921,3.035,18.147,6.174,27.771,6.174
		c9.624,0,18.85-3.139,27.771-6.174c7.385-2.512,14.359-4.885,20.04-4.885c1.675,0,3.128,0.207,4.441,0.634
		c6.601,2.144,12.141,10.058,18.006,18.436c5.258,7.513,10.696,15.28,18.131,20.691c7.533,5.482,16.677,8.298,25.52,11.02
		c9.703,2.988,18.869,5.811,22.865,11.301c3.919,5.387,3.788,14.915,3.65,25.004c-0.128,9.3-0.26,18.916,2.648,27.873
		c2.791,8.594,8.433,16.07,13.889,23.301c6.215,8.234,12.084,16.013,12.084,23.19C410.535,248.667,404.666,256.444,398.452,264.679z
		"
                      />
                    </g>
                  </svg>
                  <strong>10%</strong>
                  <span>voucher*</span>
                </IconWrapper>
                <IconWrapper>
                  <svg
                    width="50px"
                    height="50px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.65 8.93274L12.4852 4.30901C12.2923 3.89699 11.7077 3.897 11.5148 4.30902L9.35002 8.93274L4.45559 9.68243C4.02435 9.74848 3.84827 10.2758 4.15292 10.5888L7.71225 14.2461L6.87774 19.3749C6.80571 19.8176 7.27445 20.1487 7.66601 19.9317L12 17.5299L16.334 19.9317C16.7256 20.1487 17.1943 19.8176 17.1223 19.3749L16.2878 14.2461L19.8471 10.5888C20.1517 10.2758 19.9756 9.74848 19.5444 9.68243L14.65 8.93274Z"
                      stroke="var(--text-color)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <strong>100</strong>
                  <span>VIP points*</span>
                </IconWrapper>
                <IconWrapper>
                  <svg
                    fill="var(--text-color)"
                    height="50px"
                    width="50px"
                    version="1.1"
                    id="Capa_1"
                    viewBox="0 0 424.05 424.05"
                  >
                    <g>
                      <path
                        d="M384.998,216.773h-27.4c-8.192-17.55-20.955-33.479-37.333-46.57c-0.332-19.494-1.73-34.292-4.258-45.135
		c-3.916-16.8-11.332-25.318-22.044-25.318c-11.688,0-36.557,14.617-55.683,36.111c-8.61-1.206-17.376-1.815-26.132-1.815
		c-41.302,0-80.216,13.259-109.573,37.335c-26.628,21.838-42.603,50.217-45.808,80.879c-11.952-3.951-20.603-15.22-20.603-28.478
		c0-5.523-4.478-10-10-10s-10,4.477-10,10c0,24.278,17.4,44.561,40.382,49.055c2.684,31.874,19.432,61.902,47.066,84.162
		l-8.59,48.716c-0.461,2.612,0.135,5.299,1.656,7.472c1.521,2.172,3.843,3.652,6.455,4.112l37.423,6.599
		c2.611,0.458,5.299-0.136,7.472-1.657s3.652-3.843,4.112-6.455l5.37-30.452c17.417,5.423,35.741,8.166,54.638,8.166
		c18.896,0,37.22-2.742,54.637-8.166l5.37,30.452c0.46,2.612,1.939,4.934,4.112,6.455c1.692,1.185,3.697,1.809,5.735,1.809
		c0.578,0,1.159-0.05,1.736-0.152l37.423-6.599c5.439-0.959,9.07-6.146,8.111-11.584L320.684,357
		c16.18-13.031,28.791-28.827,36.913-46.227h27.4c12.621,0,22.889-10.268,22.889-22.888v-48.224
		C407.886,227.041,397.619,216.773,384.998,216.773z M384.998,290.773h-33.975c-4.103,0-7.788,2.505-9.298,6.319
		c-7.2,18.187-20.298,34.661-37.879,47.641c-3.054,2.254-4.567,6.043-3.908,9.781l7.903,44.825l-17.728,3.126l-5.675-32.183
		c-0.52-2.948-2.333-5.509-4.941-6.978c-2.61-1.469-5.74-1.69-8.529-0.607c-18.441,7.167-38.231,10.802-58.82,10.802
		c-20.59,0-40.38-3.634-58.821-10.802c-2.79-1.083-5.92-0.862-8.529,0.607c-2.608,1.469-4.422,4.029-4.941,6.978l-5.675,32.183
		l-17.727-3.126l7.903-44.825c0.659-3.738-0.854-7.527-3.908-9.781c-28.149-20.782-44.294-50.291-44.294-80.96
		c0-60.504,61.006-109.728,135.992-109.728c9.555,0,19.116,0.811,28.417,2.411c3.554,0.611,7.162-0.738,9.442-3.532
		c15.791-19.345,36.247-31.284,43-32.982c1.735,2.493,6.99,13.476,7.317,55.296c0.024,3.13,1.514,6.068,4.022,7.939
		c17.322,12.915,30.247,29.263,37.378,47.276c1.51,3.814,5.195,6.319,9.298,6.319h33.975c1.593,0,2.889,1.295,2.889,2.888v4.505
		h-0.861c-5.522,0-10,4.477-10,10s4.478,10,10,10h0.861v23.718C387.886,289.478,386.59,290.773,384.998,290.773z"
                      />
                      <path
                        d="M212.148,107c29.5,0,53.5-24,53.5-53.5s-24-53.5-53.5-53.5s-53.5,24-53.5,53.5S182.648,107,212.148,107z M212.148,20
		c18.472,0,33.5,15.028,33.5,33.5S230.62,87,212.148,87s-33.5-15.028-33.5-33.5S193.676,20,212.148,20z"
                      />
                      <path
                        d="M251.267,177.643c-12.492-3.968-25.653-5.98-39.119-5.98c-13.465,0-26.627,2.012-39.12,5.98
		c-5.264,1.672-8.176,7.294-6.504,12.558c1.673,5.264,7.294,8.176,12.559,6.503c10.532-3.345,21.657-5.042,33.065-5.042
		s22.533,1.696,33.064,5.042c1.007,0.32,2.026,0.472,3.03,0.472c4.239,0,8.176-2.719,9.527-6.976
		C259.443,184.938,256.531,179.315,251.267,177.643z"
                      />
                    </g>
                  </svg>
                  <strong>5%</strong>
                  <span>commision*</span>
                </IconWrapper>
              </IconContainer>
              <ButtonWrapper>
                <Button onClick={handleShare}>SEND INVITATION</Button>
              </ButtonWrapper>
              <DisclamerText>
                Invitations are for friends and family only. You are not
                allowerd to share them publicly. Doing so may lead to your
                remval from the SNUS community{" "}
              </DisclamerText>
            </InnerWrapper1>
          </ContainerWrapper>
          <HowContainer>
            <Title>How It Works</Title>
            <ContainerWrapper>
              <Wrapper2>
                <NumberContainer>
                  <Number>1</Number>
                  <NumberText>
                    Send an invitation tro friends and family
                  </NumberText>
                </NumberContainer>
                <NumberContainer>
                  <Number>2</Number>
                  <NumberText>
                    Your friend shop at SNUS and <Br />
                    keeps at least on item
                  </NumberText>
                </NumberContainer>
                <NumberContainer>
                  <div>
                    <svg
                      width="40px"
                      height="40px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                        fill="var(--text-color)"
                      />
                    </svg>
                  </div>
                  <NumberText>
                    You'll receive a 10% voucher, 100 VIP points, and 5%
                    commision on every purchase made by your referral for a
                    whole year.
                  </NumberText>
                </NumberContainer>
              </Wrapper2>
            </ContainerWrapper>
          </HowContainer>
          <ContainerWrapper>
            <Wrapper3>
              <IconWrapper>
                <svg
                  fill="var(--text-color)"
                  height="50px"
                  width="50px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 424.05 424.05"
                  style={{ marginTop: "20px" }}
                >
                  <g>
                    <path
                      d="M384.998,216.773h-27.4c-8.192-17.55-20.955-33.479-37.333-46.57c-0.332-19.494-1.73-34.292-4.258-45.135
		c-3.916-16.8-11.332-25.318-22.044-25.318c-11.688,0-36.557,14.617-55.683,36.111c-8.61-1.206-17.376-1.815-26.132-1.815
		c-41.302,0-80.216,13.259-109.573,37.335c-26.628,21.838-42.603,50.217-45.808,80.879c-11.952-3.951-20.603-15.22-20.603-28.478
		c0-5.523-4.478-10-10-10s-10,4.477-10,10c0,24.278,17.4,44.561,40.382,49.055c2.684,31.874,19.432,61.902,47.066,84.162
		l-8.59,48.716c-0.461,2.612,0.135,5.299,1.656,7.472c1.521,2.172,3.843,3.652,6.455,4.112l37.423,6.599
		c2.611,0.458,5.299-0.136,7.472-1.657s3.652-3.843,4.112-6.455l5.37-30.452c17.417,5.423,35.741,8.166,54.638,8.166
		c18.896,0,37.22-2.742,54.637-8.166l5.37,30.452c0.46,2.612,1.939,4.934,4.112,6.455c1.692,1.185,3.697,1.809,5.735,1.809
		c0.578,0,1.159-0.05,1.736-0.152l37.423-6.599c5.439-0.959,9.07-6.146,8.111-11.584L320.684,357
		c16.18-13.031,28.791-28.827,36.913-46.227h27.4c12.621,0,22.889-10.268,22.889-22.888v-48.224
		C407.886,227.041,397.619,216.773,384.998,216.773z M384.998,290.773h-33.975c-4.103,0-7.788,2.505-9.298,6.319
		c-7.2,18.187-20.298,34.661-37.879,47.641c-3.054,2.254-4.567,6.043-3.908,9.781l7.903,44.825l-17.728,3.126l-5.675-32.183
		c-0.52-2.948-2.333-5.509-4.941-6.978c-2.61-1.469-5.74-1.69-8.529-0.607c-18.441,7.167-38.231,10.802-58.82,10.802
		c-20.59,0-40.38-3.634-58.821-10.802c-2.79-1.083-5.92-0.862-8.529,0.607c-2.608,1.469-4.422,4.029-4.941,6.978l-5.675,32.183
		l-17.727-3.126l7.903-44.825c0.659-3.738-0.854-7.527-3.908-9.781c-28.149-20.782-44.294-50.291-44.294-80.96
		c0-60.504,61.006-109.728,135.992-109.728c9.555,0,19.116,0.811,28.417,2.411c3.554,0.611,7.162-0.738,9.442-3.532
		c15.791-19.345,36.247-31.284,43-32.982c1.735,2.493,6.99,13.476,7.317,55.296c0.024,3.13,1.514,6.068,4.022,7.939
		c17.322,12.915,30.247,29.263,37.378,47.276c1.51,3.814,5.195,6.319,9.298,6.319h33.975c1.593,0,2.889,1.295,2.889,2.888v4.505
		h-0.861c-5.522,0-10,4.477-10,10s4.478,10,10,10h0.861v23.718C387.886,289.478,386.59,290.773,384.998,290.773z"
                    />
                    <path
                      d="M212.148,107c29.5,0,53.5-24,53.5-53.5s-24-53.5-53.5-53.5s-53.5,24-53.5,53.5S182.648,107,212.148,107z M212.148,20
		c18.472,0,33.5,15.028,33.5,33.5S230.62,87,212.148,87s-33.5-15.028-33.5-33.5S193.676,20,212.148,20z"
                    />
                    <path
                      d="M251.267,177.643c-12.492-3.968-25.653-5.98-39.119-5.98c-13.465,0-26.627,2.012-39.12,5.98
		c-5.264,1.672-8.176,7.294-6.504,12.558c1.673,5.264,7.294,8.176,12.559,6.503c10.532-3.345,21.657-5.042,33.065-5.042
		s22.533,1.696,33.064,5.042c1.007,0.32,2.026,0.472,3.03,0.472c4.239,0,8.176-2.719,9.527-6.976
		C259.443,184.938,256.531,179.315,251.267,177.643z"
                    />
                  </g>
                </svg>
                <Title>Invite a Friend</Title>
              </IconWrapper>
              <RefferalContainer>
                {userProfile.referrals_made.map((referral, index) =>
                  !referral.has_completed_first_order ? (
                    <BenefitContainer key={index}>
                      <ReferralName $isActive={false}>
                        {referral.referred_full_name}
                      </ReferralName>
                      <RefferalNotFulfilled>
                        conditions for benefits not fulfilled yet
                      </RefferalNotFulfilled>
                    </BenefitContainer>
                  ) : (
                    <BenefitContainer key={index}>
                      <ReferralName $isActive={true}>
                        {referral.referred_full_name}
                      </ReferralName>
                      <RefferalFulfilledDiv>
                        <span>200 VIP points</span>
                        <RefferalFulfilled>
                          credited{" "}
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                              fill="var(--accent-color)"
                            />
                          </svg>
                        </RefferalFulfilled>
                      </RefferalFulfilledDiv>
                    </BenefitContainer>
                  )
                )}
              </RefferalContainer>
              {/*               
              <SeeAllContainer>
                <SeeAll>See your referrals</SeeAll>
              </SeeAllContainer> */}
              <div>
                <TermsTitle>* Terms and conditions</TermsTitle>
                <TermsText>
                  Once your referral makes their first order without full
                  returns, you'll get VIP points and your voucher after the
                  return period and processing time (approximately 4 to 6
                  weeks). To receive commission, you need Silver Status and two
                  active referrals, meaning two people who have placed an order
                  within one year of being referred without full returns. Read
                  our detailed terms and conditions for more information.
                </TermsText>
              </div>
            </Wrapper3>
          </ContainerWrapper>
        </>
      ) : (
        <PageLoading />
      )}
    </Container>
  );
};

export default InviteFriend;
