import styled from "styled-components";
import HeroBlogs from "../../components/pages/HeroBlogs";
import RecentBlogs from "../../components/pages/RecentBlogs";
import { useEffect, useState } from "react";
import APIService from "../../services/APIService";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
const Container = styled.main``;
const BlogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MaxWidthWrapper = styled.div`
  margin: var(--spacing-xxl) 0;
  width: calc(100% - 24px);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const BlogBigTitle = styled.h3`
  font-family: "Larken-Light";
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: var(--font-size-xxlarge);
  color: var(--text-color);
  padding: var(--spacing-md) 0;
`;

const BlogGrid = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: var(--spacing-xxl);
  }
`;
const BlogItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-md);
  cursor: pointer;
`;
const BlogPicture = styled.picture`
  width: 100%;
  height: 100%;
`;
const BlogImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
`;
const BlogTitle = styled.h3`
  font-size: var(--font-size-large);
  text-transform: capitalize;
  margin: 12px 0;
  font-family: "Satoshi";
  width: 90%;
`;
const BlogTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BlogSubtitle = styled.h6`
  font-size: var(--font-size-small);
  color: var(--primary-color);
  font-weight: 400;
`;
const BlogText = styled.p`
  font-size: var(--font-size-base);
  color: var(--p-color);
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--spacing-lg) 0;
  padding: var(--spacing-lg) 0;
  border-top: 1px solid #e4e7ec;
`;
const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  font-weight: 300;
  color: var(--p-color);
  gap: var(--spacing-xxs);
  cursor: pointer;
  &:disabled {
    visibility: none;
  }
`;
const PageNumber = styled.span`
  margin: 0 5px;
  cursor: pointer;
  opacity: 1;
  padding: 8px 12px;

  font-size: 14px;
  &.active {
    color: var(--primary-color);
    border-radius: 50%;
    background-color: rgba(86, 147, 191, 0.14);
  }
`;

const Blogs = () => {
  const { goToBlog } = useNavigation();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const loadBlogs = async () => {
    try {
      const response = await APIService.GetAllBlogs();
      setBlogs(response);
      console.log("blogs", blogs);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    loadBlogs();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 9;
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(blogsPerPage);

  useEffect(() => {
    const indexOfLastBlog = currentPage * blogsPerPage;

    setLastIndex(indexOfLastBlog);
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    setFirstIndex(indexOfFirstBlog);
  }, [currentPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(blogs.length / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (number) => {
    setCurrentPage(number);
  };
  const currentBlogs = blogs.slice(firstIndex, lastIndex);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // Dodajte +1 jer getMonth() vraća mesec od 0 do 11
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Koristite `padStart(2, '0')` da biste osigurali da su dan i mesec uvek prikazani sa dve cifre
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };

  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Container>
      <HeroBlogs />
      <RecentBlogs />

      <BlogContainer>
        <MaxWidthWrapper>
          <BlogBigTitle>All Blog Posts</BlogBigTitle>
          <BlogGrid>
            {blogs.map((post, index) => (
              <BlogItem
                key={post.id}
                onClick={() => {
                  navigate(goToBlog(post.slug));
                }}
              >
                <BlogPicture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require(`../../assets/images/blogs/blog${post.id}.webp`)}
                  />
                  <BlogImage
                    src={require(`../../assets/images/blogs/blog${post.id}.webp`)}
                    alt={post.title}
                    loading="lazy"
                  />
                </BlogPicture>
                <div>
                  <BlogSubtitle>{formatDate(post.published_date)}</BlogSubtitle>
                  <BlogTitleContainer>
                    <BlogTitle>{post.title}</BlogTitle>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 17L17 7M17 7H7M17 7V17"
                        stroke="var(--text-color)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </BlogTitleContainer>
                  <BlogText>
                    {stripHTML(post.paragraphs[0]).split(" ").length > 15
                      ? `${stripHTML(post.paragraphs[0])
                          .split(" ")
                          .slice(0, 15)
                          .join(" ")}...`
                      : stripHTML(post.paragraphs[0])}
                  </BlogText>
                  {/* <BlogText
                    dangerouslySetInnerHTML={{ __html: post.paragraphs[0] }}
                  /> */}
                </div>
              </BlogItem>
            ))}
          </BlogGrid>
          <PaginationContainer>
            <PaginationButton
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8332 10.0001H4.1665M4.1665 10.0001L9.99984 15.8334M4.1665 10.0001L9.99984 4.16675"
                  stroke="#667085"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Previous
            </PaginationButton>
            <div>
              {pageNumbers.map((number) => (
                <PageNumber
                  key={number}
                  className={currentPage === number ? "active" : ""}
                  onClick={() => handleClick(number)}
                >
                  {number}
                </PageNumber>
              ))}
            </div>
            <PaginationButton
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, pageNumbers.length))
              }
              disabled={currentPage === pageNumbers.length}
            >
              Next
              <svg
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16699 10.0001H15.8337M15.8337 10.0001L10.0003 4.16675M15.8337 10.0001L10.0003 15.8334"
                  stroke="#667085"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </PaginationButton>
          </PaginationContainer>
        </MaxWidthWrapper>
      </BlogContainer>
    </Container>
  );
};

export default Blogs;
