import React from "react";
import styled from "styled-components";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: none;
  height: var(--navbar-mini);
  position: fixed;
  background-color: var(--background-color-hover);
  width: 100%;

  justify-content: center;
  z-index: var(--zindex-top);
  @media (min-width: 768px) {
    display: flex;
  }
`;
const Wrapper = styled.div`
  max-width: var(--max-width-container);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperDiv = styled.div`
  flex: 1;
  display: flex;
`;
const LogoImg = styled.div`
  color: var(--text-color);
  font-size: var(--font-size-xxlarge);
`;
const MiniHeader = () => {
  const { goToHome } = useNavigation();
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Wrapper>
          <WrapperDiv
            style={{ justifyContent: "left", paddingLeft: "30px" }}
            onClick={() => navigate(-1)}
          >
            <svg
              width="40px"
              height="40px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 12H20M4 12L8 8M4 12L8 16"
                stroke="var(--text-color)"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </WrapperDiv>

          <WrapperDiv
            onClick={() => navigate(goToHome())}
            style={{ justifyContent: "center" }}
          >
            <LogoImg>LOGO</LogoImg>
          </WrapperDiv>
          <WrapperDiv
            style={{ justifyContent: "right", paddingRight: "30px" }}
          ></WrapperDiv>
        </Wrapper>
      </Container>
      <div style={{ paddingBottom: "50px" }} />
    </>
  );
};

export default MiniHeader;
