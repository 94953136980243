import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AuthUserContext } from "../context/AuthUserContext";
import { formatDistanceToNow } from "date-fns";

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  position: relative;
`;
const Time = styled.div`
  display: none;
  background-color: var(--background-color-hover);
  font-size: var(--font-size-small);
  position: absolute;
  top: -20px;
  left: -40px;
  font-weight: 400;
  color: var(--text-color);
  padding: 6px 4px;
  border-radius: 8px;
  z-index: 1;
`;
const OwnMessage = styled.div`
  position: relative;
  background-color: var(--primary-color);
  color: #fff;
  align-self: flex-end;
  margin: 2px;
  padding: 17px 20px;
  border-radius: 8px;

  max-width: calc(90% - 44px);
  word-wrap: break-word; // Allows long words to be broken and wrap onto the next line
  overflow-wrap: break-word;
  &:hover ${Time}, &:active ${Time} {
    display: block;
  }
`;
const SeenMsg = styled(OwnMessage)`
  padding: 0;
  background-color: transparent;
  color: var(--text-color);
  font-size: var(--font-size-small);
  text-transform: capitalize;
`;

const OtherContainer = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  max-width: 100%;
`;
const OtherMessage = styled.div`
  position: relative;
  background-color: var(--background-color-hover);
  margin: 2px;
  padding: 17px 20px;
  border-radius: 8px;

  max-width: calc(90% - 50px);
  margin-left: ${(props) => (props.$showavatar ? "5px" : "50px")};
  word-wrap: break-word; // Allows long words to be broken and wrap onto the next line
  overflow-wrap: break-word;
  &::before {
    content: ${(props) => (props.$showavatar ? '""' : "none")};
    position: absolute;
    bottom: 0px;
    left: -4px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 0px 20px 8px; // Adjust this to suit the desired arrow size
    border-color: transparent transparent var(--background-color-hover); // Use the same background color for the chat bubble arrow
  }
  &:hover ${Time}, &:active ${Time} {
    display: block;
  }
`;
const MessageContainer = styled.div`
  width: 100%;
  max-width: 100%;
`;
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: var(--info-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ChatImage = styled.img`
  max-width: 100%;
  max-height: 200px;

  transition: transform 0.25s ease; /* Dodajte blagu animaciju za efekat zumiranja */
  cursor: zoom-in;
  &:hover {
    transform: scale(
      1.05
    ); /* Slika se blago povećava prilikom prelaska mišem */
  }
`;
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Poluprozirna crna pozadina */
  cursor: zoom-out; /* Promeni kursor da indikuje mogućnost zatvaranja */
`;

const ModalImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  transition: transform 0.25s ease; /* Dodajte animaciju za efekat glatkog pojavljivanja */
  &:hover {
    transform: scale(
      1.4
    ); /* Lagano povećajte sliku kada se pređe mišem preko nje */
  }
`;

const ChatHistory = ({ messages, scrollToBottom }) => {
  const { userProfile } = useContext(AuthUserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [modalImageUrl, setModalImageUrl] = useState("");

  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const closeModal = () => {
    setModalImageUrl("");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom(); // Poziva scrollToBottom nakon što se učitavanje završi
      setIsLoading(false);
    }, 700);

    // Čišćenje tajmera prilikom demontaže komponente
    return () => clearTimeout(timer);
  }, []); // Prazan niz zavisnosti kako bi se efekat pokrenuo samo pri montiranju

  return (
    <Container>
      <Wrapper>
        {isLoading && (
          <div className="loader-container">
            <div className="loader-wrapper">
              <div className="loader" />
            </div>
          </div>
        )}
        <OtherContainer>
          <OtherMessage $showavatar={false}>
            <Time>12:32 AM</Time>Hi 👋! I'm here to answer your questions, and
            our team is available if you need extra help.
          </OtherMessage>
        </OtherContainer>
        <OtherContainer>
          <Avatar>M</Avatar>
          <OtherMessage $showavatar={true}>
            <Time>12:33AM</Time>How can I assist you?
          </OtherMessage>
        </OtherContainer>

        {messages?.map((msg, msgIndex) => {
          const isOwnMessage = msg.user === userProfile.id;
          const isLastMessage = msgIndex === messages.length - 1; // Da li je poruka poslednja u nizu
          const isLastOtherUserMessage = isLastMessage && isOwnMessage;
          const showAvatar =
            !isOwnMessage &&
            (isLastMessage ||
              (messages[msgIndex + 1] &&
                messages[msgIndex + 1].user === userProfile.id));

          const time = new Date(msg.timestamp).toLocaleTimeString([], {
            timeStyle: "short",
          }); // Prikazuje samo vreme
          const sentTimeAgo = isLastOtherUserMessage
            ? formatDistanceToNow(new Date(msg.timestamp), { addSuffix: true })
            : "";

          return isOwnMessage ? (
            <React.Fragment key={msgIndex}>
              <OwnMessage>
                <Time>{time}</Time>
                {msg.image_url && msg.image_url.length > 0 && (
                  <ChatImage
                    src={msg.image_url}
                    alt="Uploaded"
                    onClick={() => handleImageClick(msg.image_url)}
                  />
                )}
                <MessageContainer>{msg.message}</MessageContainer>
              </OwnMessage>
              {isLastOtherUserMessage && (
                <SeenMsg>
                  {msg.seen
                    ? `${sentTimeAgo}. Seen`
                    : `${sentTimeAgo}. Not seen yet `}
                </SeenMsg>
              )}
            </React.Fragment>
          ) : (
            <OtherContainer key={msgIndex}>
              {showAvatar && <Avatar>M</Avatar>}
              <OtherMessage $showavatar={showAvatar}>
                <Time>{time}</Time>{" "}
                <MessageContainer>
                  {msg.image_url && msg.image_url.length > 0 && (
                    <ChatImage
                      src={msg.image_url}
                      alt="Uploaded"
                      onClick={() => handleImageClick(msg.image_url)}
                    />
                  )}
                  {msg.message}
                </MessageContainer>
              </OtherMessage>
            </OtherContainer>
          );
        })}
      </Wrapper>
      {modalImageUrl && (
        <ModalBackdrop onClick={closeModal}>
          <ModalImage
            src={modalImageUrl}
            alt="Zoomed"
            onClick={(e) =>
              e.stopPropagation()
            } /* Sprečava propagaciju klikova na pozadinsku div komponentu */
          />
        </ModalBackdrop>
      )}
    </Container>
  );
};

export default ChatHistory;
