import Lottie from "react-lottie";
import animationData from "../../assets/images/conffeti.json";
import styled, { css } from "styled-components";
import { useContext } from "react";
import { ProductContext } from "../../context/ProductContext";

const Container = styled.div`
  position: absolute;
  background-color: transparent; // Promenjeno u transparent da se ne vidi pozadina
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
  z-index: 1;
`;

const ConffeteBox = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container>
      <Lottie options={defaultOptions} />
    </Container>
  );
};

export default ConffeteBox;
