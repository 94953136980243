import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ProductContext } from "../../context/ProductContext";
import { useNavigate } from "react-router-dom";
import APIService from "../../services/APIService";
import ProductCardBG from "../../components/product/ProductCardBG";
import { PRODUCT_CHUNK } from "../../utils/global_const";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: calc(var(--navbar-height-desktop));
  padding-top: calc(var(--navbar-height) + var(--navbar-mini));
`;
const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const ProductGrid = styled.div`
  display: grid;
  width: calc(100% - 24px);
  grid-template-columns: repeat(1, 1fr);
  gap: 4px;

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1025px) {
    width: var(--max-width-container);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;
  }
`;

const FlexDiv = styled.div`
  padding: var(--spacing-md) 0;
  display: flex;
  width: 94%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row-reverse;
  @media (min-width: 768px) {
    max-width: 90%;
    flex-direction: row;
  }
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const BarWrapper = styled.div`
  width: 100%;
  max-width: var(--max-width-container);

  position: relative;
  background-color: var(--background-color);
  height: 8px;
  border-radius: var(--border-radius-large);
  margin-top: var(--spacing-md);
  background-color: var(--background-color-hover);
`;
const BarScore = styled.div`
  position: absolute; // Make BarScore absolute to fill the Container from start
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: var(--primary-color);
  width: ${(props) => props.percentage || 0}%; // Use the percentage prop
  border-radius: var(--border-radius-large);
  transition: width 0.3s;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  width: 200px;
`;
const VisibleProductText = styled.div`
  text-align: center;
  font-size: var(--font-size-small);
`;
const MoreButton = styled.button`
  width: 100%;
`;
const SearchTitle = styled.div`
  align-self: flex-start;
`;
const SearchMain = () => {
  const { searchedProducts, setSearchedProducts } = useContext(ProductContext);
  const [inputText, setInputText] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const searchTerm = searchParams.get("q");

  const searchProductsFunc = async (searchTerm) => {
    if (!searchParams) {
      return;
    }
    console.log("search temr", searchTerm);
    try {
      const searchData = await APIService.SearchProducts(searchTerm);
      setSearchedProducts(searchData);
    } catch (error) {
      console.error("Greška pri pretrazi:", error);
      throw error; // Propagira grešku kako bi se mogla obraditi na višem nivou
    }
  };
  useEffect(() => {
    if (!searchedProducts.length || !searchedProducts) {
      searchProductsFunc(searchTerm);
    }

    setInputText(searchTerm);
  }, []);

  const [visibleProducts, setVisibleProducts] = useState(PRODUCT_CHUNK); // Prikazuje prvih 10 proizvoda
  const percentage = (visibleProducts / searchedProducts.length) * 100;
  const loadMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => {
      const potentialNextTotal = prevVisibleProducts + PRODUCT_CHUNK;
      if (potentialNextTotal > searchedProducts.length) {
        return searchedProducts.length; // Ako da, postavi na ukupan broj proizvoda (dodaje samo preostale)
      } else {
        return potentialNextTotal; // Ako ne, dodaje celu veličinu čanka
      }
    });
  };

  return (
    <Container>
      <ProductWrapper>
        <FlexDiv>
          <div>
            Search result for <strong>"{searchTerm}"</strong>
          </div>
        </FlexDiv>
        <ProductGrid>
          {searchedProducts.slice(0, visibleProducts).map((product, index) => (
            <ProductCardBG key={index} product={product} />
          ))}
        </ProductGrid>
        <ButtonWrapper>
          <BarWrapper>
            <BarScore percentage={percentage} />
          </BarWrapper>
          <VisibleProductText>
            prikazano {visibleProducts} od {searchedProducts.length} proizvoda
          </VisibleProductText>
          {visibleProducts < searchedProducts.length && ( // Prikazuje dugme "More" samo ako ima još proizvoda za učitavanje
            <MoreButton onClick={loadMoreProducts}>Load More</MoreButton>
          )}
        </ButtonWrapper>
      </ProductWrapper>
    </Container>
  );
};

export default SearchMain;
