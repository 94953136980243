import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../../layouts/header/PrimaryHeader";
import ProfileMain from "../../layouts/main/profile/Profile";
import FooterMain from "../../layouts/footer/FooterMain";
import { AuthUserContext } from "../../context/AuthUserContext";
import styled from "styled-components";
import SimpleContext from "../../context/SimpleContext";

const Desktop = styled.div`
  display: none;
  @media (min-width: 767px) {
    display: block;
  }
`;
const SEOConfig = {
  "en-US": {
    lang: "en-US",
    title: "Your Profile | SnusCo",
    description:
      "Manage your account details, view your order history, and update your preferences.",
    og: {
      title: "Your Profile | SnusCo",
      description:
        "Access and manage your personal information, orders, and settings.",
    },
    noIndex: true, // Sprečava indeksiranje ove stranice
  },
  de: {
    lang: "de",
    title: "Ihr Profil | SnusCo",
    description:
      "Verwalten Sie Ihre Kontodaten, sehen Sie Ihre Bestellhistorie ein und aktualisieren Sie Ihre Einstellungen.",
    og: {
      title: "Ihr Profil | SnusCo",
      description:
        "Greifen Sie auf Ihre persönlichen Informationen, Bestellungen und Einstellungen zu und verwalten Sie diese.",
    },
    noIndex: true,
  },
  it: {
    lang: "it",
    title: "Il Tuo Profilo | SnusCo",
    description:
      "Gestisci i dettagli del tuo account, visualizza la cronologia degli ordini e aggiorna le tue preferenze.",
    og: {
      title: "Il Tuo Profilo | SnusCo",
      description:
        "Accedi e gestisci le tue informazioni personali, ordini e impostazioni.",
    },
    noIndex: true,
  },
  fr: {
    lang: "fr",
    title: "Votre Profil | SnusCo",
    description:
      "Gérez les détails de votre compte, consultez votre historique de commandes et mettez à jour vos préférences.",
    og: {
      title: "Votre Profil | SnusCo",
      description:
        "Accédez à vos informations personnelles, commandes et paramètres et gérez-les.",
    },
    noIndex: true,
  },
  hu: {
    lang: "hu",
    title: "Az Ön Profilja | SnusCo",
    description:
      "Kezelje fiókjának adatait, tekintse meg rendelési előzményeit, és frissítse beállításait.",
    og: {
      title: "Az Ön Profilja | SnusCo",
      description:
        "Hozzáférhet személyes adataihoz, rendeléseihez és beállításaihoz, és kezelheti azokat.",
    },
    noIndex: true,
  },
  tr: {
    lang: "tr",
    title: "Profiliniz | SnusCo",
    description:
      "Hesap bilgilerinizi yönetin, sipariş geçmişinizi görüntüleyin ve tercihlerinizi güncelleyin.",
    og: {
      title: "Profiliniz | SnusCo",
      description:
        "Kişisel bilgilerinize, siparişlerinize ve ayarlarınıza erişin ve yönetin.",
    },
    noIndex: true,
  },
  "sr-Latn": {
    lang: "sr-latn",
    title: "Vaš Profil | SnusCo",
    description:
      "Upravljajte podacima o nalogu, pregledajte istoriju porudžbina i ažurirajte postavke.",
    og: {
      title: "Vaš Profil | SnusCo",
      description:
        "Pristupite i upravljajte svojim ličnim podacima, porudžbinama i postavkama.",
    },
    noIndex: true,
  },
};

const Account = () => {
  const { t, i18n } = useTranslation();

  const { fetchUserData, authTokens, userProfile, logoutUser } =
    useContext(AuthUserContext);

  const { openLoginPopup } = useContext(SimpleContext);

  useEffect(() => {
    if (!authTokens) {
      logoutUser();
      openLoginPopup();
      return;
    } else if (!userProfile) {
      fetchUserData();
    }
  }, []);

  const [seo, setSeo] = useState(SEOConfig["en-US"]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig["en-US"]);
  }, [i18n.language]);

  return (
    <div>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="robots" content="noindex, nofollow" />{" "}
        {/* Sprečava indeksiranje */}
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.snusco.com/profile" />
        <meta
          property="og:image"
          content="https://www.snusco.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Desktop>
        <PrimaryHeader />
      </Desktop>
      <ProfileMain />
      <FooterMain />
    </div>
  );
};

export default Account;
