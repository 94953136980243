import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";

const SliderContainer = styled.div`
  padding: 30px 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  @media (min-width: 767px) {
    display: none;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease-out;
  margin-left: 15vw;
  transform: translateX(
    calc(-${(props) => props.currentindex} * (65vw + 30px))
  );
`;

//LEVEL CARDS
const CardContainerX = styled.div`
  flex: 0 0 calc(65vw + 25px); /* Širina kartice + razmak */
`;
const CardContainer = styled.div`
  display: flex;
  gap: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SVGIcon = styled.svg`
  width: 60px;
  height: 60px;
  color: var(--background-color);
`;
const SmallText = styled.h6`
  display: block;
  font-size: var(--font-size-base);
  text-align: center;
`;
const BenefitText = styled(SmallText)`
  color: var(--accent-color);
  font-size: var(--font-size-small);
`;
const BenefitDesc = styled(BenefitText)`
  color: var(--text-color);
  font-weight: 400;
  letter-spacing: 1.2px;
`;

const StepsCardsSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [items] = useState([
    "Item 1",
    "Item 2",
    "Item 3",
    // Ostali jedinstveni elementi...
  ]);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, getMaxIndex(items.length))
      ),
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getMaxIndex = (itemsLength) => {
    const scrollStep = 1; // Broj elemenata za skrolanje
    return Math.max(itemsLength - scrollStep, 0);
  };

  return (
    <SliderContainer {...handlers}>
      <SliderWrapper currentindex={currentIndex}>
        <CardContainerX>
          <CardContainer>
            <SVGIcon
              width="40px"
              height="40px"
              viewBox="0 0 24 24"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="peopleIconTitle"
              stroke="var(--text-color)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="var(--text-color)"
            >
              {" "}
              <title id="peopleIconTitle">People</title>{" "}
              <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />{" "}
              <path
                strokeLinecap="round"
                d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684"
              />{" "}
            </SVGIcon>
            <div>
              <SmallText>Invite a friend</SmallText>
              <BenefitText>100+ points</BenefitText>
            </div>
            <BenefitDesc>
              We'll reward you with 100 VIP points per invitation. In addition
              you’ll receive a 10% discount voucher and 5% commission.
            </BenefitDesc>
          </CardContainer>
        </CardContainerX>
        <CardContainerX>
          <CardContainer>
            <SVGIcon
              fill="none"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <path
                clipRule="evenodd"
                d="M27.291 29.9972C12.0284 33.4425 6.58214 19.2802 8.30657 6C29.0131 6 33.7025 18.7602 29.8003 28.299C30.8281 30.0825 31.6208 31.7932 32 33.1735L29.1044 34C28.819 32.9609 28.1771 31.552 27.291 29.9972ZM27.7005 25.0165C27.5392 24.7868 27.3757 24.5584 27.2105 24.3321C24.9961 21.298 22.2474 18.3064 19.7455 16.9153L18.3056 19.6062C20.2139 20.6673 22.6438 23.2101 24.7979 26.1617C25.0551 26.514 25.3051 26.8678 25.5469 27.2207C23.1223 27.6289 21.1361 27.4778 19.5244 26.9893C17.3429 26.3282 15.61 24.9789 14.2682 23.0741C11.8963 19.7071 10.7825 14.6124 11.0434 9.14831C19.4726 9.67695 24.0312 12.6511 26.1948 15.8595C28.0071 18.5471 28.4459 21.8413 27.7005 25.0165Z"
                fill="var(--text-color)"
                fillRule="evenodd"
                wz_dt_ref="true"
              ></path>{" "}
            </SVGIcon>

            <div>
              <SmallText>Shop</SmallText>
              <BenefitText>400Ft = 1 point</BenefitText>
            </div>
            <BenefitDesc>
              This is the best bit! For every 400Ft you spend, we´ll add 1 VIP
              point to your account. We´ll even offer double points from time to
              time, so keep your eyes peeled!
            </BenefitDesc>
          </CardContainer>
        </CardContainerX>
        <CardContainerX>
          <CardContainer>
            <SVGIcon
              fill="var(--text-color)"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title />

              <g data-name="Layer 2" id="Layer_2">
                <path d="M29,11H3a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V12A1,1,0,0,0,29,11Zm-2.79,2L16,20.23,5.79,13ZM4,28V14.18l11.42,8.09a1,1,0,0,0,1.16,0L28,14.18V28Z" />
              </g>
            </SVGIcon>
            <div>
              <SmallText>Sign up to newsletter</SmallText>
              <BenefitText>+100 points</BenefitText>
            </div>
            <BenefitDesc>
              Get daily information about new campaigns, sales and new designers
              and get 100 extra VIP points for your first subscription.
            </BenefitDesc>
          </CardContainer>
        </CardContainerX>
      </SliderWrapper>
    </SliderContainer>
  );
};

export default StepsCardsSlider;
