import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import HearthA from "../../assets/icons/search.png";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { ProductContext } from "../../context/ProductContext";
const CartItem = styled.div`
  box-shadow: var(--shadow-default);
  background-color: var(--background-color-hover);
  width: 100%;
  height: 100px;

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 96%;
  height: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Picture = styled.picture`
  display: flex;
  height: 100%;
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;
const InfoDiv = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
`;
const FlexDiv = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProductName = styled.h3`
  font-size: var(--font-size-small);
  font-weight: 600;
`;
const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;
const ProductDiscountedPrice = styled(ProductName)`
  display: inline-block;
  font-weight: 700;
  color: var(--error-color);
  margin-right: 4px;
`;
const ProductPrice = styled(ProductName)`
  display: inline-block;
  text-decoration: line-through;
  font-weight: 700;
`;
const AddContainer = styled.div`
  background-color: var(--background-color-hover-alt);
  border-radius: var(--border-radius-large);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxs);
`;
const Button = styled.button`
  background-color: var(--background-color);
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-large);
  &:hover {
    background-color: var(--background-color);
  }
`;

const Icon = styled.div``;
const Input = styled.input`
  width: calc(var(--font-size-small) * 3 - var(--spacing-sm)); // Širina inputa
  height: 30px; // Visina inputa
  padding: 0 var(--spacing-sm);
  margin: 0 var(--spacing-sm);
  background-color: var(--background-color); // Boja pozadine
  text-align: center; // Centriranje teksta
  font-size: var(--font-size-small);
  border-radius: var(--border-radius-small);
  font-weight: 400;
  // Uklanjanje strelica za input tipa number
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Za Firefox
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
const Price = styled.span`
  font-size: var(--font-size-base);
  font-weight: 600;
  margin-right: 4px;
`;
const CartProduct = ({ item }) => {
  const { currencyTag } = useContext(ProductContext);
  const [inputValue, setInputValue] = useState(item.quantity);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [newValue, setNewValue] = useState("");
  const dispatch = useDispatch();

  const deleteCartItem = (id) => {
    dispatch(cartActions.removeFromCart(id));
  };
  const handleQuantityChange = () => {
    const newQuantity = Number(newValue);
    if (!isNaN(newQuantity) && newQuantity >= 1 && newQuantity <= 999) {
      dispatch(
        cartActions.updateCart({
          product: item.product,
          quantity: newQuantity,
        })
      );
      setNewValue("");
    } else {
      console.log("Quantity is out of valid range. No action taken.");
      return;
    }
  };

  const handleAddOne = () => {
    const newQuantity = inputValue + 1;

    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: newQuantity,
      })
    );
  };
  const handleRemoveOne = () => {
    const newQuantity = inputValue - 1;
    if (newQuantity === 0) {
      deleteCartItem(item.product.id);
    }
    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: newQuantity,
      })
    );
  };
  useEffect(() => {
    setInputValue(item.quantity);
  }, [item.quantity]);

  const handleChange = (event) => {
    const value = Math.max(1, Math.min(999, Number(event.target.value)));
    setInputValue(value.toString());
    setNewValue(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setInputValue(newValue);
  };

  const handleBlur = () => {
    setInputValue(item.quantity);
    const timer = setTimeout(() => {
      setIsFocused(false);
      setNewValue("");
    }, 80);
    return () => clearTimeout(timer);
  };
  return (
    <CartItem>
      <Wrapper>
        <Picture>
          <Image
            src="https://snus-s3.s3.eu-north-1.amazonaws.com/800x800/velo-mighty-peppermint-max.webp"
            alt="Velo Mighty Peppermint Max"
            srcSet="
    https://snus-s3.s3.eu-north-1.amazonaws.com/800x800/velo-mighty-peppermint-max.webp 320w,
    https://snus-s3.s3.eu-north-1.amazonaws.com/800x800/velo-mighty-peppermint-max.webp 480w,
    https://snus-s3.s3.eu-north-1.amazonaws.com/800x800/velo-mighty-peppermint-max.webp 800w"
            sizes="(max-width: 320px) 280px,
         (max-width: 480px) 440px,
         800px"
          />
        </Picture>
        <InfoDiv>
          <FlexDiv>
            <ProductName>
              {item.product.category_name} {item.product.name}
            </ProductName>
            <svg
              focusable="false"
              role="presentation"
              viewBox="0 0 64 64"
              className="svg-icon"
              style={{
                width: "var(--font-size-xlarge) ",
                height: "var(--font-size-xlarge)",
              }}
              onClick={() => deleteCartItem(item.product.id)}
            >
              <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
            </svg>
          </FlexDiv>
          <FlexDiv>
            <AddContainer>
              <Button onClick={() => handleRemoveOne()}>
                <svg
                  viewBox="0 0 160 161"
                  fill="none"
                  className="svg-icon"
                  style={{ width: "24px", height: "24px" }}
                >
                  <path
                    d="M79.516 159.561c-43.324 0-78.57-35.467-78.57-79.061 0-43.594 35.246-79.061 78.57-79.061 43.323 0 78.57 35.467 78.57 79.061 0 43.594-35.247 79.061-78.57 79.061zm0-148.661c-38.14 0-69.168 31.222-69.168 69.6 0 38.378 31.028 69.6 69.168 69.6 38.14 0 69.168-31.222 69.168-69.6 0-38.378-31.028-69.6-69.168-69.6z"
                    fill="var(--text-color)"
                  ></path>
                  <path
                    d="M108.69 74.379H49.407c-3.359 0-6.083 2.74-6.083 6.12 0 3.382 2.724 6.122 6.084 6.122h59.282c3.36 0 6.084-2.74 6.084-6.121 0-3.38-2.724-6.121-6.084-6.121z"
                    fill="var(--text-color)"
                  ></path>
                </svg>
              </Button>
              <div>
                <Input
                  type="number"
                  max="999"
                  min="0"
                  value={inputValue}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  ref={inputRef}
                />
              </div>
              {!isFocused ? (
                <Button onClick={() => handleAddOne()}>
                  <svg
                    viewBox="0 0 161 161"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <path
                      d="M119.668 74.283H85.76V40.375a6.217 6.217 0 00-12.434 0v33.908H39.418a6.217 6.217 0 000 12.434h33.908v33.908a6.217 6.217 0 1012.434 0V86.717h33.908a6.217 6.217 0 100-12.434z"
                      fill="var(--text-color)"
                    ></path>
                    <path
                      d="M80.013 159.561C36.419 159.561.952 124.094.952 80.5c0-43.594 35.467-79.061 79.06-79.061 43.595 0 79.062 35.467 79.062 79.061 0 43.594-35.467 79.061-79.061 79.061zm0-148.661c-38.378 0-69.6 31.222-69.6 69.6 0 38.378 31.222 69.6 69.6 69.6 38.378 0 69.6-31.222 69.6-69.6 0-38.378-31.222-69.6-69.6-69.6z"
                      fill="var(--text-color)"
                    ></path>
                  </svg>
                </Button>
              ) : (
                <Button onClick={() => handleQuantityChange()}>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 50 50"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      console.log("hello world");
                    }}
                    style={{
                      background: "var(--primary-color)",
                      borderRadius: "12px",
                    }}
                  >
                    <path
                      d="M5 25 L20 40 L45 5"
                      stroke="var(--background-color)"
                      strokeWidth="4"
                      fill="none"
                    />
                  </svg>
                </Button>
              )}
            </AddContainer>
            <PriceContainer>
              <div>
                <Price>
                  {currencyTag}
                  {(item.product.discount_price * inputValue).toFixed(2)}
                </Price>
                <ProductPrice>
                  {currencyTag}
                  {item.product.price.toFixed(2)}
                </ProductPrice>
              </div>
              <div>
                <ProductDiscountedPrice>
                  {currencyTag}
                  {item.product.discount_price.toFixed(2)}/piece
                </ProductDiscountedPrice>
              </div>
            </PriceContainer>
          </FlexDiv>
        </InfoDiv>
      </Wrapper>
    </CartItem>
  );
};

export default CartProduct;
