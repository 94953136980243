import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigation } from "../../../utils/navigation";
import { Link } from "react-router-dom";
import Error from "../../../components/toast/Error";
import { toast } from "react-toastify";
import ButtonLoading from "../../../components/animations/ButtonLoading";
import { AuthUserContext } from "../../../context/AuthUserContext";
import PageLoading from "../../../components/animations/PageLoading";

const Container = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h1`
  padding-top: 20px;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
  @media (min-width: 767px) {
    font-size: var(--font-size-xxlarge);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
const BorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderTop = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--background-color-hover);
  padding-bottom: 14px;
  @media (min-width: 767px) {
    border-bottom: 4px solid var(--text-color);
    width: 40px;
  }
`;
const WrapperX = styled.div`
  @media (min-width: 1024px) {
    max-width: 1400px;
    display: flex;
    gap: 40px;
  }
`;
const Wrapper = styled.div`
  padding: 20px;
`;
const RightDiv = styled.div`
  @media (min-width: 1024px) {
    margin-top: 20px;
    width: 300px;
  }
`;
const LeftDiv = styled.div`
  @media (min-width: 1024px) {
    max-width: 1000px;
    width: 1000px;
  }
`;
const PasswordContainer = styled.div`
  background-color: var(--background-color-hover);
  padding: 20px;
`;
const PasswordTitle = styled.h3`
  font-weight: 500;
  font-size: var(--font-size-large);
`;
const SmallerTitle = styled.h3`
  font-weight: 500;
  font-size: var(--font-size-base);
`;
const P = styled.p`
  font-size: var(--font-size-small);
  line-height: var(--font-size-large);
  font-weight: 500;
`;
const PTransparent = styled(P)`
  font-weight: 300;
`;

const InfoText = styled(P)`
  margin: 0;
  padding: 0;
  @media (min-width: 767px) {
    font-size: var(--font-size-base);
  }
`;

const InfoDiv = styled.div`
  margin: 10px 0;
  @media (min-width: 767px) {
    display: flex;
    gap: 20px;
  }
`;
const InfoTextTransparent = styled(PTransparent)`
  margin: 0;
  padding: 0;
  @media (min-width: 767px) {
    font-size: var(--font-size-base);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 767px) {
    width: 50%;
  }
`;

const Input = styled.input`
  width: calc(100% - 16px);
  margin-bottom: 10px;
  padding: 14px 8px;
  border: 1px solid #ccc;
  font-size: var(--font-size-small);
  font-weight: 700;
  &::placeholder {
    text-transform: capitalize;
  }
  &:disabled {
    background-color: var(--background-color);
    cursor: not-allowed;
  }
`;
const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 100%;
  text-align: center;
  margin: var(--spacing-md) 0;
  @media (min-width: 767px) {
    width: 50%;
  }
`;
const Button = styled.button`
  width: 100%;
`;
const BillingContainer = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid var(--background-color-hover);
`;
const Name = styled.h3`
  margin: 20px 0;
  font-weight: 700;
  font-size: var(--font-size-base);
`;
const FlexDiv = styled.div`
  display: flex;
  align-content: center;
  gap: 4px;
  margin: 24px 0;
`;

const RadioText = styled(Name)`
  margin: 0;
  padding: 0;
`;
const EditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const EditLink = styled(Link)`
  font-size: var(--font-size-base);
  border: none;
  border-radius: 0;
  color: var(--text-color);
  background-color: transparent;
  padding: 0;
  //border-bottom: 1px solid var(--text-color);

  &:hover {
    background-color: transparent;
  }
`;
const Label = styled.label`
  display: flex; /* Ovo osigurava da se input i tekst unutar label-a poravnavaju */

  font-size: 18px;
  cursor: pointer;
  position: relative;
`;
const InputBox = styled.input`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
`;
const TickCheckBox = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border: 1px solid var(--text-color);
`;

const HelpContainer = styled.div`
  width: 100%;
  background-color: var(--background-color-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
`;

const IconSVG = styled.svg`
  width: 60px;
  height: 60px;
  @media (min-width: 767px) {
    width: 80px;
    height: 80px;
    margin-left: 0;
  }
`;
const BorderBoxWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderBoxTop = styled.div`
  border-bottom: 6px solid var(--text-color);
  width: 40px;
`;
const EyeSpan = styled.span`
  position: relative;
`;
const EyeSVG = styled.svg`
  position: absolute;
  right: 10px;
  top: 10px;
  width: var(--font-size-xlarge);
  height: var(--font-size-xlarge);
`;
const showNotification = (text, smalltext) => {
  toast(<Error text={text} smalltext={smalltext} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast",
    progressClassName: "custom-progress-bar",
  });
};
const Settings = () => {
  const { goToAddressBook, goToAccount } = useNavigation();
  const { changePassword, userProfile, fetchUserData } =
    useContext(AuthUserContext);

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [isChecked, setIsChecked] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  const isFormFilled = oldPassword && newPassword && confirmNewPassword;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAnimating(true);
    // Ovde dodajte logiku za validaciju i resetovanje šifre
    if (newPassword !== confirmNewPassword) {
      showNotification("New password dont match.", "Molimo pokušajte ponovo.");

      return;
    }

    // Obrada resetovanja šifre
    try {
      const response = await changePassword(oldPassword, newPassword);
      goToAccount();
    } catch (error) {
      console.error(error); // Obradite grešku
      showNotification(
        "Došlo je do greške prilikom pokušaja menjanja lozinke.",
        "Molimo pokušajte ponovo."
      );
    }
  };
  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };
  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <Container>
      {userProfile ? (
        <>
          <Title>Password & contact information</Title>
          <BorderWrapper>
            <BorderTop />
          </BorderWrapper>
          <WrapperX>
            <Wrapper>
              <LeftDiv>
                <PasswordContainer>
                  <PasswordTitle>Change password</PasswordTitle>
                  <P>
                    In order to change your password, please provide your
                    current password and enter your new password twice:
                  </P>
                  <P>
                    Create a strong password with a mixture of letters, numbers
                    and symbols.
                  </P>
                  <Form onSubmit={handleSubmit}>
                    <EyeSpan>
                      <Input
                        type={showOldPassword ? "text" : "password"}
                        placeholder="Stara šifra"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      {showOldPassword ? (
                        <EyeSVG
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          <g id="style=fill">
                            <g id="eye-close">
                              <path
                                id="vector"
                                d="M15.6487 5.39465C14.4859 4.95229 13.2582 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C3.34474 14.9049 3.83895 15.4807 4.36664 16M19.3248 7.69629C19.9692 8.2894 20.5676 8.96401 21.11 9.71356C22.01 10.9532 22.01 13.0368 21.11 14.2764C18.82 17.4413 15.53 19.27 12 19.27C10.6143 19.27 9.26561 18.9882 7.99988 18.4544"
                                stroke="var(--text-color-hover)"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                id="Subtract"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.742 5.57955C15.9962 4.53817 14.0413 3.96997 11.9998 3.96997C8.19594 3.96997 4.69275 5.94267 2.28258 9.27342C1.72076 10.0475 1.46484 11.0419 1.46484 11.995C1.46484 12.9478 1.72065 13.942 2.28222 14.716C3.02992 15.7494 3.88282 16.6521 4.81727 17.4057L8.72514 13.83C8.42248 13.2886 8.25 12.664 8.25 12C8.25 9.92657 9.92657 8.25 12 8.25C12.7834 8.25 13.5113 8.48997 14.1129 8.90023L17.742 5.57955ZM6.0677 18.2947C7.86114 19.4096 9.88397 20.02 11.9998 20.02C15.8037 20.02 19.3069 18.0473 21.7171 14.7165C22.2789 13.9424 22.5348 12.948 22.5348 11.995C22.5348 11.0421 22.279 10.0479 21.7175 9.27392C20.9248 8.17837 20.0139 7.22973 19.0129 6.44988L6.0677 18.2947ZM15 11.25C15.4142 11.25 15.75 11.5858 15.75 12C15.75 14.0734 14.0734 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15C11.25 14.5858 11.5858 14.25 12 14.25C13.245 14.25 14.25 13.245 14.25 12C14.25 11.5858 14.5858 11.25 15 11.25Z"
                                fill="var(--text-color-hover)"
                              />
                              <path
                                id="vector (Stroke)"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22.5533 2.19366C22.8329 2.49926 22.8119 2.97366 22.5063 3.25328L19.3012 6.18591C18.9956 6.46553 18.5212 6.44447 18.2416 6.13888C17.962 5.83329 17.9831 5.35888 18.2886 5.07926L21.4937 2.14663C21.7993 1.86701 22.2737 1.88807 22.5533 2.19366ZM5.73136 17.5857C6.01098 17.8913 5.98992 18.3657 5.68433 18.6454L2.5063 21.5533C2.2007 21.8329 1.7263 21.8118 1.44668 21.5062C1.16706 21.2006 1.18812 20.7262 1.49371 20.4466L4.67175 17.5387C4.97734 17.2591 5.45175 17.2801 5.73136 17.5857Z"
                                fill="var(--text-color-hover)"
                              />
                            </g>
                          </g>
                        </EyeSVG>
                      ) : (
                        <EyeSVG
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          <g id="style=fill">
                            <g id="eye-open">
                              <path
                                id="Subtract"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.0001 3.96997C8.19618 3.96997 4.69299 5.94267 2.28282 9.27342C1.721 10.0475 1.46509 11.0419 1.46509 11.995C1.46509 12.9478 1.7209 13.942 2.28246 14.716C4.69264 18.0471 8.19599 20.02 12.0001 20.02C15.804 20.02 19.3072 18.0473 21.7174 14.7165C22.2792 13.9424 22.5351 12.948 22.5351 11.995C22.5351 11.0421 22.2793 10.0479 21.7177 9.27392C19.3075 5.94286 15.8042 3.96997 12.0001 3.96997ZM9.75012 12C9.75012 10.755 10.7551 9.75 12.0001 9.75C13.2451 9.75 14.2501 10.755 14.2501 12C14.2501 13.245 13.2451 14.25 12.0001 14.25C10.7551 14.25 9.75012 13.245 9.75012 12ZM12.0001 8.25C9.92669 8.25 8.25012 9.92657 8.25012 12C8.25012 14.0734 9.92669 15.75 12.0001 15.75C14.0736 15.75 15.7501 14.0734 15.7501 12C15.7501 9.92657 14.0736 8.25 12.0001 8.25Z"
                                fill="var(--text-color-hover)"
                              />
                            </g>
                          </g>
                        </EyeSVG>
                      )}
                    </EyeSpan>
                    <EyeSpan>
                      <Input
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Nova šifra"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      {showNewPassword ? (
                        <EyeSVG
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          <g id="style=fill">
                            <g id="eye-close">
                              <path
                                id="vector"
                                d="M15.6487 5.39465C14.4859 4.95229 13.2582 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C3.34474 14.9049 3.83895 15.4807 4.36664 16M19.3248 7.69629C19.9692 8.2894 20.5676 8.96401 21.11 9.71356C22.01 10.9532 22.01 13.0368 21.11 14.2764C18.82 17.4413 15.53 19.27 12 19.27C10.6143 19.27 9.26561 18.9882 7.99988 18.4544"
                                stroke="var(--text-color-hover)"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                id="Subtract"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.742 5.57955C15.9962 4.53817 14.0413 3.96997 11.9998 3.96997C8.19594 3.96997 4.69275 5.94267 2.28258 9.27342C1.72076 10.0475 1.46484 11.0419 1.46484 11.995C1.46484 12.9478 1.72065 13.942 2.28222 14.716C3.02992 15.7494 3.88282 16.6521 4.81727 17.4057L8.72514 13.83C8.42248 13.2886 8.25 12.664 8.25 12C8.25 9.92657 9.92657 8.25 12 8.25C12.7834 8.25 13.5113 8.48997 14.1129 8.90023L17.742 5.57955ZM6.0677 18.2947C7.86114 19.4096 9.88397 20.02 11.9998 20.02C15.8037 20.02 19.3069 18.0473 21.7171 14.7165C22.2789 13.9424 22.5348 12.948 22.5348 11.995C22.5348 11.0421 22.279 10.0479 21.7175 9.27392C20.9248 8.17837 20.0139 7.22973 19.0129 6.44988L6.0677 18.2947ZM15 11.25C15.4142 11.25 15.75 11.5858 15.75 12C15.75 14.0734 14.0734 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15C11.25 14.5858 11.5858 14.25 12 14.25C13.245 14.25 14.25 13.245 14.25 12C14.25 11.5858 14.5858 11.25 15 11.25Z"
                                fill="var(--text-color-hover)"
                              />
                              <path
                                id="vector (Stroke)"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22.5533 2.19366C22.8329 2.49926 22.8119 2.97366 22.5063 3.25328L19.3012 6.18591C18.9956 6.46553 18.5212 6.44447 18.2416 6.13888C17.962 5.83329 17.9831 5.35888 18.2886 5.07926L21.4937 2.14663C21.7993 1.86701 22.2737 1.88807 22.5533 2.19366ZM5.73136 17.5857C6.01098 17.8913 5.98992 18.3657 5.68433 18.6454L2.5063 21.5533C2.2007 21.8329 1.7263 21.8118 1.44668 21.5062C1.16706 21.2006 1.18812 20.7262 1.49371 20.4466L4.67175 17.5387C4.97734 17.2591 5.45175 17.2801 5.73136 17.5857Z"
                                fill="var(--text-color-hover)"
                              />
                            </g>
                          </g>
                        </EyeSVG>
                      ) : (
                        <EyeSVG
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          <g id="style=fill">
                            <g id="eye-open">
                              <path
                                id="Subtract"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.0001 3.96997C8.19618 3.96997 4.69299 5.94267 2.28282 9.27342C1.721 10.0475 1.46509 11.0419 1.46509 11.995C1.46509 12.9478 1.7209 13.942 2.28246 14.716C4.69264 18.0471 8.19599 20.02 12.0001 20.02C15.804 20.02 19.3072 18.0473 21.7174 14.7165C22.2792 13.9424 22.5351 12.948 22.5351 11.995C22.5351 11.0421 22.2793 10.0479 21.7177 9.27392C19.3075 5.94286 15.8042 3.96997 12.0001 3.96997ZM9.75012 12C9.75012 10.755 10.7551 9.75 12.0001 9.75C13.2451 9.75 14.2501 10.755 14.2501 12C14.2501 13.245 13.2451 14.25 12.0001 14.25C10.7551 14.25 9.75012 13.245 9.75012 12ZM12.0001 8.25C9.92669 8.25 8.25012 9.92657 8.25012 12C8.25012 14.0734 9.92669 15.75 12.0001 15.75C14.0736 15.75 15.7501 14.0734 15.7501 12C15.7501 9.92657 14.0736 8.25 12.0001 8.25Z"
                                fill="var(--text-color-hover)"
                              />
                            </g>
                          </g>
                        </EyeSVG>
                      )}
                    </EyeSpan>
                    <EyeSpan>
                      <Input
                        type={showConfirmNewPassword ? "text" : "password"}
                        placeholder="Potvrdi novu šifru"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                      {showConfirmNewPassword ? (
                        <EyeSVG
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            setShowConfirmNewPassword(!showConfirmNewPassword)
                          }
                        >
                          <g id="style=fill">
                            <g id="eye-close">
                              <path
                                id="vector"
                                d="M15.6487 5.39465C14.4859 4.95229 13.2582 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C3.34474 14.9049 3.83895 15.4807 4.36664 16M19.3248 7.69629C19.9692 8.2894 20.5676 8.96401 21.11 9.71356C22.01 10.9532 22.01 13.0368 21.11 14.2764C18.82 17.4413 15.53 19.27 12 19.27C10.6143 19.27 9.26561 18.9882 7.99988 18.4544"
                                stroke="var(--text-color-hover)"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                id="Subtract"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.742 5.57955C15.9962 4.53817 14.0413 3.96997 11.9998 3.96997C8.19594 3.96997 4.69275 5.94267 2.28258 9.27342C1.72076 10.0475 1.46484 11.0419 1.46484 11.995C1.46484 12.9478 1.72065 13.942 2.28222 14.716C3.02992 15.7494 3.88282 16.6521 4.81727 17.4057L8.72514 13.83C8.42248 13.2886 8.25 12.664 8.25 12C8.25 9.92657 9.92657 8.25 12 8.25C12.7834 8.25 13.5113 8.48997 14.1129 8.90023L17.742 5.57955ZM6.0677 18.2947C7.86114 19.4096 9.88397 20.02 11.9998 20.02C15.8037 20.02 19.3069 18.0473 21.7171 14.7165C22.2789 13.9424 22.5348 12.948 22.5348 11.995C22.5348 11.0421 22.279 10.0479 21.7175 9.27392C20.9248 8.17837 20.0139 7.22973 19.0129 6.44988L6.0677 18.2947ZM15 11.25C15.4142 11.25 15.75 11.5858 15.75 12C15.75 14.0734 14.0734 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15C11.25 14.5858 11.5858 14.25 12 14.25C13.245 14.25 14.25 13.245 14.25 12C14.25 11.5858 14.5858 11.25 15 11.25Z"
                                fill="var(--text-color-hover)"
                              />
                              <path
                                id="vector (Stroke)"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22.5533 2.19366C22.8329 2.49926 22.8119 2.97366 22.5063 3.25328L19.3012 6.18591C18.9956 6.46553 18.5212 6.44447 18.2416 6.13888C17.962 5.83329 17.9831 5.35888 18.2886 5.07926L21.4937 2.14663C21.7993 1.86701 22.2737 1.88807 22.5533 2.19366ZM5.73136 17.5857C6.01098 17.8913 5.98992 18.3657 5.68433 18.6454L2.5063 21.5533C2.2007 21.8329 1.7263 21.8118 1.44668 21.5062C1.16706 21.2006 1.18812 20.7262 1.49371 20.4466L4.67175 17.5387C4.97734 17.2591 5.45175 17.2801 5.73136 17.5857Z"
                                fill="var(--text-color-hover)"
                              />
                            </g>
                          </g>
                        </EyeSVG>
                      ) : (
                        <EyeSVG
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            setShowConfirmNewPassword(!showConfirmNewPassword)
                          }
                        >
                          <g id="style=fill">
                            <g id="eye-open">
                              <path
                                id="Subtract"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.0001 3.96997C8.19618 3.96997 4.69299 5.94267 2.28282 9.27342C1.721 10.0475 1.46509 11.0419 1.46509 11.995C1.46509 12.9478 1.7209 13.942 2.28246 14.716C4.69264 18.0471 8.19599 20.02 12.0001 20.02C15.804 20.02 19.3072 18.0473 21.7174 14.7165C22.2792 13.9424 22.5351 12.948 22.5351 11.995C22.5351 11.0421 22.2793 10.0479 21.7177 9.27392C19.3075 5.94286 15.8042 3.96997 12.0001 3.96997ZM9.75012 12C9.75012 10.755 10.7551 9.75 12.0001 9.75C13.2451 9.75 14.2501 10.755 14.2501 12C14.2501 13.245 13.2451 14.25 12.0001 14.25C10.7551 14.25 9.75012 13.245 9.75012 12ZM12.0001 8.25C9.92669 8.25 8.25012 9.92657 8.25012 12C8.25012 14.0734 9.92669 15.75 12.0001 15.75C14.0736 15.75 15.7501 14.0734 15.7501 12C15.7501 9.92657 14.0736 8.25 12.0001 8.25Z"
                                fill="var(--text-color-hover)"
                              />
                            </g>
                          </g>
                        </EyeSVG>
                      )}
                    </EyeSpan>

                    <ButtonWrapper>
                      <Button type="submit" disabled={!isFormFilled}>
                        Resetuj šifru
                      </Button>
                      {isAnimating && (
                        <ButtonLoading
                          isAnimating={isAnimating}
                          onComplete={handleAnimationComplete}
                        />
                      )}
                    </ButtonWrapper>
                  </Form>
                  <PTransparent>
                    In order to maintain the necessary confidentiality, we ask
                    that you keep your access details absolutely secure and
                    inaccessible to others. Never give out your login details to
                    anyone.
                  </PTransparent>
                </PasswordContainer>
                <BillingContainer>
                  <PasswordTitle>
                    Billing address & Contact information
                  </PasswordTitle>
                  {userProfile.addresses && userProfile.addresses.length > 0 ? (
                    userProfile.addresses.map((address, index) => (
                      <React.Fragment key={index}>
                        <InfoDiv>
                          <InfoTextTransparent
                            style={{ margin: 0, padding: 0 }}
                          >
                            Country
                          </InfoTextTransparent>
                          <InfoText> {address.country}</InfoText>
                        </InfoDiv>
                        <InfoDiv>
                          <InfoTextTransparent
                            style={{ margin: 0, padding: 0 }}
                          >
                            City
                          </InfoTextTransparent>
                          <InfoText> {address.city}</InfoText>
                        </InfoDiv>
                        <InfoDiv>
                          <InfoTextTransparent
                            style={{ margin: 0, padding: 0 }}
                          >
                            Postal Code
                          </InfoTextTransparent>
                          <InfoText> {address.postal_code}</InfoText>
                        </InfoDiv>
                        <InfoDiv>
                          <InfoTextTransparent
                            style={{ margin: 0, padding: 0 }}
                          >
                            Street
                          </InfoTextTransparent>
                          <InfoText>
                            {address.street} / {address.building_number}
                          </InfoText>
                        </InfoDiv>

                        <FlexDiv>
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                              fill="var(--text-color)"
                            />
                            {address.is_primary && (
                              <circle
                                cx="12"
                                cy="12"
                                r="5.25"
                                fill="var(--success-color)"
                              />
                            )}
                          </svg>
                          <RadioText>Default addresss</RadioText>
                        </FlexDiv>
                        <EditContainer>
                          <EditLink to={goToAddressBook(address.id)}>
                            Edit address
                          </EditLink>
                        </EditContainer>
                      </React.Fragment>
                    ))
                  ) : (
                    <div>No addresses available</div> // Ovo će se prikazati ako nema adresa
                  )}
                </BillingContainer>

                <PasswordContainer>
                  <PasswordTitle>Personal Settings</PasswordTitle>
                  <PTransparent>
                    In order to change your password, please provide your
                    current
                  </PTransparent>
                  <SmallerTitle>Shipment tracking</SmallerTitle>
                  <FlexDiv>
                    <Label>
                      <InputBox
                        type="checkbox"
                        checked={isChecked}
                        onChange={toggleCheckbox}
                      />

                      <TickCheckBox
                        fill="var(--success-color)"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {isChecked && (
                          <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z" />
                        )}
                      </TickCheckBox>
                    </Label>
                    <P>
                      I would like to receive notifications on the shipping
                      status and the delivery date of my parcel, and I hereby
                      agree that my email and phone number are passed on to the
                      shipping provider for that purpose. Consent may be
                      withdrawn voluntarily at any time. Additional information
                      may be found in our{" "}
                    </P>
                  </FlexDiv>
                </PasswordContainer>
              </LeftDiv>
            </Wrapper>
            <RightDiv>
              <HelpContainer>
                <IconSVG viewBox="0 0 512 512">
                  <path
                    d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
                    fill="none"
                    stroke="var(--text-color)"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                  />
                  <path
                    d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
                    fill="none"
                    stroke="var(--text-color)"
                    strokeMiterlimit={10}
                    strokeWidth={28}
                  />
                  <circle cx="250" cy="348" r="20" />
                </IconSVG>
                <RadioText>Need Help</RadioText>
                <BorderBoxWrapper>
                  <BorderBoxTop />
                </BorderBoxWrapper>
                <EditLink>In our help center you can find answers</EditLink>
              </HelpContainer>
            </RightDiv>
          </WrapperX>
        </>
      ) : (
        <PageLoading />
      )}
    </Container>
  );
};

export default Settings;
