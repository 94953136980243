import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  background-color: var(--background-color-hover);
  width: 100%;

  @media (min-width: 768px) {
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const LinkContainer = styled.div`
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
    gap: 14px;
  }
`;
const Text = styled.p`
  padding: 10px;
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: bold;
  @media (min-width: 768px) {
    display: inline-block;
  }
`;
const StyledLink = styled(Link)`
  color: var(--primary-color); // Koristi CSS varijablu za boju
  text-decoration: none; // Primera radi, uklanja podvlačenje
  font-size: var(--font-size-small);
  &:hover {
    color: var(--primary-color-hover); // Menja boju prilikom hovera
  }
`;
const FooterToolbar = () => {
  return (
    <Container>
      <Wrapper>
        <span>
          <Text>
            21+ Age verification WARNING: This product contains nicotine.
            Nicotine is an addictive chemical..
          </Text>
        </span>

        <LinkContainer>
          <StyledLink href="">Accessibility </StyledLink>
          <StyledLink href="">Privacy policy</StyledLink>
          <StyledLink href="">Terms of Use</StyledLink>
        </LinkContainer>
        <span>
          <Text>Copyright © 2022 Snus</Text>
        </span>
      </Wrapper>
    </Container>
  );
};

export default FooterToolbar;
