import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import FooterMain from "../layouts/footer/FooterMain";
import ProductMainMain from "../layouts/main/ProductMain";
import HorizontalSwipeSlider from "../components/product/HorizontalSwipeSlider";
import { ProductContext } from "../context/ProductContext";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const ProductMain = () => {
  const { t, i18n } = useTranslation();
  const { recommendedProducts, product } = useContext(ProductContext);
  const [seo, setSeo] = useState({
    title: "Loading...",
    description: "Loading product information...",
    keywords: "",
    url: window.location.href,
    images: ["https://www.snusco.com/assets/default-product-image.jpg"], // Podrazumevana slika
  });

  useEffect(() => {
    if (product) {
      const seoTitle = `${product.category_name} ${product.name} | SnusCo`;
      const seoDescription = product.short_description;
      const seoKeywords = `${product.category_name} ${product.name},  ${product.nicotine}mg, ${product.flavor}, ${product.category_name}, ${product.name}`;
      const productUrl = `https://www.snusco.com/${
        i18n.language
      }/${product.category_name.toLowerCase().replace(/ /g, "-")}-${product.name
        .toLowerCase()
        .replace(/ /g, "-")}`;

      // Pronalazimo primarnu sliku i dodajemo je na početak liste slika
      const primaryImage =
        product.images.find((image) => image.is_primary)?.large ||
        "https://www.snusco.com/assets/default-product-image.jpg";
      const additionalImages = product.images
        .filter((image) => !image.is_primary)
        .map((image) => image.large);

      setSeo({
        title: seoTitle,
        description: seoDescription,
        keywords: seoKeywords,
        url: productUrl,
        images: [primaryImage, ...additionalImages],
      });
    }
  }, [product]);
  return (
    <Container>
      <Helmet>
        <html lang={i18n.language} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={seo.url} />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        {seo.images.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <PrimaryHeader />
      <ProductMainMain />
      <HorizontalSwipeSlider items={recommendedProducts} />
      <FooterMain />
    </Container>
  );
};

export default ProductMain;
