import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ProductContext } from "../../../context/ProductContext";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import APIService from "../../../services/APIService";
import { useNavigation } from "../../../utils/navigation";
import { useTranslation } from "react-i18next";

const InputContainer = styled.div`
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: var(--background-color);
  width: 100%;
  max-width: 100vw;
  z-index: calc(var(--zindex-fixed) + 1);
  @media (min-width: 768px) {
    height: auto;
    display: block;
    position: relative;
  }
`;
const blink = keyframes`
  0% { border-color: initial; }
  50% { border-color: "#000"; }
  100% { border-color: initial; }
`;
const InputWrapper = styled.div`
  //position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 var(--spacing-sm);
  height: 40px;
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    //width: 160px;
  }
  &:focus-within {
    box-shadow: var(--shadow-large); /* Dodati shadow */
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: #e9f1f5;
  }
`;

const Input = styled.input`
  font-size: var(--font-size-medium);
  font-weight: 400;
  padding: 0;
  width: 80%;

  padding: var(--spacing-xs);
  font-size: 14px;
  font-style: normal;
  &::placeholder {
    color: #656565;
    font-weight: 400;
    line-height: 24px;
  }
  &:focus {
    animation: ${blink} 1s step-start infinite;
  }
`;
const SVGSearch = styled.svg`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
`;
const ResultContainer = styled.div`
  position: absolute;
  background-color: var(--background-color);
  width: calc(100% - 16px);
  top: calc(100% + 1px);
  left: 0;
  border-radius: 4px;
  box-shadow: var(--shadow-large);
  padding: 12px;
  z-index: 1;
`;
const ResultItem = styled.div`
  padding: 4px 0;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: var(--font-size-small);
  cursor: pointer;
`;
const ProductResult = styled(ResultItem)`
  cursor: pointer;
`;
const ResultTitle = styled.h4`
  font-family: "Satoshi";
  font-size: var(--font-size-base);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px 0;
  text-transform: uppercase;
`;
const ProductImageContainer = styled.div`
  height: 30px;
  width: 30px;
`;
const ProductPicture = styled.picture`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ProductImage = styled.img`
  height: 30px;
`;

const HeaderInput = () => {
  const { t } = useTranslation();
  const { setSearchedProducts } = useContext(ProductContext);
  const navigate = useNavigate();
  const { goToSearch, goToProduct } = useNavigation();
  const [searchValue, setSearchValue] = useState("");
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  // Debounce funkcija za pretragu kako bi se izbeglo preterano slanje zahteva
  const debouncedSearch = useRef(
    debounce(async (searchTerm) => {
      // Ovde dodajte logiku za slanje zahteva za pretragu
      console.log("Pretraga za:", searchTerm);
      try {
        const searchData = await APIService.SearchProducts(searchTerm);
        //console.log("searchData", searchData);
        setSearchProducts(searchData);
        setSearchHistory((prevHistory) => {
          // Check if the searchTerm is a substring of any existing searchTerm in the history
          const isSubstring = prevHistory.some(
            (existingTerm) =>
              existingTerm.includes(searchTerm) &&
              existingTerm.length > searchTerm.length
          );

          // Only add the searchTerm if it is not a substring of any longer searchTerm
          if (!isSubstring) {
            const newHistory = [searchTerm, ...prevHistory];

            // Filter out shorter terms if they are substrings of longer terms
            const filteredHistory = newHistory.filter((item, index) =>
              newHistory.every(
                (otherItem, otherIndex) =>
                  index === otherIndex ||
                  !item.includes(otherItem) ||
                  item.length >= otherItem.length
              )
            );

            return filteredHistory.slice(0, 5);
          }

          return prevHistory;
        });
      } catch (error) {
        console.error("Greška pri pretrazi:", error);
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (searchValue && searchValue != "") {
      debouncedSearch(searchValue);
      setIsOpen(true);
    } else {
      setSearchProducts([]);
    }
  }, [searchValue, debouncedSearch]);

  const handleShowAll = (searchValue) => {
    setSearchedProducts(searchProducts);
    navigate(goToSearch(searchValue));
    setSearchProducts([]);
  };

  const [maxProductsToShow, setMaxProductsToShow] = useState(12);
  useEffect(() => {
    const updateProductDisplay = () => {
      if (window.innerWidth >= 1024) {
        setMaxProductsToShow(10); // Desktop
      } else if (window.innerWidth >= 768) {
        setMaxProductsToShow(15); // Tablet
      } else {
        setMaxProductsToShow(12); // Mobilni
      }
    };

    updateProductDisplay();

    window.addEventListener("resize", updateProductDisplay);

    return () => window.removeEventListener("resize", updateProductDisplay);
  }, []);

  const handleProductCardClick = (category_name, name) => {
    navigate(goToProduct(category_name, name));
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  return (
    <InputContainer>
      <InputWrapper>
        <Input
          type="search"
          placeholder={t("PLACEHOLDERS.SEARCH_PRODUCTS")}
          aria-label="Search the products..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <SVGSearch
          aria-hidden="true"
          focusable="false"
          role="presentation"
          viewBox="0 0 64 64"
          className="svg-icon"
        >
          <path d="M47.16 28.58A18.58 18.58 0 1 1 28.58 10a18.58 18.58 0 0 1 18.58 18.58zM54 54L41.94 42"></path>
        </SVGSearch>
        {searchProducts.length && isOpen ? (
          <ResultContainer ref={containerRef}>
            {searchHistory.length > 0 && (
              <div>
                <ResultItem onClick={() => handleShowAll(searchValue)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_38_2247)">
                      <path
                        d="M7.5 3.79158C9.14655 3.12854 10.9891 3.14675 12.6222 3.84219C14.2553 4.53764 15.5453 5.85336 16.2083 7.49992C16.8714 9.14647 16.8532 10.989 16.1577 12.6221C15.4623 14.2552 14.1466 15.5452 12.5 16.2083M12.5 12.4999V16.6666H16.6667"
                        stroke="#999999"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.69189 5.9668V5.97513"
                        stroke="#999999"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.3833 9.1665V9.17484"
                        stroke="#999999"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.8584 12.5835V12.5918"
                        stroke="#999999"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.9668 15.3081V15.3164"
                        stroke="#999999"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.1665 16.6167V16.625"
                        stroke="#999999"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_38_2247">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {searchValue}
                </ResultItem>
                {searchHistory.map((term, index) => (
                  <ResultItem key={index} onClick={() => handleShowAll(term)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_38_2247)">
                        <path
                          d="M7.5 3.79158C9.14655 3.12854 10.9891 3.14675 12.6222 3.84219C14.2553 4.53764 15.5453 5.85336 16.2083 7.49992C16.8714 9.14647 16.8532 10.989 16.1577 12.6221C15.4623 14.2552 14.1466 15.5452 12.5 16.2083M12.5 12.4999V16.6666H16.6667"
                          stroke="#999999"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.69189 5.9668V5.97513"
                          stroke="#999999"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.3833 9.1665V9.17484"
                          stroke="#999999"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.8584 12.5835V12.5918"
                          stroke="#999999"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.9668 15.3081V15.3164"
                          stroke="#999999"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.1665 16.6167V16.625"
                          stroke="#999999"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_38_2247">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {term}
                  </ResultItem>
                ))}
              </div>
            )}
            <div>
              <ResultTitle>{t("HEADER.PRODUCTS")}</ResultTitle>
              {searchProducts?.slice(0, maxProductsToShow).map((item) => (
                <ProductResult
                  key={item.id}
                  onClick={() =>
                    handleProductCardClick(item.category_name, item.name)
                  }
                >
                  {/* <ProductImageContainer>
                    <ProductPicture>
                      {item.images.length > 0 &&
                        (() => {
                          const primaryImage = item.images.find(
                            (img) => img.is_primary
                          ); // Pronalazi primarnu sliku samo jednom
                          return (
                            primaryImage && (
                              <ProductImage
                                src={primaryImage.thumbnail} // Koristi 'thumbnail' verziju primarne slike
                                alt={item.name}
                                srcset={`
                        ${primaryImage.thumbnail} 320w,  // Koristi 'thumbnail' verziju za male ekrane
                        ${primaryImage.large} 480w,  // Koristi 'large' verziju za srednje ekrane
                        ${primaryImage.large} 800w`} // Ponovo koristi 'large' verziju za velike ekrane
                                sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                                loading="lazy"
                              />
                            )
                          );
                        })()}
                    </ProductPicture>
                  </ProductImageContainer> */}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_335_5465)">
                      <path
                        d="M2.5 8.33333C2.5 9.09938 2.65088 9.85792 2.94404 10.5657C3.23719 11.2734 3.66687 11.9164 4.20854 12.4581C4.75022 12.9998 5.39328 13.4295 6.10101 13.7226C6.80875 14.0158 7.56729 14.1667 8.33333 14.1667C9.09938 14.1667 9.85792 14.0158 10.5657 13.7226C11.2734 13.4295 11.9164 12.9998 12.4581 12.4581C12.9998 11.9164 13.4295 11.2734 13.7226 10.5657C14.0158 9.85792 14.1667 9.09938 14.1667 8.33333C14.1667 7.56729 14.0158 6.80875 13.7226 6.10101C13.4295 5.39328 12.9998 4.75022 12.4581 4.20854C11.9164 3.66687 11.2734 3.23719 10.5657 2.94404C9.85792 2.65088 9.09938 2.5 8.33333 2.5C7.56729 2.5 6.80875 2.65088 6.10101 2.94404C5.39328 3.23719 4.75022 3.66687 4.20854 4.20854C3.66687 4.75022 3.23719 5.39328 2.94404 6.10101C2.65088 6.80875 2.5 7.56729 2.5 8.33333Z"
                        stroke="#999999"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 17.5L12.5 12.5"
                        stroke="#999999"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_335_5465">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {item.category_name} {item.name}
                </ProductResult>
              ))}
            </div>
          </ResultContainer>
        ) : (
          <></>
        )}
      </InputWrapper>
    </InputContainer>
  );
};

export default HeaderInput;
