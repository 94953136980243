import React, { useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import HearthA from "../../assets/icons/search.png";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: var(--spacing-md) 0;
  width: 100vw;
  border-bottom: 1px solid #e5e5e5;
  @media (min-width: 768px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: var(--background-color-hover);
  //height: 200px;
  @media (min-width: 768px) {
    max-width: var(--max-width-container);
  }
`;

const SliderContent = styled.div`
  display: flex;
  transform: translateX(-${(props) => props.$currentIndex * 33}%);
  transition: transform 0.3s ease-out;
`;
const SliderItem = styled.div`
  flex: 0 0 auto; // Sprečava rastezanje elemenata
  width: 31%; // Širina pojedinačnih elemenata
  margin-right: 2%;
  //background-color: var(--background-color);
`;
const Picture = styled.picture`
  display: flex; // Omogućavanje flex layout-a za centriranje slike
  //height: 240px;
  cursor: pointer;
  width: 100%;
  min-height: 80px;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  //max-width: 200px;
  width: 80px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  &.secondary-image {
    display: none;
  }
`;
const ProductTitle = styled.div`
  font-size: var(--font-size-small);
  text-align: center;
  padding: var(--spacing-xxs) 0;
  width: 100%;
`;
const ProductText = styled.div`
  font-size: var(--font-size-small);
  color: var(--p-color);
  text-align: center;
`;
const FlexTop = styled.div`
  width: 90%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  gap: var(--spacing-md);
  margin: var(--spacing-md) 0;
`;
const TextHeader = styled.div`
  font-size: var(--font-size-large);
  padding: 0;
  margin: 0;
  font-weight: 300;
  padding: var(--spacing-xs) 0;
  font-family: "Larken";
`;

const Flex = styled.div`
  display: flex;
  align-content: center;
  gap: var(--spacing-md);
`;
const Button = styled.div`
  padding: 10px;
  cursor: pointer;

  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const OrderHistorySlider = ({ items }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => {
        const maxIndex = getMaxIndex(items.length);
        return Math.min(prevIndex + 1, maxIndex);
      }),
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getMaxIndex = (itemsLength) => {
    const visibleItems = 3; // Broj elemenata koji staju na ekran
    const maxIndex = Math.ceil(
      itemsLength - visibleItems < 0 ? 0 : itemsLength - visibleItems
    );
    return maxIndex;
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = getMaxIndex(items.length);
      return Math.min(prevIndex + 1, maxIndex);
    });
  };
  const maxIndex = getMaxIndex(items.length);

  return (
    <Container>
      <Wrapper>
        <FlexTop>
          <TextHeader>{t("PROFILE.PRODUCTS")}</TextHeader>
          <Flex>
            <Button
              onClick={handlePrevClick}
              className={currentIndex === 0 ? "disabled" : ""}
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
            <Button
              onClick={handleNextClick}
              className={currentIndex === maxIndex ? "disabled" : ""}
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(-180deg)" }}
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </Flex>
        </FlexTop>
        <SliderContainer {...handlers}>
          <SliderContent $currentIndex={currentIndex}>
            {items.map((item, index) => {
              const primaryImage = item.product_details.images.find(
                (img) => img.is_primary
              );
              return (
                <SliderItem key={index}>
                  {primaryImage && (
                    <Picture>
                      <Image
                        src={primaryImage.thumbnail}
                        alt={`${item.product_details.category_name} ${item.product_details.name}`}
                        srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
                        sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                        loading="lazy"
                        className="primary-image"
                      />
                    </Picture>
                  )}
                  <ProductTitle>{item.product_details.name}</ProductTitle>
                  <ProductText>
                    {item.quantity > 1
                      ? `${item.quantity} ${t("PRODUCT_CARD.CAN")}`
                      : `${item.quantity} ${t("PRODUCT_CARD.CAN")}`}
                  </ProductText>
                </SliderItem>
              );
            })}
          </SliderContent>
        </SliderContainer>
      </Wrapper>
    </Container>
  );
};

export default OrderHistorySlider;
