import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import Visa from "../../assets/icons/visa.svg";
import Mastercard from "../../assets/icons/mastercard-full.svg";
import Bitcoin from "../../assets/icons/bitcoin.svg";
import UPS from "../../assets/icons/ups-logo-svgrepo-com.svg";
import FooterToolbar from "./FooterToolbar";

const fadeInTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
  }
`;
const fadeOutBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const Container = styled.div`
  padding: 0;
  height: var(--footer-height);
  background-color: var(--background-color);
  margin-top: var(--spacing-lg);

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-xl);
    flex-direction: column;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media (min-width: 768px) {
    max-width: var(--max-width-container);
  }
`;
const GridContainer = styled.div`
  //grid-template-rows: repeat(4, 1fr);
  width: 100%;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    height: 100%;
  }
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;
const ButtonWrapper = styled.div`
  border-top: 1px solid var(--transparent-border-color);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  @media (min-width: 768px) {
    border: none;
  }
`;
const Button = styled.button`
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: var(--font-size-small);
  font-weight: 400;
  padding: 0;

  &:hover {
    background-color: var(--background-color);
  }
  @media (min-width: 768px) {
    font-weight: bold;
    font-size: var(--font-size-medium);
    cursor: initial;
  }
`;
const ButtonIcon = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`;

const DropDown = styled.div`
  display: block; /* Podrazumevano je uvek block, osim ako nije telefon i $isOpen je true */

  @media (max-width: 767px) {
    /* Samo za telefone, primenjuje se display na osnovu $isOpen */
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            display: none; /* Sakrij ako je otvoreno */
          `
        : css`
            display: block; /* Prikaži ako nije otvoreno */
          `}
  }
  @media (min-width: 767px) {
    height: 100%;
  }
`;

const Box = styled.div`
  padding: 0 8px;
  @media (min-width: 767px) {
    height: 100%;
  }
  @media (max-width: 767px) {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            animation: ${fadeOutBottom} 0.2s ease-in-out both;
          `
        : css`
            animation: ${fadeInTop} 0.3s ease-in-out both;
          `}
  }
`;

const P = styled.p`
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-lg);
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconContainer = styled(InputContainer)`
  margin-top: var(--spacing-xl);
  gap: var(--spacing-sm);
  justify-content: flex-start;
`;
const PaymentIconContainer = styled(InputContainer)`
  margin-top: var(--spacing-sm);
  gap: var(--spacing-sm);
`;

// Stilizovani input
const EmailInput = styled.input`
  width: 70%;
  border-bottom: 2px solid var(--text-color);
  margin: var(--spacing-xs) 0;
`;

const List = styled.ul`
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
`;

const ListItem = styled.li`
  font-size: var(--font-size-small);
  cursor: pointer;
  font-weight: 600;
  padding-bottom: var(--spacing-sm);
  text-transform: capitalize;
`;
const PaymentText = styled.p`
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-size: var(--font-size-small);
  font-weight: bold;
`;
const Footer = () => {
  const [isOpenBox, setIsOpenBox] = useState([true, true, true, true]);
  const [isOpenContainer, setIsOpenContainer] = useState([
    true,
    true,
    true,
    true,
  ]);
  const [isBoxAnimating, setIsBoxAnimating] = useState([
    false,
    false,
    false,
    false,
  ]);
  const toggleBox = (index) => {
    if (isBoxAnimating[index]) {
      return;
    }

    // Ažuriranje isOpenBox stanja
    const updatedIsOpenBox = [...isOpenBox];
    updatedIsOpenBox[index] = !updatedIsOpenBox[index];
    setIsOpenBox(updatedIsOpenBox);

    // Ažuriranje isBoxAnimating stanja
    const updatedIsBoxAnimating = [...isBoxAnimating];
    updatedIsBoxAnimating[index] = true;
    setIsBoxAnimating(updatedIsBoxAnimating);

    setTimeout(
      () => {
        // Ažuriranje isOpenContainer stanja
        const updatedIsOpenContainer = [...isOpenContainer];
        updatedIsOpenContainer[index] = !updatedIsOpenContainer[index];
        setIsOpenContainer(updatedIsOpenContainer);

        // Ponovno postavljanje isBoxAnimating stanja
        updatedIsBoxAnimating[index] = false;
        setIsBoxAnimating(updatedIsBoxAnimating);
      },
      !isOpenContainer[index] ? 200 : 10
    );
  };

  return (
    <div>
      <Container>
        <Wrapper>
          <GridContainer>
            <GridItems>
              <ButtonWrapper>
                <Button onClick={() => toggleBox(0)}>
                  Register and save
                  <ButtonIcon role="presentation">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                      viewBox="0 0 28 16"
                      style={{ width: "12px" }}
                    >
                      <path
                        d="M1.57 1.59l12.76 12.77L27.1 1.59"
                        strokeWidth=""
                        stroke="var(--transparent-border-color)"
                        fill="none"
                      ></path>
                    </svg>
                  </ButtonIcon>
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[0]}>
                <Box $isOpen={isOpenBox[0]}>
                  <P>
                    All offers and discount promotions are published exclusively
                    via email. Sign up and save on your favorite brands.
                  </P>
                  <InputContainer>
                    <EmailInput type="email" placeholder="Unesite vaš email" />

                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                      viewBox="0 0 64 64"
                      style={{ width: "24px", height: "24px" }}
                    >
                      <path d="M63 52H1V12h62zM1 12l25.68 24h9.72L63 12M21.82 31.68L1.56 51.16m60.78.78L41.27 31.68"></path>
                    </svg>
                    <span style={{ visibility: "hidden" }}>Subscribe</span>
                  </InputContainer>
                  <IconContainer>
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.3 9.6C17.6314 9.15817 17.5418 8.53137 17.1 8.2C16.6582 7.86863 16.0314 7.95817 15.7 8.4L13.3918 11.4776L11.2071 9.29289C11.0021 9.08791 10.7183 8.98197 10.4291 9.00252C10.1399 9.02307 9.87393 9.16809 9.7 9.4L6.7 13.4C6.36863 13.8418 6.45817 14.4686 6.9 14.8C7.34183 15.1314 7.96863 15.0418 8.3 14.6L10.6082 11.5224L12.7929 13.7071C12.9979 13.9121 13.2817 14.018 13.5709 13.9975C13.8601 13.9769 14.1261 13.8319 14.3 13.6L17.3 9.6Z"
                        fill="var(--text-color)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                        fill="var(--text-color)"
                      />
                    </svg>

                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                        fill="var(--text-color)"
                      />
                      <path
                        d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                        fill="var(--text-color)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                        fill="var(--text-color)"
                      />
                    </svg>
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z"
                        fill="var(--text-color)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                        fill="var(--text-color)"
                      />
                    </svg>
                  </IconContainer>
                </Box>
              </DropDown>
            </GridItems>

            {/* Service Section */}
            <GridItems>
              <ButtonWrapper>
                <Button onClick={() => toggleBox(1)}>
                  Service{" "}
                  <ButtonIcon role="presentation">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                      viewBox="0 0 28 16"
                      style={{ width: "12px" }}
                    >
                      <path
                        d="M1.57 1.59l12.76 12.77L27.1 1.59"
                        strokeWidth=""
                        stroke="var(--transparent-border-color)"
                        fill="none"
                      ></path>
                    </svg>
                  </ButtonIcon>
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[1]}>
                <Box $isOpen={isOpenBox[1]}>
                  <List>
                    <ListItem>program</ListItem>
                    <ListItem>account</ListItem>
                    <ListItem>1=help</ListItem>
                    <ListItem>account</ListItem>
                    <ListItem>1=help</ListItem>
                  </List>
                </Box>
              </DropDown>
            </GridItems>

            <GridItems>
              <ButtonWrapper>
                <Button onClick={() => toggleBox(2)}>
                  Service{" "}
                  <ButtonIcon role="presentation">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                      viewBox="0 0 28 16"
                      style={{ width: "12px" }}
                    >
                      <path
                        d="M1.57 1.59l12.76 12.77L27.1 1.59"
                        strokeWidth=""
                        stroke="var(--transparent-border-color)"
                        fill="none"
                      ></path>
                    </svg>
                  </ButtonIcon>
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[2]}>
                <Box $isOpen={isOpenBox[2]}>
                  <List>
                    <ListItem>program</ListItem>
                    <ListItem>account</ListItem>
                    <ListItem>1=help</ListItem>
                    <ListItem>account</ListItem>
                    <ListItem>1=help</ListItem>
                  </List>
                </Box>
              </DropDown>
            </GridItems>

            <GridItems>
              <ButtonWrapper>
                <Button onClick={() => toggleBox(3)}>
                  Service{" "}
                  <ButtonIcon role="presentation">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                      viewBox="0 0 28 16"
                      style={{ width: "12px" }}
                    >
                      <path
                        d="M1.57 1.59l12.76 12.77L27.1 1.59"
                        strokeWidth=""
                        stroke="var(--transparent-border-color)"
                        fill="none"
                      ></path>
                    </svg>
                  </ButtonIcon>
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[3]}>
                <Box $isOpen={isOpenBox[3]}>
                  <List>
                    <ListItem>program</ListItem>
                    <ListItem>account</ListItem>
                    <ListItem>1=help</ListItem>
                    <ListItem>account</ListItem>
                    <ListItem>1=help</ListItem>
                  </List>
                </Box>
              </DropDown>
            </GridItems>
          </GridContainer>

          <div>
            <div
              class="trustpilot-widget"
              data-locale="en-US"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="662a3aa81f7b950cd88686c2"
              data-style-height="52px"
              data-style-width="100%"
            >
              <a
                href="https://www.trustpilot.com/review/snusplus.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div>
            <PaymentIconContainer>
              <img src={Mastercard} alt="visa" />
              <img src={Visa} alt="visa" />
              <img src={Bitcoin} alt="visa" />
              <img src={UPS} alt="visa" />
            </PaymentIconContainer>
            <PaymentText>
              Fast and secure store for nicotine pouches online
            </PaymentText>
          </div>
          <FooterToolbar />
        </Wrapper>
      </Container>
    </div>
  );
};

export default Footer;
