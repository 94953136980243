import React, { useContext, useEffect, useState } from "react";
import { PRODUCT_CHUNK } from "../../../utils/global_const";
import { ProductContext } from "../../../context/ProductContext";
import styled from "styled-components";
import Filter from "../../../components/product/Filter";
import SortProducts from "../../../components/product/SortProducts";
import ProductCardBG from "../../../components/product/ProductCardBG";
import { useParams } from "react-router-dom";
import HeroCategory from "../../../components/pages/HeroCategory";
import Subscription from "../../../components/pages/Subscription";
import ShopSort from "../../../components/product/ShopSort";
import { useTranslation } from "react-i18next";

const Wrapper = styled.main`
  background-color: var(--background-color-hover);
  //padding-top: calc(var(--navbar-height-desktop));
  //padding-top: calc(var(--navbar-height) + var(--navbar-mini));
  @media (min-width: 768px) {
    //padding-top: calc(var(--navbar-height-desktop));
    padding: 0;
  }
`;
const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${(props) =>
    !props.$category ? "calc(var(--navbar-height) * 2)" : "0"};
`;
const ProductGrid = styled.div`
  display: grid;
  width: ${(props) => (props.$first ? "100%" : "calc(100% - 24px)")};
  grid-template-columns: ${(props) =>
    props.$first ? "repeat(2, 1fr)" : "1fr"};
  gap: var(--spacing-lg) 4px;

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1025px) {
    width: var(--max-width-container);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;
  }
`;

const FlexDiv = styled.div`
  padding: var(--spacing-md) 0;
  display: flex;
  width: 94%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row-reverse;
  @media (min-width: 768px) {
    max-width: 90%;
    flex-direction: row;
  }
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const BarWrapper = styled.div`
  width: 100%;
  max-width: var(--max-width-container);

  position: relative;
  background-color: var(--background-color);
  height: 8px;
  border-radius: var(--border-radius-large);
  margin-top: var(--spacing-md);
  background-color: var(--background-color-hover);
`;
const BarScore = styled.div`
  position: absolute; // Make BarScore absolute to fill the Container from start
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: var(--primary-color);
  width: ${(props) => props.percentage || 0}%; // Use the percentage prop
  border-radius: var(--border-radius-large);
  transition: width 0.3s;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  width: 200px;
`;
const VisibleProductText = styled.div`
  text-align: center;
  font-size: var(--font-size-small);
`;
const MoreButton = styled.button`
  width: 100%;
  border-radius: 0;
`;
const ProductView = styled.div`
  /* border: none;

  font-size: var(--font-size-base);
  font-weight: 100;
  text-transform: capitalize;
  color: var(--text-color);
  display: flex;
  gap: 14px;
  cursor: pointer;
  background-color: var(--background-color);
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--transparent-border-color);
  @media (min-width: 768px) {
    display: none;
  } */
`;
const SVGView = styled.svg`
  background-color: var(--background-color);
  border: 1px solid var(--transparent-border-color);
  padding: var(--spacing-sm) var(--spacing-md);
  //height: calc(var(--font-size-base) + 10);
  @media (min-width: 768px) {
    display: none;
  }
`;

const ShopMain = ({ sortedProducts }) => {
  const { t } = useTranslation();
  const { filteredProducts, products, category, isFirst, setIsFirst } =
    useContext(ProductContext);
  const [visibleProducts, setVisibleProducts] = useState(PRODUCT_CHUNK); // Prikazuje prvih 10 proizvoda

  useEffect(() => {
    window.scrollTo(0, 0);
    if (PRODUCT_CHUNK > filteredProducts.length) {
      setVisibleProducts(filteredProducts.length);
    } else {
      setVisibleProducts(PRODUCT_CHUNK);
    }
  }, [filteredProducts]);

  const loadMoreProducts = () => {
    if (!sortedProducts) {
      setVisibleProducts((prevVisibleProducts) => {
        const potentialNextTotal = prevVisibleProducts + PRODUCT_CHUNK; // Potencijalni novi ukupni broj
        // Proverava da li potencijalni novi ukupni broj premašuje ukupan broj proizvoda
        if (potentialNextTotal > filteredProducts.length) {
          return filteredProducts.length; // Ako da, postavi na ukupan broj proizvoda (dodaje samo preostale)
        } else {
          return potentialNextTotal; // Ako ne, dodaje celu veličinu čanka
        }
      });
      //ponavljanje
    } else {
      setVisibleProducts((prevVisibleProducts) => {
        const potentialNextTotal = prevVisibleProducts + PRODUCT_CHUNK; // Potencijalni novi ukupni broj
        // Proverava da li potencijalni novi ukupni broj premašuje ukupan broj proizvoda
        if (potentialNextTotal > sortedProducts.length) {
          return sortedProducts.length; // Ako da, postavi na ukupan broj proizvoda (dodaje samo preostale)
        } else {
          return potentialNextTotal; // Ako ne, dodaje celu veličinu čanka
        }
      });
    }
  };
  //Ponavljanje
  useEffect(() => {
    if (sortedProducts) {
      if (PRODUCT_CHUNK > sortedProducts.length) {
        setVisibleProducts(sortedProducts.length);
      } else {
        setVisibleProducts(PRODUCT_CHUNK);
      }
    }
  }, [sortedProducts]);
  const percentage = !sortedProducts
    ? (visibleProducts / filteredProducts.length) * 100
    : (visibleProducts / sortedProducts.length) * 100;

  return (
    <Wrapper>
      {category && <HeroCategory />}
      {!sortedProducts ? (
        <ProductWrapper $category={category}>
          {products.length && (
            <>
              <FlexDiv>
                <Filter />
                <ProductView onClick={() => setIsFirst(!isFirst)}>
                  {isFirst ? (
                    <SVGView
                      width="14"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="14"
                        width="6"
                        height="14"
                        transform="rotate(90 14 0)"
                        fill="#5693BF"
                      />
                      <rect
                        opacity="0.5"
                        x="14"
                        y="8"
                        width="6"
                        height="14"
                        transform="rotate(90 14 8)"
                        fill="#5693BF"
                      />
                      <rect
                        opacity="0.2"
                        x="14"
                        y="16"
                        width="6"
                        height="14"
                        transform="rotate(90 14 16)"
                        fill="#5693BF"
                      />
                    </SVGView>
                  ) : (
                    <SVGView
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="6" height="5" fill="#5693BF" />
                      <rect x="8" width="6" height="5" fill="#5693BF" />
                      <g opacity="0.6">
                        <rect y="7" width="6" height="5" fill="#5693BF" />
                        <rect x="8" y="7" width="6" height="5" fill="#5693BF" />
                      </g>
                      <g opacity="0.2">
                        <rect y="14" width="6" height="5" fill="#5693BF" />
                        <rect
                          x="8"
                          y="14"
                          width="6"
                          height="5"
                          fill="#5693BF"
                        />
                      </g>
                    </SVGView>
                  )}
                </ProductView>
                <ShopSort />
              </FlexDiv>
              {filteredProducts.length && (
                <>
                  <ProductGrid $first={!isFirst}>
                    {filteredProducts
                      .slice(0, visibleProducts)
                      .map((product, index) => (
                        <ProductCardBG
                          key={index}
                          product={product}
                          isFirst={isFirst}
                        />
                      ))}
                  </ProductGrid>
                  <ButtonWrapper>
                    <BarWrapper>
                      <BarScore percentage={percentage} />
                    </BarWrapper>
                    <VisibleProductText>
                      {t("SHOP.SHOWED_PRODUCTS", {
                        visibleProducts,
                        filteredProducts: filteredProducts.length,
                      })}
                    </VisibleProductText>
                    {visibleProducts < filteredProducts.length && ( // Prikazuje dugme "More" samo ako ima još proizvoda za učitavanje
                      <MoreButton onClick={loadMoreProducts}>
                        {t("BUTTONS.LOAD_MORE")}
                      </MoreButton>
                    )}
                  </ButtonWrapper>
                </>
              )}
            </>
          )}
        </ProductWrapper>
      ) : (
        //ponavljanje
        <ProductWrapper>
          {sortedProducts.length && (
            <>
              <ProductGrid>
                {sortedProducts
                  .slice(0, visibleProducts)
                  .map((product, index) => (
                    <ProductCardBG key={index} product={product} />
                  ))}
              </ProductGrid>
              <ButtonWrapper>
                <BarWrapper>
                  <BarScore percentage={percentage} />
                </BarWrapper>
                <VisibleProductText>
                  {t("SHOP.SHOWED_PRODUCTS", {
                    visibleProducts,
                    filteredProducts: filteredProducts.length,
                  })}
                </VisibleProductText>
                {visibleProducts < sortedProducts.length && ( // Prikazuje dugme "More" samo ako ima još proizvoda za učitavanje
                  <MoreButton onClick={loadMoreProducts}>Load More</MoreButton>
                )}
              </ButtonWrapper>
            </>
          )}
        </ProductWrapper>
      )}
      <Subscription />
    </Wrapper>
  );
};

export default ShopMain;
