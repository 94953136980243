import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import { useEffect, useState } from "react";
import FooterMain from "../layouts/footer/FooterMain";
import { ProductContext } from "../context/ProductContext";
import { AuthUserContext } from "../context/AuthUserContext";
import { useNavigation } from "../utils/navigation";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;

const ThanksContainer = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MaxWidthWrapper = styled.div`
  margin: var(--spacing-md) 0;
  width: calc(100% - var(--spacing-xl) * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const Text = styled.p`
  color: var(--p-color);
`;
const Title = styled.h3`
  font-size: var(--font-size-xlarge);
  margin: 0;
  margin-top: var(--spacing-lg);
  font-weight: 400;
`;
const BrT = styled.div`
  height: 1px;
  background-color: var(--text-color);
  width: 30%;
  margin: var(--spacing-md) 0;
  opacity: 0.1;
`;
const Blogs = () => {
  const { t, i18n } = useTranslation();
  const { createdOrder } = useContext(ProductContext);
  const { setSelectedItem, userProfile } = useContext(AuthUserContext);
  const { goToProfile } = useNavigation();
  const navigate = useNavigate();
  return (
    <>
      {createdOrder && userProfile && (
        <Container>
          <PrimaryHeader />

          <ThanksContainer>
            <MaxWidthWrapper>
              <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1027_11010)">
                  <g style={{ mixBlendMode: "multiply" }}>
                    <path
                      d="M99.7873 27.065C99.7873 27.0379 99.7873 27.0098 99.7873 26.9827C99.7432 14.0125 89.2154 3.51221 76.2349 3.51221C66.8946 3.51221 58.8242 8.94929 55.0158 16.8309H22.6782V97.6572L24.6919 100L26.7052 97.6572L28.7189 100L30.7321 97.6572L32.7449 100L34.7586 97.6572L36.7719 100L38.7847 97.6572L40.7984 100L42.8117 97.6572L44.8254 100L46.8386 97.6572L48.8514 100L50.8651 97.6572L52.8784 100L54.8916 97.6572L56.9049 100L58.9182 97.6572L60.9314 100L62.9447 97.6572L64.9584 100L66.9716 97.6572L68.9849 100L70.9986 97.6572L73.0118 100L75.0255 97.6572L77.0388 100L79.052 97.6572L81.0657 100L83.079 97.6572L85.0927 100L87.106 97.6572V47.9654C94.644 44.0377 99.7908 36.1525 99.7908 27.065H99.7873Z"
                      fill="#E6E6E6"
                    />
                  </g>
                  <path
                    d="M83.5912 13.3188V94.1451L81.5779 96.4882L79.5642 94.1451L77.5509 96.4882L75.5373 94.1451L73.524 96.4882L71.5112 94.1451L69.4975 96.4882L67.4842 94.1451L65.471 96.4882L63.4577 94.1451L61.4445 96.4882L59.4312 94.1451L57.4179 96.4882L55.4047 94.1451L53.3914 96.4882L51.3786 94.1451L49.3654 96.4882L47.3521 94.1451L45.3384 96.4882L43.3261 94.1451L41.3128 96.4882L39.2991 94.1451L37.2863 96.4882L35.2726 94.1451L33.2598 96.4882L31.2465 94.1451L29.2333 96.4882L27.2205 94.1451L25.2072 96.4882L23.1935 94.1451L21.1807 96.4882L19.167 94.1451V13.3188H83.5912Z"
                    fill="#F9F9F9"
                  />
                  <g style={{ mixBlendMode: "multiply" }}>
                    <path
                      d="M83.5907 13.3188V47.1823C82.1552 47.8642 80.6391 48.405 79.0612 48.7865C77.279 49.2178 75.4179 49.4462 73.5031 49.4462C60.4955 49.4462 49.9507 38.9018 49.9507 25.8942C49.9507 21.2699 51.2834 16.9572 53.585 13.3188H83.5907Z"
                      fill="#E6E6E6"
                    />
                  </g>
                  <path
                    d="M96.2756 23.5528C96.2756 34.6455 88.6067 43.9475 78.2814 46.4455C76.4991 46.8768 74.6377 47.1052 72.7232 47.1052C59.7156 47.1052 49.1704 36.5604 49.1704 23.5528C49.1704 10.5452 59.7152 0 72.7228 0C85.7304 0 96.2311 10.5003 96.2752 23.4705C96.2752 23.4976 96.2752 23.5257 96.2752 23.5528H96.2756Z"
                    fill="#39B54A"
                  />
                  <g style={{ mixBlendMode: "multiply" }}>
                    <path
                      d="M88.5882 19.6784L69.9303 34.936C69.5506 35.2463 69.1002 35.4128 68.6453 35.4417C67.9433 35.4866 67.2312 35.2036 66.7518 34.6173L59.3913 25.6167C58.6013 24.6508 58.7441 23.2277 59.71 22.4377L61.8947 20.651C61.9338 20.6189 61.9735 20.5886 62.014 20.5597C62.5793 20.574 63.141 20.797 63.5723 21.2283L68.258 25.914L68.7303 25.4417L69.2173 26.0373L83.9421 13.996C84.7901 13.303 85.9905 13.3279 86.8051 13.9983L87.1167 14.3099C87.1167 14.3099 87.1193 14.313 87.1207 14.3148L87.4242 14.6864L88.9069 16.4994C89.6966 17.4653 89.5537 18.8883 88.5878 19.6784H88.5882Z"
                      fill="#CCCCCC"
                    />
                  </g>
                  <g style={{ mixBlendMode: "multiply" }}>
                    <path
                      d="M96.2759 23.5527C96.2759 34.6454 88.607 43.9474 78.2817 46.4454L66.6419 34.8057L58.3196 26.4829C57.4267 25.59 57.4267 24.142 58.3196 23.2491L60.3395 21.2292C60.8011 20.7681 61.4105 20.5451 62.0149 20.5606C62.5802 20.5749 63.142 20.7979 63.5733 21.2292L68.259 25.9149L68.7313 25.4427L81.8737 12.3002C82.7475 11.4264 84.1527 11.4073 85.0492 12.2441L96.2759 23.4708C96.2759 23.4979 96.2759 23.526 96.2759 23.5531V23.5527Z"
                      fill="#E6E6E6"
                    />
                  </g>
                  <path
                    d="M87.1269 17.5534L69.8751 34.8052C69.5275 35.1524 69.0962 35.3647 68.6457 35.4412C67.9393 35.5623 67.1866 35.35 66.6409 34.8052L58.3186 26.4824C57.4257 25.5895 57.4257 24.1415 58.3186 23.2486L60.3385 21.2287C60.8001 20.7676 61.4095 20.5446 62.0139 20.5601C62.5792 20.5744 63.141 20.7974 63.5723 21.2287L68.258 25.9144L68.7303 25.4422L81.8728 12.2997C82.7465 11.4259 84.1517 11.4068 85.0482 12.2436C85.0678 12.2619 85.0874 12.2806 85.1065 12.2997L86.8051 13.9983L87.1264 14.3196C87.1456 14.3388 87.1638 14.3579 87.1821 14.3775C87.2733 14.4745 87.3539 14.5782 87.4247 14.6864C88.0047 15.5731 87.9054 16.7744 87.1264 17.5534H87.1269Z"
                    fill="white"
                  />
                  <path
                    d="M26.6923 83.5688H37.3977C38.2323 83.5688 38.9093 84.2454 38.9093 85.0805C38.9093 85.9151 38.2328 86.5921 37.3977 86.5921H26.6923C25.8577 86.5921 25.1807 85.9155 25.1807 85.0805C25.1807 84.2459 25.8572 83.5688 26.6923 83.5688Z"
                    fill="#E6E6E6"
                  />
                  <path
                    d="M47.2766 76.6919H26.6923C25.8574 76.6919 25.1807 77.3686 25.1807 78.2033C25.1807 79.038 25.8574 79.7147 26.6923 79.7147H47.2766C48.1115 79.7147 48.7882 79.038 48.7882 78.2033C48.7882 77.3686 48.1115 76.6919 47.2766 76.6919Z"
                    fill="#E6E6E6"
                  />
                  <path
                    d="M37.6123 43.2129H26.4777C25.7614 43.2129 25.1807 43.7936 25.1807 44.51V44.5104C25.1807 45.2267 25.7614 45.8075 26.4777 45.8075H37.6123C38.3286 45.8075 38.9093 45.2267 38.9093 44.5104V44.51C38.9093 43.7936 38.3286 43.2129 37.6123 43.2129Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M74.3944 61.7852H28.3637C26.6057 61.7852 25.1807 63.2102 25.1807 64.9682V68.4735C25.1807 70.2314 26.6057 71.6565 28.3637 71.6565H74.3944C76.1523 71.6565 77.5774 70.2314 77.5774 68.4735V64.9682C77.5774 63.2102 76.1523 61.7852 74.3944 61.7852Z"
                    fill="#E6E6E6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1027_11010">
                    <rect width="100" height="100" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Title>Order placed, thank you!</Title>
              <Text>
                Shipping to: xyz street, near abc shop, Colony, state,
                country...
              </Text>
              <BrT />
              <button
                className="button-transparent"
                onClick={() => {
                  navigate(goToProfile());
                  setSelectedItem("order_history");
                }}
              >
                Review Orders
              </button>
            </MaxWidthWrapper>
          </ThanksContainer>

          <FooterMain />
        </Container>
      )}
    </>
  );
};

export default Blogs;
