import React, { useContext, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import FilterData from "./FilterData";
import { ProductContext } from "../../context/ProductContext";
import { useTranslation } from "react-i18next";
const slideInFromRight = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;
const slideOutRight = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
`;
const ContainerX = styled.div`
  width: 100vw;
`;
const Container = styled.div`
  margin-top: var(--spacing-xxs);
  background-color: var(--transparent-color);
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--zindex-default);
  min-width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
  z-index: 9999;
`;
const Wrapper = styled.div`
  background-color: var(--background-color);
  //padding: 0 var(--spacing-md);

  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  /* animation: ${(props) => (props.$isOpen ? slideInFromRight : slideOutRight)}
    0.5s ease-in forwards; */
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideInFromRight} 0.5s ease-out forwards; // Duže trajanje za slide-in
        `
      : css`
          animation: ${slideOutRight} 0.2s ease-in forwards; // Kraće trajanje za slide-out
        `}
  @media (min-width: 768px) {
    max-width: var(--sidebar-width);
  }
  overflow: auto;
  background-color: var(--background-color-hover);
`;

// Styled komponenta za kontejner koji drži hamburger ikonu
const IconContainer = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`;
const SeltectedFilters = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap; // Omogućava stavkama da pređu u novi red ako nema dovoljno prostora
  gap: 4px; // Razmak između stavki
  width: 100%;

  > div {
    flex: 0 0 auto; // Svaka stavka će zauzeti početnu veličinu zasnovanu na svom sadržaju i neće rasti
  }
`;
const SelectedFilter = styled.button`
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  gap: 8px;
`;
const ButtonSvg = styled.svg`
  width: 20px;
  height: 20px;
  stroke: var(--background-color);
  stroke-width: 7px;
`;

const IconTitle = styled.span`
  border: none;

  font-size: var(--font-size-base);
  font-weight: 100;
  text-transform: capitalize;
  color: var(--text-color);
  display: flex;
  gap: 14px;
  cursor: pointer;

  background-color: var(--background-color);
  border: 1px solid var(--transparent-border-color);
  padding: var(--spacing-sm) var(--spacing-md);
  border: ${({ $totalfilters }) =>
    $totalfilters > 0
      ? "1px solid var(--primary-color)"
      : "1px solid var(--transparent-border-color)"};
  position: relative;
`;
const MenuTitle = styled(IconTitle)`
  border: none;
  background-color: transparent;
`;
const XDiv = styled.div`
  padding: 0 0 0 var(--spacing-md);
  cursor: pointer;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--transparent-border-color);
`;
const SvgContainer = styled.div`
  height: var(--navbar-height);
  width: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
`;
const XRight = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
`;
const A = styled.a`
  text-decoration: underline;
  font-size: var(--font-size-small);
  font-weight: 700;
`;
const XSvg = styled.svg``;
const MiddleDiv = styled.div``;
const SelectedFilterNumber = styled.div`
  position: absolute;
  right: 0;
  top: -10px;
  border-radius: 50%;
  padding: 2px;
  background-color: var(--primary-color);
  font-size: var(--font-size-small);
  color: var(--background-color);
`;

const Filter = () => {
  const { t } = useTranslation();
  const { products } = useContext(ProductContext);
  const [selectedCategoryNames, setSelectedCategoryNames] = useState([]);
  const [selectedFlavours, setSelectedFlavours] = useState([]);
  const [selectedNicotineCategory, setSelectedNicotineCategory] = useState([]);

  // Izračunavanje nivoa nikotina
  const nicotineLevels = products.map((product) =>
    parseFloat(product.nicotine)
  );
  const maxNicotine = Math.ceil(Math.max(...nicotineLevels));
  const minNicotine = Math.floor(Math.min(...nicotineLevels));
  const nicotineRange = Math.ceil((maxNicotine - minNicotine) / 3);

  const [selectedNicotineRange, setSelectedNicotineRange] = useState([
    minNicotine,
    maxNicotine,
  ]);
  useEffect(() => {
    setSelectedNicotineRange([minNicotine, maxNicotine]);
  }, [minNicotine, maxNicotine]);

  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeVisible, setShouldBeVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldBeVisible(true);
    } else {
      const timer = setTimeout(() => {
        setShouldBeVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleResetFilters = () => {
    setSelectedCategoryNames([]);
    setSelectedFlavours([]);
    setSelectedNicotineCategory([]);
    setSelectedNicotineRange([minNicotine, maxNicotine]);
  };

  const totalSelectedFilters =
    selectedCategoryNames.length +
    selectedFlavours.length +
    selectedNicotineCategory.length +
    (selectedNicotineRange[0] !== minNicotine ||
    selectedNicotineRange[1] !== maxNicotine
      ? 1
      : 0);

  return (
    <>
      <IconTitle onClick={toggleMenu} $totalfilters={totalSelectedFilters}>
        {t("SHOP.FILTER")}
        {totalSelectedFilters > 0 && (
          <SelectedFilterNumber>
            {totalSelectedFilters > 10
              ? totalSelectedFilters
              : `0${totalSelectedFilters}`}
          </SelectedFilterNumber>
        )}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_672_13136)">
            <path
              d="M10 4.99992C10 5.44195 10.1756 5.86587 10.4882 6.17843C10.8007 6.49099 11.2246 6.66659 11.6667 6.66659C12.1087 6.66659 12.5326 6.49099 12.8452 6.17843C13.1577 5.86587 13.3333 5.44195 13.3333 4.99992C13.3333 4.55789 13.1577 4.13397 12.8452 3.82141C12.5326 3.50885 12.1087 3.33325 11.6667 3.33325C11.2246 3.33325 10.8007 3.50885 10.4882 3.82141C10.1756 4.13397 10 4.55789 10 4.99992Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33301 5H9.99967"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.333 5H16.6663"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 9.99992C5 10.4419 5.17559 10.8659 5.48816 11.1784C5.80072 11.491 6.22464 11.6666 6.66667 11.6666C7.10869 11.6666 7.53262 11.491 7.84518 11.1784C8.15774 10.8659 8.33333 10.4419 8.33333 9.99992C8.33333 9.55789 8.15774 9.13397 7.84518 8.82141C7.53262 8.50885 7.10869 8.33325 6.66667 8.33325C6.22464 8.33325 5.80072 8.50885 5.48816 8.82141C5.17559 9.13397 5 9.55789 5 9.99992Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33301 10H4.99967"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.33301 10H16.6663"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 14.9999C12.5 15.4419 12.6756 15.8659 12.9882 16.1784C13.3007 16.491 13.7246 16.6666 14.1667 16.6666C14.6087 16.6666 15.0326 16.491 15.3452 16.1784C15.6577 15.8659 15.8333 15.4419 15.8333 14.9999C15.8333 14.5579 15.6577 14.134 15.3452 13.8214C15.0326 13.5088 14.6087 13.3333 14.1667 13.3333C13.7246 13.3333 13.3007 13.5088 12.9882 13.8214C12.6756 14.134 12.5 14.5579 12.5 14.9999Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33301 15H12.4997"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.833 15H16.6663"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_672_13136">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </IconTitle>
      <ContainerX>
        <IconContainer>
          {/* <SeltectedFilters>
            {selectedCategoryNames.map((categoryName, index) => (
              <SelectedFilter
                key={`category-${index}`}
                onClick={() =>
                  setSelectedCategoryNames(
                    selectedCategoryNames.filter(
                      (name) => name !== categoryName
                    )
                  )
                }
              >
                {categoryName}
                <ButtonSvg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  viewBox="0 0 64 64"
                  className="svg-icon"
                >
                  <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
                </ButtonSvg>
              </SelectedFilter>
            ))}
            {selectedFlavours.map((flavour, index) => (
              <SelectedFilter
                key={`flavour-${index}`}
                onClick={() =>
                  setSelectedFlavours(
                    selectedFlavours.filter((f) => f !== flavour)
                  )
                }
              >
                {flavour}
                <ButtonSvg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  viewBox="0 0 64 64"
                  className="svg-icon"
                >
                  <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
                </ButtonSvg>
              </SelectedFilter>
            ))}
            {selectedNicotineCategory.map((nicotineCategory, index) => (
              <SelectedFilter
                key={`nicotine-${index}`}
                onClick={() =>
                  setSelectedNicotineCategory(
                    selectedNicotineCategory.filter(
                      (nc) => nc !== nicotineCategory
                    )
                  )
                }
              >
                {nicotineCategory}
                <ButtonSvg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  viewBox="0 0 64 64"
                  className="svg-icon"
                >
                  <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
                </ButtonSvg>
              </SelectedFilter>
            ))}

            {(selectedNicotineRange[0] !== minNicotine ||
              selectedNicotineRange[1] !== maxNicotine) && (
              <SelectedFilter
                onClick={() =>
                  setSelectedNicotineRange([minNicotine, maxNicotine])
                }
              >
                From: {selectedNicotineRange[0]}MG - To{" "}
                {selectedNicotineRange[1]}
                MG
                <ButtonSvg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  viewBox="0 0 64 64"
                  className="svg-icon"
                >
                  <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
                </ButtonSvg>
              </SelectedFilter>
            )}
          </SeltectedFilters> */}
        </IconContainer>
        <Container
          onClick={toggleMenu}
          $isOpen={isOpen}
          $shouldBeVisible={shouldBeVisible}
        >
          <Wrapper
            $isOpen={isOpen}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <XDiv>
              <MenuTitle>{t("SHOP.FILTER")}</MenuTitle>
              <XRight>
                <A onClick={() => handleResetFilters()}>
                  {t("SHOP.CLEAR_FILTER")}
                </A>
                <SvgContainer>
                  <XSvg
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    viewBox="0 0 64 64"
                    className="svg-icon"
                    onClick={toggleMenu}
                  >
                    <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
                  </XSvg>
                </SvgContainer>
              </XRight>
            </XDiv>
            <MiddleDiv>
              <FilterData
                selectedCategoryNames={selectedCategoryNames}
                setSelectedCategoryNames={setSelectedCategoryNames}
                selectedFlavours={selectedFlavours}
                setSelectedFlavours={setSelectedFlavours}
                selectedNicotineCategory={selectedNicotineCategory}
                setSelectedNicotineCategory={setSelectedNicotineCategory}
                selectedNicotineRange={selectedNicotineRange}
                setSelectedNicotineRange={setSelectedNicotineRange}
                minNicotine={minNicotine}
                maxNicotine={maxNicotine}
                nicotineRange={nicotineRange}
              />
            </MiddleDiv>
          </Wrapper>
        </Container>
      </ContainerX>
    </>
  );
};

export default Filter;
