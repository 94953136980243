import { useEffect, useState } from "react";
import styled from "styled-components";

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px; /* Smanjena širina */
  height: 22px; /* Smanjena visina */
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  transition: 0.4s;
  border-radius: 22px; /* Promenjen radijus zaobljenja da odgovara visini */

  &:before {
    position: absolute;
    content: "";
    height: 18px; /* Smanjena visina thumb-a */
    width: 18px; /* Smanjena širina thumb-a */
    left: 2px; /* Manji levi margina */
    bottom: 2px; /* Manji donji margina */
    background-color: var(--background-color);
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: var(--success-color);
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px var(--text-color);
  }

  &:checked + ${Slider}:before {
    transform: translateX(18px); /* Ažurirano da odgovara smanjenoj širini */
  }
`;
const SmallSVGLeft = styled.svg`
  position: absolute;

  left: 0;
  top: 0;
`;
const SmallSVGRight = styled.svg`
  position: absolute;
  //fill: var(--background-color);
  right: 0;
  top: 2px;
`;

const NeutralToggleSwitch = ({ isOn, setIsOn }) => {
  return (
    <Switch>
      <Input
        type="checkbox"
        checked={isOn}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={() => {}}
      />
      <Slider />
      {isOn ? (
        <SmallSVGLeft
          fill="var(--background-color)"
          width="24px"
          height="24px"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z" />
        </SmallSVGLeft>
      ) : (
        <SmallSVGRight
          width="20px"
          height="20px"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="var(--background-color)"
            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
          />
        </SmallSVGRight>
      )}
    </Switch>
  );
};

export default NeutralToggleSwitch;
