import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import APIService from "../../services/APIService";
import { useTranslation } from "react-i18next";

const BlogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MaxWidthWrapper = styled.div`
  margin: var(--spacing-xxl) 0;
  width: calc(100% - 24px);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const BlogBigTitle = styled.h3`
  font-family: "Larken-Light";
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: var(--font-size-xxlarge);
  color: var(--text-color);

  padding: var(--spacing-md) 0;
`;

const BlogWrapper = styled.div`
  @media (min-width: 768px) {
    display: grid;
    gap: var(--spacing-xl);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "blog1 blog2"
      "blog1 blog3";
  }
`;

const BlogInner = styled.div`
  @media (min-width: 768px) {
    gap: var(--spacing-md);

    ${(props) =>
      props.$isfirst
        ? css`
            grid-area: blog1;
          `
        : props.$issecond
        ? css`
            grid-area: blog2;
            display: flex;
          `
        : css`
            grid-area: blog3;
            display: flex;
          `}
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    align-items: center;
    justify-content: center;
  }
  cursor: pointer;
`;
const BlogPicture = styled.picture`
  width: 100%;
  min-width: 50%;
  height: 100%;
  height: auto;
`;
const BlogImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
`;
const BlogTitle = styled.h3`
  font-size: var(--font-size-large);
  text-transform: capitalize;
  margin: 12px 0;
  font-family: "Satoshi";
  width: 90%;
`;
const BlogTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BlogSubtitle = styled.h6`
  font-size: var(--font-size-small);
  color: var(--primary-color);
  font-weight: 400;
`;
const BlogText = styled.p`
  font-size: var(--font-size-base);
  color: var(--p-color);
`;

const Button = styled.button``;

const RecentBlogs = () => {
  const { t } = useTranslation();
  const { goToBlog } = useNavigation();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const loadBlogs = async () => {
    try {
      const response = await APIService.GetAllBlogs();

      // Sortirajte blogove prema datumu (najsvežiji prvo)
      const sortedBlogs = response.sort(
        (a, b) => new Date(b.published_date) - new Date(a.published_date)
      );

      // Uzmi prva tri bloga iz sortiranog niza
      const topThreeBlogs = sortedBlogs.slice(0, 3);

      setBlogs(topThreeBlogs);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    loadBlogs();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // Dodajte +1 jer getMonth() vraća mesec od 0 do 11
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Koristite `padStart(2, '0')` da biste osigurali da su dan i mesec uvek prikazani sa dve cifre
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };

  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <BlogContainer>
      <MaxWidthWrapper>
        <BlogBigTitle>{t("HERO.RECENT_BLOGS")}</BlogBigTitle>
        <BlogWrapper>
          {blogs.map((post, index) => (
            <BlogInner
              key={post.id}
              $isfirst={index === 0}
              $issecond={index === 1}
              onClick={() => {
                navigate(goToBlog(post.slug));
              }}
            >
              <BlogPicture $isfirst={index === 0} $issecond={index === 1}>
                <source
                  media="(max-width: 768px)"
                  srcSet={require(`../../assets/images/blogs/blog${post.id}.webp`)}
                />
                <BlogImage
                  src={require(`../../assets/images/blogs/blog${post.id}.webp`)}
                  alt={post.title}
                  loading="lazy"
                />
              </BlogPicture>
              <div>
                <BlogSubtitle>{formatDate(post.published_date)}</BlogSubtitle>
                <BlogTitleContainer>
                  <BlogTitle>{post.title}</BlogTitle>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 17L17 7M17 7H7M17 7V17"
                      stroke="var(--text-color)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </BlogTitleContainer>
                <BlogText>
                  {stripHTML(post.paragraphs[0]).split(" ").length > 15
                    ? `${stripHTML(post.paragraphs[0])
                        .split(" ")
                        .slice(0, 15)
                        .join(" ")}...`
                    : stripHTML(post.paragraphs[0])}
                </BlogText>
              </div>
            </BlogInner>
          ))}
        </BlogWrapper>
      </MaxWidthWrapper>
    </BlogContainer>
  );
};

export default RecentBlogs;
