import styled from "styled-components";
import ToggleSwitch from "../../components/animations/ToggleSwitch";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const List = styled.ul`
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: var(--spacing-xxs);
  height: 20px;
`;

const ListItem = styled.li`
  display: inline; /* Display items inline */
  margin-right: var(--spacing-lg); /* Add some space between items */
  font-size: var(--font-size-small);
  cursor: pointer;
`;
const ThemeContainer = styled.div`
  display: none;
  margin-right: 10%;
  @media (min-width: 768px) {
    display: block;
  }
`;

const HeaderToolbar = () => {
  return (
    <Container>
      <List>
        <ListItem>My Loyalty points</ListItem>
        <ListItem>Shop snus</ListItem>
        <ListItem>Customer Service</ListItem>
      </List>
      <ThemeContainer>
        <ToggleSwitch />
      </ThemeContainer>
    </Container>
  );
};

export default HeaderToolbar;
