import styled from "styled-components";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import FooterMain from "../layouts/footer/FooterMain";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../utils/navigation";

const Container = styled.main`
  font-family: "Larken-Light";
  background-color: var(--background-color-hover);
  text-align: center;
`;
const Title = styled.h1`
  color: var(--text-color);
  //font-family: Larken;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: var(--spacing-md) 0;
`;
const Subtitle = styled.h6`
  font-size: var(--font-size-base);
  color: var(--primary-color);
  font-weight: 400;
  padding: 80px 0 0 0;
`;
const Text = styled.h6`
  font-size: var(--font-size-base);
  color: var(--text-color);
  font-weight: 400;
`;

const Button = styled.button`
  background-color: var(--text-color);
  color: var(--background-color);
  &:hover {
    background-color: transparent;
    color: var(--text-color);
  }
  margin: 64px 0 100px 0;
  border-radius: 0;
  width: 200px;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    flex-direction: column;
  }
`;
const SVGIcon = styled.svg`
  display: block;
  width: 60%;
  padding-bottom: var(--spacing-xxl);
  @media (min-width: 768px) {
    width: 100%;
  }
`;

const Error404 = () => {
  const navigate = useNavigate();
  const { goToHome } = useNavigation();
  return (
    <>
      <PrimaryHeader />
      <Container>
        <Subtitle>404 error</Subtitle>
        <Title>Page not found...</Title>
        <Text>
          Sorry, the page you are looking for doesn't exist or has been moved.
          Try searching our site:
        </Text>
        <FlexDiv>
          <Button
            onClick={() => {
              navigate(goToHome());
            }}
          >
            Go To Home
          </Button>

          <SVGIcon
            width="333"
            height="254"
            viewBox="0 0 333 254"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="166.5" cy="133.111" r="120.59" fill="#E4ECF1" />
            <circle cx="39.8812" cy="42.6683" r="12.059" fill="#F2F4F7" />
            <circle cx="299.149" cy="202.449" r="9.04423" fill="#F2F4F7" />
            <circle cx="38.3735" cy="220.538" r="15.0737" fill="#F2F4F7" />
            <circle cx="317.238" cy="81.8598" r="15.0737" fill="#F2F4F7" />
            <circle cx="288.598" cy="29.1019" r="10.5516" fill="#F2F4F7" />
            <g filter="url(#filter0_dd_293_6095)">
              <path
                d="M166.5 83.2964L176.919 78.9324L166.501 83.3703V207.623L262.504 167.393V43.0853L262.972 42.8893L262.504 42.6819V42.4731L262.264 42.5754L167.258 0.461914L70.0283 41.8791L70.4886 42.0768L70.0283 165.88L166.5 207.549L166.5 83.2964Z"
                fill="#F9FAFB"
              />
              <path
                d="M166.5 83.2964V207.549L70.0283 165.88L70.4886 42.0767L166.5 83.2964Z"
                fill="url(#paint0_linear_293_6095)"
              />
              <path
                d="M166.502 83.3704V207.623L262.504 167.393V42.4731L166.502 83.3704Z"
                fill="url(#paint1_linear_293_6095)"
              />
              <path
                d="M166.5 83.2964L262.972 42.8893L167.258 0.461914L70.0283 41.8792L166.5 83.2964Z"
                fill="#F2F4F7"
              />
              <path
                d="M106.395 26.3885L202.498 68.2189L203.371 98.702L230.26 87.6071L229.447 56.9315L130.5 16.1201L106.395 26.3885Z"
                fill="#E4E7EC"
              />
            </g>
            <g filter="url(#filter1_b_293_6095)">
              <rect
                x="124.294"
                y="157.229"
                width="84.4128"
                height="84.4128"
                rx="42.2064"
                fill="#5693BF"
                fillOpacity="0.49"
              />
              <path
                d="M182.329 215.262L174.679 207.612M178.811 197.676C178.811 205.446 172.513 211.745 164.743 211.745C156.973 211.745 150.674 205.446 150.674 197.676C150.674 189.906 156.973 183.607 164.743 183.607C172.513 183.607 178.811 189.906 178.811 197.676Z"
                stroke="white"
                strokeWidth="3.5172"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_dd_293_6095"
                x="50.0283"
                y="0.461914"
                width="232.943"
                height="247.161"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology
                  radius="4"
                  operator="erode"
                  in="SourceAlpha"
                  result="effect1_dropShadow_293_6095"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="4" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_293_6095"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology
                  radius="4"
                  operator="erode"
                  in="SourceAlpha"
                  result="effect2_dropShadow_293_6095"
                />
                <feOffset dy="20" />
                <feGaussianBlur stdDeviation="12" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_dropShadow_293_6095"
                  result="effect2_dropShadow_293_6095"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect2_dropShadow_293_6095"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_b_293_6095"
                x="116.294"
                y="149.229"
                width="100.413"
                height="100.413"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_293_6095"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_293_6095"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_293_6095"
                x1="70.0283"
                y1="166.505"
                x2="108.128"
                y2="63.3212"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#DEE1E8" />
                <stop offset="1" stopColor="#EFF1F5" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_293_6095"
                x1="166.501"
                y1="85.8024"
                x2="275.281"
                y2="107.46"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#E4E7EC" />
                <stop offset="1" stopColor="#F2F4F7" />
              </linearGradient>
            </defs>
          </SVGIcon>
        </FlexDiv>
      </Container>
      <FooterMain />
    </>
  );
};

export default Error404;
