import { useState, useContext } from "react";
import { AuthUserContext } from "../../context/AuthUserContext";
import styled from "styled-components";
import SimpleContext from "../../context/SimpleContext";
import ButtonLoading from "../animations/ButtonLoading";
import { toast } from "react-toastify";
import Error from "../toast/Error";
import { motion } from "framer-motion";
import Success from "../toast/Success";
import { useTranslation } from "react-i18next";

const FormWrapper = styled.div`
  min-width: 90%;
  max-width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ExitContainer = styled.div`
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 10%;
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //gap: 8px;
  margin: 10px 0;
`;

const Title = styled.h1`
  font-size: var(--font-size-xxlarge);
  text-align: center;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: var(--text-color);
  text-transform: uppercase;
  margin-bottom: var(--spacing-sm);
`;
const Subtitle = styled.div`
  font-size: var(--font-size-small);
  color: var(--p2-color);
  text-align: center;

  margin-bottom: var(--spacing-xl);
`;

const Input = styled.input`
  outline: none;
  padding: var(--spacing-sm) 0 var(--spacing-sm) var(--spacing-xs);
  border: 1px solid #ccc;
  width: calc(100% - 8px);
  font-size: var(--font-size-small);
  color: var(--text-color);
  background-color: var(--background-color);
  margin: var(--spacing-xxs) 0 0 0;
  ::placeholder {
    font-size: var(--font-size-small);
    color: var(--p-color);
  }
`;
const Label = styled.label`
  font-size: var(--font-size-base);
  text-transform: capitalize;
  color: var(--text-color);
  text-align: left;
  min-width: 100%;
  font-family: "Larken";
`;

const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 90%;
  text-align: center;
  margin: var(--spacing-xs) 0;
`;
const Button = styled.button`
  width: 100%;
  height: 44px;
  background-color: var(--text-color);
  text-transform: capitalize;
  border-radius: 0;
  border-color: var(--text-color);
  &:hover {
    background-color: var(--background-color);
    color: var(--text-color);
  }
`;
const DivBr = styled.div`
  margin: 10px 0px 20px 0px;
  border-bottom: 1px solid #ccc;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessege = styled(motion.div)`
  font-size: var(--font-size-small);
  text-transform: lowercase;
  padding: 0;
  margin: 0;
  color: var(--error-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--font-size-small);
`;

const showNotification = (text, smalltext) => {
  toast(<Success text={text} smalltext={smalltext} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast",
    progressClassName: "custom-progress-bar",
  });
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const [isAnimating, setIsAnimating] = useState(false);

  const [errorEmailText, setErrorEmailText] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    showNotification(t("NOTIFICATIONS.EMAIL_RECOVERY_SUCCESS"));
  };
  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  return (
    <>
      <Title>{t("LOGIN.FORGOT_PASSWORD_TITLE")}</Title>
      <Subtitle>{t("LOGIN.FORGOT_PASSWORD_SUBTITLE")}</Subtitle>

      <Form onSubmit={handleSubmit}>
        <FormWrapper>
          <Label htmlFor="email">{t("LABELS.EMAIL")}</Label>
          <Input
            type="email"
            id="email"
            value={email}
            placeholder={t("PLACEHOLDERS.EMAIL")}
            onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
            required
          />
        </FormWrapper>

        <ButtonWrapper>
          <Button type="submit">{t("BUTTONS.RECOVER_PASSWORD")}</Button>
          {isAnimating && (
            <ButtonLoading
              isAnimating={isAnimating}
              onComplete={handleAnimationComplete}
            />
          )}
        </ButtonWrapper>
      </Form>
    </>
  );
};

export default ForgotPassword;
