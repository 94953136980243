import styled from "styled-components";
import HearthA from "../../assets/icons/search.png";
import { useContext } from "react";
import { ProductContext } from "../../context/ProductContext";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background-color);
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  //gap: 12px;
`;
const ImageDiv = styled.div`
  width: 23%;
  margin-right: 2%;
  background-color: var(--background-color);
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;
const RightContainer = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spacing-xs);
`;

const FlexColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;
const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;
const Info = styled.span`
  font-size: var(--font-size-small);
`;
const Title = styled.h2`
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
`;

const OrderProduct = ({ product }) => {
  const { maxAttributes } = useContext(ProductContext);
  const nicotinePercentage =
    (parseFloat(product.nicotine) / parseFloat(maxAttributes.nicotine)) * 100;

  const primaryImage = product.images.find((img) => img.is_primary);
  return (
    <Container>
      <ImageDiv>
        {primaryImage && (
          <Image
            src={primaryImage.thumbnail}
            alt={`${product.category_name} ${product.name}`}
            srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
            sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
            loading="lazy"
            className="primary-image"
          />
        )}
      </ImageDiv>
      <RightContainer>
        <FlexColumn>
          <Title>
            {product.category_name} {product.name}
          </Title>{" "}
          <Info>
            {product.format} {product.flavor}
          </Info>
        </FlexColumn>

        <Info>{product.pouches_per_can} nicotine pouches</Info>
      </RightContainer>
    </Container>
  );
};

export default OrderProduct;
