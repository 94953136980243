import { useContext } from "react";

import styled from "styled-components";
import { ProductContext } from "../../context/ProductContext";
import ConffeteBox from "../animations/ConffeteBox";

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  background-color: var(--transparent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: #1a2c38;
  background-color: var(--background-color);
  position: relative;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;

  padding: 60px 0;
  width: 94%;
  @media (min-width: 768px) {
    width: 500px;
  }
`;
const Icon = styled.div`
  font-size: 111px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const Title = styled.h3`
  text-align: center;
  font-family: "Larken-Regular";
  font-size: var(--font-size-xlarge);
  font-style: normal;
  font-weight: 400;
  margin: var(--spacing-lg) 0;
  @media (min-width: 768px) {
    margin: var(--spacing-md) 0;
  }
`;
const Text = styled.h6`
  text-align: center;
  font-size: var(--font-size-base);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 70%;
`;
const ExitContainer = styled.div`
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 10%;
`;

const Conffete = () => {
  const { setActivateConffete } = useContext(ProductContext);
  return (
    <Container>
      <Wrapper>
        <ExitContainer
          onClick={() => {
            setActivateConffete(false);
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            viewBox="0 0 64 64"
            className="svg-icon"
            style={{
              width: "calc(var(--font-size-xxlarge) + 5px)",
              height: "calc(var(--font-size-xxlarge) + 5px)",
            }}
          >
            <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
          </svg>
        </ExitContainer>
        <Icon>🎉</Icon>
        <Title>Congratulation!</Title>
        <Text>
          You will get free shipping as your order is above{" "}
          <strong>$200</strong> now
        </Text>
        <ConffeteBox />
      </Wrapper>
    </Container>
  );
};

export default Conffete;
