import { useContext, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { generateMenuData } from "../../../utils/menu_data";
import { Link, useNavigate } from "react-router-dom";
import ChangePreferencesIcon from "../../../components/account/ChangePreferencesIcon";
import { ProductContext } from "../../../context/ProductContext";
import { useNavigation } from "../../../utils/navigation";
import { AuthUserContext } from "../../../context/AuthUserContext";
import SimpleContext from "../../../context/SimpleContext";
import { useTranslation } from "react-i18next";
const fadeInTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
  }
`;
const fadeOutBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const Container = styled.div`
  background-color: var(--background-color);
  overflow-y: auto; /* Omogućava vertikalno skrolovanje */
  height: calc(100vh - 100px);

  display: flex; /* Dodato za flexbox layout */
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 100%;
  @media (min-width: 768px) {
    //max-width: var(--max-width-container);
  }
`;
const GridContainer = styled.div`
  min-width: 100%;
  height: 100%;
  min-height: 100%;

  overflow-x: hidden;
  position: relative;
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;

const ButtonWrapper = styled.div`
  box-shadow: var(--shadow-small);
  border-bottom: 1px solid var(--transparent-border-color);
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  border-radius: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  font-size: var(--font-size-base);
  font-weight: 600;
  padding: 12px 0;

  &:hover {
    background-color: var(--background-color);
  }
  // min-width: 100%;
`;
const ButtonIcon = styled.span``;

const DropDown = styled.div`
  /* Samo za telefone, primenjuje se display na osnovu $isOpen */
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: none; /* Sakrij ako je otvoreno */
        `
      : css`
          display: flex; /* Prikaži ako nije otvoreno */
          align-items: center;
          justify-content: center;
          background-color: #fff;
        `}
`;

const Box = styled.div`
  width: 90%;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${fadeOutBottom} 0.2s ease-in-out both;
        `
      : css`
          animation: ${fadeInTop} 0.3s ease-in-out both;
        `}
`;

const List = styled.div`
  padding: var(--spacing-md) 0;
  outline: none;
  list-style: none;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
  box-shadow: var(--shadow-large);
`;
const ListItem = styled(Link)`
  text-decoration: none;
  color: var(--text-color);

  padding: 14px 10px;
  background-color: #e6ecf0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(284deg, #fef8f4 1.78%, #ecf4f9 87.76%);
  &:hover svg {
    opacity: 1;
  }
`;

const SvgArrow = styled.svg`
  width: 12px;
  height: 12px;
  transition: transform 0.3s ease; /* Smooth transition */

  transform: rotate(-180deg);
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(-90deg);
    `}
`;
const FlagDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const TButton = styled.button`
  margin-top: 14px;
  padding: var(--spacing-sm) var(--spacing-lg);
  width: 94%;
`;
const LButton = styled(TButton)`
  background-color: var(--text-color);
  color: var(--background-color);
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
const Picture = styled.picture`
  background-color: ${({ color }) => color || "#fff"};

  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const Image = styled.img`
  width: 24px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;
const HamburgerMenuLeftData = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const { categories } = useContext(ProductContext);
  const { setSelectedItem, userProfile, authTokens } =
    useContext(AuthUserContext);
  const { openLoginPopup, openRegisterPopup } = useContext(SimpleContext);
  const navigate = useNavigate();
  const { goToCategory, goToProfile } = useNavigation();

  const [isOpenBox, setIsOpenBox] = useState([true, true, true]);
  const [isOpenContainer, setIsOpenContainer] = useState([true, true, true]);
  const [isBoxAnimating, setIsBoxAnimating] = useState([false, false, false]);

  const toggleBox = (index) => {
    if (isBoxAnimating[index]) {
      return;
    }

    const updatedIsOpenBox = [...isOpenBox];
    updatedIsOpenBox[index] = !updatedIsOpenBox[index];
    setIsOpenBox(updatedIsOpenBox);

    const updatedIsBoxAnimating = [...isBoxAnimating];
    updatedIsBoxAnimating[index] = true;
    setIsBoxAnimating(updatedIsBoxAnimating);

    setTimeout(
      () => {
        const updatedIsOpenContainer = [...isOpenContainer];
        updatedIsOpenContainer[index] = !updatedIsOpenContainer[index];
        setIsOpenContainer(updatedIsOpenContainer);

        updatedIsBoxAnimating[index] = false;
        setIsBoxAnimating(updatedIsBoxAnimating);
      },
      !isOpenContainer[index] ? 200 : 10
    );
  };

  const toggleMenu = () => {
    setIsOpen(false);
  };
  const toggleProfile = (event, item) => {
    event.preventDefault();
    navigate(goToProfile());
    setSelectedItem(item);
    toggleMenu();
  };
  const toggleCategory = (event, category) => {
    event.preventDefault();
    navigate(goToCategory(category.name));
    toggleMenu();
  };

  return (
    <Container>
      <Wrapper>
        <GridContainer>
          {authTokens && (
            <GridItems>
              <ButtonWrapper>
                <Button onClick={() => toggleBox(0)}>
                  Profile
                  <ButtonIcon role="presentation">
                    <SvgArrow
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                      viewBox="0 0 28 16"
                      $isOpen={isOpenBox[0]}
                    >
                      <path
                        d="M1.57 1.59l12.76 12.77L27.1 1.59"
                        strokeWidth=""
                        stroke="var(--text-color)"
                        fill="none"
                      ></path>
                    </SvgArrow>
                  </ButtonIcon>
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[0]}>
                <Box $isOpen={isOpenBox[0]}>
                  {" "}
                  {/* Prikažemo sadržaj sekcije */}
                  <List>
                    <ListItem
                      onClick={(event) => toggleProfile(event, "profile")}
                    >
                      {t("HEADER.PROFILE")}
                    </ListItem>
                    <ListItem>{t("HEADER.ORDERS")}</ListItem>
                    <ListItem>{t("HEADER.SNUSCO_COINS")}</ListItem>
                  </List>
                </Box>
              </DropDown>
            </GridItems>
          )}
          <GridItems>
            <ButtonWrapper>
              <Button onClick={() => toggleBox(1)}>
                {t("HEADER.CATEGORIES")}
                <ButtonIcon role="presentation">
                  <SvgArrow
                    aria-hidden="true"
                    focusable="false"
                    className="svg-icon"
                    viewBox="0 0 28 16"
                    $isOpen={isOpenBox[1]}
                  >
                    <path
                      d="M1.57 1.59l12.76 12.77L27.1 1.59"
                      strokeWidth=""
                      stroke="var(--text-color)"
                      fill="none"
                    ></path>
                  </SvgArrow>
                </ButtonIcon>
              </Button>
            </ButtonWrapper>

            <DropDown $isOpen={isOpenContainer[1]}>
              <Box $isOpen={isOpenBox[1]}>
                {" "}
                {/* Prikažemo sadržaj sekcije */}
                <List>
                  {categories &&
                    categories.length > 0 &&
                    categories.map((category, index) => (
                      <ListItem
                        key={index}
                        onClick={(event) => {
                          toggleCategory(event, category);
                        }}
                      >
                        <FlexDiv>
                          <Picture color={category.color}>
                            <Image
                              src={require(`../../../assets/images/categories/${category.slug}.png`)}
                              alt={category.name}
                              width="34"
                              //height="30"
                            />
                          </Picture>
                          {category.name}
                        </FlexDiv>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </DropDown>
          </GridItems>
          <FlagDiv>
            <ChangePreferencesIcon />
          </FlagDiv>
          {!authTokens && (
            <>
              <TButton
                className="button-transparent"
                onClick={() => {
                  openRegisterPopup();
                  setIsOpen(false);
                }}
              >
                {t("HEADER.SIGN_UP")}
              </TButton>
              <LButton
                className="button-transparent"
                onClick={() => {
                  openLoginPopup();
                  setIsOpen(false);
                }}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_414_6527)">
                    <path
                      d="M10.7135 2.104C11.5376 2.104 12.3432 2.34837 13.0284 2.80621C13.7136 3.26405 14.2477 3.9148 14.563 4.67616C14.8784 5.43752 14.9609 6.27529 14.8001 7.08355C14.6394 7.8918 14.2425 8.63423 13.6598 9.21695C13.0771 9.79967 12.3347 10.1965 11.5264 10.3573C10.7182 10.518 9.88039 10.4355 9.11903 10.1202C8.35767 9.8048 7.70692 9.27075 7.24908 8.58555C6.79125 7.90034 6.54688 7.09476 6.54688 6.27067L6.55104 6.08984C6.59764 5.01713 7.05655 4.00382 7.83206 3.26122C8.60757 2.51862 9.63982 2.10407 10.7135 2.104Z"
                      fill="white"
                    />
                    <path
                      d="M12.3789 12.104C13.484 12.104 14.5438 12.543 15.3252 13.3244C16.1066 14.1058 16.5456 15.1656 16.5456 16.2707V17.104C16.5456 17.546 16.37 17.97 16.0574 18.2825C15.7449 18.5951 15.3209 18.7707 14.8789 18.7707H6.54557C6.10355 18.7707 5.67962 18.5951 5.36706 18.2825C5.0545 17.97 4.87891 17.546 4.87891 17.104V16.2707C4.87891 15.1656 5.31789 14.1058 6.09929 13.3244C6.8807 12.543 7.9405 12.104 9.04557 12.104H12.3789Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_414_6527">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.712891 0.4375)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {t("HEADER.LOGIN")}
              </LButton>
            </>
          )}
        </GridContainer>
      </Wrapper>
    </Container>
  );
};

export default HamburgerMenuLeftData;
