import styled from "styled-components";
import { languages } from "../../utils/global_const";
import { useTranslation } from "react-i18next";

const RadioButtonWrapper = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 100px;
  border: 1px solid var(--primary-color);
  display: inline-block;
  margin-right: 10px;
  position: relative;
`;
const RadioButton = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 100px;
  display: inline-block;

  background-color: ${(props) =>
    props.$isSelected ? "var(--primary-color)" : "transparent"};
  position: absolute;
  top: 2px;
  left: 2px;
`;

const RadioContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  margin: 12px 0;
  font-size: var(--font-size-small);
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  return (
    <div>
      {languages.map((lang) => (
        <RadioContainer
          key={lang.code}
          onClick={() => changeLanguage(lang.code)}
        >
          <RadioButtonWrapper>
            {/* Proveravamo da li je trenutni jezik jednak jezičkom kodu i na osnovu toga postavljamo $isSelected */}
            <RadioButton $isSelected={i18n.language === lang.code} />
          </RadioButtonWrapper>
          {lang.label}
        </RadioContainer>
      ))}
    </div>
  );
};

export default LanguageSelector;
