import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatContext from "../context/ChatContext";
import SimpleContext from "../context/SimpleContext";
import ChatLobby from "./ChatLobby";
import useChatWebSocket from "../services/useWebSocket";
import { AuthUserContext } from "../context/AuthUserContext";
const OtherContainer = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
  gap: 0;
  background-color: orange;
  max-width: 100%;
`;
const OtherMessage = styled.div`
  position: relative;
  background-color: var(--background-color-hover);
  margin: 2px;
  padding: 17px 20px;
  border-radius: 8px;
  word-wrap: break-word; // Allows long words to be broken and wrap onto the next line
  overflow-wrap: break-word;
  max-width: calc(90% - 50px);
  gap: 0;
  width: calc(90% - 50px);
  margin-left: ${(props) => (props.$showavatar ? "5px" : "44px")};
  &::before {
    content: ${(props) => (props.$showavatar ? '""' : "none")};
    position: absolute;
    bottom: 0px;
    left: -4px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 0px 20px 8px; // Adjust this to suit the desired arrow size
    border-color: transparent transparent var(--background-color-hover); // Use the same background color for the chat bubble arrow
  }
`;
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--info-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
`;

const ChatImage = styled.img`
  margin: 0;
  padding: 0;

  transition: transform 0.25s ease; /* Dodajte blagu animaciju za efekat zumiranja */
  cursor: zoom-in;
  &:hover {
    transform: scale(
      1.05
    ); /* Slika se blago povećava prilikom prelaska mišem */
  }
  max-width: 100%;
`;
const UnreadContainer = styled.div`
  position: fixed;
  right: calc((100vw - 1400px) / 2 - 30px);
  bottom: calc(10% + 70px);
  max-height: 40vh;
  overflow-y: scroll;

  max-width: 350px;
  width: 350px;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1400px) {
    right: 10%;
    bottom: 100px;
  }
`;
const ChatNotification = () => {
  const { loadChats, chatHistory, setSelectedChat } = useContext(ChatContext);
  const { showChat, openChatPopup } = useContext(SimpleContext);
  const { userProfile, authTokens } = useContext(AuthUserContext);
  const friendsListRef = useRef(null);
  const scrollToBottom = () => {
    if (friendsListRef.current) {
      friendsListRef.current.scrollTo({
        top: friendsListRef.current.scrollHeight,
        behavior: "smooth", // Dodaje animaciju skrolovanja
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const ws = useChatWebSocket();
  useEffect(() => {
    if (!authTokens) {
      return;
    }
    loadChats();
  }, [authTokens]);
  const goToChat = (chat) => {
    console.log("Aktivirao otvaranje");
    openChatPopup();
    //setSelectedChat(chat);
  };
  return (
    <>
      {!showChat && userProfile && (
        <>
          <UnreadContainer ref={friendsListRef}>
            {chatHistory?.map((chat) => {
              // Only proceed if the chat is active
              if (!chat.is_active) return null;

              return chat.messages
                .filter((msg) => !msg.seen && msg.user !== userProfile.id) // Filters unseen messages from other users
                .map((msg, msgIndex, filteredMessages) => {
                  // Determine if the message is the last one in a group or if the next message is from the current user
                  const isLastInGroup =
                    msgIndex === filteredMessages.length - 1 ||
                    filteredMessages[msgIndex + 1].user === userProfile.id;
                  console.log(msg);
                  return (
                    <OtherContainer
                      key={msgIndex}
                      //onClick={() => goToChat(chat)}
                    >
                      {isLastInGroup && <Avatar>M</Avatar>}
                      <OtherMessage $showavatar={isLastInGroup}>
                        {msg.image_url && (
                          <ChatImage src={msg.image_url} alt="Uploaded" />
                        )}
                        {msg.message}
                      </OtherMessage>
                    </OtherContainer>
                  );
                });
            })}
          </UnreadContainer>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="chat-icon"
            onClick={() => {
              openChatPopup();
              setSelectedChat(null);
            }}
          >
            <path
              d="M15.5 11.5H15.51M11.5 11.5H11.51M7.5 11.5H7.51M15.6953 19.2318L19.1027 20.3676C19.8845 20.6282 20.6282 19.8844 20.3676 19.1027L19.2318 15.6953M15.3 19.1C15.3 19.1 14.0847 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5C20 14 19.1 15.3 19.1 15.3"
              stroke="var(--background-color)"
              fill="none"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </>
      )}
      {showChat && userProfile && <ChatLobby ws={ws} />}
    </>
  );
};

export default ChatNotification;
