import axios from "axios";
import i18next from "i18next";

export default class APIService {
  static URL =
    window.location.protocol === "https:"
      ? //? "https://api.snusplus.com/"
        "https://api.nikotinskevrecice.com/"
      : "http://127.0.0.1:8000/";

  static async GetAllChats(accessToken) {
    const language = i18next.language; // Pretpostavljamo da i18next upravlja trenutnim jezikom
    const response = await axios.get(APIService.URL + `api/chats/`, {
      headers: {
        "Accept-Language": language,
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
  static async UploadFileToS3(file, accessToken) {
    try {
      // Korak 1: Dobijanje presigned URL-a
      const presignedUrlResponse = await axios.post(
        `${APIService.URL}api/generate-presigned-url/`,
        {
          file_name: file.name,
          file_type: file.type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Korak 2: Upload fajla na S3 koristeći presigned URL
      await axios.put(presignedUrlResponse.data.presigned_url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      console.log("Upload na S3 je uspešan.");
      const imageUrl = `https://snus-s3.s3.eu-north-1.amazonaws.com/conversations/${file.name}`;

      // Vraća URL uploadovane slike kako bi se mogao dalje koristiti
      return imageUrl;
    } catch (error) {
      console.error("Greška prilikom upload-a:", error);
      throw error;
    }
  }
}
