import React, { useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import HearthA from "../../assets/icons/search.png";

const Container = styled.div`
  margin: 8px 0;
  width: 96%;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: var(--background-color);
  //height: 200px;
  @media (min-width: 768px) {
    max-width: var(--max-width-container);
  }
`;

const SliderContent = styled.div`
  display: flex;
  transform: translateX(-${(props) => props.$currentIndex * 25}%);
  transition: transform 0.3s ease-out;
  @media (min-width: 768px) {
    //transform: translateX(-${(props) => props.$currentIndex * 30}%);
  }
  @media (min-width: 1024px) {
    //transform: translateX(-${(props) => props.$currentIndex * 25}%);
  }
`;
const SliderItem = styled.div`
  flex: 0 0 auto; // Sprečava rastezanje elemenata
  width: 23%; // Širina pojedinačnih elemenata
  margin-right: 2%;
  background-color: var(--background-color);

  @media (min-width: 768px) {
    width: 30%;
  }
  @media (min-width: 1024px) {
    width: 23%;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;

const OrderItemsSlider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => {
        const maxIndex = getMaxIndex(items.length);
        return Math.min(prevIndex + 1, maxIndex);
      }),
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getMaxIndex = (itemsLength) => {
    const visibleItems = 4; // Broj elemenata koji staju na ekran
    const maxIndex = Math.ceil(
      itemsLength - visibleItems < 0 ? 0 : itemsLength - visibleItems
    );
    return maxIndex;
  };

  return (
    <Container>
      <Wrapper>
        <SliderContainer {...handlers}>
          <SliderContent $currentIndex={currentIndex}>
            {items.map((item, index) => {
              const primaryImage = item.product_details.images.find(
                (img) => img.is_primary
              );
              return (
                <SliderItem key={index}>
                  {primaryImage && (
                    <Image
                      src={primaryImage.thumbnail}
                      alt={`${item.product_details.category_name} ${item.product_details.name}`}
                      srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
                      sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                      loading="lazy"
                      className="primary-image"
                    />
                  )}
                </SliderItem>
              );
            })}
          </SliderContent>
        </SliderContainer>
      </Wrapper>
    </Container>
  );
};

export default OrderItemsSlider;
