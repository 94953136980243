import Lottie from "react-lottie";
import animationData from "../../assets/images/conffeti.json";
import styled, { css } from "styled-components";
import { useContext } from "react";
import { ProductContext } from "../../context/ProductContext";

const Container = styled.div`
  position: fixed;
  background-color: transparent; // Promenjeno u transparent da se ne vidi pozadina
  width: 100vw;
  height: 30vh;
  top: 20%;
  left: 0%;
  z-index: 999999999;
  @media (max-width: 768px) {
    &:not(:first-child) {
      display: none;
    }
  }
  @media (min-width: 768px) {
    width: 20vw;
    height: 30vh;

    ${({ index }) => {
      switch (index) {
        case 1:
          return css`
            left: 30%;
            top: calc(var(--navbar-height-desktop) + 20%);
          `;
        case 5:
          return css`
            left: calc(10% + 30vw);
            top: calc(var(--navbar-height-desktop) + 30vh);
          `;
        case 2:
          return css`
            left: calc(10% + 30vw);
          `;
        case 3:
          return css`
            left: calc(10% + 40vw);
            top: calc(var(--navbar-height-desktop) + 30%);
          `;
        case 6:
          return css`
            left: calc(10% + 40vw);
          `;
        case 4:
          return css`
            left: calc(10% + 50vw);
            top: calc(var(--navbar-height-desktop) + 20%);
          `;
        default:
          return css``;
      }
    }}
  }
`;

const LottieConffete = () => {
  const { setActivateConffete } = useContext(ProductContext);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Handler koji se poziva kada se animacija završi
  const handleAnimationComplete = () => {
    setActivateConffete(false);
  };

  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((index) => (
        <Container key={index} index={index}>
          <Lottie
            options={defaultOptions}
            height={"100%"}
            width={"100%"}
            eventListeners={[
              {
                eventName: "complete", // Događaj koji označava kraj animacije
                callback: handleAnimationComplete, // Funkcija koja se poziva na kraju animacije
              },
            ]}
          />
        </Container>
      ))}
    </>
  );
};

export default LottieConffete;
