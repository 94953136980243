import styled from "styled-components";
import APIService from "../../services/APIService";
import { useState } from "react";
import Warning from "../toast/Warning";
import Success from "../toast/Success";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: rgba(86, 147, 191, 0.09);
  @media (min-width: 1025px) {
    width: 500px;
  }
`;

const InputContainer = styled.form`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--spacing-md);
  @media (min-width: 768px) {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    gap: 0;
  }
`;
const Input = styled.input`
  font-size: var(--font-size-medium);
  font-weight: 400;

  width: calc(90% - var(--spacing-xs) * 2);
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 var(--spacing-xs);
  font-size: var(--font-size-base);
  font-style: normal;
  font-family: Inter;
  border: 1px solid var(--primary-color);
  background-color: var(--background-color);
  &::placeholder {
    color: var(--p-color);

    font-weight: 400;
    line-height: 24px;
  }
  @media (min-width: 768px) {
    width: calc(65% - var(--spacing-xs) * 2);
  }
`;
const Button = styled.button`
  border-radius: 0;

  width: 90%;
  font-size: var(--font-size-base);
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  @media (min-width: 768px) {
    width: 30%;
  }
  background-color: var(--text-color);
  border: 1px solid var(--text-color);
  &:hover {
    color: var(--text-color);
    background-color: transparent;
  }
`;
const showSuccessNotification = (text) => {
  toast(<Success text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast-success",
    progressClassName: "custom-progress-bar",
  });
};
const showWarningNotification = (text) => {
  toast(<Warning text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast-success",
    progressClassName: "custom-progress-bar",
  });
};
const SimpleSubscription = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      return;
    }

    try {
      const response = await APIService.subscribe(email);
      //console.log("Subscription successful:", response);
      showSuccessNotification("Subscription successful");
      // Optionally, handle success (e.g., show a success message)
      setEmail("");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          showWarningNotification("Emai already subscribed.");
        } else {
          showWarningNotification("Subscription failed. Try again later.");
        }
      } else {
        showWarningNotification("Subscription failed. Try again later.");
      }
    }
  };

  const validateEmail = (email) => {
    const trimmedEmail = email.trim();
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(trimmedEmail).toLowerCase());
  };
  return (
    <Container>
      <Wrapper>
        <InputContainer onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder={t("PLACEHOLDERS.EMAIL")}
            aria-label="Subscription"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit">{t("BUTTONS.SUBMIT")}</Button>
        </InputContainer>
      </Wrapper>
    </Container>
  );
};

export default SimpleSubscription;
