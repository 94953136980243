import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

const SAVE_FOR_LATER_KEY = "saveForLater";

const getSaveForLaterFromStorage = () => {
  const saveForLater = window.localStorage.getItem(SAVE_FOR_LATER_KEY);
  if (saveForLater) {
    try {
      const bytes = CryptoJS.AES.decrypt(saveForLater, "my-new-secret-key");
      const decryptedSaveForLater = JSON.parse(
        bytes.toString(CryptoJS.enc.Utf8)
      );
      return decryptedSaveForLater;
    } catch (e) {
      console.error("Error decrypting save for later:", e);
      return [];
    }
  } else {
    return [];
  }
};

const saveSaveForLaterToStorage = (saveForLater) => {
  try {
    const encryptedSaveForLater = CryptoJS.AES.encrypt(
      JSON.stringify(saveForLater),
      "my-new-secret-key"
    ).toString();
    window.localStorage.setItem(SAVE_FOR_LATER_KEY, encryptedSaveForLater);
  } catch (e) {
    console.error("Error encrypting save for later:", e);
  }
};

const initialState = {
  itemsList: getSaveForLaterFromStorage(),
};

const saveForLaterSlice = createSlice({
  name: "saveForLater",
  initialState,
  reducers: {
    addToSaveForLater(state, action) {
      const { product, quantity } = action.payload;

      const existingItem = state.itemsList.find(
        (item) => item.product.id === product.id
      );
      if (existingItem) {
        console.warn("Product already saved for later");
      } else {
        state.itemsList.push({ product, quantity });
        saveSaveForLaterToStorage(state.itemsList);
      }
    },
    removeFromSaveForLater(state, action) {
      const id = action.payload;
      state.itemsList = state.itemsList.filter(
        (item) => item.product.id !== id
      );
      saveSaveForLaterToStorage(state.itemsList);
    },
    resetSaveForLater(state) {
      state.itemsList = [];
      saveSaveForLaterToStorage(state.itemsList);
    },
  },
});

export const saveForLaterActions = saveForLaterSlice.actions;
export default saveForLaterSlice.reducer;
