import styled from "styled-components";
import HeroSupport from "../../components/pages/HeroSupport";

const Container = styled.main``;
const SupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MaxWidthWrapper = styled.div`
  margin: var(--spacing-md) 0;
  width: calc(100% - var(--spacing-xl) * 2);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const SupportGrid = styled.div`
  padding: var(--spacing-xl) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing-xxl);
  }
`;
const SupportItem = styled.div`
  width: 100%;
  background-color: rgba(86, 147, 191, 0.08);
`;
const ItemWrapper = styled.div`
  padding: var(--spacing-xl);
`;
const SVGIcon = styled.svg`
  margin-bottom: var(--spacing-xxl);
  background-color: var(--primary-color);
  padding: var(--spacing-xs);
  border-radius: var(--spacing-xs);
`;
const SupportTitle = styled.h3`
  font-size: var(--font-size-base);
  text-transform: capitalize;
  margin: 12px 0;
`;
const SupportSubtitle = styled.h6`
  font-size: var(--font-size-base);
  color: var(--primary-color);
  font-weight: 400;
`;
const SupportText = styled.p`
  font-size: var(--font-size-small);
  color: var(--p-color);
  padding: 8px 0 20px 0;
`;
const Support = () => {
  const cards = [
    {
      id: 1,
      svg: (
        <SVGIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </SVGIcon>
      ),
      title: "Chat to sales",
      text: "Speak to our friendly team..",
      contact: "aaa@gmail.com",
    },
    {
      id: 2,
      svg: (
        <SVGIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </SVGIcon>
      ),
      title: "Whatsapp",
      text: "We’re here to help.",
      contact: "+12312312",
    },
    {
      id: 3,
      svg: (
        <SVGIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </SVGIcon>
      ),
      title: "Visit us",
      text: "Visit our office HQ.",
      contact: "100 Smith Street Collingwood VIC 3066 AU",
    },
    {
      id: 4,
      svg: (
        <SVGIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </SVGIcon>
      ),
      title: "Call us",
      text: "Mon-Fri from 8am to 5pm.",
      contact: "+1 (555) 000-0000",
    },
  ];
  return (
    <Container>
      <HeroSupport />

      <SupportContainer>
        <MaxWidthWrapper>
          <SupportGrid>
            {cards.map((card, index) => (
              <SupportItem key={card.id}>
                <ItemWrapper>
                  {card.svg}
                  <SupportTitle>{card.title}</SupportTitle>
                  <SupportText>{card.text}</SupportText>
                  <SupportSubtitle>{card.contact}</SupportSubtitle>
                </ItemWrapper>
              </SupportItem>
            ))}
          </SupportGrid>
        </MaxWidthWrapper>
      </SupportContainer>
    </Container>
  );
};

export default Support;
