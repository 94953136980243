import styled from "styled-components";
import ProgressPieBar from "../../../components/account/ProgressPieBar";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthUserContext } from "../../../context/AuthUserContext";
import PageLoading from "../../../components/animations/PageLoading";
import Slider from "react-slider";
import Error from "../../../components/toast/Error";
import { toast } from "react-toastify";

const Container = styled.main``;
const FILTER_POINT_TYPE = "Redeem";

const LEVELS = {
  SILVER: { id: 0, name: "SILVER", minPoints: 0 },
  GOLD: { id: 1, name: "GOLD", minPoints: 750 },
  DIAMOND: { id: 2, name: "DIAMOND", minPoints: 1500 },
};

function calculateLevelProgress(vipPoints) {
  let currentLevel, nextLevel, progressToNextLevel, pointsToNextLevel;

  if (vipPoints >= LEVELS.DIAMOND.minPoints) {
    currentLevel = LEVELS.DIAMOND;
    progressToNextLevel = 100; // Korisnik je dostigao maksimalni nivo
    pointsToNextLevel = 0; // Nema sledećeg nivoa
  } else if (vipPoints >= LEVELS.GOLD.minPoints) {
    currentLevel = LEVELS.GOLD;
    nextLevel = LEVELS.DIAMOND;
    const pointsForNextLevel = LEVELS.DIAMOND.minPoints - LEVELS.GOLD.minPoints;
    progressToNextLevel =
      pointsForNextLevel > 0
        ? ((vipPoints - LEVELS.GOLD.minPoints) / pointsForNextLevel) * 100
        : 0;
    pointsToNextLevel = LEVELS.DIAMOND.minPoints - vipPoints;
  } else {
    currentLevel = LEVELS.SILVER;
    nextLevel = LEVELS.GOLD;
    const pointsForNextLevel = LEVELS.GOLD.minPoints - LEVELS.SILVER.minPoints;
    progressToNextLevel =
      pointsForNextLevel > 0 ? (vipPoints / pointsForNextLevel) * 100 : 0;
    pointsToNextLevel = LEVELS.GOLD.minPoints - vipPoints;
  }

  return {
    currentLevel: currentLevel.name,
    progressToNextLevel: Math.min(progressToNextLevel, 100), // Osigurava da procenat ne premašuje 100%
    pointsToNextLevel, // Broj poena potrebnih do sledećeg nivoa
    nextLevel: nextLevel ? nextLevel.name : null,
  };
}

const Title = styled.h1`
  padding-top: 20px;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
  @media (min-width: 767px) {
    font-size: var(--font-size-xxlarge);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
const BorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderTop = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--background-color-hover);
  padding-bottom: 14px;
  @media (min-width: 767px) {
    border-bottom: 4px solid var(--text-color);
    width: 40px;
  }
`;
const InfoBoxGridContainer = styled.div`
  margin: 50px 0 30px 0;
  @media (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
const InfoBoxGrid = styled.div`
  width: 100%;
  max-width: 1200px;

  @media (min-width: 767px) {
    display: grid;
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
`;
const InfoContainer = styled.div`
  margin: 0 auto;
  width: 96%;
  max-width: 100%;
  height: 100%;
  background-color: var(--background-color-hover);
`;

const InfoBoxTopContainer = styled.div`
  //margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--text-color);
  position: relative;
`;
const InfoBoxTopWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 50px 0 20px 0;
`;
const InfoBoxTopTitle = styled.span`
  display: block;
  color: var(--background-color-hover);
  font-size: var(--font-size-large);
  letter-spacing: 1.1px;
  text-align: center;
`;
const InfoBoxTopText = styled(InfoBoxTopTitle)`
  font-size: var(--font-size-small);
  padding: 10px 0;
`;
const IconWrapper = styled.div`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  background-color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconSVG = styled.svg`
  width: 30px;
  height: 30px;
`;
const Triangle = styled.div`
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: var(--text-color);
  margin: 20px auto -15px;
`;

const InfoBottomContainer = styled.div`
  padding: 20px;
`;
const InfoBoxBottomTitle = styled.span`
  margin: 10px 0;
  display: block;
  color: var(--text-color);
  font-size: var(--font-size-xxlarge);
  letter-spacing: 1.2px;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;
const Button = styled.button`
  width: 50%;
  text-transform: uppercase;
  padding: 18px 0;
`;
const SmallText = styled.h6`
  display: block;
  font-size: var(--font-size-small);
  color: var(--text-color-hover);
  font-weight: 400;
  letter-spacing: 1.1px;
  text-align: center;
`;
const SmallDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;
const SmallSVG = styled.svg`
  width: var(--font-size-xlarge);
  height: var(--font-size-xlarge);
`;
const List = styled.ul`
  list-style-type: none;
  margin: 14px 0;
  padding: 0;
`;
const ListItem = styled.li`
  list-style-type: none; /* Uklanja markere za liste */
  padding: 4px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font-size-base);
  font-weight: 600;
`;
const TickSVG = styled.svg`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--text-color);
`;
const BorderBoxWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderBoxTop = styled.div`
  border-bottom: 6px solid var(--text-color);
  width: 40px;
`;

const InfoBoxBottomTitle2 = styled.span`
  margin: 10px 0;
  display: block;
  color: var(--text-color);
  font-size: var(--font-size-large);
  text-align: center;
`;
const SVGWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 20px 0;
  position: relative;
`;
const MediumText = styled(SmallText)`
  font-weight: 700;
  text-transform: uppercase;
  font-size: var(--font-size-base);
  letter-spacing: normal;
  padding: 8px 0;
`;
const PointsBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  margin: 24px 0;
  gap: 14px;
`;

const ActivityContainer = styled.div`
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 767px) {
    display: none;
  }
`;
const ActivityContainerDesktop = styled.div`
  display: none;
  @media (min-width: 767px) {
    margin: 30px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding-left: 2%;
  }
  @media (min-width: 1100px) {
    padding-left: 1%;
  }
`;
const ActivityWrapperDesktop = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
`;
const Table = styled.table`
  width: 96%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: var(--background-color-hover);
  padding: 12px;
  text-align: left;
  &:first-child {
    width: 70%;
  }
  &:last-child {
    width: 30%;
  }
`;
const ThReversed = styled(Th)`
  &:first-child {
    width: 30%;
  }
  &:last-child {
    width: 70%;
  }
`;

const Td = styled.td`
  background-color: var(--background-color-hover-alt);
  padding: 20px;
  &:first-child {
    font-weight: 300;
    font-size: var(--font-size-small);
  }
  &:last-child {
    font-weight: 600;
  }
`;
const TdReversed = styled(Td)`
  &:first-child {
    font-weight: 600;
    font-size: var(--font-size-base);
  }
  &:last-child {
    font-weight: 300;
    font-size: var(--font-size-small);
  }
`;

const ShowAllButton = styled.button`
  width: 96%;
  background-color: var(--background-color-hover);
  color: var(--text-color);
  border: none;
  border-radius: 0;
  &:hover {
    background-color: var(--background-color-hover-alt);
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  height: 16px;
`;
const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: var(
    --text-color
  ); /* Pretpostavljam da su ovo CSS varijable koje ste već definisali */
  color: var(--background-color);
  border-radius: 50%;
  cursor: grab;
  top: -5px;
  left: 0;
  font-size: var(
    --font-size-small
  ); /* Pretpostavljam da je ovo CSS varijabla koju ste već definisali */
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 0 ? "var(--primary-color)" : "var(--background-color)"};
  border-radius: 20px;
`;
const TrackTitle = styled.div`
  margin: 10px 0;
  color: var(--text-color);
  font-size: var(--font-size-base);
  display: flex;
  justify-content: space-between;
`;
const SpanSlider = styled.span`
  flex: 1;
  max-width: 40%;
  overflow: hidden;
`;
const SliderInput = styled.input`
  /* Uklonite sve podrazumevane stilove za input i dodajte svoje */
  all: unset;
  -moz-appearance: textfield;
  appearance: none;
  max-width: 100%;
`;

const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const showNotification = (text, smalltext) => {
  toast(<Error text={text} smalltext={smalltext} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast",
    progressClassName: "custom-progress-bar",
  });
};
const MyBalance = () => {
  const {
    fetchUserData,
    userProfile,
    userPointsHistory,
    fetchUserPointsHistory,
    createVoucher,
  } = useContext(AuthUserContext);
  useEffect(() => {
    fetchUserData();
    fetchUserPointsHistory();
  }, []);
  const initialRowsToShow = 2;

  const [visibleRows, setVisibleRows] = useState(2);
  const [sliderValue, setSliderValue] = useState(0);
  const showAllPointsData = () => {
    setVisibleRows(userPointsHistory.length);
  };
  const showLessPointsData = () => {
    setVisibleRows(initialRowsToShow);
  };

  const [visibleRedeemedRows, setVisibleRedeemedRows] = useState(2);
  const showAllRedeemedPoints = () => {
    setVisibleRedeemedRows(
      userPointsHistory.filter((row) => row.point_type === FILTER_POINT_TYPE)
        .length
    );
  };

  // Funkcija za smanjivanje broja vidljivih iskorišćenih tačaka na početni broj
  const showLessRedeemedPoints = () => {
    setVisibleRedeemedRows(2); // Vraća na početni broj vidljivih redova
  };
  const levelInfo =
    userProfile && calculateLevelProgress(userProfile.vip_points);

  const handleCreateVoucher = async () => {
    const voucherData = {
      points: sliderValue,
    };
    if (sliderValue < 150) {
      showNotification(
        "Vrednost vaučera je manji od 150.",
        "Izaberite vecu vrednost za vaučer ili nastavite bez njega."
      );
      return;
    }
    try {
      const createdVoucher = await createVoucher(voucherData);
    } catch (error) {
      console.error("Greška prilikom ažuriranja adrese:", error); // Obradite grešku
    }
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "var(--font-size-base) Arial"; // Prilagodite da odgovara vašem fontu i veličini
      const width = context.measureText(sliderValue).width;
      inputRef.current.style.width = `${width + 40}px`; // Dodajte malo dodatnog prostora
    }
  }, [sliderValue]);
  const handleChange = (e) => {
    const value = e.target.value;
    if (
      value === "" ||
      (Number(value) <= userProfile.points && Number(value) >= 0)
    ) {
      setSliderValue(value);
    }
  };
  return (
    <Container>
      {userProfile && userPointsHistory ? (
        <>
          <Title>My Balance</Title>
          <BorderWrapper>
            <BorderTop />
          </BorderWrapper>

          <InfoBoxGridContainer>
            <InfoBoxGrid>
              <InfoContainer>
                <InfoBoxTopContainer>
                  <IconWrapper>
                    <IconSVG
                      viewBox="0 0 56 56"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="var(--text-color)"
                        stroke="var(--background-color-hover)"
                        strokeWidth="3"
                        d="M 27.9999 51.9063 C 41.0546 51.9063 51.9063 41.0781 51.9063 28 C 51.9063 14.9453 41.0312 4.0937 27.9765 4.0937 C 14.8983 4.0937 4.0937 14.9453 4.0937 28 C 4.0937 41.0781 14.9218 51.9063 27.9999 51.9063 Z M 22.2577 17.5469 C 24.8124 17.5469 26.8046 19 27.9999 21.1797 C 29.1718 19 31.2109 17.5469 33.7187 17.5469 C 37.7733 17.5469 40.6796 20.5937 40.6796 24.8125 C 40.6796 31.0937 34.0468 36.8594 29.1718 39.9766 C 28.7968 40.2109 28.3514 40.5391 28.0468 40.5391 C 27.7421 40.5391 27.2265 40.2109 26.8280 39.9766 C 21.9530 36.8594 15.2968 31.0937 15.2968 24.8125 C 15.2968 20.5937 18.2265 17.5469 22.2577 17.5469 Z"
                      />
                    </IconSVG>
                  </IconWrapper>
                  <InfoBoxTopWrapper>
                    <InfoBoxTopTitle>My Commision</InfoBoxTopTitle>
                    <InfoBoxTopText>
                      refer friends to earn commisions
                    </InfoBoxTopText>
                  </InfoBoxTopWrapper>
                  <Triangle />
                </InfoBoxTopContainer>
                <InfoBottomContainer>
                  <InfoBoxBottomTitle>
                    {userProfile.points} points
                  </InfoBoxBottomTitle>
                  <div>
                    <TrackTitle>
                      <SpanSlider>
                        <SliderInput
                          ref={inputRef}
                          max={userProfile.points}
                          type="number"
                          value={sliderValue}
                          onChange={handleChange}
                          placeholder="Enter value manually"
                        />{" "}
                        points
                      </SpanSlider>
                      <SpanSlider>
                        {(sliderValue * 0.01).toFixed(2)}$
                      </SpanSlider>
                    </TrackTitle>
                    <StyledSlider
                      min={0}
                      max={userProfile.points}
                      value={sliderValue}
                      defaultValue={0}
                      onChange={(value) => setSliderValue(value)}
                      renderThumb={Thumb}
                      renderTrack={Track}
                      trackClassName="example-track"
                    />
                  </div>
                  <ButtonWrapper>
                    <Button onClick={() => handleCreateVoucher()}>
                      Create Voucher
                    </Button>
                  </ButtonWrapper>
                  <InfoBoxBottomTitle2>Your Status:</InfoBoxBottomTitle2>

                  <SmallDiv>
                    <SmallText>{userProfile.vip_points} VIP points</SmallText>
                    <SmallSVG
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_429_11254)">
                        <path
                          d="M10 17L15 12"
                          stroke="var(--text-color-hover)"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 12L10 7"
                          stroke="var(--text-color-hover)"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_429_11254">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </SmallSVG>
                  </SmallDiv>
                  <SmallText>
                    $0.01 (${userProfile.points * 0.01} available)
                  </SmallText>
                  <BorderBoxWrapper>
                    <BorderBoxTop />
                  </BorderBoxWrapper>
                  <List>
                    <ListItem>
                      <TickSVG
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                          fill="var(--success-color)"
                        />
                      </TickSVG>
                      1x Points Multiplier
                    </ListItem>
                    <ListItem>
                      <TickSVG
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                          fill="var(--success-color)"
                        />
                      </TickSVG>
                      Refferal Reward: 200 Points
                    </ListItem>
                    <ListItem>
                      {" "}
                      <TickSVG
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                          fill="var(--background-color-hover)"
                        />
                      </TickSVG>
                      Subscription Reward: 100 Points
                    </ListItem>
                  </List>
                </InfoBottomContainer>
              </InfoContainer>
              <ActivityContainer>
                <Table>
                  <thead>
                    <tr>
                      <Th>Activity</Th>
                      <Th>Points</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {userPointsHistory
                      .slice(0, visibleRows)
                      .map((data, index) => (
                        <tr key={index}>
                          <Td>
                            {data.point_type}, {data.reason}
                          </Td>
                          <Td>{data.points}</Td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {visibleRows < userPointsHistory.length && (
                  <ShowAllButton onClick={showAllPointsData}>
                    Prikaži vise
                  </ShowAllButton>
                )}
                {visibleRows === userPointsHistory.length && (
                  <ShowAllButton onClick={showLessPointsData}>
                    Prikaži manjee
                  </ShowAllButton>
                )}
              </ActivityContainer>

              <InfoContainer>
                <InfoBoxTopContainer>
                  <IconWrapper>
                    <IconSVG
                      viewBox="0 0 76 76"
                      version="1.1"
                      baseProfile="full"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="38"
                        cy="38"
                        r="35"
                        fill="none"
                        stroke="var(--background-color-hover)"
                        strokeWidth="4"
                      />
                      <path
                        fill="var(--background-color-hover)"
                        fillOpacity="1"
                        strokeWidth="0.2"
                        strokeLinejoin="round"
                        d="M 26.455,50.6456L 20.4642,50.633L 13.1944,28.0567L 18.815,28.0567L 21.5748,37.5809L 22.6729,41.5697L 23.5942,45.6336L 23.6952,45.6336C 24.2785,42.9115 24.9629,40.2696 25.7482,37.708L 28.6426,28.0567L 34.0944,28.0567L 26.455,50.6456 Z M 36.8389,28.0567L 41.8633,28.0567L 41.8633,50.6456L 36.8389,50.6456L 36.8389,28.0567 Z M 46.6344,50.6456L 46.6344,28.1622C 48.1506,27.8878 50.4422,27.74 53.5091,27.7189C 56.6418,27.7189 58.942,28.2889 60.4098,29.4289C 61.1502,29.9743 61.7325,30.7025 62.1567,31.6138C 62.581,32.525 62.7972,33.5769 62.8056,34.7696C 62.8049,35.9653 62.6316,37.04 62.2857,37.9935C 61.9397,38.947 61.4254,39.7191 60.7426,40.31C 59.8692,41.0621 58.809,41.6219 57.5619,41.9895C 56.3148,42.3571 54.9303,42.5402 53.4082,42.5389L 51.6589,42.4133L 51.6589,50.6456L 46.6344,50.6456 Z M 51.6589,31.572L 51.6589,38.7218C 52.0626,38.8236 52.6345,38.8715 53.3746,38.8656C 54.7503,38.8551 55.8253,38.5078 56.5997,37.8236C 57.374,37.1395 57.7678,36.1814 57.7811,34.9493C 57.7748,33.8265 57.4259,32.9552 56.7342,32.3354C 56.0426,31.7156 55.0461,31.4012 53.7446,31.3922C 52.7298,31.3982 52.0345,31.4581 51.6589,31.572 Z "
                      />
                    </IconSVG>
                  </IconWrapper>
                  <InfoBoxTopWrapper>
                    <InfoBoxTopTitle>My Status</InfoBoxTopTitle>
                    <InfoBoxTopText>
                      Earn points to increase your VIP status
                    </InfoBoxTopText>
                  </InfoBoxTopWrapper>
                  <Triangle />
                </InfoBoxTopContainer>
                <InfoBottomContainer>
                  <InfoBoxBottomTitle2>Your Status:</InfoBoxBottomTitle2>

                  <SVGWrapper>
                    <svg
                      viewBox="0 0 76 76"
                      version="1.1"
                      baseProfile="full"
                      xmlns="http://www.w3.org/2000/svg"
                      width={70}
                      style={{ marginTop: 5, zIndex: 2 }}
                    >
                      <circle
                        cx="38"
                        cy="38"
                        r="35"
                        fill="none"
                        stroke="var(--text-color)"
                        strokeWidth="1"
                      />

                      <path
                        fill="var(--text-color)"
                        fillOpacity="1"
                        strokeWidth="0.2"
                        strokeLinejoin="round"
                        d="M 26.455,50.6456L 20.4642,50.633L 13.1944,28.0567L 18.815,28.0567L 21.5748,37.5809L 22.6729,41.5697L 23.5942,45.6336L 23.6952,45.6336C 24.2785,42.9115 24.9629,40.2696 25.7482,37.708L 28.6426,28.0567L 34.0944,28.0567L 26.455,50.6456 Z M 36.8389,28.0567L 41.8633,28.0567L 41.8633,50.6456L 36.8389,50.6456L 36.8389,28.0567 Z M 46.6344,50.6456L 46.6344,28.1622C 48.1506,27.8878 50.4422,27.74 53.5091,27.7189C 56.6418,27.7189 58.942,28.2889 60.4098,29.4289C 61.1502,29.9743 61.7325,30.7025 62.1567,31.6138C 62.581,32.525 62.7972,33.5769 62.8056,34.7696C 62.8049,35.9653 62.6316,37.04 62.2857,37.9935C 61.9397,38.947 61.4254,39.7191 60.7426,40.31C 59.8692,41.0621 58.809,41.6219 57.5619,41.9895C 56.3148,42.3571 54.9303,42.5402 53.4082,42.5389L 51.6589,42.4133L 51.6589,50.6456L 46.6344,50.6456 Z M 51.6589,31.572L 51.6589,38.7218C 52.0626,38.8236 52.6345,38.8715 53.3746,38.8656C 54.7503,38.8551 55.8253,38.5078 56.5997,37.8236C 57.374,37.1395 57.7678,36.1814 57.7811,34.9493C 57.7748,33.8265 57.4259,32.9552 56.7342,32.3354C 56.0426,31.7156 55.0461,31.4012 53.7446,31.3922C 52.7298,31.3982 52.0345,31.4581 51.6589,31.572 Z "
                      />
                    </svg>
                    <ProgressPieBar
                      percentage={levelInfo.progressToNextLevel.toFixed(2)}
                      size={80}
                    />
                  </SVGWrapper>

                  <MediumText>{levelInfo.currentLevel}</MediumText>
                  <SmallText>{userProfile.vip_points} VIP points</SmallText>

                  <PointsBottom style={{ alignItems: "center" }}>
                    <svg
                      viewBox="0 0 76 76"
                      version="1.1"
                      baseProfile="full"
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                    >
                      <circle
                        cx="38"
                        cy="38"
                        r="35"
                        fill="none"
                        stroke="var(--background-color)"
                        strokeWidth="4"
                      />

                      <path
                        fill="var(--background-color)"
                        fillOpacity="1"
                        strokeWidth="0.2"
                        strokeLinejoin="round"
                        d="M 26.455,50.6456L 20.4642,50.633L 13.1944,28.0567L 18.815,28.0567L 21.5748,37.5809L 22.6729,41.5697L 23.5942,45.6336L 23.6952,45.6336C 24.2785,42.9115 24.9629,40.2696 25.7482,37.708L 28.6426,28.0567L 34.0944,28.0567L 26.455,50.6456 Z M 36.8389,28.0567L 41.8633,28.0567L 41.8633,50.6456L 36.8389,50.6456L 36.8389,28.0567 Z M 46.6344,50.6456L 46.6344,28.1622C 48.1506,27.8878 50.4422,27.74 53.5091,27.7189C 56.6418,27.7189 58.942,28.2889 60.4098,29.4289C 61.1502,29.9743 61.7325,30.7025 62.1567,31.6138C 62.581,32.525 62.7972,33.5769 62.8056,34.7696C 62.8049,35.9653 62.6316,37.04 62.2857,37.9935C 61.9397,38.947 61.4254,39.7191 60.7426,40.31C 59.8692,41.0621 58.809,41.6219 57.5619,41.9895C 56.3148,42.3571 54.9303,42.5402 53.4082,42.5389L 51.6589,42.4133L 51.6589,50.6456L 46.6344,50.6456 Z M 51.6589,31.572L 51.6589,38.7218C 52.0626,38.8236 52.6345,38.8715 53.3746,38.8656C 54.7503,38.8551 55.8253,38.5078 56.5997,37.8236C 57.374,37.1395 57.7678,36.1814 57.7811,34.9493C 57.7748,33.8265 57.4259,32.9552 56.7342,32.3354C 56.0426,31.7156 55.0461,31.4012 53.7446,31.3922C 52.7298,31.3982 52.0345,31.4581 51.6589,31.572 Z "
                      />
                    </svg>
                    <span>
                      Only <strong>{levelInfo.pointsToNextLevel}</strong> Points
                      to <br /> status <strong>{levelInfo.nextLevel}</strong>
                    </span>
                  </PointsBottom>
                </InfoBottomContainer>
              </InfoContainer>
            </InfoBoxGrid>

            <ActivityContainer>
              <Table>
                <thead>
                  <tr>
                    <ThReversed>Points</ThReversed>
                    <ThReversed>Activity</ThReversed>
                  </tr>
                </thead>
                <tbody>
                  {userPointsHistory
                    .filter((row) => row.point_type === FILTER_POINT_TYPE)
                    .slice(0, visibleRedeemedRows)
                    .map((data, index) => (
                      <tr key={index}>
                        <TdReversed>{data.points}</TdReversed>
                        <TdReversed>
                          {data.point_type}, {data.reason}
                        </TdReversed>
                      </tr>
                    ))}
                </tbody>
              </Table>

              {visibleRedeemedRows <
                userPointsHistory.filter(
                  (row) => row.point_type === FILTER_POINT_TYPE
                ).length && (
                <ShowAllButton onClick={showAllRedeemedPoints}>
                  Prikaži više
                </ShowAllButton>
              )}
              {visibleRedeemedRows >=
                userPointsHistory.filter(
                  (row) => row.point_type === FILTER_POINT_TYPE
                ).length && (
                <ShowAllButton onClick={showLessRedeemedPoints}>
                  Prikaži manje
                </ShowAllButton>
              )}
            </ActivityContainer>
            <ActivityContainerDesktop>
              <ActivityWrapperDesktop>
                <Table>
                  <thead>
                    <tr>
                      <Th>Activity</Th>
                      <Th>Points</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {userPointsHistory
                      .slice(0, visibleRows)
                      .map((data, index) => (
                        <tr key={index}>
                          <Td>
                            {data.point_type}, {data.reason}
                          </Td>
                          <Td>{data.points}</Td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {visibleRows < userPointsHistory.length && (
                  <ShowAllButton onClick={showAllPointsData}>
                    Prikaži vise
                  </ShowAllButton>
                )}
                {visibleRows === userPointsHistory.length && (
                  <ShowAllButton onClick={showLessPointsData}>
                    Prikaži manje
                  </ShowAllButton>
                )}
              </ActivityWrapperDesktop>
              <ActivityWrapperDesktop>
                <Table>
                  <thead>
                    <tr>
                      <ThReversed>Points</ThReversed>
                      <ThReversed>Activity</ThReversed>
                    </tr>
                  </thead>
                  <tbody>
                    {userPointsHistory
                      .filter((row) => row.point_type === FILTER_POINT_TYPE)
                      .slice(0, visibleRedeemedRows)
                      .map((data, index) => (
                        <tr key={index}>
                          <TdReversed>{data.points}</TdReversed>
                          <TdReversed>
                            {data.point_type}, {data.reason}
                          </TdReversed>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {visibleRedeemedRows <
                  userPointsHistory.filter(
                    (row) => row.point_type === FILTER_POINT_TYPE
                  ).length && (
                  <ShowAllButton onClick={showAllRedeemedPoints}>
                    Prikaži više
                  </ShowAllButton>
                )}
                {visibleRedeemedRows >=
                  userPointsHistory.filter(
                    (row) => row.point_type === FILTER_POINT_TYPE
                  ).length && (
                  <ShowAllButton onClick={showLessRedeemedPoints}>
                    Prikaži manje
                  </ShowAllButton>
                )}
              </ActivityWrapperDesktop>
            </ActivityContainerDesktop>
          </InfoBoxGridContainer>
        </>
      ) : (
        <PageLoading />
      )}
    </Container>
  );
};

export default MyBalance;
