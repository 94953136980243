import { useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import SimpleContext from "../../../context/SimpleContext";
import { AuthUserContext } from "../../../context/AuthUserContext";
import { useNavigation } from "../../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const fadeIn = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const Container = styled.div`
  position: relative;
`;
const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const Ul = styled.ul`
  padding: 0;
  border: 0;
  outline: none;
  list-style: none;
  position: absolute;
  right: 0%;
  top: 100%;

  width: 200px;
  box-shadow: var(--shadow-large);
  display: block;
  background-color: none;
  animation: ${fadeIn} 0.3s ease-out;
  z-index: 1;
`;
const Li = styled.li`
  font-weight: 500;
  padding: 14px;
  font-size: var(--font-size-small);
  background: var(--background-color);
  border-bottom: 1px solid var(--background-color-hover);
  cursor: pointer;
  color: var(--text-color);
  text-transform: uppercase;
  &:hover {
    color: var(--background-color);
    background-color: var(--primary-color);
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xxs);
`;
const AvatarImage = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: var(--secondary-color);
  background: radial-gradient(
    circle at 30px 30px,
    var(--primary-color),
    var(--secondary-color)
  );
`;
const FlexBox = styled.div`
  display: flex;
  gap: var(--spacing-md);
`;
const TButton = styled.button`
  padding: var(--spacing-sm) var(--spacing-lg);
  min-width: 100px;
`;
const Button = styled(TButton)`
  background-color: var(--text-color);
  color: var(--background-color);
`;
const ProfileAvatar = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { openLoginPopup, openRegisterPopup } = useContext(SimpleContext);
  const { authTokens, setSelectedItem } = useContext(AuthUserContext);
  const { goToProfile } = useNavigation();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  return (
    <>
      {authTokens ? (
        <UserContainer>
          {/* */}
          <AvatarContainer
            onClick={() => {
              setShow(!show);
            }}
          >
            <AvatarImage>S</AvatarImage>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-0.000325203 0.333496L4.66634 5.66683L9.33301 0.333496L-0.000325203 0.333496Z"
                fill="var(--text-color)"
              />
            </svg>
          </AvatarContainer>

          {show && authTokens && (
            <Ul ref={containerRef}>
              <Li
                key={0}
                delay={0}
                onClick={() => {
                  navigate(goToProfile());
                  setSelectedItem("profile");
                }}
              >
                {t("HEADER.PROFILE")}
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                    fill="var(--text-color)"
                  />
                </svg>
              </Li>
              <Li
                key={1}
                delay={1}
                onClick={() => {
                  navigate(goToProfile());
                  setSelectedItem("order_history");
                }}
              >
                {t("HEADER.ORDERS")}
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                    fill="var(--text-color)"
                  />
                </svg>
              </Li>

              <Li
                key={2}
                delay={2}
                onClick={() => {
                  navigate(goToProfile());
                  setSelectedItem("points");
                }}
              >
                {t("HEADER.SNUSCO_COINS")}
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                    fill="var(--text-color)"
                  />
                </svg>
              </Li>
            </Ul>
          )}
        </UserContainer>
      ) : (
        <FlexBox>
          <TButton
            className="button-transparent"
            onClick={() => {
              openRegisterPopup();
              setShow(false);
            }}
          >
            {t("HEADER.SIGN_UP")}
          </TButton>
          <Button
            className="button-transparent"
            onClick={() => {
              openLoginPopup();
              setShow(false);
            }}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_414_6527)">
                <path
                  d="M10.7135 2.104C11.5376 2.104 12.3432 2.34837 13.0284 2.80621C13.7136 3.26405 14.2477 3.9148 14.563 4.67616C14.8784 5.43752 14.9609 6.27529 14.8001 7.08355C14.6394 7.8918 14.2425 8.63423 13.6598 9.21695C13.0771 9.79967 12.3347 10.1965 11.5264 10.3573C10.7182 10.518 9.88039 10.4355 9.11903 10.1202C8.35767 9.8048 7.70692 9.27075 7.24908 8.58555C6.79125 7.90034 6.54688 7.09476 6.54688 6.27067L6.55104 6.08984C6.59764 5.01713 7.05655 4.00382 7.83206 3.26122C8.60757 2.51862 9.63982 2.10407 10.7135 2.104Z"
                  fill="white"
                />
                <path
                  d="M12.3789 12.104C13.484 12.104 14.5438 12.543 15.3252 13.3244C16.1066 14.1058 16.5456 15.1656 16.5456 16.2707V17.104C16.5456 17.546 16.37 17.97 16.0574 18.2825C15.7449 18.5951 15.3209 18.7707 14.8789 18.7707H6.54557C6.10355 18.7707 5.67962 18.5951 5.36706 18.2825C5.0545 17.97 4.87891 17.546 4.87891 17.104V16.2707C4.87891 15.1656 5.31789 14.1058 6.09929 13.3244C6.8807 12.543 7.9405 12.104 9.04557 12.104H12.3789Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_414_6527">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.712891 0.4375)"
                  />
                </clipPath>
              </defs>
            </svg>
            {t("HEADER.LOGIN")}
          </Button>
        </FlexBox>
      )}
    </>
  );
};

export default ProfileAvatar;
