import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { ProductContext } from "../../context/ProductContext";
import Slider from "react-slider";

const fadeInTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
  }
`;
const fadeOutBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const Container = styled.div`
  background-color: var(--background-color-hover);
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
const GridContainer = styled.div`
  //grid-template-rows: repeat(4, 1fr);

  min-width: 100%;
  overflow-x: hidden;
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
`;
const ButtonWrapper = styled.div`
  border-top: 1px solid var(--transparent-border-color);
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  background-color: var(--background-color-hover);
  color: var(--text-color);
  border: none;
  border-radius: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: var(--font-size-base);
  font-weight: 700;
  padding: 8px 0;

  &:hover {
    background-color: var(--background-color-hover);
  }
  // min-width: 100%;
`;
const ButtonIcon = styled.span``;

const DropDown = styled.div`
  width: 100%;
  /* Samo za telefone, primenjuje se display na osnovu $isOpen */
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: none; /* Sakrij ako je otvoreno */
        `
      : css`
          display: block; /* Prikaži ako nije otvoreno */
        `}
`;

const Box = styled.div`
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${fadeOutBottom} 0.2s ease-in-out both;
        `
      : css`
          animation: ${fadeInTop} 0.3s ease-in-out both;
        `}
`;

const List = styled.ul`
  list-style: none; /* Remove default bullet points */
  padding: 0 8px; /* Remove default padding */
  margin: 0;
`;

const ListItem = styled.li`
  font-size: var(--font-size-base);
  cursor: pointer;
  letter-spacing: 1.2px;
  text-transform: capitalize;
  font-size: var(--font-size-small);
  font-weight: 600;
`;

const Label = styled.label`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  line-height: 1.5;
  margin: 0 0 1rem;
  text-transform: none;
  cursor: pointer;
`;
const LabelTitle = styled.p`
  margin: 0;
  padding: 0;
  display: block;
  flex: 1 1 auto;
  order: 2;
  line-height: 1;
  font-size: var(--font-size-small);
  font-weight: 700;
  text-transform: capitalize;
`;
const LabelCount = styled.div`
  display: block;
  flex: 0 1 auto;
  min-width: 2em;
  margin-left: auto;
  margin-right: 0;
  color: var(--text-color-alt);
  order: 4;
  line-height: 1;
`;
const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RangeDiv = styled.div`
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledSlider = styled(Slider)`
  width: 100%;
  height: 10px;
`;

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: var(--text-color);
  color: var(--background-color);
  border-radius: 50%;
  cursor: grab;
  top: -7px;
  font-size: var(--font-size-small);
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 2
      ? "var(--background-color-hover)"
      : props.index === 1
      ? "var(--primary-color)"
      : "var(--background-color-hover)"};
  border-radius: 20px;
`;

const Thumb = (props, state) => (
  <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const FilterData = ({
  selectedCategoryNames,
  setSelectedCategoryNames,
  selectedFlavours,
  setSelectedFlavours,
  selectedNicotineCategory,
  setSelectedNicotineCategory,
  selectedNicotineRange,
  setSelectedNicotineRange,
  minNicotine,
  maxNicotine,
  nicotineRange,
}) => {
  const { products, setFilteredProducts } = useContext(ProductContext);
  // Kalkulacija granica za kategorije jačine nikotina
  const [nicotineCategories, setNicotineCategories] = useState([
    { name: "light", max: minNicotine + nicotineRange },
    { name: "normal", max: minNicotine + 2 * nicotineRange },
    { name: "strong", max: maxNicotine },
  ]);
  useEffect(() => {
    setNicotineCategories([
      { name: "light", max: minNicotine + nicotineRange },
      { name: "normal", max: minNicotine + 2 * nicotineRange },
      { name: "strong", max: maxNicotine },
    ]);
  }, [maxNicotine, minNicotine, nicotineRange]);
  const { uniqueCategoryNames, uniqueFlavours, categoryCounts, flavourCounts } =
    useMemo(() => {
      const categorySet = new Set();
      const flavourSet = new Set();
      const categoryCounts = {};
      const flavourCounts = {};

      products.forEach((product) => {
        categorySet.add(product.category_name);
        flavourSet.add(product.flavor);

        categoryCounts[product.category_name] =
          (categoryCounts[product.category_name] || 0) + 1;
        flavourCounts[product.flavor] =
          (flavourCounts[product.flavor] || 0) + 1;
      });

      return {
        uniqueCategoryNames: Array.from(categorySet),
        uniqueFlavours: Array.from(flavourSet),
        categoryCounts,
        flavourCounts,
      };
    }, [products]);

  const filter_data = [
    {
      name: "category_name",
      items: uniqueCategoryNames.map((category) => ({
        name: category,
        count: categoryCounts[category],
      })),
    },
    {
      name: "flavour",
      items: uniqueFlavours.map((flavour) => ({
        name: flavour,
        count: flavourCounts[flavour],
      })),
    },
    {
      name: "nicotine",
      items: nicotineCategories.map((category) => ({
        name: category.name,
        count: products.filter((product) => {
          const nicotine = parseFloat(product.nicotine);
          if (category.name === "light") return nicotine <= category.max;
          if (category.name === "normal")
            return (
              nicotine > category.max - nicotineRange &&
              nicotine <= category.max
            );
          if (category.name === "strong")
            return nicotine > category.max - nicotineRange;
        }).length,
      })),
    },
  ];

  const handleSelection = (item, sectionName) => {
    if (sectionName === "category_name") {
      setSelectedCategoryNames((prevSelected) =>
        prevSelected.includes(item.name)
          ? prevSelected.filter((name) => name !== item.name)
          : [...prevSelected, item.name]
      );
    } else if (sectionName === "flavour") {
      setSelectedFlavours((prevSelected) =>
        prevSelected.includes(item.name)
          ? prevSelected.filter((name) => name !== item.name)
          : [...prevSelected, item.name]
      );
    } else if (sectionName === "nicotine") {
      // Postavljanje selektovane kategorije nicotine na item.name ako trenutno nije selektovana,
      // ili je uklanja iz selekcije ako jeste trenutno selektovana
      setSelectedNicotineCategory((prevSelected) =>
        prevSelected.includes(item.name)
          ? prevSelected.filter((name) => name !== item.name)
          : [...prevSelected, item.name]
      );
    }
  };

  const handleSliderChange = (value) => {
    setSelectedNicotineRange(value); // Osvežava stanje sa novim opsegom nikotina
  };
  useEffect(() => {
    let filtered = products.filter((product) => {
      const categoryMatch =
        selectedCategoryNames.length === 0 ||
        selectedCategoryNames.includes(product.category_name);
      const flavourMatch =
        selectedFlavours.length === 0 ||
        selectedFlavours.includes(product.flavor);
      const nicotineMatch =
        selectedNicotineCategory.length === 0 ||
        selectedNicotineCategory.some((selectedCategory) => {
          const category = nicotineCategories.find(
            (c) => c.name === selectedCategory
          );
          if (!category) return false;

          const nicotineValue = parseFloat(product.nicotine);
          if (category.name === "light") {
            return nicotineValue <= category.max;
          } else if (category.name === "normal") {
            return (
              nicotineValue > minNicotine + nicotineRange &&
              nicotineValue <= category.max
            );
          } else if (category.name === "strong") {
            return nicotineValue > category.max - nicotineRange;
          }
        });

      return categoryMatch && flavourMatch && nicotineMatch;
    });
    // Novi korak: Filtriranje na osnovu selectedNicotineRange
    filtered = filtered.filter((product) => {
      const nicotineValue = parseFloat(product.nicotine);
      return (
        nicotineValue >= selectedNicotineRange[0] &&
        nicotineValue <= selectedNicotineRange[1]
      );
    });

    setFilteredProducts(filtered);
  }, [
    selectedCategoryNames,
    selectedFlavours,
    selectedNicotineCategory,
    selectedNicotineRange,
    products,
  ]);

  const [isOpenBox, setIsOpenBox] = useState(filter_data.map(() => true));
  const [isOpenContainer, setIsOpenContainer] = useState(
    filter_data.map(() => true)
  );
  const [isBoxAnimating, setIsBoxAnimating] = useState(
    filter_data.map(() => false)
  );

  const toggleBox = (index) => {
    if (isBoxAnimating[index]) {
      return;
    }

    const updatedIsOpenBox = [...isOpenBox];
    updatedIsOpenBox[index] = !updatedIsOpenBox[index];
    setIsOpenBox(updatedIsOpenBox);

    const updatedIsBoxAnimating = [...isBoxAnimating];
    updatedIsBoxAnimating[index] = true;
    setIsBoxAnimating(updatedIsBoxAnimating);

    setTimeout(
      () => {
        const updatedIsOpenContainer = [...isOpenContainer];
        updatedIsOpenContainer[index] = !updatedIsOpenContainer[index];
        setIsOpenContainer(updatedIsOpenContainer);

        updatedIsBoxAnimating[index] = false;
        setIsBoxAnimating(updatedIsBoxAnimating);
      },
      !isOpenContainer[index] ? 200 : 10
    );
  };

  return (
    <Container>
      <Wrapper>
        <GridContainer>
          {filter_data.map((section, index) => (
            <GridItems key={index}>
              <ButtonWrapper>
                <Button onClick={() => toggleBox(index)}>
                  <span>
                    {section.name === "category_name" ? "Brand" : section.name}
                  </span>
                  <ButtonIcon role="presentation">
                    {" "}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                      viewBox="0 0 28 16"
                      style={
                        isOpenContainer[index]
                          ? { width: "12px", transform: "rotate(180deg)" }
                          : { width: "12px" }
                      }
                    >
                      <path
                        d="M1.57 1.59l12.76 12.77L27.1 1.59"
                        strokeWidth=""
                        stroke="var(--text-color)"
                        fill="none"
                      ></path>
                    </svg>
                  </ButtonIcon>
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[index]}>
                <Box $isOpen={isOpenBox[index]}>
                  <List>
                    {section.items.map((item, itemIndex) => (
                      <ListItem
                        key={itemIndex}
                        onClick={() => handleSelection(item, section.name)}
                      >
                        <Label>
                          <svg
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: "4px",
                            }}
                          >
                            <path
                              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                              stroke="var(--text-color)"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="var(--background-color)"
                            />
                            {(section.name === "category_name" &&
                              selectedCategoryNames.includes(item.name)) ||
                            (section.name === "nicotine" &&
                              selectedNicotineCategory.includes(item.name)) ||
                            (section.name === "flavour" &&
                              selectedFlavours.includes(item.name)) ? (
                              <path
                                d="M7.75 12L10.58 14.83L16.25 9.17004"
                                stroke="var(--text-color)"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            ) : null}
                          </svg>
                          <LabelTitle>{item.name}</LabelTitle>
                          <LabelCount>({item.count})</LabelCount>
                        </Label>
                      </ListItem>
                    ))}
                    {section.name === "nicotine" && (
                      <RangeContainer>
                        <RangeDiv>
                          <StyledSlider
                            defaultValue={[
                              selectedNicotineRange[0],
                              selectedNicotineRange[1],
                            ]}
                            value={[
                              selectedNicotineRange[0],
                              selectedNicotineRange[1],
                            ]}
                            min={minNicotine}
                            max={maxNicotine}
                            renderTrack={Track}
                            renderThumb={Thumb}
                            onAfterChange={handleSliderChange}
                          />
                        </RangeDiv>
                      </RangeContainer>
                    )}
                  </List>
                </Box>
              </DropDown>
            </GridItems>
          ))}
        </GridContainer>
      </Wrapper>
    </Container>
  );
};

export default FilterData;
