import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import HearthA from "../../assets/icons/search.png";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { ProductContext } from "../../context/ProductContext";
import ProductAttributePie from "./ProductAttributePie";
import hoverImg from "../../assets/images/product_bg_hover.svg";
import { saveForLaterActions } from "../../store/save-slice";
const CartItem = styled.div`
  box-shadow: var(--shadow-default);
  background-color: var(--background-color);
  @media (min-width: 768px) {
    width: 100%;
    height: 280px;
  }

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  overflow: hidden;
  width: 94%;
  padding: var(--spacing-md) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-md);
  @media (min-width: 768px) {
    padding: 0;
    width: 94%;
    height: 80%;
    flex-direction: row;
  }
`;
const Picture = styled.picture`
  display: flex;
  width: 100%;
  max-height: 180px;
  background-image: url(${hoverImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    max-height: none;
    height: 100%;

    max-width: 40%;
    min-width: 40%;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;
const InfoDiv = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    min-width: 60%;
    max-width: 60%;
    height: 90%;
  }
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;
const FlexDiv = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //gap: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
`;
const ProductName = styled.h3`
  font-size: var(--font-size-large);
  font-weight: 600;
`;
const AddContainer = styled.div`
  background-color: var(--background-color-hover-alt);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

const RowPrices = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Larken-Light";
`;
const Price = styled.span`
  font-size: var(--font-size-large);
  font-weight: 600;
`;
const DiscountedPrice = styled.span`
  font-size: var(--font-size-small);
  font-weight: 400;
  text-decoration: line-through;
  opacity: 0.5;
`;
const OffPercentage = styled.span`
  font-size: var(--font-size-base);
  color: var(--success-color);
  white-space: nowrap;
`;
const PricePiece = styled.span`
  font-size: var(--font-size-small);
  color: var(--text-color);
  font-weight: 700;
`;
const PieContainer = styled(FlexDiv)`
  display: flex;
  @media (min-width: 768px) {
    width: 50%;
  }
  gap: 0;
`;
const BottomFlex = styled(FlexDiv)`
  display: block;
  @media (min-width: 768px) {
    display: flex;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  border-top: 1px solid var(--transparent-border-color);
  margin: 12px 0 0 0;
  @media (min-width: 768px) {
    border: none;
    margin: 0;
  }
`;
const ButtonCard = styled.button`
  white-space: nowrap;
  border: none;
  text-transform: uppercase;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    color: var(--text-color);
    border: none;
  }
`;
const CartProductSaveForLater = ({ item }) => {
  const { currencyTag, maxAttributes } = useContext(ProductContext);
  const [inputValue, setInputValue] = useState(item.quantity);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [newValue, setNewValue] = useState("");
  const dispatch = useDispatch();

  const savingsPercentage = Math.round(
    ((item.product.price - item.product.discount_price) / item.product.price) *
      100
  );

  const handleRemoveFromSaveForLater = () => {
    dispatch(saveForLaterActions.removeFromSaveForLater(item.product.id));
  };
  const handleAddToCart = () => {
    dispatch(
      cartActions.addToCart({
        product: item.product,
        quantity: item.quantity,
      })
    );
    handleRemoveFromSaveForLater();
  };

  const nicotinePercentage =
    (parseFloat(item.product.nicotine) / parseFloat(maxAttributes.nicotine)) *
    100;

  useEffect(() => {
    setInputValue(item.quantity);
  }, [item.quantity]);

  return (
    <CartItem>
      <Wrapper>
        <Picture>
          {item.product.images.length > 0 &&
            (() => {
              const primaryImage = item.product.images.find(
                (img) => img.is_primary
              ); // Pronalazi primarnu sliku samo jednom
              return (
                primaryImage && (
                  <Image
                    src={primaryImage.thumbnail} // Koristi 'thumbnail' verziju primarne slike
                    alt={item.product.name}
                    srcSet={`
                        ${primaryImage.thumbnail} 320w,  // Koristi 'thumbnail' verziju za male ekrane
                        ${primaryImage.large} 480w,  // Koristi 'large' verziju za srednje ekrane
                        ${primaryImage.large} 800w`} // Ponovo koristi 'large' verziju za velike ekrane
                    sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                    loading="lazy"
                  />
                )
              );
            })()}
        </Picture>
        <InfoDiv>
          <ProductName>
            {item.product.category_name} {item.product.name}
          </ProductName>
          <PieContainer>
            <ProductAttributePie
              type="nicotine"
              percentage={nicotinePercentage}
              label="Nicotine"
            />
            <ProductAttributePie
              type="intensity"
              percentage={90.67}
              label="Intensity"
            />
          </PieContainer>
          <FlexDiv>
            <RowPrices>
              <Price>
                {currencyTag}
                {(item.product.discount_price * inputValue).toFixed(2)}
              </Price>
              <PricePiece>
                {currencyTag}
                {item.product.discount_price.toFixed(2)}/piece
              </PricePiece>
              {savingsPercentage > 0 && (
                <>
                  <OffPercentage>{savingsPercentage}% off</OffPercentage>
                  <DiscountedPrice>
                    {currencyTag}
                    {item.product.price.toFixed(2)}
                  </DiscountedPrice>
                </>
              )}
            </RowPrices>
          </FlexDiv>
          <BottomFlex>
            <AddContainer>{item.quantity}</AddContainer>
            <ButtonsContainer>
              <ButtonCard
                className="button-transparent"
                style={{
                  borderRight: "1px solid var(--transparent-border-color)",
                }}
                onClick={handleAddToCart}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_689_40704)">
                    <path
                      d="M11.6665 2.5V5.83333C11.6665 6.05435 11.7543 6.26631 11.9106 6.42259C12.0669 6.57887 12.2788 6.66667 12.4998 6.66667H15.8332"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1665 17.5H5.83317C5.39114 17.5 4.96722 17.3244 4.65466 17.0118C4.3421 16.6993 4.1665 16.2754 4.1665 15.8333V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H11.6665L15.8332 6.66667V15.8333C15.8332 16.2754 15.6576 16.6993 15.345 17.0118C15.0325 17.3244 14.6085 17.5 14.1665 17.5Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 14.1667V9.16675"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_689_40704">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Move To Cart
              </ButtonCard>
              <ButtonCard
                className="button-transparent"
                onClick={() => handleRemoveFromSaveForLater()}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_689_40711)">
                    <path
                      d="M3.3335 5.83325H16.6668"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.3335 9.16675V14.1667"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.6665 9.16675V14.1667"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.1665 5.83325L4.99984 15.8333C4.99984 16.2753 5.17543 16.6992 5.48799 17.0118C5.80055 17.3243 6.22448 17.4999 6.6665 17.4999H13.3332C13.7752 17.4999 14.1991 17.3243 14.5117 17.0118C14.8242 16.6992 14.9998 16.2753 14.9998 15.8333L15.8332 5.83325"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_689_40711">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Remove
              </ButtonCard>
            </ButtonsContainer>
          </BottomFlex>
        </InfoDiv>
      </Wrapper>
    </CartItem>
  );
};

export default CartProductSaveForLater;
