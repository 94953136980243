import { useState } from "react";
import styled from "styled-components";
import OrderItemsSlider from "../product/OrderItemsSlider";
import OrderProduct from "../product/OrderProduct";
import { transform } from "framer-motion";
import OrderHistorySlider from "../product/OrderHistorySlider";
import { useTranslation } from "react-i18next";

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const CardWrapper = styled.div`
  background-color: var(--background-color);
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
`;

const CardTop = styled.div`
  display: flex;
  gap: 14px;
  align-items: flex-start;
  //justify-content: center;
  width: 90%;
`;
const CardTopWrapper = styled.div`
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  > *:nth-child(1) {
    order: 1;
  }

  > *:nth-child(2) {
    order: 2;
  }

  > *:nth-child(3) {
    order: 5;
  }
  > *:nth-child(4) {
    order: 4;
  }
  > *:nth-child(5) {
    order: 3;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    > *:nth-child(1) {
      order: 1;
    }

    > *:nth-child(2) {
      order: 2;
    }

    > *:nth-child(3) {
      order: 3;
    }
    > *:nth-child(4) {
      order: 4;
    }
    > *:nth-child(5) {
      order: 5;
    }
  }
`;
const ArrowSVG = styled.svg`
  cursor: pointer;
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  @media (min-width: 768px) {
    position: static;
  }
`;

const OrderItemsContainer = styled.div`
  width: 96%;
  display: none;
  align-items: center;
  gap: var(--spacing-lg);
  flex-direction: column;
  border-bottom: 1px solid #e5e5e5;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;
const OrderItemsWrapper = styled.div`
  padding: var(--spacing-xl) 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 8px 0;
  gap: var(--spacing-sm);
`;

const TextHeader = styled.div`
  font-size: var(--font-size-base);
  padding: 0;
  margin: 0;
  font-weight: 300;
  padding: var(--spacing-xs) 0;
  font-family: "Larken";
`;
const TextHeaderGreen = styled.div`
  font-size: var(--font-size-base);
  padding: 0;
  margin: 0;
  font-weight: 300;
  padding: var(--spacing-xs) 0;
  font-family: "Satoshi";
  text-transform: uppercase;
  color: var(--success-color);
`;
const PriceHeader = styled.div`
  font-size: var(--font-size-base);
  padding: 0;
  margin: 0;
  font-weight: 700;
  padding: var(--spacing-xs) 0;
`;
const FlexDiv = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  width: 100%;
`;
const FlexMobile = styled.div`
  width: 100%;

  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-sm);
  @media (min-width: 768px) {
    display: block;
  }
`;
const BrT = styled.div`
  height: 1px;
  background-color: var(--text-color);
  width: 100%;
  margin: var(--spacing-md) 0;
  opacity: 0.1;
  @media (min-width: 768px) {
    display: none;
  }
`;
const Picture = styled.picture`
  display: flex; // Omogućavanje flex layout-a za centriranje slike
  //height: 240px;
  cursor: pointer;
  width: 100%;
  min-height: 80px;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  //max-width: 200px;
  width: 80px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  &.secondary-image {
    display: none;
  }
`;
const ProductTitle = styled.div`
  font-size: var(--font-size-small);
  text-align: center;
  padding: var(--spacing-xxs) 0;
  width: 140px;
`;
const ProductText = styled.div`
  font-size: var(--font-size-small);
  color: var(--p-color);
  text-align: center;
`;
const TimelineGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr); /* Default horizontal grid */
  gap: var(--spacing-sm);

  @media (max-width: 768px) {
    /* For screens smaller than 768px, use a vertical layout */

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
`;

const TimelineFlex = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
const GraphicContainer = styled(FlexDiv)`
  gap: 0;
  width: 100%;
  height: 120px;

  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    height: auto;
  }
`;
const GraphicBorder = styled.div`
  height: 120px;
  width: 1px;
  border-left: 2px solid var(--success-color);
  @media (min-width: 768px) {
    height: 1px;
    width: 100%;
    border-bottom: 2px solid var(--success-color);
  }
`;
const GraphicTimeline = styled.div`
  width: 13px;
  height: 13px;
  min-width: 13px;
  min-height: 13px;
  border-radius: 50%;
  background-color: var(--success-color);
  display: flex;
  align-items: center;
  position: relative;
`;
const TimeLineTitle = styled.div`
  font-size: var(--font-size-base);
  color: var(--success-color);
  text-align: center;
  width: 100%;
  white-space: nowrap;
  font-weight: bold;
  font-family: "Larken";
  //height: 120px;
  position: absolute;
  right: -20px;
  bottom: 0%;
  @media (min-width: 768px) {
    top: -30px;
    right: 20px;
  }
`;
const TimeLineText = styled.div`
  text-align: center;
  color: var(--p-color);
  font-size: var(--font-size-small);
  font-family: "Larken";
  width: 100%;
  white-space: nowrap;
  position: absolute;
  right: -20px;
  bottom: -20px;
  @media (min-width: 768px) {
    bottom: -20px;
    right: 20px;
  }
`;

const BottomText = styled(ProductTitle)`
  width: auto;
  text-align: left;
`;

const OrderDetails = ({ order }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState({});
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // Dodajte +1 jer getMonth() vraća mesec od 0 do 11
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Koristite `padStart(2, '0')` da biste osigurali da su dan i mesec uvek prikazani sa dve cifre
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };
  const toggleShowMore = (orderId) => {
    setShowMore((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  };
  console.log(order);
  return (
    <CardContainer>
      <CardWrapper>
        <CardTop>
          <CardTopWrapper>
            <FlexMobile>
              <TextHeader>ID #{order.id}</TextHeader>
              <PriceHeader>
                {order.currency_symbol}
                {order.total_price}
              </PriceHeader>
            </FlexMobile>
            <BrT />
            <FlexDiv>
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3594 26.7188C20.7376 26.7188 26.7188 20.7376 26.7188 13.3594C26.7188 5.9812 20.7376 0 13.3594 0C5.9812 0 0 5.9812 0 13.3594C0 20.7376 5.9812 26.7188 13.3594 26.7188Z"
                  fill="#FEDA2C"
                />
                <path
                  d="M13.359 23.6574C19.0467 23.6574 23.6574 19.0467 23.6574 13.359C23.6574 7.67132 19.0467 3.06055 13.359 3.06055C7.67132 3.06055 3.06055 7.67132 3.06055 13.359C3.06055 19.0467 7.67132 23.6574 13.359 23.6574Z"
                  fill="#FCAA17"
                />
                <path
                  d="M13.3585 5.44482L15.7006 10.1904L20.9375 10.9515L17.1481 14.6454L18.0427 19.8612L13.3585 17.3987L8.6743 19.8612L9.56891 14.6454L5.7793 10.9515L11.0164 10.1904L13.3585 5.44482Z"
                  fill="#FEDA2C"
                />
              </svg>
              <TextHeader>
                {t("PROFILE.COINS_EARNED", { points: 100 })}
              </TextHeader>
            </FlexDiv>
            <BrT />
            <FlexDiv>
              <div>
                <TextHeader>
                  {t("PROFILE.ORDER_FROM")} {formatDate(order.created_at)}
                </TextHeader>
                <TextHeaderGreen>
                  {t("PROFILE.ORDER_STATUS")} {order.order_status}
                </TextHeaderGreen>
              </div>
              <ArrowSVG
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => toggleShowMore(order.id)}
                style={
                  showMore[order.id]
                    ? { transform: "rotate(-90deg)" }
                    : { transform: "rotate(0)" }
                }
              >
                <g clipPath="url(#clip0_429_11254)">
                  <path
                    d="M10 17L15 12"
                    stroke="#292929"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 12L10 7"
                    stroke="#292929"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_429_11254">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </ArrowSVG>
            </FlexDiv>
          </CardTopWrapper>
        </CardTop>
      </CardWrapper>

      {showMore[order.id] && ( // Proveravamo stanje za specifičnu narudžbinu
        <>
          <OrderItemsContainer>
            <TextHeader>{t("PROFILE.PRODUCTS")}</TextHeader>

            <OrderItemsWrapper>
              {order.order_items.map((item, itemIndex) => {
                const primaryImage = item.product_details.images.find(
                  (img) => img.is_primary
                );
                return (
                  <div key={itemIndex}>
                    <Picture>
                      {primaryImage && (
                        <Image
                          src={primaryImage.thumbnail}
                          alt={`${item.product_details.category_name} ${item.product_details.name}`}
                          srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
                          sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                          loading="lazy"
                          className="primary-image"
                        />
                      )}
                    </Picture>
                    <ProductTitle>
                      {item.product_details.category_name}{" "}
                      {item.product_details.name}
                    </ProductTitle>
                    <ProductText>
                      {item.quantity > 1
                        ? `${item.quantity} ${t("PRODUCT_CARD.CAN")}`
                        : `${item.quantity} ${t("PRODUCT_CARD.CAN")}`}
                    </ProductText>
                  </div>
                );
              })}
            </OrderItemsWrapper>
          </OrderItemsContainer>
          <OrderHistorySlider items={order.order_items} />
          <CardWrapper>
            <OrderItemsContainer style={{ border: "none", display: "flex" }}>
              <TextHeader>{t("PROFILE.TIMELINE")}</TextHeader>
              <TimelineGrid>
                <div />
                <TimelineFlex style={{ justifyContent: "center" }}>
                  <TimelineFlex style={{ maxWidth: "90%" }}>
                    <GraphicContainer>
                      <GraphicTimeline>
                        <TimeLineTitle>Order Confirmed</TimeLineTitle>
                        <TimeLineText>
                          {formatDate(order.created_at)}
                        </TimeLineText>
                      </GraphicTimeline>
                      <GraphicBorder />
                    </GraphicContainer>
                    <GraphicContainer>
                      <GraphicBorder />
                      <GraphicTimeline>
                        <TimeLineTitle>Shipped</TimeLineTitle>
                        <TimeLineText>
                          {formatDate(order.created_at)}
                        </TimeLineText>
                      </GraphicTimeline>
                      <GraphicBorder />
                    </GraphicContainer>
                    <GraphicContainer>
                      <GraphicBorder />
                      <GraphicTimeline>
                        <TimeLineTitle>Out for delivery</TimeLineTitle>
                        <TimeLineText>
                          {formatDate(order.created_at)}
                        </TimeLineText>
                      </GraphicTimeline>
                      <GraphicBorder />
                    </GraphicContainer>
                    <GraphicContainer>
                      <GraphicBorder />
                      <GraphicTimeline>
                        <TimeLineTitle>Delivered</TimeLineTitle>
                        <TimeLineText>
                          {formatDate(order.created_at)}
                        </TimeLineText>
                      </GraphicTimeline>
                    </GraphicContainer>
                  </TimelineFlex>
                </TimelineFlex>
                <div />
              </TimelineGrid>
            </OrderItemsContainer>
          </CardWrapper>
          <CardWrapper
            style={{ backgroundColor: "var(--background-color-hover)" }}
          >
            <CardTop>
              <CardTopWrapper>
                <div>
                  <TextHeader>
                    {t("PROFILE.DELIVER_TO")} Uros, 001122
                  </TextHeader>
                  <BottomText>
                    {order.address_details.country}
                    {", "}
                    {order.address_details.city}
                    {", "}
                    {order.address_details.postal_code}
                    {", "}
                    {order.address_details.street}
                  </BottomText>
                </div>
                <div>
                  <TextHeader>{t("PROFILE.SHIPPING_COST")}</TextHeader>
                  <BottomText style={{ textTransform: "uppercase" }}>
                    {order.shipping_cost == 0
                      ? t("RECEIPT.FREE")
                      : `${order.currency_symbol}
                      ${order.shipping_cost}`}
                  </BottomText>
                </div>
              </CardTopWrapper>
            </CardTop>
          </CardWrapper>
        </>
      )}
    </CardContainer>
  );
};

export default OrderDetails;
