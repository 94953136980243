import React, { useContext, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

import CartProduct from "../../../components/product/CartProduct";
import { useSelector } from "react-redux";
import CartBar from "../components/CartBar";
import { ProductContext } from "../../../context/ProductContext";
import { freeShippingThreshold } from "../../../utils/global_const";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../../utils/navigation";

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;
const slideOutRight = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;
const ContainerX = styled.div``;
const Container = styled.div`
  background-color: var(--transparent-color);
  position: fixed;
  left: 0;
  top: 0;

  z-index: var(--zindex-default);
  width: 100%;
  min-height: 100vh;
  height: 100vh;

  //box-sizing: border-box;
  //overflow-x: hidden;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
  padding: 0;
  margin: 0;
`;
const Wrapper = styled.div`
  background-color: var(--background-color);

  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    max-width: calc(var(--sidebar-width) * 1.3);
  }
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideInFromRight} 0.5s ease-out forwards; // Duže trajanje za slide-in
        `
      : css`
          animation: ${slideOutRight} 0.2s ease-in forwards; // Kraće trajanje za slide-out
        `}

  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
`;

// Styled komponenta za kontejner koji drži hamburger ikonu
const HamburgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const TopDiv = styled.div`
  //position: relative;
  cursor: pointer;
  height: var(--navbar-height);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid var(--transparent-border-color);
  padding: 12px 0px;

  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--background-color);
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`;
const SPrice = styled.h4`
  margin: 0;
  padding: 0;
  color: var(--error-color);
  margin-left: 4px;
  display: inline-block;
`;
const TotalItem = styled.h5`
  margin: 0;
  padding: 0;
  display: inline-block;
`;
const InnerContainer = styled.div`
  padding: 0 20px;
  margin: 20px 0;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: var(--font-size-xxlarge);
  font-weight: 700;
  color: var(--accent-color);
  text-transform: uppercase;
  margin-left: 40px;
`;

const MiddleDiv = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 60vh;
`;

const SumQuantity = styled.div`
  position: absolute;
  right: -10;
  top: -10px;

  background-color: var(--accent-color);
  color: var(--background-color);
  border-radius: var(--border-radius-large);
  padding: 0;
  margin: 0;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  font-weight: 700;
`;
const BottomDiv = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--background-color);
`;
const BottomRecipie = styled.div`
  background-color: var(--text-color-hover);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: 14px;

  //height: var(--navbar-height-desktop);
`;

const BottomFlexDiv = styled.div`
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PaymentDiv = styled.div`
  padding: var(--spacing-xs);
  //padding-top: calc(var(--navbar-mini) + 10px);
  color: var(--background-color);
`;
const PaymentText = styled.span`
  font-weight: 400;
  font-size: var(--text-size-small);
  //padding: 4px 0;
`;
const PaymentGrantText = styled.span`
  font-weight: 700;
  font-size: var(--text-size-small);
  padding: 4px 0;
`;

const DarkButton = styled.button`
  border-radius: 0;
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  &:hover {
    background-color: var(--background-color-hover);
  }
`;

const CartMenu = () => {
  const { currencyTag } = useContext(ProductContext);
  const navigate = useNavigate();
  const { goToCheckout } = useNavigation();
  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeVisible, setShouldBeVisible] = useState(false);
  const cartItems = useSelector((state) => state.cart.itemsList);
  const subtotal = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );
  const subtotalRounded = parseFloat(subtotal.toFixed(2));
  const [shippingCost, setShippingCost] = useState(0.0);
  useEffect(() => {
    if (freeShippingThreshold > subtotalRounded) {
      setShippingCost(20.0);
    } else {
      setShippingCost(0);
    }
  }, [subtotalRounded]);
  useEffect(() => {
    if (isOpen) {
      setShouldBeVisible(true);
    } else {
      const timer = setTimeout(() => {
        setShouldBeVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <ContainerX>
      <HamburgerContainer onClick={toggleMenu}>
        <svg
          aria-hidden="true"
          focusable="false"
          role="presentation"
          viewBox="0 0 64 64"
          className="svg-icon"
        >
          <g fill="none" stroke="var(--text-color)" strokeWidth="4">
            <path d="M25 26c0-15.79 3.57-20 8-20s8 4.21 8 20"></path>
            <path d="M14.74 18h36.51l3.59 36.73h-43.7z"></path>
          </g>
        </svg>
        {totalQuantity !== 0 && <SumQuantity>{totalQuantity}</SumQuantity>}
        <span>Cart</span>
      </HamburgerContainer>
      <Container
        onClick={toggleMenu}
        $isOpen={isOpen}
        $shouldBeVisible={shouldBeVisible}
      >
        <Wrapper
          $isOpen={isOpen}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <TopDiv>
            <Title>Cart</Title>
            <svg
              focusable="false"
              role="presentation"
              viewBox="0 0 64 64"
              className="svg-icon"
              style={{
                width: "calc(var(--font-size-xxlarge) + 5px)",
                height: "calc(var(--font-size-xxlarge) + 5px)",
                position: "absolute",
                right: "40px",
              }}
              onClick={toggleMenu}
            >
              <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
            </svg>

            {totalQuantity !== 0 && (
              <TotalPrice>
                <span>
                  <TotalItem>SUBTOTAL:</TotalItem>{" "}
                  <SPrice>
                    {currencyTag}
                    {subtotalRounded}
                  </SPrice>
                </span>
                <TotalItem>({totalQuantity}) items</TotalItem>
              </TotalPrice>
            )}
          </TopDiv>
          <InnerContainer>
            {totalQuantity !== 0 ? (
              <MiddleDiv>
                {cartItems.map((item, index) => (
                  <CartProduct item={item} key={index} />
                ))}
              </MiddleDiv>
            ) : (
              <TotalPrice>
                <TotalItem>You have no items in your cart</TotalItem>
              </TotalPrice>
            )}
          </InnerContainer>
          {totalQuantity !== 0 && (
            <BottomDiv>
              <div>
                <CartBar $isScrolled={false} position="initial" />
              </div>
              <PaymentDiv>
                <BottomRecipie>
                  <BottomFlexDiv>
                    <PaymentText>Subtotal</PaymentText>
                    <div>
                      <PaymentText>{currencyTag}</PaymentText>
                      <PaymentText>{subtotalRounded}</PaymentText>
                    </div>
                  </BottomFlexDiv>
                  <BottomFlexDiv>
                    <PaymentText>Shipping</PaymentText>
                    <div>
                      <PaymentText>{currencyTag}</PaymentText>
                      <PaymentText>{shippingCost}.00</PaymentText>
                    </div>
                  </BottomFlexDiv>
                  <BottomFlexDiv
                    style={{
                      paddingTop: "2px",
                      borderTop: "1px solid var(--background-color)",
                    }}
                  >
                    <PaymentGrantText>Grand Total</PaymentGrantText>
                    <div>
                      <PaymentGrantText>{currencyTag}</PaymentGrantText>
                      <PaymentGrantText>
                        {(subtotalRounded + shippingCost).toFixed(2)}
                      </PaymentGrantText>
                    </div>
                  </BottomFlexDiv>
                  <DarkButton onClick={() => navigate(goToCheckout())}>
                    To checkout
                  </DarkButton>
                </BottomRecipie>
              </PaymentDiv>
            </BottomDiv>
          )}
        </Wrapper>
      </Container>
    </ContainerX>
  );
};

export default CartMenu;
