import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cart-slice";
import saveForLaterSlice from "./save-slice";
const store = configureStore({
  reducer: {
    cart: cartSlice,
    saveForLater: saveForLaterSlice,
  },
});

export default store;
