import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function useNavigation() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  function goToHome() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}`;
  }
  function goToShop() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/nicotine-pouches`;
  }
  function goToNewArrivals() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/new-arrivals`;
  }
  function goToBestSellers() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/best-sellers`;
  }
  function goToProduct(category, title) {
    const currentLanguage = i18n.language;
    const formattedCategory = encodeURIComponent(
      category.toString().toLowerCase().replace(/ /g, "-")
    );
    const formattedTitle = encodeURIComponent(
      title.toString().toLowerCase().replace(/ /g, "-")
    );

    return `/${currentLanguage}/${formattedCategory}-${formattedTitle}`;
  }

  function goToCategory(category) {
    const currentLanguage = i18n.language;
    const formattedCategory = encodeURIComponent(
      category.toString().toLowerCase().replace(/ /g, "-")
    );
    return `/${currentLanguage}/nicotine-pouches/${formattedCategory}`;
  }

  function goToRegistration() {
    const currentLanguage = i18n.language;
    navigate(`/${currentLanguage}/register`);
  }
  function goToBlogs() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/blogs`;
  }
  function goToBlog(blog) {
    const currentLanguage = i18n.language;
    const formattedBlog = encodeURIComponent(
      blog.toString().toLowerCase().replace(/ /g, "-")
    );
    return `/${currentLanguage}/blog/${formattedBlog}`;
  }
  function goToProfile() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/profile`;
  }
  function goToAccount() {
    const currentLanguage = i18n.language;
    navigate(`/${currentLanguage}/account`);
  }
  function goToInviteFriend() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/invite-friend`;
  }
  function goToAccountPoints() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/account-points`;
  }
  function goToMyBalance() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/my-balance`;
  }
  function goToOrders() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/orders`;
  }
  function goToSettings() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/settings`;
  }
  function goToVoucher() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/voucher`;
  }
  function goToAddressBook(addressId) {
    const currentLanguage = i18n.language;
    console.log(addressId);
    return `/${currentLanguage}/address-book/${addressId}`;
  }
  function goToCart() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/cart`;
  }
  function goToCheckout() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/checkout`;
  }
  function goToSearch(searchValue) {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/search?q=${searchValue}`;
  }
  function goToUserPreferences(searchValue) {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/user-preferences`;
  }

  //less piority
  function goToSupport() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/support`;
  }
  function goToPrivacyPolicy() {
    return `/privacy-policy`;
  }
  function goToThankYou() {
    const currentLanguage = i18n.language;
    return `/${currentLanguage}/thank-you`;
  }

  return {
    goToHome,
    goToShop,
    goToNewArrivals,
    goToBestSellers,
    goToRegistration,
    goToBlogs,
    goToBlog,
    goToProfile,
    goToAccount,
    goToInviteFriend,
    goToAccountPoints,
    goToMyBalance,
    goToOrders,
    goToSettings,
    goToVoucher,
    goToAddressBook,
    goToProduct,
    goToCategory,
    goToCart,
    goToCheckout,
    goToSearch,
    goToUserPreferences,
    goToSupport,
    goToPrivacyPolicy,
    goToThankYou,
  };
}
