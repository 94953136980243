import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../../context/ProductContext";

const Component = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  width: 100%;

  @media only screen and (min-width: 768px) {
    margin: 0;
    justify-content: flex-end;
  }
`;

const SelectedSort = styled.h2`
  border: none;

  font-size: var(--font-size-base);
  font-weight: 100;
  text-transform: capitalize;
  color: var(--text-color);
  display: flex;
  gap: 14px;
  cursor: pointer;
  white-space: nowrap;
  background-color: var(--background-color);
  border: 1px solid var(--transparent-border-color);
  padding: var(--spacing-sm) var(--spacing-md);
  @media (min-width: 768px) {
    background-color: var(--background-color-hover);
    border: none;
  }
`;

const OptionsContainer = styled.div`
  background-color: var(--background-color-hover);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  @media (min-width: 768px) {
    border: 1px solid var(--border-color);
    display: flex;
    position: static;
    z-index: 1;
  }
`;
const RadioButton = styled.div`
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 20px;
  border: 1px solid var(--text-color-hover);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`;
const CheckedRadio = styled.div`
  background-color: var(--primary-color);
  width: 50%;
  height: 50%;
  border-radius: 20px;
`;
const Option = styled.div`
  padding: var(--spacing-sm) var(--spacing-xs);
  text-transform: capitalize;
  white-space: nowrap;
  @media (min-width: 768px) {
    border-bottom: ${(props) =>
      props.selected ? "2px solid var(--primary-color)" : "none"};

    &:hover {
      background-color: var(--primary-color);
    }
  }
  cursor: pointer;

  display: flex;
  justify-content: space-between;
`;
const OpenSortTitle = styled.div`
  text-transform: uppercase;
  padding: var(--spacing-md) var(--spacing-xs);
  border-bottom: 1px solid var(--transparent-border-color);

  @media (min-width: 768px) {
    display: none;
  }
`;
const MaskContainer = styled.div`
  background-color: var(--transparent-color);
  position: fixed;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  right: 0;
  top: 0;
  z-index: 1;
  min-width: 100%;
  height: 100vh;
  z-index: 9999;
  @media (min-width: 768px) {
    display: none;
  }
`;

const ShopSort = () => {
  const { t } = useTranslation();
  const items = [
    "strength",
    "price_asc",
    "price_desc",
    "name",
    "best_sellers",
    "date",
  ];
  const [selectedSort, setSelectedSort] = useState("strength");
  let { sortProducts, products } = useContext(ProductContext);
  const [isOpen, setIsOpen] = useState(false);

  // useEffect hook to handle sorting
  useEffect(() => {
    // Check if 'price_desc' is selected and set 'isAscending' accordingly
    if (selectedSort === "price_desc") {
      sortProducts(products, "price", false); // Sort by price, descending
    } else if (selectedSort === "price_asc") {
      sortProducts(products, "price", true); // Sort by price, ascending
    } else {
      sortProducts(products, selectedSort); // Sort by other criteria
    }
  }, [selectedSort]);

  const toggleSort = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Component>
      <SelectedSort onClick={toggleSort}>{t("SHOP.SORT")}</SelectedSort>

      <OptionsContainer $isOpen={isOpen}>
        <OpenSortTitle>{t("SHOP.SORT")}</OpenSortTitle>
        {items.map((item, index) => (
          <Option
            key={index}
            selected={item === selectedSort}
            onClick={() => {
              setSelectedSort(item);
              setIsOpen(false);
            }}
          >
            {t(`SHOP.${item.toUpperCase()}`)}
            <RadioButton>
              {item === selectedSort && <CheckedRadio />}
            </RadioButton>
          </Option>
        ))}
      </OptionsContainer>
      <MaskContainer $isOpen={isOpen} onClick={toggleSort} />
    </Component>
  );
};

export default ShopSort;
