import { useState } from "react";
import styled from "styled-components";

const Container = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h1`
  padding-top: 20px;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
  @media (min-width: 767px) {
    font-size: var(--font-size-xxlarge);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
const BorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderTop = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--background-color-hover);
  padding-bottom: 14px;
  @media (min-width: 767px) {
    border-bottom: 4px solid var(--text-color);
    width: 40px;
  }
`;

const Wrapper = styled.div`
  width: calc(100% - 20px);
  @media (min-width: 1024px) {
    max-width: 800px;
  }
`;
const Flex1 = styled.div`
  @media (min-width: 767px) {
    flex: 1;
  }
`;
const VoucherContainer = styled.div`
  background-color: var(--background-color-hover);
  padding: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
    gap: 24px;
  }
`;
const Input = styled.input`
  margin-bottom: 10px;
  padding: 18px 8px;
  border: 1px solid var(--background-color);
  border-radius: 4px;
  font-weight: 500;
  &::placeholder {
    text-transform: uppercase;
  }
  &:disabled {
    background-color: var(--background-color);
    cursor: not-allowed;
  }
  @media (min-width: 767px) {
    width: 40%;
    height: 50px;
    padding: 0 8px;
  }
`;
const Button = styled.button`
  width: 100%;
  @media (min-width: 767px) {
    width: 50%;
    max-width: 200px;
    height: 50px;
  }
`;
const VoucherTitle = styled.h3`
  padding: 20px 0;
  font-size: var(--font-size-base);
  font-weight: 500;
  border-bottom: 2px solid var(--background-color-hover);
`;

const NoVouchersDiv = styled.div`
  width: 100%;
`;
const NoVouchers = styled.h3`
  padding: 20px 0 0 0;
  font-size: var(--font-size-large);
  font-weight: 500;
`;
const SmallText = styled.p`
  padding: 0;
  font-size: var(--font-size-small);
  font-weight: 500;
  color: var(--text-color-hover);
`;

const UsedVouchers = styled.div`
  margin: 20px 0;
`;
const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  padding: 20px 0 40px 0;
  border-bottom: 2px solid var(--background-color-hover);
`;
const CardLeft = styled.div`
  flex: 1;
  padding: 20px;
  border: 4px solid black;
  border-radius: 20px;
  position: relative;
`;
const CardRight = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-content: center;
`;
const MedalIcon = styled.svg`
  position: absolute;
  bottom: -20px;
  right: 20%;
  width: 40px;
  height: 40px;
  background-color: var(--background-color);
`;
const MediumText = styled(SmallText)`
  font-weight: 500;
`;

const Voucher = () => {
  const noVoucher = false;
  const [voucher, setVoucher] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Unesen vaucer!");
  };
  return (
    <Container>
      <Title>Vouchers</Title>
      <BorderWrapper>
        <BorderTop />
      </BorderWrapper>
      <Wrapper>
        <Flex1>
          <VoucherContainer>
            <VoucherTitle>Transfer vouchers to account</VoucherTitle>
            <Form onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Stara šifra"
                value={voucher}
                onChange={(e) => setVoucher(e.target.value)}
              />

              <Button type="submit">Transfer Voucher</Button>
            </Form>
          </VoucherContainer>
        </Flex1>
        <Flex1>
          {noVoucher ? (
            <NoVouchersDiv>
              <NoVouchers>No Vouchers unused</NoVouchers>
              <SmallText>(Reedem vouchers in the shopping cart)</SmallText>
            </NoVouchersDiv>
          ) : (
            <>
              <UsedVouchers>
                <VoucherTitle>2 Discounted vouchers</VoucherTitle>
              </UsedVouchers>
              <CardContainer>
                <CardLeft>
                  10%{" "}
                  <MedalIcon
                    version="1.1"
                    id="_x32_"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="black"
                  >
                    <g>
                      <path d="M355.432,371.022c-0.007,0-0.007,0-0.014,0.007c-0.007,0.007-0.014,0.014-0.028,0.021L355.432,371.022z" />
                      <path d="M364.842,362.601l-0.007,0.007c-0.008,0.006-0.008,0.014-0.014,0.021L364.842,362.601z" />
                      <path
                        d="M147.158,362.601l0.057,0.064c-0.007-0.007-0.007-0.007-0.007-0.015c-0.007-0.007-0.022-0.021-0.028-0.028
		L147.158,362.601z"
                      />
                      <path d="M217.769,341.982l-0.15-0.035c0.007,0,0.014,0.006,0.014,0.006c0.007,0,0.007,0,0.007,0L217.769,341.982z" />
                      <path
                        d="M448.893,179.946c-4.722-7.404-10.405-12.787-14.701-17.56c-2.148-2.34-3.946-4.466-5.128-6.159
		c-1.202-1.7-1.7-2.916-1.792-3.301c-0.107-0.398-0.236-1.18-0.228-2.339c-0.05-2.938,0.946-8.051,2.325-13.904
		c1.309-5.91,3.001-12.554,3.059-20.284c0-5.185-0.889-11.244-4.225-17.019c-1.885-3.264-4.296-5.96-6.806-8.108
		c-4.438-3.762-9.104-5.995-13.499-7.759c-6.601-2.596-12.909-4.182-17.845-5.874c-2.454-0.825-4.544-1.658-6.031-2.425
		c-1.508-0.755-2.368-1.43-2.674-1.75l-0.007-0.014c-0.491-0.42-1.771-2.283-2.987-5.448c-1.92-4.722-3.628-11.756-6.33-19.196
		c-1.394-3.734-3.073-7.639-5.604-11.536c-2.525-3.862-6.067-7.817-10.974-10.654c-5.789-3.336-11.841-4.232-17.026-4.225
		c-7.732,0.05-14.374,1.75-20.284,3.058c-5.853,1.38-10.974,2.376-13.904,2.326c-1.166,0.007-1.942-0.121-2.34-0.234
		c-0.505-0.079-2.503-1.025-5.12-3.151c-3.99-3.13-8.99-8.4-15.114-13.506c-3.101-2.546-6.55-5.092-10.747-7.19
		C266.75,1.615,261.672,0,255.996,0c-7.617,0.014-13.954,2.831-18.868,5.925c-7.397,4.729-12.781,10.412-17.553,14.7
		c-2.34,2.155-4.466,3.954-6.159,5.128c-1.7,1.202-2.909,1.7-3.293,1.786c-0.412,0.114-1.187,0.242-2.346,0.234
		c-2.931,0.05-8.044-0.946-13.897-2.326c-5.918-1.315-12.56-3.008-20.298-3.066c-5.185,0-11.238,0.897-17.013,4.218l-0.149,0.086
		l0.121-0.064c-3.257,1.885-5.946,4.295-8.086,6.806c-3.756,4.438-5.989,9.097-7.753,13.492c-2.588,6.607-4.174,12.908-5.867,17.844
		c-0.832,2.461-1.665,4.545-2.426,6.038c-0.76,1.5-1.429,2.361-1.742,2.66c-0.434,0.491-2.304,1.779-5.469,3.002
		c-4.73,1.92-11.763,3.62-19.203,6.33c-3.734,1.387-7.638,3.066-11.536,5.604c-3.862,2.525-7.81,6.074-10.647,10.981
		c-3.336,5.775-4.232,11.834-4.232,17.026c0.058,7.731,1.758,14.374,3.066,20.291c1.387,5.853,2.383,10.968,2.334,13.897
		c0,1.16-0.121,1.942-0.236,2.34l0.007-0.014c-0.085,0.512-1.038,2.518-3.172,5.128c-3.129,3.997-8.4,8.99-13.506,15.114
		c-2.546,3.094-5.1,6.543-7.198,10.739c-2.077,4.161-3.692,9.238-3.692,14.914c0.014,7.617,2.831,13.961,5.924,18.876
		c4.73,7.404,10.42,12.795,14.715,17.56c2.155,2.346,3.954,4.466,5.135,6.166c1.202,1.7,1.7,2.91,1.792,3.301l0.064,0.248
		l-0.072-0.263c0.114,0.406,0.236,1.181,0.236,2.34c0.049,2.93-0.946,8.044-2.334,13.897c-1.308,5.917-3.008,12.56-3.066,20.291
		c0,5.199,0.897,11.252,4.232,17.027c1.877,3.258,4.289,5.96,6.806,8.101c4.431,3.77,9.096,6.003,13.499,7.766
		c6.6,2.596,12.908,4.175,17.844,5.868c2.461,0.832,4.545,1.664,6.038,2.425c1.5,0.761,2.361,1.43,2.66,1.749
		c0.455,0.406,1.579,1.992,2.696,4.73c1.138,2.703,2.283,6.386,3.535,10.49l-0.008-0.028c1.067,3.506,2.226,7.311,3.777,11.251
		c1.564,3.926,3.498,8.037,6.55,12.041c1.258,1.672,2.788,3.286,4.502,4.808l-45.155,97.515l62.516-1.87L208.074,512l44.288-95.666
		l44.274,95.659l39.024-48.897l62.516,1.871l-43.37-93.646c0.206-0.114,0.412-0.178,0.611-0.292c3.976-2.297,7.063-5.32,9.417-8.42
		c3.3-4.367,5.306-8.834,6.927-13.073c2.39-6.358,3.926-12.404,5.568-17.105c0.797-2.347,1.601-4.324,2.34-5.732
		c0.726-1.422,1.373-2.233,1.672-2.525c0.441-0.497,2.312-1.778,5.477-3.001c4.73-1.92,11.763-3.627,19.196-6.33
		c3.734-1.387,7.638-3.065,11.536-5.605c3.862-2.518,7.809-6.067,10.647-10.974c3.343-5.782,4.232-11.842,4.232-17.027
		c-0.05-7.73-1.75-14.373-3.059-20.283c-1.379-5.854-2.375-10.968-2.325-13.905c-0.008-1.166,0.121-1.942,0.228-2.34
		c0.092-0.512,1.038-2.518,3.164-5.128c3.129-3.99,8.393-8.99,13.499-15.114c2.546-3.094,5.099-6.55,7.19-10.739
		c2.077-4.161,3.692-9.239,3.692-14.915C454.803,191.197,451.987,184.86,448.893,179.946z M424.228,202.185
		c-1.977,3.279-6.536,8.016-11.529,13.4c-2.489,2.731-5.092,5.682-7.518,9.138c-2.397,3.457-4.702,7.475-6.045,12.461
		c-0.925,3.442-1.224,6.764-1.224,9.864c0.05,7.774,1.743,14.474,3.058,20.398c1.38,5.86,2.376,10.959,2.326,13.79
		c0,0.946-0.086,1.615-0.172,1.985l-0.142,0.455l-0.52,0.54c-0.704,0.633-2.546,1.749-5.32,2.831
		c-4.146,1.672-10.071,3.221-16.429,5.348c-3.186,1.089-6.515,2.333-9.9,4.054c-3.378,1.721-6.863,3.925-10.064,7.119
		c-4.125,4.147-6.608,8.712-8.514,13.015c-2.816,6.48-4.424,12.724-6.095,17.902c-1.586,5.178-3.364,9.132-4.495,10.491
		l-0.021,0.028l-0.562,0.633l-0.17,0.135l-0.1,0.064l0.05-0.036l-0.449,0.143c-0.369,0.085-1.031,0.17-1.984,0.17
		c-2.824,0.05-7.923-0.946-13.791-2.326c-5.924-1.316-12.617-3.008-20.39-3.058c-3.108,0-6.422,0.299-9.865,1.216l-0.306,0.085
		l-0.86,0.27l0.007,0.014c-8.009,2.561-13.435,7.091-18.214,11.202c-4.765,4.218-8.876,8.357-12.34,11.131l0.022-0.014
		c-1.992,1.614-3.713,2.738-4.879,3.286c-1.194,0.569-1.671,0.583-1.799,0.583c-0.121,0-0.598-0.014-1.792-0.583
		c-1.16-0.548-2.873-1.665-4.844-3.265c-3.527-2.831-7.731-7.091-12.624-11.394c-4.95-4.232-10.618-8.926-19.103-11.309
		c-3.442-0.924-6.756-1.223-9.865-1.223c-7.766,0.05-14.466,1.742-20.39,3.058c-5.86,1.38-10.967,2.376-13.797,2.326
		c-0.946,0-1.615-0.085-1.985-0.17l-0.455-0.143l-0.021-0.014l-0.725-0.761l-0.015-0.021l-0.021-0.022
		c-0.654-0.846-1.643-2.61-2.61-5.085c-0.982-2.468-1.956-5.583-3.001-9.018l-0.007-0.028c-1.245-4.04-2.567-8.528-4.502-13.179
		c-1.956-4.63-4.509-9.594-8.976-14.103c-4.907-4.85-10.327-7.46-15.327-9.48c-7.517-2.938-14.7-4.63-19.85-6.572
		c-2.553-0.939-4.53-1.899-5.661-2.646c-1.167-0.775-1.38-1.166-1.394-1.194l-0.007-0.015l-0.142-0.448
		c-0.079-0.37-0.171-1.031-0.171-1.985c-0.05-2.824,0.946-7.923,2.334-13.783c1.315-5.924,3.015-12.624,3.065-20.405
		c0-3.101-0.306-6.416-1.216-9.85l-0.007-0.015c-1.836-6.657-5.228-11.557-8.528-15.754c-5.028-6.237-10.363-11.251-13.827-15.504
		c-1.735-2.091-2.966-3.919-3.57-5.164c-0.626-1.273-0.648-1.785-0.648-1.942c-0.014-0.149,0.072-1.166,1.452-3.364
		c1.984-3.271,6.55-8.016,11.543-13.392c2.489-2.731,5.099-5.682,7.518-9.138c2.404-3.457,4.708-7.475,6.06-12.461l0.007-0.014
		c0.91-3.442,1.216-6.75,1.216-9.851c-0.05-7.78-1.75-14.48-3.065-20.404c-1.388-5.86-2.383-10.96-2.334-13.784
		c0-0.953,0.092-1.614,0.171-1.984l0.142-0.448l0.007-0.014l0.52-0.548c0.697-0.626,2.546-1.743,5.313-2.824
		c4.146-1.678,10.078-3.229,16.436-5.356c3.186-1.081,6.522-2.333,9.908-4.054c3.37-1.721,6.863-3.934,10.064-7.12
		c4.843-4.907,7.447-10.32,9.459-15.312c2.937-7.51,4.637-14.694,6.579-19.85c0.939-2.56,1.899-4.544,2.653-5.676
		c0.746-1.124,1.124-1.351,1.18-1.387l0.448-0.142c0.377-0.086,1.046-0.178,1.992-0.178c2.837-0.05,7.937,0.946,13.797,2.334
		c5.925,1.315,12.624,3.008,20.398,3.058c3.101,0,6.415-0.298,9.857-1.224c6.65-1.821,11.557-5.206,15.761-8.513
		c6.23-5.014,11.259-10.356,15.511-13.819c2.092-1.743,3.919-2.966,5.164-3.578c1.273-0.626,1.785-0.64,1.928-0.64
		c0.15-0.015,1.174,0.071,3.378,1.458c3.272,1.977,8.023,6.543,13.4,11.536c2.731,2.489,5.683,5.092,9.14,7.51
		c3.456,2.404,7.474,4.701,12.46,6.053c3.436,0.918,6.75,1.216,9.858,1.216c7.774-0.05,14.473-1.743,20.398-3.058
		c5.868-1.388,10.967-2.383,13.791-2.326c0.952-0.007,1.614,0.086,1.984,0.164l0.448,0.142l0.548,0.519
		c0.634,0.704,1.75,2.554,2.831,5.327c1.671,4.147,3.222,10.072,5.348,16.43c1.088,3.194,2.34,6.522,4.054,9.908
		c1.728,3.37,3.934,6.863,7.126,10.064l0.235,0.227l-0.242-0.242c4.915,4.851,10.326,7.461,15.32,9.481
		c7.518,2.937,14.701,4.63,19.843,6.579c2.56,0.932,4.537,1.892,5.668,2.646c1.167,0.775,1.387,1.173,1.409,1.202l0.134,0.448
		c0.085,0.369,0.172,1.031,0.172,1.984c0.049,2.831-0.946,7.93-2.326,13.791c-1.315,5.925-3.008,12.624-3.058,20.398
		c0,3.1,0.298,6.422,1.216,9.865c1.835,6.656,5.228,11.557,8.528,15.76c5.021,6.23,10.356,11.245,13.819,15.497
		c1.735,2.092,2.958,3.919,3.563,5.164c0.626,1.273,0.648,1.792,0.648,1.942C425.7,198.971,425.615,199.988,424.228,202.185z"
                      />
                      <path
                        d="M255.996,77.679c-66.904,0.007-121.128,54.231-121.135,121.135c-0.007,34.971,14.857,66.556,38.591,88.639
		c19.978,18.656,46.379,30.612,75.481,32.282h0.007c0.022,0,0.043,0.007,0.064,0.007l-0.036-0.007
		c2.362,0.15,4.709,0.213,7.027,0.213c66.912-0.006,121.128-54.223,121.135-121.135C377.124,131.91,322.908,77.686,255.996,77.679z
		 M326.208,269.019c-18.008,17.987-42.758,29.075-70.212,29.081c-1.949,0-3.841-0.049-5.675-0.17l-0.079-0.007
		c-23.897-1.358-45.461-11.13-61.89-26.45c-19.501-18.179-31.635-43.932-31.642-72.658c0.007-27.453,11.095-52.203,29.081-70.204
		c18.001-17.987,42.752-29.075,70.205-29.082c27.453,0.007,52.203,11.095,70.204,29.082c17.987,18.001,29.075,42.751,29.082,70.204
		C355.276,226.268,344.188,251.025,326.208,269.019z"
                      />
                    </g>
                  </MedalIcon>
                </CardLeft>
                <CardRight>
                  <MediumText>
                    dsadadsadsadsa sa s <strong>sadadsasda</strong>
                  </MediumText>
                </CardRight>
              </CardContainer>
              <CardContainer>
                <CardLeft>
                  10%{" "}
                  <MedalIcon
                    version="1.1"
                    id="_x32_"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="black"
                  >
                    <g>
                      <path d="M355.432,371.022c-0.007,0-0.007,0-0.014,0.007c-0.007,0.007-0.014,0.014-0.028,0.021L355.432,371.022z" />
                      <path d="M364.842,362.601l-0.007,0.007c-0.008,0.006-0.008,0.014-0.014,0.021L364.842,362.601z" />
                      <path
                        d="M147.158,362.601l0.057,0.064c-0.007-0.007-0.007-0.007-0.007-0.015c-0.007-0.007-0.022-0.021-0.028-0.028
		L147.158,362.601z"
                      />
                      <path d="M217.769,341.982l-0.15-0.035c0.007,0,0.014,0.006,0.014,0.006c0.007,0,0.007,0,0.007,0L217.769,341.982z" />
                      <path
                        d="M448.893,179.946c-4.722-7.404-10.405-12.787-14.701-17.56c-2.148-2.34-3.946-4.466-5.128-6.159
		c-1.202-1.7-1.7-2.916-1.792-3.301c-0.107-0.398-0.236-1.18-0.228-2.339c-0.05-2.938,0.946-8.051,2.325-13.904
		c1.309-5.91,3.001-12.554,3.059-20.284c0-5.185-0.889-11.244-4.225-17.019c-1.885-3.264-4.296-5.96-6.806-8.108
		c-4.438-3.762-9.104-5.995-13.499-7.759c-6.601-2.596-12.909-4.182-17.845-5.874c-2.454-0.825-4.544-1.658-6.031-2.425
		c-1.508-0.755-2.368-1.43-2.674-1.75l-0.007-0.014c-0.491-0.42-1.771-2.283-2.987-5.448c-1.92-4.722-3.628-11.756-6.33-19.196
		c-1.394-3.734-3.073-7.639-5.604-11.536c-2.525-3.862-6.067-7.817-10.974-10.654c-5.789-3.336-11.841-4.232-17.026-4.225
		c-7.732,0.05-14.374,1.75-20.284,3.058c-5.853,1.38-10.974,2.376-13.904,2.326c-1.166,0.007-1.942-0.121-2.34-0.234
		c-0.505-0.079-2.503-1.025-5.12-3.151c-3.99-3.13-8.99-8.4-15.114-13.506c-3.101-2.546-6.55-5.092-10.747-7.19
		C266.75,1.615,261.672,0,255.996,0c-7.617,0.014-13.954,2.831-18.868,5.925c-7.397,4.729-12.781,10.412-17.553,14.7
		c-2.34,2.155-4.466,3.954-6.159,5.128c-1.7,1.202-2.909,1.7-3.293,1.786c-0.412,0.114-1.187,0.242-2.346,0.234
		c-2.931,0.05-8.044-0.946-13.897-2.326c-5.918-1.315-12.56-3.008-20.298-3.066c-5.185,0-11.238,0.897-17.013,4.218l-0.149,0.086
		l0.121-0.064c-3.257,1.885-5.946,4.295-8.086,6.806c-3.756,4.438-5.989,9.097-7.753,13.492c-2.588,6.607-4.174,12.908-5.867,17.844
		c-0.832,2.461-1.665,4.545-2.426,6.038c-0.76,1.5-1.429,2.361-1.742,2.66c-0.434,0.491-2.304,1.779-5.469,3.002
		c-4.73,1.92-11.763,3.62-19.203,6.33c-3.734,1.387-7.638,3.066-11.536,5.604c-3.862,2.525-7.81,6.074-10.647,10.981
		c-3.336,5.775-4.232,11.834-4.232,17.026c0.058,7.731,1.758,14.374,3.066,20.291c1.387,5.853,2.383,10.968,2.334,13.897
		c0,1.16-0.121,1.942-0.236,2.34l0.007-0.014c-0.085,0.512-1.038,2.518-3.172,5.128c-3.129,3.997-8.4,8.99-13.506,15.114
		c-2.546,3.094-5.1,6.543-7.198,10.739c-2.077,4.161-3.692,9.238-3.692,14.914c0.014,7.617,2.831,13.961,5.924,18.876
		c4.73,7.404,10.42,12.795,14.715,17.56c2.155,2.346,3.954,4.466,5.135,6.166c1.202,1.7,1.7,2.91,1.792,3.301l0.064,0.248
		l-0.072-0.263c0.114,0.406,0.236,1.181,0.236,2.34c0.049,2.93-0.946,8.044-2.334,13.897c-1.308,5.917-3.008,12.56-3.066,20.291
		c0,5.199,0.897,11.252,4.232,17.027c1.877,3.258,4.289,5.96,6.806,8.101c4.431,3.77,9.096,6.003,13.499,7.766
		c6.6,2.596,12.908,4.175,17.844,5.868c2.461,0.832,4.545,1.664,6.038,2.425c1.5,0.761,2.361,1.43,2.66,1.749
		c0.455,0.406,1.579,1.992,2.696,4.73c1.138,2.703,2.283,6.386,3.535,10.49l-0.008-0.028c1.067,3.506,2.226,7.311,3.777,11.251
		c1.564,3.926,3.498,8.037,6.55,12.041c1.258,1.672,2.788,3.286,4.502,4.808l-45.155,97.515l62.516-1.87L208.074,512l44.288-95.666
		l44.274,95.659l39.024-48.897l62.516,1.871l-43.37-93.646c0.206-0.114,0.412-0.178,0.611-0.292c3.976-2.297,7.063-5.32,9.417-8.42
		c3.3-4.367,5.306-8.834,6.927-13.073c2.39-6.358,3.926-12.404,5.568-17.105c0.797-2.347,1.601-4.324,2.34-5.732
		c0.726-1.422,1.373-2.233,1.672-2.525c0.441-0.497,2.312-1.778,5.477-3.001c4.73-1.92,11.763-3.627,19.196-6.33
		c3.734-1.387,7.638-3.065,11.536-5.605c3.862-2.518,7.809-6.067,10.647-10.974c3.343-5.782,4.232-11.842,4.232-17.027
		c-0.05-7.73-1.75-14.373-3.059-20.283c-1.379-5.854-2.375-10.968-2.325-13.905c-0.008-1.166,0.121-1.942,0.228-2.34
		c0.092-0.512,1.038-2.518,3.164-5.128c3.129-3.99,8.393-8.99,13.499-15.114c2.546-3.094,5.099-6.55,7.19-10.739
		c2.077-4.161,3.692-9.239,3.692-14.915C454.803,191.197,451.987,184.86,448.893,179.946z M424.228,202.185
		c-1.977,3.279-6.536,8.016-11.529,13.4c-2.489,2.731-5.092,5.682-7.518,9.138c-2.397,3.457-4.702,7.475-6.045,12.461
		c-0.925,3.442-1.224,6.764-1.224,9.864c0.05,7.774,1.743,14.474,3.058,20.398c1.38,5.86,2.376,10.959,2.326,13.79
		c0,0.946-0.086,1.615-0.172,1.985l-0.142,0.455l-0.52,0.54c-0.704,0.633-2.546,1.749-5.32,2.831
		c-4.146,1.672-10.071,3.221-16.429,5.348c-3.186,1.089-6.515,2.333-9.9,4.054c-3.378,1.721-6.863,3.925-10.064,7.119
		c-4.125,4.147-6.608,8.712-8.514,13.015c-2.816,6.48-4.424,12.724-6.095,17.902c-1.586,5.178-3.364,9.132-4.495,10.491
		l-0.021,0.028l-0.562,0.633l-0.17,0.135l-0.1,0.064l0.05-0.036l-0.449,0.143c-0.369,0.085-1.031,0.17-1.984,0.17
		c-2.824,0.05-7.923-0.946-13.791-2.326c-5.924-1.316-12.617-3.008-20.39-3.058c-3.108,0-6.422,0.299-9.865,1.216l-0.306,0.085
		l-0.86,0.27l0.007,0.014c-8.009,2.561-13.435,7.091-18.214,11.202c-4.765,4.218-8.876,8.357-12.34,11.131l0.022-0.014
		c-1.992,1.614-3.713,2.738-4.879,3.286c-1.194,0.569-1.671,0.583-1.799,0.583c-0.121,0-0.598-0.014-1.792-0.583
		c-1.16-0.548-2.873-1.665-4.844-3.265c-3.527-2.831-7.731-7.091-12.624-11.394c-4.95-4.232-10.618-8.926-19.103-11.309
		c-3.442-0.924-6.756-1.223-9.865-1.223c-7.766,0.05-14.466,1.742-20.39,3.058c-5.86,1.38-10.967,2.376-13.797,2.326
		c-0.946,0-1.615-0.085-1.985-0.17l-0.455-0.143l-0.021-0.014l-0.725-0.761l-0.015-0.021l-0.021-0.022
		c-0.654-0.846-1.643-2.61-2.61-5.085c-0.982-2.468-1.956-5.583-3.001-9.018l-0.007-0.028c-1.245-4.04-2.567-8.528-4.502-13.179
		c-1.956-4.63-4.509-9.594-8.976-14.103c-4.907-4.85-10.327-7.46-15.327-9.48c-7.517-2.938-14.7-4.63-19.85-6.572
		c-2.553-0.939-4.53-1.899-5.661-2.646c-1.167-0.775-1.38-1.166-1.394-1.194l-0.007-0.015l-0.142-0.448
		c-0.079-0.37-0.171-1.031-0.171-1.985c-0.05-2.824,0.946-7.923,2.334-13.783c1.315-5.924,3.015-12.624,3.065-20.405
		c0-3.101-0.306-6.416-1.216-9.85l-0.007-0.015c-1.836-6.657-5.228-11.557-8.528-15.754c-5.028-6.237-10.363-11.251-13.827-15.504
		c-1.735-2.091-2.966-3.919-3.57-5.164c-0.626-1.273-0.648-1.785-0.648-1.942c-0.014-0.149,0.072-1.166,1.452-3.364
		c1.984-3.271,6.55-8.016,11.543-13.392c2.489-2.731,5.099-5.682,7.518-9.138c2.404-3.457,4.708-7.475,6.06-12.461l0.007-0.014
		c0.91-3.442,1.216-6.75,1.216-9.851c-0.05-7.78-1.75-14.48-3.065-20.404c-1.388-5.86-2.383-10.96-2.334-13.784
		c0-0.953,0.092-1.614,0.171-1.984l0.142-0.448l0.007-0.014l0.52-0.548c0.697-0.626,2.546-1.743,5.313-2.824
		c4.146-1.678,10.078-3.229,16.436-5.356c3.186-1.081,6.522-2.333,9.908-4.054c3.37-1.721,6.863-3.934,10.064-7.12
		c4.843-4.907,7.447-10.32,9.459-15.312c2.937-7.51,4.637-14.694,6.579-19.85c0.939-2.56,1.899-4.544,2.653-5.676
		c0.746-1.124,1.124-1.351,1.18-1.387l0.448-0.142c0.377-0.086,1.046-0.178,1.992-0.178c2.837-0.05,7.937,0.946,13.797,2.334
		c5.925,1.315,12.624,3.008,20.398,3.058c3.101,0,6.415-0.298,9.857-1.224c6.65-1.821,11.557-5.206,15.761-8.513
		c6.23-5.014,11.259-10.356,15.511-13.819c2.092-1.743,3.919-2.966,5.164-3.578c1.273-0.626,1.785-0.64,1.928-0.64
		c0.15-0.015,1.174,0.071,3.378,1.458c3.272,1.977,8.023,6.543,13.4,11.536c2.731,2.489,5.683,5.092,9.14,7.51
		c3.456,2.404,7.474,4.701,12.46,6.053c3.436,0.918,6.75,1.216,9.858,1.216c7.774-0.05,14.473-1.743,20.398-3.058
		c5.868-1.388,10.967-2.383,13.791-2.326c0.952-0.007,1.614,0.086,1.984,0.164l0.448,0.142l0.548,0.519
		c0.634,0.704,1.75,2.554,2.831,5.327c1.671,4.147,3.222,10.072,5.348,16.43c1.088,3.194,2.34,6.522,4.054,9.908
		c1.728,3.37,3.934,6.863,7.126,10.064l0.235,0.227l-0.242-0.242c4.915,4.851,10.326,7.461,15.32,9.481
		c7.518,2.937,14.701,4.63,19.843,6.579c2.56,0.932,4.537,1.892,5.668,2.646c1.167,0.775,1.387,1.173,1.409,1.202l0.134,0.448
		c0.085,0.369,0.172,1.031,0.172,1.984c0.049,2.831-0.946,7.93-2.326,13.791c-1.315,5.925-3.008,12.624-3.058,20.398
		c0,3.1,0.298,6.422,1.216,9.865c1.835,6.656,5.228,11.557,8.528,15.76c5.021,6.23,10.356,11.245,13.819,15.497
		c1.735,2.092,2.958,3.919,3.563,5.164c0.626,1.273,0.648,1.792,0.648,1.942C425.7,198.971,425.615,199.988,424.228,202.185z"
                      />
                      <path
                        d="M255.996,77.679c-66.904,0.007-121.128,54.231-121.135,121.135c-0.007,34.971,14.857,66.556,38.591,88.639
		c19.978,18.656,46.379,30.612,75.481,32.282h0.007c0.022,0,0.043,0.007,0.064,0.007l-0.036-0.007
		c2.362,0.15,4.709,0.213,7.027,0.213c66.912-0.006,121.128-54.223,121.135-121.135C377.124,131.91,322.908,77.686,255.996,77.679z
		 M326.208,269.019c-18.008,17.987-42.758,29.075-70.212,29.081c-1.949,0-3.841-0.049-5.675-0.17l-0.079-0.007
		c-23.897-1.358-45.461-11.13-61.89-26.45c-19.501-18.179-31.635-43.932-31.642-72.658c0.007-27.453,11.095-52.203,29.081-70.204
		c18.001-17.987,42.752-29.075,70.205-29.082c27.453,0.007,52.203,11.095,70.204,29.082c17.987,18.001,29.075,42.751,29.082,70.204
		C355.276,226.268,344.188,251.025,326.208,269.019z"
                      />
                    </g>
                  </MedalIcon>
                </CardLeft>
                <CardRight>
                  <MediumText>
                    dsadadsadsadsa sa s <strong>sadadsasda</strong>
                  </MediumText>
                </CardRight>
              </CardContainer>
            </>
          )}
        </Flex1>
      </Wrapper>
    </Container>
  );
};

export default Voucher;
