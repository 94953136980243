import styled from "styled-components";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  width: 100%;
  background-color: var(--background-color-hover);
  height: var(--navbar-mini);
`;
const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: var(--font-size-small);
  letter-spacing: var(--spacing-xxs);

  gap: var(--spacing-md);
  cursor: pointer;
`;
const GridItemLeft = styled(GridItem)`
  border-right: 1px solid var(--text-color);
`;
const BottomHeader = () => {
  const { goToBestSellers, goToNewArrivals } = useNavigation();
  const navigate = useNavigate();
  return (
    <GridContainer>
      <GridItemLeft
        onClick={() => {
          navigate(goToNewArrivals());
        }}
      >
        Shop new
      </GridItemLeft>
      <GridItem
        onClick={() => {
          navigate(goToBestSellers());
        }}
      >
        Special offers
      </GridItem>
    </GridContainer>
  );
};

export default BottomHeader;
