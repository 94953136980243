import styled from "styled-components";

// Styled komponenta za zajedničke stilove atributa
const AttributeContainer = styled.div`
  //width: 94%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  margin: var(--spacing-sm) 0;
`;

// Styled komponenta za kreiranje pie chart-a
const PieChart = styled.div`
  font-family: "Satoshi";

  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  border: 1px solid var(--p-color);
  span {
    color: var(--text-color); // Bela boja teksta unutar pie chart-a
    font-size: var(--font-size-base); // Velicina teksta unutar pie chart-a
    display: none;
  }
`;

// Styled komponenta za labelu atributa
const AttributeLabel = styled.div`
  margin-top: var(--spacing-xxs);
  font-size: var(--font-size-small); // Velicina teksta labela
  color: var(--text-color); // Boja teksta labela
  text-transform: uppercase;
  font-family: "Larken-Light";
`;

const ProductAttributePie = ({ type, percentage, label }) => {
  const primaryColor =
    percentage < 50 ? "var(--success-color)" : "var(--error-color)"; // Set colors based on percentage
  const background = `conic-gradient(${primaryColor} ${percentage}%, var(--background-color) 0)`;

  return (
    <AttributeContainer>
      <PieChart background={background}>
        <span>{percentage}</span>
      </PieChart>
      <AttributeLabel>{label}</AttributeLabel>
    </AttributeContainer>
  );
};

export default ProductAttributePie;
