import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ProfileMenu from "../../../components/profile/ProfileMenu";
import { AuthUserContext } from "../../../context/AuthUserContext";
import OrderDetails from "../../../components/profile/OrderDetails";
import PersonalInformation from "../../../components/profile/PersonalInformation";
import ManageAddresses from "../../../components/profile/ManageAddresses";
import Coins from "../../../components/profile/Coins";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Referrals from "../../../components/profile/Referrals";
import { useTranslation } from "react-i18next";
import OrderHistoryFilter from "../../../components/profile/OrderHistoryFilter";

const Container = styled.main`
  display: flex;

  background-color: var(--background-color-hover);
  padding: 0;
  flex-direction: column;
  @media (min-width: 768px) {
    padding-top: calc(var(--navbar-height-desktop));
    justify-content: center;
    flex-direction: row;
  }
`;
const Wrapper = styled.div`
  width: 100%;

  @media (min-width: 767px) {
    max-width: var(--max-width-container);
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: var(--spacing-lg);
    padding: 0 10px;
  }
`;
const LeftContainer = styled.div``;
const RightContainer = styled.div``;
const OrderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: flex-start;
  gap: 14px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const LayoutContainer = styled.div`
  background-color: var(--background-color);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-xxl);
`;
const LayoutContainer2 = styled(LayoutContainer)`
  padding: 0;
  background-color: var(--background-color-hover);

  @media (min-width: 768px) {
    padding: 0 var(--spacing-lg);
    background-color: var(--background-color);
  }
`;
const MobileHeader = styled.div`
  display: flex;
  padding: var(--spacing-md);
  background-color: var(--background-color-hover);
  gap: Var(--spacing-md);
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #e5e5e5;
  @media (min-width: 768px) {
    display: none;
  }
`;
const BackArrow = styled.svg`
  cursor: pointer;
`;
const HeaderText = styled.p`
  font-size: var(--font-size-base);
  font-weight: 200;
  padding: 0;
  margin: 0;
`;
const TopTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 0;
`;
const TopTitle = styled.h2`
  color: var(--text-color);

  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);
  width: 90%;
  font-size: var(--font-size-base);
  font-weight: bold;

  text-transform: uppercase;

  opacity: 0.7;

  @media (min-width: 768px) {
    display: none;
  }
`;
const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
`;
const EmptyText = styled.p`
  color: var(--p-color);
  font-size: var(--font-size-small);
  @media (min-width: 768px) {
    font-size: var(--font-size-base);
  }
`;

const Profile = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [newAddress, setNewAddress] = useState(false);
  const { userProfile, selectedItem, setSelectedItem } =
    useContext(AuthUserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isMobile) {
      if (selectedItem === "profile") {
        setSelectedItem("");
      }
    } else {
      if (selectedItem === "") {
        setSelectedItem("profile");
      }
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedItem]);
  return (
    <Container>
      <MobileHeader>
        <BackArrow
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            //poseban slucaj za addrese
            if (newAddress === true) {
              setNewAddress(false);
              return;
            }
            if (selectedItem !== "") {
              setSelectedItem("");
            } else {
              navigate(-1);
            }
          }}
        >
          <g clipPath="url(#clip0_838_11238)">
            <path
              d="M16.7742 8L3.22581 8"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.09677 4.12903L3.2258 8"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.09677 11.871L3.2258 8"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_838_11238">
              <rect
                width="20"
                height="15.4839"
                fill="white"
                transform="translate(20 15.7419) rotate(-180)"
              />
            </clipPath>
          </defs>
        </BackArrow>
        {selectedItem === "" && (
          <>
            <div className="avatar">S</div>
            <HeaderText>
              {t("PROFILE.HEY")}
              {userProfile?.first_name}
            </HeaderText>
          </>
        )}
        {selectedItem === "profile" && (
          <HeaderText>{t("PROFILE.PROFILE_INFO")}</HeaderText>
        )}
        {selectedItem === "addresses" && (
          <HeaderText>{t("PROFILE.MANAGE_ADDRESSES")}</HeaderText>
        )}
        {selectedItem === "order_history" && (
          <HeaderText>{t("PROFILE.ORDERS")}</HeaderText>
        )}
        {selectedItem === "points" && (
          <HeaderText>{t("PROFILE.SNUSCO_COIN")}</HeaderText>
        )}

        {selectedItem === "referrals" && (
          <HeaderText>{t("PROFILE.REFERRALS")}</HeaderText>
        )}
      </MobileHeader>
      <Wrapper>
        <LeftContainer>
          <ProfileMenu
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        </LeftContainer>
        <RightContainer>
          {selectedItem === "profile" && (
            <>
              <TopTitleContainer>
                <TopTitle>{t("PROFILE.PERSONAL_INFO")}</TopTitle>
              </TopTitleContainer>
              <LayoutContainer>
                <PersonalInformation />
              </LayoutContainer>
            </>
          )}
          {selectedItem === "addresses" && (
            //TODO
            <LayoutContainer2>
              <ManageAddresses
                newAddress={newAddress}
                setNewAddress={setNewAddress}
              />
            </LayoutContainer2>
          )}
          {selectedItem === "order_history" && (
            <OrderContainer>
              {userProfile?.order_history.length !== 0 ? (
                // userProfile?.order_history.map((order, index) => (
                //   <OrderDetails key={order.id} order={order} />
                // ))
                <OrderHistoryFilter orders={userProfile?.order_history} />
              ) : (
                <EmptyContainer>
                  <EmptyText>No orders found</EmptyText>
                </EmptyContainer>
              )}
            </OrderContainer>
          )}

          {selectedItem === "points" && <Coins />}
          {selectedItem === "referrals" && <Referrals />}
        </RightContainer>
      </Wrapper>
    </Container>
  );
};

export default Profile;
