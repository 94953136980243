import { Link } from "react-router-dom";
import styled from "styled-components";
import OrderItemsSlider from "../../../components/product/OrderItemsSlider";
import { useContext, useEffect, useState } from "react";
import OrderProduct from "../../../components/product/OrderProduct";
import { AuthUserContext } from "../../../context/AuthUserContext";
import PageLoading from "../../../components/animations/PageLoading";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1`
  padding-top: 20px;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
  @media (min-width: 767px) {
    font-size: var(--font-size-xxlarge);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
const BorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderTop = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--background-color-hover);
  padding-bottom: 14px;
  @media (min-width: 767px) {
    border-bottom: 4px solid var(--text-color);
    width: 40px;
  }
`;
const NoOrdersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NoOrdersWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
`;

const NoText = styled.span`
  color: var(--error-color);
  font-size: var(--font-size-small);
  font-weight: 700;
`;
const ShopNowButton = styled.button``;

const ContainerUserDetails = styled.div`
  width: 100%;
  margin: 20px 0;
  background-color: var(--background-color-hover);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapperUserDetails = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  @media (min-width: 768px) {
    max-width: var(--max-width-container);
  }
`;
const UserDetailsTitle = styled.h4`
  padding: 12px 0 4px 0;
`;
const UserDetailsText = styled.div`
  font-size: var(--font-size-small);
  font-weight: 500;
  padding: 0;
  margin: 0;
`;
const TextSmall = styled(UserDetailsText)`
  font-weight: 300;
`;
const PaymentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const CardContainerX = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: var(--max-width-container);
    align-items: flex-start;
  }
`;
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const CardWrapper = styled.div`
  background-color: var(--background-color-hover);
  padding: 20px 0;
  width: 96%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  position: relative;
`;
const CardTop = styled.div`
  display: flex;
  gap: 14px;
`;
const ArrowSVG = styled.svg`
  position: absolute;
  right: 30px;
  top: calc(50% - 20px);
  cursor: pointer;
`;
const Triangle = styled.div`
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: var(--background-color-hover);
  position: absolute;
  bottom: -10px;
  left: calc(50% - 10px);
`;

const OrderItemsContainer = styled.div`
  width: 96%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin: 8px 0;
`;

const Orders = () => {
  const [showMore, setShowMore] = useState({});
  const { userProfile, fetchUserData } = useContext(AuthUserContext);
  useEffect(() => {
    if (!userProfile) {
      fetchUserData();
      return;
    }
  }, [userProfile]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // Dodajte +1 jer getMonth() vraća mesec od 0 do 11
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Koristite `padStart(2, '0')` da biste osigurali da su dan i mesec uvek prikazani sa dve cifre
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };
  const toggleShowMore = (orderId) => {
    setShowMore((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  };
  return (
    <Container>
      <Title>Orders & Returns</Title>
      {userProfile ? (
        <>
          <BorderWrapper>
            <BorderTop />
          </BorderWrapper>
          {userProfile.order_history.length === 0 ? (
            <NoOrdersContainer>
              <NoOrdersWrapper>
                <NoText>
                  Unfortunately, you have not yet placed any orders.
                </NoText>
                <ShopNowButton>SHOP NOW</ShopNowButton>
              </NoOrdersWrapper>
            </NoOrdersContainer>
          ) : (
            <>
              <ContainerUserDetails>
                <WrapperUserDetails>
                  <UserDetailsTitle>Delievered</UserDetailsTitle>
                  <UserDetailsText>
                    ID:{userProfile.order_history[0].id}
                  </UserDetailsText>
                  <UserDetailsText>
                    FROM: {formatDate(userProfile.order_history[0].created_at)}
                  </UserDetailsText>

                  <UserDetailsTitle>Delivery Address</UserDetailsTitle>
                  <UserDetailsText>
                    {userProfile.order_history[0].address_details.country}{" "}
                  </UserDetailsText>
                  <UserDetailsText>
                    {userProfile.order_history[0].address_details.city}{" "}
                    {userProfile.order_history[0].address_details.postal_code}
                  </UserDetailsText>
                  <UserDetailsText>
                    {userProfile.order_history[0].address_details.street}{" "}
                    {
                      userProfile.order_history[0].address_details
                        .building_number
                    }
                  </UserDetailsText>

                  <PaymentDiv>
                    <UserDetailsTitle>Payment Method</UserDetailsTitle>
                    <UserDetailsTitle style={{ color: "var(--error-color)" }}>
                      {userProfile.order_history[0].currency_symbol}
                      {userProfile.order_history[0].total_price}
                    </UserDetailsTitle>
                  </PaymentDiv>
                  <PaymentDiv>
                    <UserDetailsText>
                      {userProfile.order_history[0].payment_method}
                    </UserDetailsText>
                    <UserDetailsText>
                      incl. Shipping cost{" "}
                      {userProfile.order_history[0].currency_symbol}
                      {userProfile.order_history[0].shipping_cost}
                    </UserDetailsText>
                  </PaymentDiv>
                </WrapperUserDetails>
              </ContainerUserDetails>
              <CardContainerX>
                {userProfile.order_history.map((order, index) => (
                  <CardContainer key={index}>
                    <CardWrapper>
                      <CardTop>
                        <svg
                          height="80px"
                          width="80px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 511.984 511.984"
                        >
                          <polygon
                            style={{ fill: "#CCD1D9" }}
                            points="73.138,194.326 73.138,396.172 255.992,469.326 438.846,396.172 438.846,194.326 "
                          />
                          <polygon
                            style={{ fill: "#CCD1D9" }}
                            points="255.992,469.326 438.846,396.172 438.846,194.326 255.992,194.326 "
                          />
                          <polygon
                            style={{ fill: "#CCD1D9" }}
                            points="329.13,42.658 255.992,80.454 182.853,42.658 0,137.139 73.138,174.92 0,212.716 
	182.853,307.174 255.992,269.402 329.13,307.174 511.984,212.716 438.846,174.92 511.984,137.139 "
                          />
                          <polygon
                            style={{ fill: "#CCD1D9" }}
                            points="255.992,80.454 73.138,174.92 255.992,269.402 438.846,174.92 "
                          />
                        </svg>
                        <div>
                          <UserDetailsTitle>Delievered</UserDetailsTitle>
                          <TextSmall>Id:{order.id}</TextSmall>
                          <TextSmall>
                            From {formatDate(order.created_at)}
                          </TextSmall>
                        </div>
                        <ArrowSVG
                          width="30px"
                          height="30px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => toggleShowMore(order.id)}
                        >
                          <g clipPath="url(#clip0_429_11254)">
                            <path
                              d="M10 17L15 12"
                              stroke="#292929"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15 12L10 7"
                              stroke="#292929"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_429_11254">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </ArrowSVG>
                      </CardTop>
                      <Triangle />
                    </CardWrapper>
                    {showMore[order.id] ? ( // Proveravamo stanje za specifičnu narudžbinu
                      <OrderItemsContainer>
                        {order.order_items.map((item, itemIndex) => (
                          <OrderProduct
                            key={itemIndex}
                            product={item.product_details}
                          />
                        ))}
                      </OrderItemsContainer>
                    ) : (
                      <OrderItemsSlider items={order.order_items} />
                    )}
                  </CardContainer>
                ))}
              </CardContainerX>
            </>
          )}
        </>
      ) : (
        <PageLoading />
      )}
    </Container>
  );
};

export default Orders;
