import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AuthUserContext } from "../../../context/AuthUserContext";
import PageLoading from "../../../components/animations/PageLoading";
import { useNavigation } from "../../../utils/navigation";
import Error from "../../../components/toast/Error";
import { toast } from "react-toastify";

const Container = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h1`
  padding-top: 20px;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
  @media (min-width: 767px) {
    font-size: var(--font-size-xxlarge);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
const BorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderTop = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--background-color-hover);
  padding-bottom: 14px;
  @media (min-width: 767px) {
    border-bottom: 4px solid var(--text-color);
    width: 40px;
  }
`;
const WrapperX = styled.div`
  @media (min-width: 1024px) {
    max-width: 1400px;
    display: flex;
    gap: 40px;
  }
`;
const Wrapper = styled.div`
  padding: 20px;
`;
const RightDiv = styled.div`
  @media (min-width: 1024px) {
    margin-top: 20px;
    width: 300px;
  }
`;
const LeftDiv = styled.div`
  @media (min-width: 1024px) {
    max-width: 1000px;
    width: 1000px;
  }
`;
const PasswordContainer = styled.div`
  background-color: var(--background-color-hover);
  padding: 20px;
`;
const PasswordTitle = styled.h3`
  font-weight: 500;
  font-size: var(--font-size-large);
`;

const P = styled.p`
  font-size: var(--font-size-small);
  line-height: var(--font-size-large);
  font-weight: 500;
`;
const PTransparent = styled(P)`
  line-height: normal;
  font-weight: 300;
  margin-top: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 767px) {
    width: 90%;
  }
`;
const FormDiv = styled.div`
  width: 100%;
  min-width: 100%;
  @media (min-width: 767px) {
    display: flex;
    gap: 24px;
  }
`;
const FormPart = styled.div`
  width: 100%;
  flex: 1;
`;
const FormFlex = styled.div`
  display: flex;
  gap: 4px;
  width: calc(100% + 8px);
`;
const Input = styled.input`
  flex: 3;
  width: 100%;
  margin-bottom: 10px;
  padding: 14px 0 14px 8px;
  border: 1px solid #ccc;
  &::placeholder {
  }
  &:disabled {
    background-color: var(--background-color);
    cursor: not-allowed;
  }
`;
const SmallInput = styled(Input)`
  flex: 1;
  width: 100%;
`;

const Button = styled.button`
  width: calc(100% + 8px);
  @media (min-width: 767px) {
    width: 200px;
  }
`;

const Name = styled.h3`
  margin: 20px 0;
  font-weight: 700;
  font-size: var(--font-size-base);
`;

const RadioText = styled(Name)`
  margin: 0;
  padding: 0;
`;

const EditButton = styled.button`
  font-size: var(--font-size-base);
  border: none;
  border-radius: 0;
  color: var(--text-color);
  background-color: transparent;
  padding: 0;
  border-bottom: 1px solid var(--text-color);

  &:hover {
    background-color: transparent;
  }
`;

const HelpContainer = styled.div`
  width: 100%;
  background-color: var(--background-color-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
`;

const IconSVG = styled.svg`
  width: 60px;
  height: 60px;
  @media (min-width: 767px) {
    width: 80px;
    height: 80px;
    margin-left: 0;
  }
`;
const BorderBoxWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderBoxTop = styled.div`
  border-bottom: 6px solid var(--text-color);
  width: 40px;
`;
const showNotification = (text, smalltext) => {
  toast(<Error text={text} smalltext={smalltext} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast",
    progressClassName: "custom-progress-bar",
  });
};
const AddressBook = () => {
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [street, setStreet] = useState("");
  const [secondaryStreet, setSecondaryStreet] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [latitude, setLatitude] = useState(null); // Možda će biti potrebno unositi kao string, pa konvertovati u broj
  const [longitude, setLongitude] = useState(null); // Isto kao i za latitude

  const { addressId } = useParams();
  const [address, setAddress] = useState(null);
  const { setUserProfile, userProfile, fetchUserData, updateAddressBook } =
    useContext(AuthUserContext);
  const { goToAccount, goToSettings } = useNavigation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ovde dodajte logiku za validaciju i ažuriranje adrese
    const addressData = {
      country,
      city,
      postal_code: postalCode, // Osigurajte da ključevi odgovaraju onima u serializeru na backendu
      street,
      secondary_street: secondaryStreet || null, // Postavlja na null ako je prazan string
      building_number: buildingNumber || null,
      latitude: latitude ? parseFloat(latitude) : null, // Pretvorite u broj ako je potrebno
      longitude: longitude ? parseFloat(longitude) : null,
    };

    console.log("Podaci o adresi za ažuriranje:", addressData);

    try {
      // Pretpostavljamo da `addressId` i `updateAddress` funkcija postoje
      const updatedAddress = await updateAddressBook(addressId, addressData);

      navigate(goToSettings());
    } catch (error) {
      console.error("Greška prilikom ažuriranja adrese:", error); // Obradite grešku
      showNotification(
        "Došlo je do greške prilikom pokušaja ažuriranja adrese.",
        "Molimo pokušajte ponovo."
      );
    }
  };

  useEffect(() => {
    if (!userProfile) {
      fetchUserData();
      return;
    }
    // Pretpostavljamo da je `userProfile.addresses` niz adresa
    const foundAddress = userProfile.addresses.find(
      (addr) => addr.id.toString() === addressId
    );

    if (foundAddress) {
      setAddress(foundAddress);
      setCountry(foundAddress.country || "");
      setCity(foundAddress.city || "");
      setPostalCode(foundAddress.postal_code || "");
      setStreet(foundAddress.street || "");
      setSecondaryStreet(foundAddress.secondary_street || "");
      setBuildingNumber(foundAddress.building_number || "");
    } else {
      console.error("Adresa nije pronađena.");
      goToAccount();
    }
  }, [addressId, userProfile]);

  return (
    <Container>
      {userProfile ? (
        <>
          <Title>Edit Address Book</Title>
          <BorderWrapper>
            <BorderTop />
          </BorderWrapper>
          <WrapperX>
            <Wrapper>
              <LeftDiv>
                <PasswordContainer>
                  <PasswordTitle>Update Address Information</PasswordTitle>
                  <Form onSubmit={handleSubmit}>
                    <FormDiv>
                      <FormPart>
                        <P>
                          {userProfile.first_name} {userProfile.last_name}
                        </P>
                        <Input
                          type="text"
                          placeholder="Country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <FormFlex>
                          <SmallInput
                            type="text"
                            placeholder="Postal Code"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                          <Input
                            type="text"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </FormFlex>
                        <Input
                          type="text"
                          placeholder="Street"
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                        />
                      </FormPart>
                      <FormPart>
                        <P>Part 2</P>

                        <Input
                          type="text"
                          placeholder="Secondary Street (optional)"
                          value={secondaryStreet}
                          onChange={(e) => setSecondaryStreet(e.target.value)}
                        />
                        <Input
                          type="text"
                          placeholder="Building Number (optional)"
                          value={buildingNumber}
                          onChange={(e) => setBuildingNumber(e.target.value)}
                        />
                        <PTransparent>+3812321312412</PTransparent>
                      </FormPart>
                    </FormDiv>
                    <Button type="submit">Save</Button>
                  </Form>
                </PasswordContainer>
              </LeftDiv>
            </Wrapper>
            <RightDiv>
              <HelpContainer>
                <IconSVG viewBox="0 0 512 512">
                  <path
                    d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
                    fill="none"
                    stroke="var(--text-color)"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                  />
                  <path
                    d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
                    fill="none"
                    stroke="var(--text-color)"
                    strokeMiterlimit={10}
                    strokeWidth={28}
                  />
                  <circle cx="250" cy="348" r="20" />
                </IconSVG>
                <RadioText>Need Help</RadioText>
                <BorderBoxWrapper>
                  <BorderBoxTop />
                </BorderBoxWrapper>
                <EditButton>In our help center you can find answers</EditButton>
              </HelpContainer>
            </RightDiv>
          </WrapperX>
        </>
      ) : (
        <PageLoading />
      )}
    </Container>
  );
};

export default AddressBook;
