import { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import SimpleContext from "../../../context/SimpleContext";
import { AuthUserContext } from "../../../context/AuthUserContext";
import { useNavigation } from "../../../utils/navigation";
const fadeIn = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const Container = styled.div`
  position: relative;
`;
const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const Ul = styled.ul`
  padding: 0;
  border: 0;
  outline: none;
  list-style: none;
  position: absolute;
  right: 0%;

  width: 200px;
  box-shadow: var(--shadow-large);
  display: block;
  background-color: none;
  animation: ${fadeIn} 0.3s ease-out;
  z-index: 1;
`;
const Li = styled.li`
  font-weight: 500;
  padding: 14px;
  font-size: var(--font-size-small);
  background: var(--background-color);
  border-bottom: 1px solid var(--background-color-hover);
  cursor: pointer;
  color: var(--text-color);
  text-transform: uppercase;
  &:hover {
    color: var(--background-color);
    background-color: var(--primary-color);
  }
`;

const DropDownTop = () => {
  const [show, setShow] = useState(false);
  const { openLoginPopup, openRegisterPopup } = useContext(SimpleContext);
  const { authTokens } = useContext(AuthUserContext);
  const { goToAccount } = useNavigation();

  return (
    <Container>
      <UserContainer>
        <svg
          aria-hidden="true"
          focusable="false"
          role="presentation"
          viewBox="0 0 64 64"
          className="svg-icon"
          onClick={() => {
            setShow(!show);
            if (authTokens) {
              goToAccount();
            }
          }}
        >
          <path d="M35 39.84v-2.53c3.3-1.91 6-6.66 6-11.41 0-7.63 0-13.82-9-13.82s-9 6.19-9 13.82c0 4.75 2.7 9.51 6 11.41v2.53c-10.18.85-18 6-18 12.16h42c0-6.19-7.82-11.31-18-12.16z"></path>
        </svg>
        <span>User</span>
      </UserContainer>
      {show && !authTokens && (
        <Ul>
          <Li
            key={0}
            delay={0}
            onClick={() => {
              openLoginPopup();
              setShow(false);
            }}
          >
            Login
          </Li>
          <Li
            key={1}
            delay={1}
            onClick={() => {
              openRegisterPopup();
              setShow(false);
            }}
          >
            Register
          </Li>
        </Ul>
      )}
    </Container>
  );
};

export default DropDownTop;
