import { createContext, useState, useEffect, useContext, useRef } from "react";
import { AuthUserContext } from "./AuthUserContext";
import ChatService from "../services/ChatService";

const ChatContext = createContext();

export default ChatContext;

export const ChatProvider = ({ children }) => {
  const { authTokens } = useContext(AuthUserContext);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [roomExist, setRoomExist] = useState(false);

  const [messages, setMessages] = useState([]);

  const loadChats = async () => {
    // if (chatHistory.length) {
    //   return;
    // }
    try {
      const response = await ChatService.GetAllChats(authTokens.access);
      setChatHistory(response);
    } catch (error) {
      console.error(error);
    }
  };

  let values = {
    chatHistory,
    setChatHistory,
    loadChats,
    messages,
    setMessages,
    selectedChat,
    setSelectedChat,

    roomExist,
    setRoomExist,
  };

  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
};
