import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import ChatListHistory from "./ChatListHistory";
import ChatHistory from "./ChatHistory";
import NewChat from "./NewChat";
import ChatContext from "../context/ChatContext";
import { AuthUserContext } from "../context/AuthUserContext";
import SimpleContext from "../context/SimpleContext";
import useChatWebSocket from "../services/useWebSocket";
const ChatContainer = styled.div`
  position: fixed;

  height: 100vh;
  max-height: 100%;

  overflow: hidden;
  z-index: 99999;
  width: 100vw;

  @media (min-width: 768px) {
    //border: 1px solid var(--primary-color);
    border-radius: 14px;
    max-height: 90vh;
    width: 460px;
    position: absolute;
    right: 40px;
    bottom: 5vh;
    box-shadow: var(--shadow-large);
  }
  @media (min-width: 1400px) {
    right: calc((100vw - 1400px) / 2 - 40px);
  }
`;
const ChatHeader = styled.div`
  width: 100%;
  height: 60px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--primary-color);
  color: var(--background-color);
  font-size: var(--font-size-base);
  font-weight: 600;
  transition: height 0.3s ease;
`;

const FriendsList = styled.div`
  //position: relative;
  height: 100%;
  //min-height: calc(100% - 120px);
  //max-height: calc(100% - 120px);
  background-color: var(--background-color);
  overflow-y: scroll;
  transition: min-height 0.3s ease, max-height 0.1s ease;
`;

const PaddingBox = styled.div`
  height: 140px;
`;
const MessageContainer = styled.div`
  position: relative;
`;
const BottomButton = styled.button`
  position: absolute;
  bottom: 40px;
  left: 30%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  width: 40%;
`;

const ShadowDiv = styled.div`
  z-index: 2;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    var(--background-color),
    rgba(255, 255, 255, 0)
  );
  height: 90px;
`;
const ChatFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  color: var(--background-color);
  font-size: var(--font-size-base);
  font-weight: 600;
  z-index: 9999;
  height: 80px;
`;
const SVGContainer = styled.div`
  font-weight: 600;
  font-size: var(--font-size-small);
  color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    color: var(--background-color-hover) !important;
  }
`;
const SVGBottom = styled.svg`
  &:hover {
    path {
      stroke: var(--background-color-hover) !important;
    }
    cursor: pointer;
  }
`;
const FooterWrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const EndedConvoMsg = styled.div`
  color: var(--text-color);
  font-weight: 300;
  font-size: var(--font-size-base);
  border-top: 1px solid var(--background-color-hover);
  z-index: 2;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
  background: var(--background-color);
  height: 110px;
  padding-top: 10px;
`;
const SelectedChatHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 80%;
  height: 100%;
`;
const HomeHeader = styled.div`
  width: 90%;

  height: 90%;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 80%;
  }
`;
const HomeTop = styled.div`
  flex: 1;
`;
const HomeBottom = styled.div`
  flex: 2;
`;
const HomeTitle = styled.div`
  font-size: var(--font-size-xxlarge);
`;
const HomeButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  background-color: var(--background-color);
  color: var(--text-color);
  width: 100%;
  border: none;
  text-align: left;
  font-size: var(--font-size-base);
  &:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
  }
`;

const AvaratOverlap = styled.div`
  display: flex;
  gap: -4px;
`;
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  background-color: var(--info-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const ActiveStatus = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--error-color);
`;
const FriendInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const AdminName = styled.div`
  font-size: var(--font-size-base);
  font-weight: 600;
`;
const FriendName = styled(AdminName)`
  font-weight: 300;
`;
const NewMessageHeader = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
const LogoText = styled.h3`
  font-weight: 600;
  color: var(--background-color);
`;
const BigAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
`;
const FlexDiv = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
`;
const UnreadMessageCount = styled.div`
  position: absolute;
  top: 30px;
  right: 60px;
  padding: 8px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--error-color);
  color: #fff;
  font-size: var(--font-size-small);
  font-weight: 700;
  border-radius: 50%;
`;
const ChatLobby = ({ ws }) => {
  const {
    chatHistory,
    loadChats,
    messages,
    selectedChat,
    setSelectedChat,
    setChatHistory,
  } = useContext(ChatContext);
  const { closeChatPopup } = useContext(SimpleContext);
  const { userProfile } = useContext(AuthUserContext);
  const [showHome, setShowHome] = useState(true);
  const [sendNewMessage, setSendNewMessage] = useState(false);
  const [lastActiveChat, setLastActiveChat] = useState(null);

  const friendsListRef = useRef(null);

  const [headerHeight, setHeaderHeight] = useState("100%");
  const [middleHeight, setMiddleHeight] = useState("calc(100% - 120px)");

  useEffect(() => {
    if (!chatHistory) {
      return;
    }
    const activeChats = chatHistory.filter((chat) => chat.is_active);
    if (activeChats.length > 0) {
      const lastChat = activeChats[0]; // Get the last active chat
      setLastActiveChat(lastChat);
    } else {
      // Reset the state if there are no active chats
      setLastActiveChat(null);
    }
  }, [chatHistory]);

  const handleSendNewMessage = () => {
    setHeaderHeight("120px");
    setSendNewMessage(true);
    setShowHome(false);
  };
  const handleShowMessages = () => {
    // Toggle the width between 60px and 240px
    setHeaderHeight("100%");
    setShowHome(true);
    setSendNewMessage(false);
  };
  const handleShowHome = () => {
    setHeaderHeight("60px");
    setSelectedChat(null);
    setShowHome(false);
    setSendNewMessage(false);
  };
  useEffect(() => {
    if (selectedChat) {
      setMiddleHeight("calc(100% - 60px)");
    } else {
      setMiddleHeight("calc(100% - 140px)");
    }
  }, [selectedChat, showHome]);

  const scrollToBottom = () => {
    if (friendsListRef.current) {
      friendsListRef.current.scrollTo({
        top: friendsListRef.current.scrollHeight,
        behavior: "smooth", // Dodaje animaciju skrolovanja
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const deselectChat = () => {
    const updatedChats = chatHistory.map((chat) => {
      if (chat.id === selectedChat.id) {
        // Pronađen je čet koji treba ažurirati
        return {
          ...chat,
          messages: [...messages], // Ažurirajte poruke za ovaj čet sa novim nizom poruka
        };
      }
      return chat; // Za sve ostale četove, vratite ih kakvi jesu
    });

    setChatHistory(updatedChats);

    setSelectedChat(null);
  };
  const handleChatMsgClick = (chat) => {
    setHeaderHeight("60px");
    setSelectedChat(chat);
    setShowHome(false);
    setSendNewMessage(false);
  };

  return (
    <ChatContainer>
      <ChatHeader style={{ height: `${headerHeight}` }}>
        {!sendNewMessage ? (
          <>
            {showHome ? (
              <HomeHeader>
                <HomeTop>
                  <AvaratOverlap>
                    <Avatar>S</Avatar>
                    <Avatar style={{ marginLeft: "-12px" }}>S</Avatar>
                    <Avatar style={{ marginLeft: "-12px" }}>S</Avatar>
                  </AvaratOverlap>
                  <svg
                    focusable="false"
                    role="presentation"
                    viewBox="0 0 64 64"
                    className="svg-icon"
                    style={{
                      width: "var(--font-size-xxlarge)",
                      height: "var(--font-size-xxlarge)",
                      position: "absolute",
                      right: "40px",
                      top: "calc( 20px )",
                    }}
                    onClick={() => {
                      closeChatPopup();
                      setSelectedChat(null);
                    }}
                  >
                    <path
                      d="M19 17.61l27.12 27.13m0-27.12L19 44.74"
                      stroke="var(--background-color)"
                      strokeWidth={4}
                    ></path>
                  </svg>
                </HomeTop>
                <HomeBottom>
                  <HomeTitle>Hi 👋</HomeTitle>
                  <HomeTitle>How can we help?</HomeTitle>
                  {lastActiveChat ? (
                    <HomeButton
                      onClick={() => handleChatMsgClick(lastActiveChat)}
                    >
                      <div>
                        <div style={{ color: "var(--text-color)" }}>
                          Recent Message
                        </div>
                        <FlexDiv>
                          <Avatar>S</Avatar>
                          <div>
                            <div>
                              {lastActiveChat.messages.length > 0 &&
                              lastActiveChat.messages[
                                lastActiveChat.messages.length - 1
                              ].message.length > 25
                                ? `${lastActiveChat.messages[
                                    lastActiveChat.messages.length - 1
                                  ].message.slice(0, 25)}...`
                                : lastActiveChat.messages[
                                    lastActiveChat.messages.length - 1
                                  ].message}
                            </div>
                            <div
                              style={{
                                fontWeight: "300",
                                color: "var(--text-color)",
                              }}
                            >
                              Spasko
                            </div>
                          </div>
                        </FlexDiv>
                      </div>
                      {lastActiveChat.messages.filter(
                        (msg) => !msg.seen && msg.user !== userProfile.id
                      ).length > 0 && (
                        <UnreadMessageCount>
                          {
                            lastActiveChat.messages.filter(
                              (msg) => !msg.seen && msg.user !== userProfile.id
                            ).length
                          }
                        </UnreadMessageCount>
                      )}
                    </HomeButton>
                  ) : (
                    <HomeButton onClick={handleSendNewMessage}>
                      <div>
                        Send us A messagae
                        <div style={{ fontWeight: "300" }}>
                          We typically reply in under a minute
                        </div>
                      </div>
                      <svg
                        fill="var(--text-color)"
                        height="20px"
                        width="20px"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512.003 512.003"
                      >
                        <g>
                          <g>
                            <path
                              d="M507.284,248.364L12.35,0.898C8.894-0.826,4.721-0.007,2.153,2.86c-2.56,2.884-2.867,7.125-0.759,10.351l159.07,242.79
			L1.393,498.792c-2.108,3.226-1.801,7.467,0.759,10.351c1.664,1.86,4.002,2.859,6.383,2.859c1.289,0,2.594-0.29,3.814-0.896
			l494.933-247.467c2.893-1.451,4.719-4.403,4.719-7.637S510.176,249.815,507.284,248.364z"
                            />
                          </g>
                        </g>
                      </svg>
                    </HomeButton>
                  )}
                </HomeBottom>
              </HomeHeader>
            ) : (
              <>
                {selectedChat ? (
                  <SelectedChatHeader>
                    <svg
                      fill="var(--background-color)"
                      width="24px"
                      height="24px"
                      viewBox="0 0 96 96"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => deselectChat()}
                      style={{ marginRight: "12px" }}
                    >
                      <title />
                      <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z" />
                    </svg>
                    <Avatar>
                      S <ActiveStatus />
                    </Avatar>
                    <FriendInfo>
                      <AdminName>Spasko</AdminName>
                      <FriendName>Active</FriendName>
                    </FriendInfo>
                  </SelectedChatHeader>
                ) : (
                  <>
                    <span> Messages </span>
                    <svg
                      focusable="false"
                      role="presentation"
                      viewBox="0 0 64 64"
                      className="svg-icon"
                      style={{
                        width: "var(--font-size-xxlarge)",
                        height: "var(--font-size-xxlarge)",
                        position: "absolute",
                        right: "40px",
                        top: "calc( 15px )",
                      }}
                      onClick={() => {
                        closeChatPopup();
                        setSelectedChat(null);
                      }}
                    >
                      <path
                        d="M19 17.61l27.12 27.13m0-27.12L19 44.74"
                        stroke="var(--background-color)"
                        strokeWidth={4}
                      ></path>
                    </svg>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <NewMessageHeader>
            {" "}
            <svg
              fill="var(--background-color)"
              width="24px"
              height="24px"
              viewBox="0 0 96 96"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setSendNewMessage(false);
                handleShowHome(true);
              }}
              style={{ position: "absolute", left: "30px", top: "40px" }}
            >
              <title />
              <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z" />
            </svg>
            <LogoText>SNUSPLUS</LogoText>
            <AvaratOverlap>
              <BigAvatar>S</BigAvatar>
              <BigAvatar style={{ marginLeft: "-12px" }}>S</BigAvatar>
              <BigAvatar style={{ marginLeft: "-12px" }}>S</BigAvatar>
            </AvaratOverlap>
            <div> We typically reply in under a minute</div>
          </NewMessageHeader>
        )}
      </ChatHeader>
      <FriendsList
        style={{ minHeight: `${middleHeight}`, maxHeight: `${middleHeight}` }}
        ref={friendsListRef}
      >
        {!selectedChat ? (
          <>
            {!sendNewMessage ? (
              <ChatListHistory />
            ) : (
              <>
                <ChatHistory
                  messages={messages}
                  scrollToBottom={scrollToBottom}
                />
              </>
            )}
            <PaddingBox />
          </>
        ) : (
          <span>
            {selectedChat && selectedChat.is_active ? (
              <ChatHistory
                messages={messages}
                scrollToBottom={scrollToBottom}
              />
            ) : (
              <ChatHistory
                messages={
                  chatHistory.find((chat) => chat.id === selectedChat.id)
                    .messages
                }
                scrollToBottom={scrollToBottom}
              />
            )}
            <PaddingBox />
          </span>
        )}
      </FriendsList>

      {!sendNewMessage && !selectedChat && (
        <MessageContainer>
          <BottomButton
            onClick={
              lastActiveChat
                ? () => handleChatMsgClick(lastActiveChat)
                : handleSendNewMessage
            }
          >
            Send us A Message{" "}
          </BottomButton>
          <ShadowDiv />
        </MessageContainer>
      )}

      {!sendNewMessage &&
        selectedChat &&
        (selectedChat.is_active ? (
          <NewChat scrollToBottom={scrollToBottom} ws={ws} />
        ) : (
          <MessageContainer>
            <BottomButton
              onClick={
                lastActiveChat
                  ? () => handleChatMsgClick(lastActiveChat)
                  : handleSendNewMessage
              }
            >
              Send us A Message{" "}
            </BottomButton>
            <EndedConvoMsg>Your Conversation has ended</EndedConvoMsg>
          </MessageContainer>
        ))}

      {!selectedChat && !sendNewMessage && (
        <ChatFooter>
          <FooterWrapper>
            <SVGContainer onClick={handleShowMessages}>
              <SVGBottom
                width="34px"
                height="34px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 21V15M19 15L17 17M19 15L21 17M21 11V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.0799 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.0799 19 6.2 19H13M20.6067 8.26229L15.5499 11.6335C14.2669 12.4888 13.6254 12.9165 12.932 13.0827C12.3192 13.2295 11.6804 13.2295 11.0677 13.0827C10.3743 12.9165 9.73279 12.4888 8.44975 11.6335L3.14746 8.09863"
                  stroke="var(--background-color)"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </SVGBottom>
              Home
            </SVGContainer>

            <SVGContainer onClick={handleShowHome}>
              <SVGBottom
                width="36px"
                height="36px"
                viewBox="0 0 192 192"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
              >
                <path
                  fill="var(--background-color)"
                  d="M18.5 46v-6a6 6 0 0 0-4.243 10.243L18.5 46ZM42 52h104V40H42v12Zm118 14v60h12V66h-12Zm-14 74H62v12h84v-12ZM42 40H18.5v12H42V40Zm6 86V76.127H36V126h12ZM14.257 50.243l18.814 18.813 8.485-8.485-18.813-18.814-8.486 8.486ZM48 76.127a22 22 0 0 0-6.444-15.556l-8.485 8.485A10 10 0 0 1 36 76.127h12ZM62 140c-7.732 0-14-6.268-14-14H36c0 14.359 11.64 26 26 26v-12Zm98-14c0 7.732-6.268 14-14 14v12c14.359 0 26-11.641 26-26h-12Zm-14-74c7.732 0 14 6.268 14 14h12c0-14.36-11.641-26-26-26v12Z"
                />
                <path
                  stroke="var(--background-color)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="10"
                  d="M66 84h76m-76 24h44"
                />
              </SVGBottom>
              Mesages
            </SVGContainer>
          </FooterWrapper>
        </ChatFooter>
      )}
      {sendNewMessage && <NewChat scrollToBottom={scrollToBottom} ws={ws} />}
    </ChatContainer>
  );
};

export default ChatLobby;
