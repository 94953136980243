import styled from "styled-components";
import backgroundDesktopImage from "../../assets/images/home_desktop/home_desktop_2x.webp";
import bannerMobileImage from "../../assets/images/home-mobile/home-mobile_2x.webp";

const Container = styled.main``;
const SupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MaxWidthWrapper = styled.div`
  margin: var(--spacing-md) 0;
  width: calc(100% - var(--spacing-xl) * 2);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
    width: 800px;
  }
`;
const Text = styled.p`
  color: var(--p-color);
`;
const Title = styled.h3`
  font-size: var(--font-size-xxlarge);
  margin: 0;
  margin-top: var(--spacing-lg);
  font-weight: 400;
`;

//HERO
const HeroContainer = styled.div`
  font-family: "Larken-Light";
  overflow: hidden;
  background-image: url(${bannerMobileImage});
  background-size: cover;
  background-position: center;
  text-align: center;
  @media (min-width: 768px) {
    background-image: url(${backgroundDesktopImage});
    text-align: left;
  }
  height: 50vh;
`;

const InnerHeroContainer = styled.div`
  z-index: 9;
  @media (min-width: 768px) and (max-width: 1040px) {
  }
  @media (min-width: 768px) {
    width: var(--max-width-container);
    width: 800px;
    height: auto;
  }
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 12px;
  height: 90%;
`;

const HeroTitle = styled.h1`
  color: var(--text-color);
  //font-family: Larken;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  max-width: 800px;
`;

const HeroText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  //line-height: 28px;
  text-align: center;
`;
const HeroDate = styled(HeroText)`
  font-family: "Larken";
  font-weight: 700;
  text-transform: uppercase;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <HeroContainer className="hero-image">
        <InnerHeroContainer>
          <TextContainer>
            <HeroDate>Current as of 20 Jan 2022</HeroDate>
            <HeroTitle>Privacy Policy</HeroTitle>
            <HeroText>
              Your privacy is important to us at Untitled. We respect your
              privacy regarding any information we may collect from you across
              our website.
            </HeroText>
          </TextContainer>
        </InnerHeroContainer>
      </HeroContainer>
      <SupportContainer>
        <MaxWidthWrapper>
          <Text>
            Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
            suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
            quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
            posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
            feugiat sapien varius id. Eget quis mi enim, leo lacinia pharetra,
            semper. Eget in volutpat mollis at volutpat lectus velit, sed
            auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant
            diam at. Suscipit tristique risus, at donec. In turpis vel et quam
            imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.{" "}
          </Text>
          <Title>What information do we collect?</Title>
          <Text>
            Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla
            odio nisl vitae. In aliquet pellentesque aenean hac vestibulum
            turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada
            fringilla. Elit nisi in eleifend sed nisi.
          </Text>
          <Text>
            {" "}
            Pulvinar at orci, proin imperdiet commodo consectetur convallis
            risus. Sed condimentum enim dignissim adipiscing faucibus consequat,
            urna. Viverra purus et erat auctor aliquam. Risus, volutpat
            vulputate posuere purus sit congue convallis aliquet. Arcu id augue
            ut feugiat donec porttitor neque. Mauris, neque ultricies eu
            vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in
            tellus, pharetra, porttitor.{" "}
          </Text>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
          <Title>How do we use your information?</Title>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
          <Title>Do we use cookies and other tracking technologies?</Title>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
          <Title>How long do we keep your information?</Title>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
          <Title>How do we keep your information safe?</Title>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
          <Title>What are your privacy rights?</Title>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
          <Title>How can you contact us about this policy?</Title>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
          <Text>
            Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
            id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
            molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
            velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
            nibh orci.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>
    </Container>
  );
};

export default PrivacyPolicy;
