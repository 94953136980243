import { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import SimpleContext from "../../context/SimpleContext";
import ButtonLoading from "../animations/ButtonLoading";
import { AuthUserContext } from "../../context/AuthUserContext";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  background-color: var(--transparent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  animation: ${fadeIn} 0.5s forwards;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 94%;
  max-width: 500px;
  background-color: #1a2c38;
  background-color: var(--background-color-hover);
  border-radius: 10px;
  position: relative;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  padding: 24px 0;
  max-height: 90vh;
  opacity: 0;
  animation: ${fadeIn} 1s 0.5s forwards;
  @media (min-width: 768px) {
    min-width: 600px;
    max-width: 600px;
  }
`;
const ExitContainer = styled.div`
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 10%;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //gap: 8px;
  margin: 10px 0;
  width: 100%;
`;
const FormWrapper = styled.div`
  min-width: 90%;
  max-width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Input = styled.input`
  outline: none;
  padding: var(--spacing-sm) 0 var(--spacing-sm) var(--spacing-xs);
  box-shadow: var(--shadow-large);
  width: calc(100% - 8px);
  font-size: var(--font-size-small);
  font-weight: 700;
  color: var(--text-color);
  border: none;
  background-color: var(--background-color);
  margin: var(--spacing-xxs) 0;
`;
const Label = styled.label`
  font-size: var(--font-size-small);
  text-transform: uppercase;
  color: var(--text-color);
  text-align: left;
  min-width: 100%;
`;
const Title = styled.h1`
  padding: 20px 0;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
  @media (min-width: 767px) {
    font-size: var(--font-size-xxlarge);
    font-weight: 500;
    text-transform: uppercase;
  }
`;
const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
const Description = styled.div`
  width: 90%;
  font-size: var(--font-size-small);
`;
const SubTitle = styled.h3`
  width: 90%;
  text-align: left;
  padding: 20px 0;
  font-size: var(--font-size-base);
  font-weight: 700;
  border-top: 1px solid var(--text-color);
  @media (min-width: 767px) {
    font-size: var(--font-size-xlarge);
    font-weight: 500;
    text-transform: uppercase;
  }
`;
const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 90%;
  text-align: center;
  margin: var(--spacing-md) 0;
`;
const Button = styled.button`
  width: 100%;
  padding: var(--spacing-md) 0;

  text-transform: uppercase;
`;

const EditAddressBook = ({ editAddress }) => {
  const { setShowEditAddressBook, showEditAddressBook } =
    useContext(SimpleContext);
  const { userProfile, updateAddressBook } = useContext(AuthUserContext);
  const [phoneNumber, setPhoneNumber] = useState(userProfile.phone_number);
  const [country, setCountry] = useState(editAddress.country);
  const [city, setCity] = useState(editAddress.city);
  const [postalCode, setPostalCode] = useState(editAddress.postal_code);
  const [street, setStreet] = useState(editAddress.street);
  const [secondaryStreet, setSecondaryStreet] = useState(
    editAddress.secondary_street
  );
  const [buildingNumber, setBuildingNumber] = useState(
    editAddress.building_number
  );
  const [latitude, setLatitude] = useState(null); // Možda će biti potrebno unositi kao string, pa konvertovati u broj
  const [longitude, setLongitude] = useState(null); // Isto kao i za latitude

  const [isAnimating, setIsAnimating] = useState(false);
  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAnimating(true);
    const addressData = {
      phone_number: phoneNumber,
      country,
      city,
      postal_code: postalCode, // Osigurajte da ključevi odgovaraju onima u serializeru na backendu
      street,
      secondary_street: secondaryStreet || null, // Postavlja na null ako je prazan string
      building_number: buildingNumber || null,
      latitude: latitude ? parseFloat(latitude) : null, // Pretvorite u broj ako je potrebno
      longitude: longitude ? parseFloat(longitude) : null,
    };
    console.log("Podaci o adresi za ažuriranje:", editAddress.id, addressData);

    try {
      // Pretpostavljamo da `addressId` i `updateAddress` funkcija postoje
      const updatedAddress = await updateAddressBook(
        editAddress.id,
        addressData
      );
      setShowEditAddressBook(false);
    } catch (error) {
      console.error("Greška prilikom ažuriranja adrese:", error); // Obradite grešku
      // showNotification(
      //   "Došlo je do greške prilikom pokušaja ažuriranja adrese.",
      //   "Molimo pokušajte ponovo."
      // );
    }
  };
  return (
    <Container>
      <Wrapper>
        <ExitContainer
          onClick={() => {
            setShowEditAddressBook(false);
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            viewBox="0 0 64 64"
            className="svg-icon"
            style={{
              width: "calc(var(--font-size-xxlarge) + 5px)",
              height: "calc(var(--font-size-xxlarge) + 5px)",
            }}
          >
            <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
          </svg>
        </ExitContainer>
        <Title>Edit Address</Title>
        <DescriptionContainer>
          <Description>
            This address is selected as your default <strong> billing </strong>
            and delivery address. We will use this address at checkout as the
            default options for <strong> billing </strong> and delivery.
          </Description>
        </DescriptionContainer>
        <Form onSubmit={handleSubmit}>
          <FormWrapper>
            <Label htmlFor="Name">Name:</Label>
            <Input type="text" value={"Uros"} disabled />
          </FormWrapper>
          <FormWrapper>
            <Label htmlFor="Surname">Surname:</Label>
            <Input type="text" value={"Spasenic"} disabled />
          </FormWrapper>
          <FormWrapper style={{ marginBottom: "24px" }}>
            <Label htmlFor="number">Mobile Number:</Label>
            <Input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormWrapper>
          <SubTitle>Address</SubTitle>
          <FormWrapper>
            <Label htmlFor="Country">Country:</Label>
            <Input
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <Label htmlFor="Postal Code">Postal Code:</Label>
            <Input
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <Label htmlFor="City">City:</Label>
            <Input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <Label htmlFor="Street">Street:</Label>
            <Input
              type="text"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <Label htmlFor="Secondary Street">
              Secondary Street (optional):
            </Label>
            <Input
              type="text"
              value={secondaryStreet}
              onChange={(e) => setSecondaryStreet(e.target.value)}
            />
          </FormWrapper>
          <FormWrapper>
            <Label htmlFor="Building Number">Building Number (optional):</Label>
            <Input
              type="text"
              value={buildingNumber}
              onChange={(e) => setBuildingNumber(e.target.value)}
            />
          </FormWrapper>
          <ButtonWrapper>
            <Button type="submit">Edit Address</Button>
            {isAnimating && (
              <ButtonLoading
                isAnimating={isAnimating}
                onComplete={handleAnimationComplete}
              />
            )}
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default EditAddressBook;
