import React, { useContext, useEffect, useRef, useState } from "react";

import styled from "styled-components";
import Picker from "emoji-picker-react";
import ChatContext from "../context/ChatContext";
import { AuthUserContext } from "../context/AuthUserContext";
import ChatService from "../services/ChatService";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  //background: var(--background-color);
  font-size: var(--font-size-base);
  font-weight: 600;
  z-index: 9999;
  max-height: 120px;
`;
const MessageInputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--background-color);
  border-top: 2px solid var(--background-color-hover);
  max-height: 120px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
`;
const MessageInputWrapper = styled.div`
  width: 94%;
  padding: var(--spacing-sm) 0 var(--spacing-sm) 0;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
const LinkButton = styled.div`
  cursor: pointer;
`;
const FileInput = styled.input`
  cursor: pointer;
`;
const MessageInput = styled.textarea`
  resize: none;
  outline: none;
  overflow: none;

  width: calc(100% - 8px);
  font-size: var(--font-size-base);
  color: var(--text-color);
  border: none;
  margin: 0;
  max-height: 100%;
  box-sizing: border-box;
  background-color: var(--background-color);
`;
const EmojiPickerWrapper = styled.div`
  position: absolute;
  z-index: 999;
  top: -300px;
  left: 0;
  width: 100%;

  z-index: 1;
  box-sizing: border-box;
`;
const NewChat = ({ scrollToBottom, ws }) => {
  const [text, setText] = useState("");
  const inputRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { setMessages, selectedChat } = useContext(ChatContext);
  const { authTokens, userProfile } = useContext(AuthUserContext);
  const fileInputRef = useRef(null);
  const readMessages = () => {
    if (selectedChat) {
      console.log("Read all messages for the chat room.");

      ws.current.send(
        JSON.stringify({ type: "mark_as_read", room_id: selectedChat.id })
      );
    }
  };
  useEffect(() => {
    const fetchMessages = () => {
      if (selectedChat) {
        console.log("Fetching all messages for the chat room.");
        setMessages([]);
        ws.current.send(
          JSON.stringify({ type: "fetch_messages", room_id: selectedChat.id })
        );
      }
    };
    fetchMessages();
  }, []);

  const sendMessage = () => {
    if (text.trim() !== "") {
      if (selectedChat && selectedChat.id) {
        // Ako postoji selectedChat.id, šaljemo poruku
        const message = {
          room_id: selectedChat.id,
          message: text,
          sender: "user",
        };
        ws.current.send(JSON.stringify(message));
      } else {
        // Ako selectedChat.id ne postoji, šaljemo zahtev za kreiranje nove sobe
        const createRoomRequest = {
          type: "create_chat",
          message: text, // Ova poruka može biti početna poruka za chat
          sender: "user",
        };
        ws.current.send(JSON.stringify(createRoomRequest));
      }
      setText("");
    }
  };

  const handleChange = (event) => {
    setText(event.target.value);

    // Resetujte visinu na minimalnu pre postavljanja nove visine
    inputRef.current.style.height = "auto";
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage(text);
    }
  };
  const onEmojiClick = (event, emojiObject) => {
    setText((prevInput) => prevInput + emojiObject.emoji);
    setShowEmojiPicker(false); // Opcionalno zatvorite picker nakon izbora
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      const file = files[0];

      try {
        const imageUrl = await ChatService.UploadFileToS3(
          file,
          authTokens.access
        );

        if (selectedChat && selectedChat.id) {
          const message = {
            room_id: selectedChat.id,
            message: "",
            image_url: imageUrl,
            sender: "user",
          };
          ws.current.send(JSON.stringify(message));
        } else {
          const createRoomRequest = {
            type: "create_chat",
            message: "",
            image_url: imageUrl,
            sender: "user",
          };
          ws.current.send(JSON.stringify(createRoomRequest));
        }
        //scrollToBottom();
      } catch (error) {
        console.error("Greška prilikom upload-a:", error);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Postavljanje fokusa na input polje
      inputRef.current.select(); // Selektovanje teksta unutar input polja
    }
  }, []);

  return (
    <Container>
      <MessageInputContainer>
        <MessageInputWrapper>
          {showEmojiPicker && (
            <EmojiPickerWrapper>
              <Picker
                onEmojiClick={onEmojiClick}
                pickerStyle={{ width: "100%", height: 300 }}
              />
            </EmojiPickerWrapper>
          )}

          <MessageInput
            ref={inputRef}
            onChange={handleChange}
            value={text}
            onKeyPress={handleKeyPress}
            placeholder="Message"
            onFocus={readMessages}
          />

          <LinkButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="6.725"
                stroke="#757575"
                strokeWidth="1.3"
              ></circle>
              <path
                fill="#757575"
                fillRule="evenodd"
                d="M5.818 7.534a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2m4.364 0a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2"
                clipRule="evenodd"
              ></path>
              <path
                stroke="#757575"
                strokeLinecap="round"
                strokeWidth="1.3"
                d="M10 10c-.44.604-1.172 1-2 1s-1.56-.396-2-1"
              ></path>
            </svg>
          </LinkButton>
          {!text ? (
            <LinkButton>
              <label>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }} // Skrivanje input elementa
                  onChange={handleFileChange}
                  accept="image/*" // Dozvoljava samo izbor slika
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <path
                    stroke="#757575"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.3"
                    d="m5.27 9.512 5.882-5.882a1.685 1.685 0 0 1 2.383 2.384l-5.928 5.929A3.314 3.314 0 0 1 2.92 7.257l4.149-4.15"
                  ></path>
                </svg>
              </label>
            </LinkButton>
          ) : (
            <LinkButton onClick={() => sendMessage()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
              >
                <path
                  fill="var(--primary-color)"
                  fillRule="evenodd"
                  d="m4.394 14.7 9.356-5.4c1-.577 1-2.02 0-2.598L4.394 1.299a1.5 1.5 0 0 0-2.25 1.3v3.438l4.059 1.088c.494.132.494.833 0 .966l-4.06 1.087v4.224a1.5 1.5 0 0 0 2.25 1.299"
                  clipRule="evenodd"
                ></path>
              </svg>
            </LinkButton>
          )}
        </MessageInputWrapper>
      </MessageInputContainer>
    </Container>
  );
};

export default NewChat;
