import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ProductContext } from "../../../context/ProductContext";
import { freeShippingThreshold } from "../../../utils/global_const";

const Container = styled.div`
  display: ${(props) => (props.percentage == 0 ? "none" : "flex")};
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: var(--background-color-hover);
  border-radius: var(--border-radius-small);
  box-shadow: 0 2px 4px rgba(251, 225, 225, 0.1);
  position: fixed;
  position: ${(props) => props.position};

  width: 100%;

  height: var(--navbar-mini);
  z-index: var(--zindex-fixed);
  font-size: var(--font-size-small);
  color: var(--primary-color);

  top: ${(props) => (props.$isScrolled ? "var(--navbar-height)" : "initial")};
  @media (min-width: 768px) {
    top: ${(props) =>
      props.$isScrolled ? "var(--navbar-height-desktop)" : "initial"};
  }
`;

const BarWrapper = styled.div`
  width: 90%;
  max-width: var(--max-width-container);

  position: relative;
  background-color: var(--background-color);
  height: 8px;
  border-radius: var(--border-radius-large);
  margin-top: var(--spacing-md);
`;
const BarScore = styled.div`
  position: absolute; // Make BarScore absolute to fill the Container from start
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: var(--primary-color);
  width: ${(props) => props.percentage || 0}%; // Use the percentage prop
  border-radius: var(--border-radius-large);
  transition: width 0.3s;
`;

const ShippingInfo = styled.div`
  position: absolute; // Make BarScore absolute to fill the Container from start
  top: -14px;
  left: ${(props) => props.percentage || 0}%;
  /* /transform: rotate(-4deg) translateX(-30%); */
  z-index: 1; // Make sure this text appears above the BarScore
  font-weight: bold;
  @media (min-width: 768px) {
    left: ${(props) =>
      props.percentage > 15
        ? props.percentage + 10
        : props.percentage || 0}%; // Use the percentage prop
  }
  opacity: ${(props) => (!props.free ? "1" : "0.4")};
  transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

const BarValue = styled.div`
  z-index: 1;
  position: absolute;
  right: 0px;
  top: -14px;
  font-weight: bold;
  opacity: ${(props) => (props.free ? "1" : "0.4")};
`;

const CartBar = ({ isScrolled, position }) => {
  const cartItems = useSelector((state) => state.cart.itemsList);
  const { setActivateConffete } = useContext(ProductContext);

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );

  // Izračunavanje procenta napretka ka besplatnoj dostavi
  let percentage = (totalPrice / freeShippingThreshold) * 100;
  percentage = percentage > 100 ? 100 : percentage;

  useEffect(() => {
    const isConffeteActivated = localStorage.getItem("isConffeteActivated");

    if (percentage === 100 && isConffeteActivated !== "true") {
      setTimeout(() => {
        setActivateConffete(true);
      }, 300); // 300ms = 0.3 sekunde
      localStorage.setItem("isConffeteActivated", "true");
    }
    if (percentage < 100 && isConffeteActivated === "true") {
      localStorage.setItem("isConffeteActivated", "false");
    }
  }, [percentage, setActivateConffete]);

  return (
    <Container
      $isScrolled={isScrolled}
      position={position}
      percentage={percentage}
    >
      {/* Pass the percentage prop to BarScore */}

      <BarWrapper>
        <BarScore percentage={percentage} />
        <ShippingInfo
          free={totalPrice >= freeShippingThreshold}
          percentage={
            percentage < 15
              ? percentage
              : percentage > 85
              ? 65
              : percentage - 15
          }
        >
          Shipping: €12
        </ShippingInfo>
        <BarValue free={totalPrice >= freeShippingThreshold}>FREE</BarValue>
      </BarWrapper>
      <div>
        {totalPrice >= freeShippingThreshold ? (
          <span>Free Shipping Unlocked 🎉</span>
        ) : (
          <span>
            Shop for{" "}
            <strong>€{(freeShippingThreshold - totalPrice).toFixed(2)}</strong>{" "}
            more to reach next shipping level
          </span>
        )}
      </div>
    </Container>
  );
};

export default CartBar;
