import styled from "styled-components";
import backgroundDesktopImage from "../../assets/images/home_desktop/home_desktop_2x.webp";
import bannerMobileImage from "../../assets/images/home-mobile/home-mobile_2x.webp";
import SimpleContext from "../../context/SimpleContext";
import SimpleSubscription from "./SimpleSubscription";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  font-family: "Larken-Light";
  overflow: hidden;
  background-image: url(${bannerMobileImage});
  background-size: cover;
  background-position: center;
  text-align: center;
  @media (min-width: 768px) {
    background-image: url(${backgroundDesktopImage});
    text-align: left;
  }
`;

const InnerHeroContainer = styled.div`
  z-index: 9;
  @media (min-width: 768px) and (max-width: 1040px) {
  }
  @media (min-width: 768px) {
    width: var(--max-width-container);
    height: auto;
  }
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 12px;
  height: 90%;
`;

const HeroTitle = styled.h1`
  color: var(--text-color);
  //font-family: Larken;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  max-width: 800px;
`;

const HeroText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  //line-height: 28px;
`;
const HeroFirstText = styled(HeroText)`
  text-transform: uppercase;
  font-weight: bold;
  @media (min-width: 768px) {
    text-transform: capitalize;
    font-weight: 500;
  }
`;
const HeroButton = styled.button``;

const HeroBlogs = () => {
  const { t } = useTranslation();
  return (
    <Container className="hero-image">
      <InnerHeroContainer>
        <TextContainer>
          <HeroFirstText>{t("HERO.OUR_BLOG")}</HeroFirstText>
          <HeroTitle>{t("HERO.KNOWLEDGE_CENTER")}</HeroTitle>
          <HeroText>{t("HERO.SUBSCRIBE_TEXT")}</HeroText>
        </TextContainer>
        <SimpleSubscription />
      </InnerHeroContainer>
    </Container>
  );
};

export default HeroBlogs;
