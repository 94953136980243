import styled from "styled-components";

import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import backgroundDesktopImage from "../../assets/images/home_desktop/home_desktop_2x.webp";
import bannerMobileImage from "../../assets/images/home-mobile/home-mobile_2x.webp";

const Container = styled.div`
  overflow: hidden;
  background-image: url(${require("../../assets/images/blog2/blog2_2x.webp")});
  background-image: url(${bannerMobileImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  width: 90%;
  height: 50vh;
  @media (min-width: 768px) {
    background-position: center;
    background-image: url(${require("../../assets/images/blog2/blog2_2x.webp")});
    background-image: url(${backgroundDesktopImage});
    height: 50vh;
    text-align: left;
  }
  margin: 0;
`;

const InnerHeroContainer = styled.div`
  z-index: 9;
  @media (min-width: 768px) and (max-width: 1040px) {
  }
  @media (min-width: 768px) {
    width: var(--max-width-container);
    height: auto;
  }
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl);
  padding: 140px 0;
  width: 94%;
  height: 90%;
  overflow: hidden;
`;

const HeroBlog = () => {
  const { t } = useTranslation();
  const { goToShop } = useNavigation();
  const navigate = useNavigate();

  return (
    <Container>
      <InnerHeroContainer></InnerHeroContainer>
    </Container>
  );
};

export default HeroBlog;
