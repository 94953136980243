import styled, { keyframes } from "styled-components";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../../context/ProductContext";

const fadeIn = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const Component = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;

  //
  @media only screen and (min-width: 768px) {
    margin: 0;
    justify-content: flex-end;
  }
`;

const Select = styled.select`
  padding: var(--spacing-sm) var(--spacing-xs);
  border: none;
  background-color: var(--background-color);
  width: 180px;
  font-size: var(--font-size-small);
  font-weight: 700;
  text-transform: capitalize;
  color: var(--text-color);
`;
const Option = styled.option`
  text-transform: capitalize;
  background-color: var(--background-color);
  &:hover {
    background-color: var(--primary-color);
  }
`;

const SortProducts = () => {
  const { t } = useTranslation();
  //sortItems(products, 'price', false);
  const items = ["strength", "price", "name", "bestSellers"];
  const [selectedSort, setSelectedSort] = useState("strength");
  const [isActive, setIsActive] = useState(null);
  let { sortProducts, products } = useContext(ProductContext);
  useEffect(() => {
    sortProducts(products, selectedSort);
  }, [selectedSort]);

  return (
    <Component>
      <Select
        value={selectedSort}
        onChange={(e) => {
          setSelectedSort(e.target.value);
          setIsActive(false);
        }}
      >
        {/* Prikaži trenutno odabranu opciju na prvom mestu */}
        <Option key="selected" value={selectedSort}>
          {selectedSort}
        </Option>

        {/* Prikazujemo ostale opcije bez trenutno odabrane opcije */}
        {items
          .filter((item) => item !== selectedSort)
          .map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
      </Select>
    </Component>
  );
};

export default SortProducts;
