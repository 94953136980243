import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import HearthA from "../../assets/icons/search.png";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { ProductContext } from "../../context/ProductContext";
import ProductAttributePie from "./ProductAttributePie";
import hoverImg from "../../assets/images/product_bg_hover.svg";
import { saveForLaterActions } from "../../store/save-slice";
import { useTranslation } from "react-i18next";
const CartItem = styled.div`
  box-shadow: var(--shadow-default);
  background-color: var(--background-color);
  @media (min-width: 768px) {
    width: 100%;
    height: 280px;
  }

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  overflow: hidden;
  width: 94%;
  padding: var(--spacing-md) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-md);
  @media (min-width: 768px) {
    padding: 0;
    width: 94%;
    height: 80%;
    flex-direction: row;
  }
`;
const Picture = styled.picture`
  display: flex;
  width: 100%;
  max-height: 180px;
  background-image: url(${hoverImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    max-height: none;
    height: 100%;

    max-width: 40%;
    min-width: 40%;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;
const InfoDiv = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    min-width: 60%;
    max-width: 60%;
    height: 90%;
  }
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;
const FlexDiv = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //gap: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
`;
const ProductName = styled.h3`
  font-size: var(--font-size-large);
  font-weight: 600;
`;
const AddContainer = styled.div`
  background-color: var(--background-color-hover-alt);
  max-width: 130px;
  border-radius: var(--border-radius-large);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxs);
`;
const Button = styled.button`
  background-color: var(--background-color);
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-large);
  &:hover {
    background-color: var(--background-color);
  }
`;

const Icon = styled.div``;
const Input = styled.input`
  width: calc(var(--font-size-small) * 3 - var(--spacing-sm)); // Širina inputa
  height: 30px; // Visina inputa
  padding: 0 var(--spacing-sm);
  margin: 0 var(--spacing-sm);
  background-color: var(--background-color); // Boja pozadine
  text-align: center; // Centriranje teksta
  font-size: var(--font-size-small);
  border-radius: var(--border-radius-small);
  font-weight: 400;
  // Uklanjanje strelica za input tipa number
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Za Firefox
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const FlexDivPrice = styled(FlexDiv)`
  margin: var(--spacing-xxs) 0;
  justify-content: flex-start;
`;
const RowPrices = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Larken-Light";
`;
const Price = styled.span`
  font-size: var(--font-size-large);
  font-weight: 600;
`;
const DiscountedPrice = styled.span`
  font-size: var(--font-size-small);
  font-weight: 400;
  text-decoration: line-through;
  opacity: 0.5;
`;
const OffPercentage = styled.span`
  font-size: var(--font-size-base);
  color: var(--success-color);
  white-space: nowrap;
`;
const PricePiece = styled.span`
  font-size: var(--font-size-small);
  color: var(--text-color);
  font-weight: 700;
`;
const PieContainer = styled(FlexDiv)`
  display: flex;
  justify-content: flex-start;
  @media (min-width: 768px) {
    width: 50%;
  }
  gap: var(--spacing-md);
`;
const BottomFlex = styled(FlexDiv)`
  display: block;
  @media (min-width: 768px) {
    display: flex;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  border-top: 1px solid var(--transparent-border-color);
  margin: 12px 0 0 0;
  @media (min-width: 768px) {
    border: none;
    margin: 0;
  }
`;
const ButtonCard = styled.button`
  white-space: nowrap;
  border: none;
  text-transform: uppercase;
  background-color: transparent;
  padding: var(--spacing-md) var(--spacing-sm);
  &:hover {
    background-color: transparent;
    color: var(--text-color);
    border: none;
  }
  width: 100%;
`;
const RemoveButton = styled(ButtonCard)`
  display: flex;
  justify-content: flex-start;
`;
const CartProductBig = ({ item }) => {
  const { t } = useTranslation();
  const { currencyTag, maxAttributes } = useContext(ProductContext);
  const [inputValue, setInputValue] = useState(item.quantity);
  const dispatch = useDispatch();

  const savingsPercentage = Math.round(
    ((item.product.price - item.product.discount_price) / item.product.price) *
      100
  );
  const handleAddToSaveForLater = () => {
    dispatch(
      saveForLaterActions.addToSaveForLater({
        product: item.product,
        quantity: item.quantity,
      })
    );
    deleteCartItem(item.product.id);
  };

  const deleteCartItem = (id) => {
    dispatch(cartActions.removeFromCart(id));
  };
  const nicotinePercentage =
    (parseFloat(item.product.nicotine) / parseFloat(maxAttributes.nicotine)) *
    100;

  const handleAddOne = () => {
    const newQuantity = inputValue + 1;

    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: newQuantity,
      })
    );
  };
  const handleRemoveOne = () => {
    const newQuantity = inputValue - 1;
    if (newQuantity === 0) {
      deleteCartItem(item.product.id);
    }
    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: newQuantity,
      })
    );
  };
  useEffect(() => {
    setInputValue(item.quantity);
  }, [item.quantity]);

  const handleChange = (event) => {
    const value = Math.max(1, Math.min(999, Number(event.target.value)));
    setInputValue(value);
    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: value,
      })
    );
  };

  return (
    <CartItem>
      <Wrapper>
        <Picture>
          {item.product.images.length > 0 &&
            (() => {
              const primaryImage = item.product.images.find(
                (img) => img.is_primary
              ); // Pronalazi primarnu sliku samo jednom
              return (
                primaryImage && (
                  <Image
                    src={primaryImage.thumbnail} // Koristi 'thumbnail' verziju primarne slike
                    alt={item.product.name}
                    srcSet={`
                        ${primaryImage.thumbnail} 320w,  // Koristi 'thumbnail' verziju za male ekrane
                        ${primaryImage.large} 480w,  // Koristi 'large' verziju za srednje ekrane
                        ${primaryImage.large} 800w`} // Ponovo koristi 'large' verziju za velike ekrane
                    sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                    loading="lazy"
                  />
                )
              );
            })()}
        </Picture>
        <InfoDiv>
          <ProductName>
            {item.product.category_name} {item.product.name}
          </ProductName>
          <PieContainer>
            <ProductAttributePie
              type="nicotine"
              percentage={nicotinePercentage}
              label={t("PRODUCT_CARD.NICOTINE")}
            />
            <ProductAttributePie
              type="intensity"
              percentage={90.67}
              label={t("PRODUCT_CARD.INTENSITY")}
            />
          </PieContainer>
          <FlexDiv>
            <RowPrices>
              <Price>
                {currencyTag}
                {(item.product.discount_price * inputValue).toFixed(2)}
              </Price>
              <PricePiece>
                {currencyTag}
                {item.product.discount_price.toFixed(2)}/
                {t("PRODUCT_CARD.PRICE_PIECE")}
              </PricePiece>
              {savingsPercentage > 0 && (
                <>
                  <OffPercentage>{savingsPercentage}% off</OffPercentage>
                  <DiscountedPrice>
                    {currencyTag}
                    {item.product.price.toFixed(2)}
                  </DiscountedPrice>
                </>
              )}
            </RowPrices>
          </FlexDiv>
          <BottomFlex>
            <AddContainer>
              <Button onClick={() => handleRemoveOne()}>
                <svg
                  viewBox="0 0 160 161"
                  fill="none"
                  className="svg-icon"
                  style={{ width: "24px", height: "24px" }}
                >
                  <path
                    d="M79.516 159.561c-43.324 0-78.57-35.467-78.57-79.061 0-43.594 35.246-79.061 78.57-79.061 43.323 0 78.57 35.467 78.57 79.061 0 43.594-35.247 79.061-78.57 79.061zm0-148.661c-38.14 0-69.168 31.222-69.168 69.6 0 38.378 31.028 69.6 69.168 69.6 38.14 0 69.168-31.222 69.168-69.6 0-38.378-31.028-69.6-69.168-69.6z"
                    fill="var(--text-color)"
                  ></path>
                  <path
                    d="M108.69 74.379H49.407c-3.359 0-6.083 2.74-6.083 6.12 0 3.382 2.724 6.122 6.084 6.122h59.282c3.36 0 6.084-2.74 6.084-6.121 0-3.38-2.724-6.121-6.084-6.121z"
                    fill="var(--text-color)"
                  ></path>
                </svg>
              </Button>
              <div>
                <Input
                  type="number"
                  max="999"
                  min="0"
                  value={inputValue}
                  onChange={handleChange}
                />
              </div>

              <Button onClick={() => handleAddOne()}>
                <svg
                  viewBox="0 0 161 161"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg-icon"
                  style={{ width: "24px", height: "24px" }}
                >
                  <path
                    d="M119.668 74.283H85.76V40.375a6.217 6.217 0 00-12.434 0v33.908H39.418a6.217 6.217 0 000 12.434h33.908v33.908a6.217 6.217 0 1012.434 0V86.717h33.908a6.217 6.217 0 100-12.434z"
                    fill="var(--text-color)"
                  ></path>
                  <path
                    d="M80.013 159.561C36.419 159.561.952 124.094.952 80.5c0-43.594 35.467-79.061 79.06-79.061 43.595 0 79.062 35.467 79.062 79.061 0 43.594-35.467 79.061-79.061 79.061zm0-148.661c-38.378 0-69.6 31.222-69.6 69.6 0 38.378 31.222 69.6 69.6 69.6 38.378 0 69.6-31.222 69.6-69.6 0-38.378-31.222-69.6-69.6-69.6z"
                    fill="var(--text-color)"
                  ></path>
                </svg>
              </Button>
            </AddContainer>
            <ButtonsContainer>
              <ButtonCard
                className="button-transparent"
                style={{
                  borderRight: "1px solid var(--transparent-border-color)",
                }}
                onClick={handleAddToSaveForLater}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_689_40704)">
                    <path
                      d="M11.6665 2.5V5.83333C11.6665 6.05435 11.7543 6.26631 11.9106 6.42259C12.0669 6.57887 12.2788 6.66667 12.4998 6.66667H15.8332"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1665 17.5H5.83317C5.39114 17.5 4.96722 17.3244 4.65466 17.0118C4.3421 16.6993 4.1665 16.2754 4.1665 15.8333V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H11.6665L15.8332 6.66667V15.8333C15.8332 16.2754 15.6576 16.6993 15.345 17.0118C15.0325 17.3244 14.6085 17.5 14.1665 17.5Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 14.1667V9.16675"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.9165 12.0833L9.99984 14.1666L12.0832 12.0833"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_689_40704">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {t("BUTTONS.SAVE_FOR_LATER")}
              </ButtonCard>
              <RemoveButton
                className="button-transparent"
                onClick={() => deleteCartItem(item.product.id)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_689_40711)">
                    <path
                      d="M3.3335 5.83325H16.6668"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.3335 9.16675V14.1667"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.6665 9.16675V14.1667"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.1665 5.83325L4.99984 15.8333C4.99984 16.2753 5.17543 16.6992 5.48799 17.0118C5.80055 17.3243 6.22448 17.4999 6.6665 17.4999H13.3332C13.7752 17.4999 14.1991 17.3243 14.5117 17.0118C14.8242 16.6992 14.9998 16.2753 14.9998 15.8333L15.8332 5.83325"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_689_40711">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {t("BUTTONS.REMOVE")}
              </RemoveButton>
            </ButtonsContainer>
          </BottomFlex>
        </InfoDiv>
      </Wrapper>
    </CartItem>
  );
};

export default CartProductBig;
