import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import CheckoutMain from "../layouts/main/CheckoutMain";
import MiniHeader from "../layouts/header/MiniHeader";
import EditAddressBook from "../components/account/EditAddressBook";
import { useContext, useEffect, useState } from "react";
import SimpleContext from "../context/SimpleContext";
import AddAddressBook from "../components/account/AddAddressBook";
import { AuthUserContext } from "../context/AuthUserContext";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../utils/navigation";

const SEOConfig = {
  "en-US": {
    lang: "en-US",
    title: "Checkout | SnusCo",
    description:
      "Complete your purchase of premium nicotine pouches. Enjoy secure and fast checkout for a seamless experience.",
    og: {
      title: "Checkout | SnusCo",
      description:
        "Finalize your order with SnusCo and get ready to enjoy our premium nicotine pouches delivered to your door.",
    },
  },
  de: {
    lang: "de",
    title: "Kasse | SnusCo",
    description:
      "Schließen Sie den Kauf Ihrer Premium-Nikotinbeutel ab. Genießen Sie einen sicheren und schnellen Checkout für ein nahtloses Erlebnis.",
    og: {
      title: "Kasse | SnusCo",
      description:
        "Schließen Sie Ihre Bestellung bei SnusCo ab und freuen Sie sich auf die Lieferung unserer Premium-Nikotinbeutel direkt zu Ihnen nach Hause.",
    },
  },
  it: {
    lang: "it",
    title: "Checkout | SnusCo",
    description:
      "Completa il tuo acquisto di pouches di nicotina premium. Goditi un pagamento sicuro e veloce per un'esperienza senza interruzioni.",
    og: {
      title: "Checkout | SnusCo",
      description:
        "Finalizza il tuo ordine con SnusCo e preparati a goderti le nostre pouches di nicotina premium consegnate a casa tua.",
    },
  },
  fr: {
    lang: "fr",
    title: "Paiement | SnusCo",
    description:
      "Complétez votre achat de sachets de nicotine premium. Profitez d'un paiement sécurisé et rapide pour une expérience sans faille.",
    og: {
      title: "Paiement | SnusCo",
      description:
        "Finalisez votre commande avec SnusCo et préparez-vous à savourer nos sachets de nicotine premium livrés à votre porte.",
    },
  },
  hu: {
    lang: "hu",
    title: "Fizetés | SnusCo",
    description:
      "Fejezze be a prémium nikotinos tasakok vásárlását. Élvezze a biztonságos és gyors fizetést egy zökkenőmentes élmény érdekében.",
    og: {
      title: "Fizetés | SnusCo",
      description:
        "Véglegesítse rendelését a SnusCo-nál, és készüljön fel, hogy élvezze prémium nikotinos tasakjainkat, amelyek házhoz jönnek.",
    },
  },
  tr: {
    lang: "tr",
    title: "Ödeme | SnusCo",
    description:
      "Premium nikotin torbaları satın alımınızı tamamlayın. Kesintisiz bir deneyim için güvenli ve hızlı ödeme seçeneklerimizden yararlanın.",
    og: {
      title: "Ödeme | SnusCo",
      description:
        "SnusCo ile siparişinizi tamamlayın ve premium nikotin torbalarımızın kapınıza gelmesi için hazırlanın.",
    },
  },
  "sr-Latn": {
    lang: "sr-latn",
    title: "Plaćanje | SnusCo",
    description:
      "Završite kupovinu premium nikotinskih kesica. Uživajte u sigurnom i brzom procesu plaćanja za besprekorno iskustvo.",
    og: {
      title: "Plaćanje | SnusCo",
      description:
        "Finalizirajte svoju narudžbinu sa SnusCo i pripremite se da uživate u našim premium nikotinskim kesicama isporučenim na vaša vrata.",
    },
  },
};

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { goToHome } = useNavigation();
  const { showEditAddressBook, showAddAddressBook, openLoginPopup } =
    useContext(SimpleContext);
  const [editAddress, setEditAddress] = useState(null);
  const { fetchUserData, userProfile, authTokens } =
    useContext(AuthUserContext);

  useEffect(() => {
    if (!authTokens) {
      navigate(goToHome());
      openLoginPopup();
      return;
    }

    fetchUserData();
  }, []);
  // useEffect(() => {
  //   if (userProfile && !userProfile.is_email_verified) {
  //     navigate(goToHome());
  //     openLoginPopup();
  //     return;
  //   }
  // }, [userProfile]);
  const [seo, setSeo] = useState(SEOConfig["en-US"]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig["en-US"]);
  }, [i18n.language]);
  return (
    <div>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <link
          rel="alternate"
          href="http://www.snusco.com/en-US/checkout"
          hreflang={seo.lang}
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/de/checkout"
          hreflang="de"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/it/checkout"
          hreflang="it"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/fr/checkout"
          hreflang="fr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/hu/checkout"
          hreflang="hu"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/tr/checkout"
          hreflang="tr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/sr-latn/checkout"
          hreflang="sr-latn"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/checkout"
          hreflang="x-default"
        />

        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.snusco.com/checkout" />
        <meta
          property="og:image"
          content="http://www.snusco.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {showEditAddressBook && <EditAddressBook editAddress={editAddress} />}
      {showAddAddressBook && <AddAddressBook />}
      <MiniHeader />
      {userProfile && <CheckoutMain setEditAddress={setEditAddress} />}
    </div>
  );
};

export default Checkout;
