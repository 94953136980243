import styled from "styled-components";
import MegaMenu from "./desktop/MegaMenu";
import HeaderToolbar from "./HeaderToolbar";
import { useEffect, useState } from "react";
import BottomHeader from "./BottomHeader";
import SearchHeader from "./SearchHeader";
import HamburgerMenu from "./mobile/HamburgerMenu";
import CartMenu from "./mobile/CartMenu";
import CartBar from "./components/CartBar";
import { useNavigation } from "../../utils/navigation";
import DropDownTop from "./components/DropDownTop";
import { useNavigate } from "react-router-dom";
import { generateMenuData } from "../../utils/menu_data";
import ChangePreferencesIcon from "../../components/account/ChangePreferencesIcon";

const Container = styled.div`
  position: relative;
`;

const MainHeaderWrapper = styled.div`
  width: 100%;
  box-shadow: var(--shadow-default);
  z-index: var(--zindex-top);
  position: fixed;
  top: ${(props) => (props.$isScrolled ? "0" : "initial")};
  min-height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  @media (min-width: 768px) {
    min-height: var(--navbar-height-desktop);
  }
`;
const GridContainer = styled.header`
  height: 100%;
  width: 100%;
  max-width: var(--max-width-container);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 var(--spacing-md);

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
`;
const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: var(--spacing-lg) 0;
  gap: var(--spacing-md);
`;
const GridItemLinks = styled(GridItem)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const LogoImg = styled.div`
  color: var(--text-color);
  font-size: var(--font-size-xxlarge);
`;

const LinkTitle = styled.div`
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    text-underline-offset: var(--spacing-xxs);
    text-decoration-thickness: var(--border-width-thick);
  }
`;
const IconDiv = styled.div`
  cursor: pointer;
  font-size: var(--font-size-small);
  font-weight: bold;
`;
const IconDivDesktop = styled(IconDiv)`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;
const IconDivNoDesktop = styled(IconDiv)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const MainHeader = () => {
  const menu_data = generateMenuData();
  const [isScrolled, setIsScrolled] = useState(false);
  const { goToHome } = useNavigation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0); // Ažurira state ako korisnik skroluje nadole
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Očistite event listener kad se komponenta demontira
    };
  }, []);
  return (
    <Container>
      <HeaderToolbar />
      <MainHeaderWrapper $isScrolled={isScrolled}>
        <GridContainer>
          <GridItem>
            <LogoImg onClick={() => navigate(goToHome())}>LOGO</LogoImg>
          </GridItem>
          <GridItemLinks>
            {menu_data.map(
              (
                section,
                index // Mapiramo kroz sve sekcije
              ) => (
                <LinkTitle key={index}>
                  <MegaMenu megaMenuData={section} />
                </LinkTitle>
              )
            )}
          </GridItemLinks>
          <GridItem>
            <IconDivDesktop>
              <ChangePreferencesIcon />
            </IconDivDesktop>
            <IconDiv>
              <DropDownTop />
            </IconDiv>
            <IconDiv>
              <SearchHeader />
            </IconDiv>

            <IconDivNoDesktop>
              <HamburgerMenu />
            </IconDivNoDesktop>
            <IconDiv>
              <CartMenu />
            </IconDiv>
          </GridItem>
        </GridContainer>
      </MainHeaderWrapper>
      <div className="container-wrapper" />
      <CartBar isScrolled={isScrolled} position="fixed" />
      <BottomHeader />
    </Container>
  );
};

export default MainHeader;
