import { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { ProductContext } from "../../context/ProductContext";

const fadeInTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
  }
`;
const fadeOutBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const Container = styled.div`
  background-color: var(--background-color);
  margin-top: var(--spacing-lg);
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media (min-width: 768px) {
    //max-width: var(--max-width-container);
  }
`;
const GridContainer = styled.div`
  //grid-template-rows: repeat(4, 1fr);
  margin: 20px 0;
  min-width: 100%;
  overflow-x: hidden;
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm);
  padding-bottom: 0;
`;

const Button = styled.button`
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  border-radius: 0;
  border-top: 1px solid var(--transparent-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: var(--font-size-small);
  font-weight: 600;
  padding: 0;

  &:hover {
    background-color: var(--background-color);
  }
  min-width: 100%;
`;
const ButtonIcon = styled.span``;

const DropDown = styled.div`
  /* Samo za telefone, primenjuje se display na osnovu $isOpen */
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: none; /* Sakrij ako je otvoreno */
        `
      : css`
          display: block; /* Prikaži ako nije otvoreno */
        `}
`;

const Box = styled.div`
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${fadeOutBottom} 0.2s ease-in-out both;
        `
      : css`
          animation: ${fadeInTop} 0.3s ease-in-out both;
        `}
`;

const P = styled.p`
  font-size: var(--font-size-base);

  padding: 0;
  margin: 0;
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconContainer = styled(InputContainer)`
  margin-top: var(--spacing-xl);
  gap: var(--spacing-sm);
  justify-content: flex-start;
`;

const ProductInfo = () => {
  const { product } = useContext(ProductContext);
  const [isOpenBox, setIsOpenBox] = useState([false, true, true, true]);
  const [isOpenContainer, setIsOpenContainer] = useState([
    false,
    true,
    true,
    true,
  ]);
  const [isBoxAnimating, setIsBoxAnimating] = useState([
    false,
    false,
    false,
    false,
  ]);

  console.log("proizvod", product, product?.description);
  useEffect(() => {
    return () => {
      // Očistite sve postojeće timeout-e kada se komponenta demontira
      isBoxAnimating.forEach((_, index) =>
        clearTimeout(timeoutIdRef.current[index])
      );
    };
  }, []);

  // Referenca za čuvanje ID-eva timeout-a kako bi se mogli očistiti
  const timeoutIdRef = useRef([]);

  const toggleBox = (index) => {
    if (isBoxAnimating[index]) {
      return;
    }

    const updatedIsOpenBox = [...isOpenBox];
    updatedIsOpenBox[index] = !updatedIsOpenBox[index];
    setIsOpenBox(updatedIsOpenBox);

    const updatedIsBoxAnimating = [...isBoxAnimating];
    updatedIsBoxAnimating[index] = true;
    setIsBoxAnimating(updatedIsBoxAnimating);

    // Postavljanje timeout-a za animaciju i ažuriranje stanja
    timeoutIdRef.current[index] = setTimeout(
      () => {
        const updatedIsOpenContainer = [...isOpenContainer];
        updatedIsOpenContainer[index] = !updatedIsOpenContainer[index];
        setIsOpenContainer(updatedIsOpenContainer);

        updatedIsBoxAnimating[index] = false;
        setIsBoxAnimating(updatedIsBoxAnimating);
      },
      !isOpenContainer[index] ? 200 : 10
    );
  };

  return (
    <Container>
      <Wrapper>
        <GridContainer>
          <GridItems>
            <Button onClick={() => toggleBox(0)}>
              Short Description
              <ButtonIcon role="presentation">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className="svg-icon"
                  viewBox="0 0 28 16"
                  style={{ width: "12px" }}
                >
                  <path
                    d="M1.57 1.59l12.76 12.77L27.1 1.59"
                    strokeWidth=""
                    stroke="var(--transparent-border-color)"
                    fill="none"
                  ></path>
                </svg>
              </ButtonIcon>
            </Button>
            <DropDown $isOpen={isOpenContainer[0]}>
              <Box $isOpen={isOpenBox[0]}>
                <P>{product?.short_description}</P>

                <IconContainer>
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3 9.6C17.6314 9.15817 17.5418 8.53137 17.1 8.2C16.6582 7.86863 16.0314 7.95817 15.7 8.4L13.3918 11.4776L11.2071 9.29289C11.0021 9.08791 10.7183 8.98197 10.4291 9.00252C10.1399 9.02307 9.87393 9.16809 9.7 9.4L6.7 13.4C6.36863 13.8418 6.45817 14.4686 6.9 14.8C7.34183 15.1314 7.96863 15.0418 8.3 14.6L10.6082 11.5224L12.7929 13.7071C12.9979 13.9121 13.2817 14.018 13.5709 13.9975C13.8601 13.9769 14.1261 13.8319 14.3 13.6L17.3 9.6Z"
                      fill="var(--text-color)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                      fill="var(--text-color)"
                    />
                  </svg>
                </IconContainer>
              </Box>
            </DropDown>
          </GridItems>

          {/* Service Section */}
          <GridItems>
            <Button onClick={() => toggleBox(1)}>
              Description
              <ButtonIcon role="presentation">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className="svg-icon"
                  viewBox="0 0 28 16"
                  style={{ width: "12px" }}
                >
                  <path
                    d="M1.57 1.59l12.76 12.77L27.1 1.59"
                    strokeWidth=""
                    stroke="var(--transparent-border-color)"
                    fill="none"
                  ></path>
                </svg>
              </ButtonIcon>
            </Button>
            <DropDown $isOpen={isOpenContainer[1]}>
              <Box $isOpen={isOpenBox[1]}>
                <P>{product?.description}</P>
                <P>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                  nam optio voluptatem nobis, odit aut ipsa labore voluptatum
                  voluptatibus recusandae perferendis odio velit reiciendis quas
                  cupiditate illo alias enim ullam.
                </P>
                <P>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                  nam optio voluptatem nobis, odit aut ipsa labore voluptatum
                  voluptatibus recusandae perferendis odio velit reiciendis quas
                  cupiditate illo alias enim ullam.
                </P>
                <P>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                  nam optio voluptatem nobis, odit aut ipsa labore voluptatum
                  voluptatibus recusandae perferendis odio velit reiciendis quas
                  cupiditate illo alias enim ullam.
                </P>
              </Box>
            </DropDown>
          </GridItems>
        </GridContainer>
      </Wrapper>
    </Container>
  );
};

export default ProductInfo;
