import { useContext } from "react";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";

const AddressContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
`;
const AddressWrapper = styled.div`
  padding: var(--spacing-xl);
  margin: var(--spacing-lg) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AddressTitle = styled.div`
  font-size: var(--font-size-base);
  font-weight: normal;
`;
const AdressInfo = styled.p`
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--p-color);
`;
const AddressType = styled.p`
  display: inline;
  font-size: var(--font-size-small);
  font-family: "Larken";
  margin-top: 0;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: 20px;
  text-transform: capitalize;
  color: var(--text-color);
  background-color: #d7e0e7;
  margin-left: 14px;
`;

const DelieveryAddress = ({ address, setSelectedAddress }) => {
  const { userProfile } = useContext(AuthUserContext);
  return (
    <AddressContainer>
      <AddressWrapper>
        <div>
          <AddressTitle>
            Deliver to: {address.country}, {address.id}
            <AddressType>{address.type}</AddressType>
          </AddressTitle>
          <AdressInfo>
            {address.street}, {address.city}, {address.postal_code} ..
          </AdressInfo>
        </div>
        <button
          className="button-transparent"
          onClick={() => setSelectedAddress(null)}
        >
          Change
        </button>
      </AddressWrapper>
    </AddressContainer>
  );
};

export default DelieveryAddress;
