import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import FooterMain from "../layouts/footer/FooterMain";
import { useParams } from "react-router-dom";
import APIService from "../services/APIService";
import BlogMain from "../layouts/main/BlogMain";

const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const ProductMain = () => {
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const [blog, setBlog] = useState();
  const [seo, setSeo] = useState({
    title: "Loading...",
    description: "Loading blog information...",
    keywords: "",
    url: window.location.href,
    images: ["https://www.snusco.com/assets/default-blog-image.jpg"], // Podrazumevana slika
  });

  useEffect(() => {
    if (!slug) {
      return;
    }
    setBlog(null);
    const fetchBlog = async () => {
      try {
        const blog_copy = await APIService.GetBlogBySlug(slug);
        setBlog(blog_copy);
      } catch (error) {
        console.error(error);
        throw error; // Bacanje greške omogućava da se ista obradi na višem nivou ako je potrebno
      }
    };

    fetchBlog();
  }, [slug]);
  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  useEffect(() => {
    if (blog) {
      const seoTitle = `${blog.title} | SnusCo`;
      const plainTextParagraph = stripHTML(blog.paragraphs[0]);
      const seoDescription = plainTextParagraph.slice(0, 150) + "...";
      const seoKeywords = blog.tags
        ? blog.tags.join(", ")
        : blog.title.split(" ").join(", ");
      const blogUrl = `https://www.snusco.com/${i18n.language}/blog/${blog.slug}`;

      const primaryImage =
        blog.images?.find((image) => image.is_primary)?.large ||
        "https://www.snusco.com/assets/default-blog-image.jpg";
      const additionalImages = blog.images
        ? blog.images
            .filter((image) => !image.is_primary)
            .map((image) => image.large)
        : [];

      setSeo({
        title: seoTitle,
        description: seoDescription,
        keywords: seoKeywords,
        url: blogUrl,
        images: [primaryImage, ...additionalImages],
      });
    }
    console.log(blog);
  }, [blog]);
  return (
    <Container>
      <Helmet>
        <html lang={i18n.language} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="blog" />
        <meta property="og:url" content={seo.url} />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        {seo.images.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <PrimaryHeader />
      <BlogMain blog={blog} />
      <FooterMain />
    </Container>
  );
};

export default ProductMain;
