import { useRef, useState } from "react";
import styled from "styled-components";
const Container = styled.div`
  position: absolute;
  bottom: calc(100% + 9px);
  left: -9px;
  background-color: var(--background-color);
  width: 260px;
  border-radius: 8px;
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
  box-shadow: var(--shadow-large);
`;
const Wrapper = styled.div`
  padding: var(--spacing-sm);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  position: relative;
`;
const Title = styled.h6`
  font-size: var(--font-size-small);
`;
const Text = styled(Title)`
  font-family: "Satoshi";
  color: var(--p-color);
`;
const IconContainer = styled.div`
  position: relative;
  display: inline;
`;
const SVGIcon = styled.svg`
  position: absolute;
  bottom: -8px;
  left: 12px;
`;
const Tooltip = ({ title, text }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  const handleTouchStart = () => {
    setVisible(true);
  };

  const handleTouchEnd = () => {
    setVisible(false);
  };

  return (
    <IconContainer
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      ref={tooltipRef}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.99984 0.666504C13.6023 0.666504 17.3332 4.39734 17.3332 8.99984C17.3349 11.1877 16.4761 13.2885 14.9423 14.8487C13.4084 16.4089 11.3225 17.3032 9.13486 17.3387C6.94725 17.3741 4.83345 16.5478 3.24984 15.0382C1.66622 13.5285 0.739828 11.4566 0.670671 9.26984L0.666504 8.99984L0.669837 8.7665C0.793171 4.27234 4.47484 0.666504 8.99984 0.666504ZM8.99984 8.1665H8.1665L8.069 8.17234C7.86646 8.19643 7.67979 8.29397 7.54435 8.44647C7.40891 8.59898 7.3341 8.79587 7.3341 8.99984C7.3341 9.20381 7.40891 9.40069 7.54435 9.5532C7.67979 9.70571 7.86646 9.80325 8.069 9.82734L8.1665 9.83317V12.3332L8.17234 12.4307C8.19427 12.6167 8.27824 12.7899 8.41068 12.9223C8.54312 13.0548 8.71632 13.1387 8.90234 13.1607L8.99984 13.1665H9.83317L9.93067 13.1607C10.1167 13.1387 10.2899 13.0548 10.4223 12.9223C10.5548 12.7899 10.6387 12.6167 10.6607 12.4307L10.6665 12.3332L10.6607 12.2357C10.6407 12.0657 10.569 11.9059 10.455 11.7781C10.3411 11.6503 10.1906 11.5607 10.024 11.5215L9.93067 11.5048L9.83317 11.4998V8.99984L9.82734 8.90234C9.8054 8.71632 9.72144 8.54312 9.58899 8.41068C9.45655 8.27824 9.28335 8.19427 9.09734 8.17234L8.99984 8.1665ZM9.00817 5.6665L8.90234 5.67234C8.6998 5.69643 8.51312 5.79397 8.37768 5.94647C8.24224 6.09898 8.16743 6.29587 8.16743 6.49984C8.16743 6.70381 8.24224 6.90069 8.37768 7.0532C8.51312 7.20571 8.6998 7.30325 8.90234 7.32734L8.99984 7.33317L9.10567 7.32734C9.30821 7.30325 9.49488 7.20571 9.63033 7.0532C9.76577 6.90069 9.84058 6.70381 9.84058 6.49984C9.84058 6.29587 9.76577 6.09898 9.63033 5.94647C9.49488 5.79397 9.30821 5.69643 9.10567 5.67234L9.00817 5.6665Z"
          fill="#4E4E4E"
        />
      </svg>
      <Container $visible={visible}>
        <Wrapper>
          <Title>Title</Title>
          <Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
            quod quis molestiae delectus ut eum debitis suscipit et molestias
            explicabo aliquam nobis veritatis quo porro corrupti, minima iusto
            ea culpa!e
          </Text>
          <SVGIcon
            width="17"
            height="9"
            viewBox="0 0 17 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5711 0.485289C15.462 0.485289 15.9081 1.56243 15.2782 2.1924L9.20711 8.26347C8.81658 8.654 8.18342 8.654 7.79289 8.26347L1.72183 2.1924C1.09187 1.56243 1.53803 0.485289 2.42894 0.485289L14.5711 0.485289Z"
              fill="white"
            />
          </SVGIcon>
        </Wrapper>
      </Container>
    </IconContainer>
  );
};

export default Tooltip;
