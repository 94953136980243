import { useContext, useState } from "react";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useSelector } from "react-redux";
import {
  DEFAULT_CURRENCY,
  freeShippingThreshold,
  getShippingCostPrice,
} from "../../utils/global_const";
import { ProductContext } from "../../context/ProductContext";
import { jwtDecode } from "jwt-decode";
import TagManager from "react-gtm-module";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../utils/navigation";
import { useTranslation } from "react-i18next";
import SimpleContext from "../../context/SimpleContext";

const ActiveFillHeader = styled.div`
  padding: var(--spacing-xl) var(--spacing-lg);
  background-color: #c4d9e8;

  gap: var(--spacing-md);
  align-items: center;
  font-family: "Larken-Light";
  text-transform: uppercase;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;
const HeaderMobile = styled.h2`
  padding: var(--spacing-md);
  font-family: "Larken";
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  font-size: var(--font-size-large);
  @media (min-width: 768px) {
    display: none;
  }
`;

const StepNumber = styled.div`
  border: 1px solid var(--text-color);
  padding: 4px 10px;
  border-radius: 50%;
`;
const SelectAddressContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const SelectAddressWrapper = styled.div`
  width: calc(94% - var(--spacing-xl) * 2);
  background-color: var(--background-color);
  padding: var(--spacing-xl);
  margin: var(--spacing-xxs) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  cursor: pointer;
  @media (min-width: 768px) {
    width: 100%;
  }
`;
const AddressTitle = styled.h3`
  font-size: var(--font-size-base);
  font-weight: normal;
`;
const AdressInfo = styled.p`
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--p-color);
`;
const CheckboxButton = styled.div`
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 4px;
  border: 1px solid var(--text-color-hover);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckedCheckbox = styled.div`
  background-color: var(--text-color);
  width: 8px;
  height: 8px;
  border-radius: 20px;
`;
const RadioButton = styled.div`
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 20px;
  border: 1px solid var(--text-color-hover);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckedRadio = styled.div`
  background-color: var(--text-color);
  width: 8px;
  height: 8px;
  border-radius: 20px;
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
`;
const NoteContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: var(--spacing-md) 0;
`;
const NoteTextarea = styled.textarea`
  width: calc(100% - 30px);
  padding: 12px;
  border: 1px solid #f3dccc;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  min-height: 80px;
  background-color: #f3eeeb;

  &:focus {
    outline: none;
    border-color: #f3dccc;
    box-shadow: 0 0 5px #f3dccc;
  }
`;
const RightBox = styled.div`
  padding: var(--spacing-lg);

  width: calc(94% - var(--spacing-lg) * 2);
  background-color: var(--background-color);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  @media (min-width: 768px) {
    display: none;
  }
`;
const RightTitle = styled.h3`
  color: var(--text-color);

  font-size: var(--font-size-base);
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.7;
`;
const BrT = styled.div`
  height: 1px;
  background-color: var(--text-color);
  width: 100%;
  margin: var(--spacing-lg) 0;
  opacity: 0.1;
`;

const CoinText = styled.p`
  font-size: var(--font-size-small);
  color: #26a541;
  color: var(--text-color);
  font-weight: 400;
`;
const CoinWorth = styled.p`
  display: inline;
  padding: 2px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 37px;
  background: rgba(86, 147, 191, 0.2);
  color: #4e4e4e;
`;
const SubmitButtonWrapper = styled.div`
  padding: var(--spacing-xl) 0;
  background-color: var(--background-color);
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (min-width: 768px) {
    padding: var(--spacing-xl);
    justify-content: flex-end;
  }
`;
const SubmitButton = styled.button`
  white-space: nowrap;

  color: var(--background-color);
  background-color: var(--text-color);
  text-transform: capitalize;
  &:hover {
    color: var(--text-color);
    background-color: var(--background-color);
  }
  margin: 0;
  width: 94%;
  @media (min-width: 768px) {
    width: auto;
  }
`;

const Payment = ({ useCoins, setUseCoins, selectedAddress }) => {
  const { t } = useTranslation();
  const [note, setNote] = useState("");
  const [paymentOption, setPaymentOption] = useState("Cash on Delivery");
  const { userProfile, authTokens } = useContext(AuthUserContext);
  const { createOrder, setCreatedOrder } = useContext(ProductContext);
  const { setShowVerification } = useContext(SimpleContext);

  const cartItems = useSelector((state) => state.cart.itemsList);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navigate = useNavigate();
  const { goToThankYou } = useNavigation();

  const handleCoinClick = () => {
    setUseCoins(!useCoins);

    if (isMobile) {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Skroluje do vrha stranice sa animacijom
    }
  };
  const handleSubmit = async () => {
    if (userProfile && !userProfile.is_email_verified) {
      setShowVerification(true);
      return;
    }
    // Kreiranje objekta sa podacima narudžbine
    const orderData = {
      address: selectedAddress.id,
      order_items: cartItems.map((item) => ({
        product: item.product.id,
        quantity: item.quantity,
      })),
      payment_method: paymentOption,
      use_points: useCoins,
      note: note,
    };
    try {
      // Poziv funkcije za kreiranje narudžbine (pretpostavljamo da funkcija `createAddressBook` postoji)
      const createdOrderCopy = await createOrder(orderData, authTokens.access);
      console.log("Order created successfully", createdOrderCopy);
      setCreatedOrder(createdOrderCopy);
      // Ovde dodajte logiku koja se izvršava nakon uspešnog kreiranja narudžbine
      const totalPrice = cartItems.reduce(
        (total, item) => total + item.product.discount_price * item.quantity,
        0
      );
      const currency = localStorage.getItem("currency") || DEFAULT_CURRENCY;
      const shipping =
        totalPrice >= freeShippingThreshold
          ? 0.0
          : getShippingCostPrice(currency, DEFAULT_CURRENCY).toFixed(2);
      const items = cartItems.map((item, index) => ({
        item_id: item.product.id,
        item_name: item.product.category_name + " " + item.product.name,
        affiliation: "Snusco", // Customize as needed
        discount:
          Number(item.product.price) - Number(item.product.discount_price),
        index: index,
        item_category: item.product.category_name,
        item_variant: item.product.nicotine + " MG",
        price: item.product.discount_price,
        quantity: item.quantity,
      }));
      const user_info = jwtDecode(authTokens.access);

      //   TagManager.dataLayer({
      //     dataLayer: {
      //       event: "purchase",
      //       transaction_id: `T_${new Date().getTime()}`,
      //       value: totalPrice.toFixed(2),
      //       shipping: Number(shipping).toFixed(2),
      //       currency: currency,
      //       items: items,
      //       user_id: user_info.user_id,
      //     },
      //   });
      navigate(goToThankYou());
    } catch (error) {
      console.error("Greška prilikom kreiranja narudžbine:", error); // Obradite grešku
    }
  };
  return (
    <div>
      <NoteContainer>
        <NoteTextarea
          id="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder={t("PLACEHOLDERS.NOTE")}
        />
      </NoteContainer>
      <ActiveFillHeader>
        <StepNumber>3</StepNumber>
        {t("CHECKOUT.PAYMENT_OPTION")}
      </ActiveFillHeader>
      <HeaderMobile>{t("CHECKOUT.PAYMENT_OPTIONS")}</HeaderMobile>
      {userProfile?.points && userProfile.points > 0 && (
        <SelectAddressContainer>
          <SelectAddressWrapper onClick={handleCoinClick} style={{ margin: 0 }}>
            <FlexDiv>
              <CheckboxButton>
                {useCoins && (
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3L4.5 8.5L2 6"
                      stroke="#5693BF"
                      strokeWidth="1.6666"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </CheckboxButton>
              <div>
                <AddressTitle>{t("RECEIPT.COINS_LABEL")}</AddressTitle>
                <AdressInfo>{t("CHECKOUT.COIN_DESCRIPTION")}</AdressInfo>
              </div>
            </FlexDiv>

            <FlexDiv style={{ gap: "var(--spacing-xxs)" }}>
              <svg
                width="27"
                height="28"
                viewBox="0 0 27 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3594 27.3594C20.7376 27.3594 26.7188 21.3782 26.7188 14C26.7188 6.62182 20.7376 0.640625 13.3594 0.640625C5.9812 0.640625 0 6.62182 0 14C0 21.3782 5.9812 27.3594 13.3594 27.3594Z"
                  fill="#FEDA2C"
                />
                <path
                  d="M13.3595 24.298C19.0471 24.298 23.6579 19.6873 23.6579 13.9996C23.6579 8.31194 19.0471 3.70117 13.3595 3.70117C7.6718 3.70117 3.06104 8.31194 3.06104 13.9996C3.06104 19.6873 7.6718 24.298 13.3595 24.298Z"
                  fill="#FCAA17"
                />
                <path
                  d="M13.3595 6.08545L15.7016 10.8311L20.9385 11.5921L17.1491 15.2861L18.0437 20.5019L13.3595 18.0393L8.67527 20.5019L9.56988 15.2861L5.78027 11.5921L11.0174 10.8311L13.3595 6.08545Z"
                  fill="#FEDA2C"
                />
              </svg>
              {userProfile.points}
            </FlexDiv>
          </SelectAddressWrapper>
          <RightBox>
            <RightTitle
              style={{
                color: "var(--primary-color)",
              }}
            >
              {t("RECEIPT.COINS_LABEL")}
            </RightTitle>

            <BrT />
            <CoinText>{t("RECEIPT.COIN_TOOLTIP")}</CoinText>
            <CoinWorth>{t("RECEIPT.COIN_WORTH")}</CoinWorth>
          </RightBox>
        </SelectAddressContainer>
      )}
      <SelectAddressContainer>
        <SelectAddressWrapper onClick={() => setPaymentOption("PayPal")}>
          <FlexDiv>
            <RadioButton>
              {paymentOption === "PayPal" && <CheckedRadio />}
            </RadioButton>
            <FlexDiv style={{ gap: "var(--spacing-xxs)" }}>
              <svg
                width="22"
                height="26"
                viewBox="0 0 22 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="paypal">
                  <path
                    id="vector"
                    d="M19.8485 6.61723C20.1602 4.58517 19.8485 3.23046 18.7576 1.97996C17.5629 0.573146 15.3813 0 12.5762 0H4.52484C3.95345 0 3.48595 0.416834 3.38206 0.98998L0.00566396 22.3527C-0.0462806 22.7695 0.265387 23.1343 0.680943 23.1343H5.66762L5.30401 25.3226C5.25206 25.6874 5.51179 26 5.92734 26H10.1349C10.6543 26 11.0699 25.6353 11.1218 25.1663L12.0049 19.6433C12.0568 19.1743 12.5243 18.8096 12.9918 18.8096H13.6151C17.6668 18.8096 20.8874 17.1423 21.8224 12.3487C22.186 10.3687 22.0302 8.6493 20.9913 7.50301C20.6796 7.13828 20.316 6.87776 19.8485 6.61723Z"
                    fill="#009CDE"
                  />
                  <path
                    id="vector_2"
                    d="M19.8485 6.61723C20.1601 4.58517 19.8485 3.23046 18.7576 1.97996C17.5629 0.573146 15.3813 0 12.5762 0H4.52484C3.95345 0 3.48595 0.416834 3.38206 0.98998L0.00566396 22.3527C-0.0462806 22.7695 0.265387 23.1343 0.680943 23.1343H5.66762L6.86234 15.4228C6.96623 14.8497 7.43373 14.4329 8.00512 14.4329H10.3946C15.0696 14.4329 18.7057 12.5571 19.7446 7.03407C19.7965 6.92986 19.7965 6.77355 19.8485 6.61723Z"
                    fill="#012169"
                  />
                  <path
                    id="vector_3"
                    d="M8.26485 6.66934C8.31679 6.30461 8.78429 5.83567 9.25179 5.83567H15.589C16.3163 5.83567 17.0435 5.88777 17.6668 5.99198C18.2382 6.09619 19.2771 6.35671 19.7965 6.66934C20.1082 4.63727 19.7965 3.28257 18.7057 2.03206C17.5629 0.573146 15.3813 0 12.5762 0H4.52484C3.95345 0 3.48595 0.416834 3.38206 0.98998L0.00566396 22.3527C-0.0462806 22.7695 0.265387 23.1343 0.680943 23.1343H5.66762L8.26485 6.66934Z"
                    fill="#003087"
                  />
                </g>
              </svg>
              <AddressTitle>{t("CHECKOUT.PAYPAL")}</AddressTitle>
            </FlexDiv>
          </FlexDiv>
        </SelectAddressWrapper>
      </SelectAddressContainer>
      <SelectAddressContainer>
        <SelectAddressWrapper
          onClick={() => setPaymentOption("Cash on Delivery")}
        >
          <FlexDiv>
            <RadioButton>
              {paymentOption === "Cash on Delivery" && <CheckedRadio />}
            </RadioButton>

            <AddressTitle>{t("CHECKOUT.COD")}</AddressTitle>
          </FlexDiv>
        </SelectAddressWrapper>
      </SelectAddressContainer>
      <SubmitButtonWrapper>
        <SubmitButton onClick={handleSubmit} className="button-transparent">
          {t("BUTTONS.SUBMIT")}
        </SubmitButton>
      </SubmitButtonWrapper>
    </div>
  );
};

export default Payment;
