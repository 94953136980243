import styled from "styled-components";
import APIService from "../../services/APIService";
import { useState } from "react";
import Warning from "../toast/Warning";
import Success from "../toast/Success";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--text-color);
`;

const Wrapper = styled.div`
  margin: var(--spacing-xxl) 0;
  width: 94%;

  padding: var(--spacing-xxl) 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-xxl);

  background: rgba(86, 147, 191, 0.09);
  @media (min-width: 1025px) {
  }
  @media (min-width: 765px) {
    width: var(--max-width-container);
    height: 100px;
    //border: 1px solid rgba(86, 147, 191, 0.35);
    padding: var(--spacing-xxl) 0;
    flex-direction: row;
  }
`;
const LeftContainer = styled.div`
  width: 80%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  @media (min-width: 765px) {
    padding: 0 var(--spacing-xl);
    width: 60%;
    max-width: 60%;
    flex-direction: row;
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    //flex-direction: column;
  }
`;
const Title = styled.h3`
  font-family: "Larken-Light";
  text-align: left;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: var(--font-size-xxlarge);
  color: var(--text-color);
  color: #d0d5dd;
  cursor: pointer;
`;
const Text = styled.p`
  font-size: var(--font-size-base);
  max-width: 100%;
  color: #d0d5dd;
  @media (min-width: 768px) and (max-width: 1040px) {
    max-width: 80%;
  }
`;

const InputContainer = styled.form`
  position: relative;
  width: 100%;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  //gap: var(--spacing-xxs);

  padding: 0 var(--spacing-sm);

  @media (min-width: 768px) and (max-width: 1040px) {
    //width: 160px;
  }
  @media (min-width: 768px) {
    width: 40%;
    max-width: 40%;
    flex-direction: row;
    gap: 0;
  }
`;
const Input = styled.input`
  font-size: var(--font-size-medium);
  font-weight: 400;

  width: calc(90% - var(--spacing-xs) * 2);
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 var(--spacing-xs);
  font-size: var(--font-size-base);
  font-style: normal;
  font-family: "Larken";
  border: 1px solid var(--primary-color);
  background-color: var(--background-color);
  &::placeholder {
    color: var(--p-color);

    font-weight: 400;
    line-height: 24px;
  }
  @media (min-width: 768px) {
    width: calc(65% - var(--spacing-xs) * 2);
  }
`;
const Button = styled.button`
  border-radius: 0;
  width: 90%;
  font-size: var(--font-size-base);
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  @media (min-width: 768px) {
    width: 33%;
  }
  font-family: "Larken";
`;
const showSuccessNotification = (text) => {
  toast(<Success text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast-success",
    progressClassName: "custom-progress-bar",
  });
};
const showWarningNotification = (text) => {
  toast(<Warning text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast-success",
    progressClassName: "custom-progress-bar",
  });
};
const Subscription = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  //const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      //setError("Please enter a valid email address.");
      return;
    }
    //setError("");
    try {
      const response = await APIService.subscribe(email);
      //console.log("Subscription successful:", response);
      showSuccessNotification(t("NOTIFICATIONS.SUBSCRIPTION_SUCCESS"));
      // Optionally, handle success (e.g., show a success message)
      setEmail("");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          showWarningNotification(t("NOTIFICATIONS.EMAIL_ALREADY_SUBSCRIBED"));
        } else {
          showWarningNotification(t("NOTIFICATIONS.SUBSCRIPTION_FAILED"));
        }
      } else {
        showWarningNotification(t("NOTIFICATIONS.SUBSCRIPTION_FAILED"));
      }
      // console.error("Subscription failed:", error);
      // Optionally, handle error (e.g., show an error message)
    }
  };

  const validateEmail = (email) => {
    const trimmedEmail = email.trim();
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(trimmedEmail).toLowerCase());
  };
  return (
    <Container>
      <Wrapper>
        <LeftContainer>
          <div>
            <Title>{t("SUBSCRIPTION.TITLE")}</Title>
            <Text>{t("SUBSCRIPTION.TEXT")}</Text>
          </div>
        </LeftContainer>
        <InputContainer onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder={t("PLACEHOLDERS.EMAIL")}
            aria-label="Subscription"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit">{t("BUTTONS.SUBMIT")}</Button>
        </InputContainer>
      </Wrapper>
    </Container>
  );
};

export default Subscription;
