import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ChatContext from "../context/ChatContext";
import { AuthUserContext } from "../context/AuthUserContext";
const Friend = styled.div`
  position: relative;
  height: 80px;
  border-bottom: 1px solid var(--background-color-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--background-color-hover);
    cursor: pointer;
  }
`;
const FriendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;
const FriendLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;
const FriendInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const LastMessage = styled.div`
  font-size: var(--font-size-base);
  font-weight: 400;
`;
const FriendName = styled(LastMessage)`
  font-weight: 200;
`;
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--info-color);
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UnreadMessageCount = styled.div`
  position: absolute;
  top: 25px;
  right: 60px;
  padding: 8px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--error-color);
  color: #fff;
  font-size: var(--font-size-small);
  font-weight: 700;
  border-radius: 50%;
`;

const ChatListHistory = () => {
  const { setSelectedChat, chatHistory } = useContext(ChatContext);
  const { userProfile } = useContext(AuthUserContext);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  return (
    <div>
      {chatHistory?.map((chat) => {
        const unreadMessagesCount = chat.messages.filter(
          (msg) => !msg.seen && msg.user !== userProfile.id
        ).length;

        return (
          <Friend onClick={() => handleChatClick(chat)} key={chat.id}>
            <FriendContainer>
              <FriendLeft>
                <Avatar>S</Avatar>
                <FriendInfo>
                  <LastMessage>
                    {chat.messages.length > 0
                      ? chat.messages[chat.messages.length - 1].message.length >
                        40
                        ? `${chat.messages[
                            chat.messages.length - 1
                          ].message.substring(0, 40)}...`
                        : chat.messages[chat.messages.length - 1].message
                      : "Nema poruka"}
                  </LastMessage>
                  <FriendName>{chat.admin_email} - 35w ago</FriendName>
                </FriendInfo>
              </FriendLeft>
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_429_11254)">
                  <path
                    d="M10 17L15 12"
                    stroke="var(--primary-color)"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 12L10 7"
                    stroke="var(--primary-color)"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_429_11254">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {chat.is_active && unreadMessagesCount > 0 && (
                <UnreadMessageCount>{unreadMessagesCount}</UnreadMessageCount>
              )}
            </FriendContainer>
          </Friend>
        );
      })}
    </div>
  );
};

export default ChatListHistory;
