import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ProductContext } from "../../context/ProductContext";
import { useNavigation } from "../../utils/navigation";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 0;
  background-color: var(--text-color);
  padding: 40px 0 20px 0;
  @media (min-width: 768px) {
    min-height: 240px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #d0d5dd;
  font-family: "Inter";

  @media (min-width: 768px) {
    max-width: var(--max-width-container);
    align-items: flex-start;
  }
`;
const GridContainer = styled.div`
  //grid-template-rows: repeat(4, 1fr);

  width: 90%;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    height: 100%;
    width: 100%;
    gap: 4px;
  }
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  &:nth-child(2) {
    @media (min-width: 768px) {
      grid-column: span 2; /* Drugi element zauzima dva stuba */
    }
  }
`;

const List = styled.div`
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  display: flex;
  gap: 14px;

  padding: var(--spacing-md) 0;
  flex-wrap: wrap;

  border-bottom: 1px solid #69696953;
  @media (min-width: 768px) {
    //display: block;
    gap: 4px;
    padding: 0;
    border: none;
    flex-direction: column;
    max-height: 200px; // inace je max 240px gore u Container imas
  }
`;

const ListItem = styled.div`
  font-size: var(--font-size-base);
  cursor: pointer;
  font-weight: 500;
  padding-bottom: var(--spacing-md);
  text-transform: capitalize;
`;

const LogoDiv = styled.div`
  font-size: var(--font-size-xlarge);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.438px;
  display: flex;
  gap: var(--spacing-xs);

  align-items: flex-start;
  cursor: pointer;
  color: var(--background-color);
  margin-bottom: 14px;
`;

const ContainerToolbar = styled.div`
  background-color: var(--text-color);
  width: 100%;
  color: var(--background-color-hover);
  background-color: #1a1a1a;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const WrapperToolbar = styled.div`
  width: 90%;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex-direction: column;

  padding: 10px;
  gap: var(--spacing-md);
  @media (min-width: 768px) {
    flex-direction: row;
    max-width: var(--max-width-container);
    gap: 0;
  }
`;

const Text = styled.p`
  padding: 10px;
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: bold;
  @media (min-width: 768px) {
    display: inline-block;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  justify-content: flex-start;
  cursor: pointer;
`;
const CategoriesTitle = styled.h3`
  padding: 14px 0 10px 0;
  font-size: var(--font-size-large);
  color: #d0d5dd;
  @media (min-width: 768px) {
    padding: 0;
    padding-bottom: var(--spacing-md);
  }
`;
const Wraning = styled.p`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--p-color);
  font-family: "Satoshi";
  font-size: var(--font-size-base);
  font-weight: 700;
  gap: 4px;
  width: 90%;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;
const FooterMain = () => {
  const { t } = useTranslation();
  const { categories } = useContext(ProductContext);
  const navigate = useNavigate();
  const { goToCategory, goToSupport, goToPrivacyPolicy } = useNavigation();
  return (
    <div>
      <Container>
        <Wrapper>
          <GridContainer>
            <GridItems>
              <LogoDiv>
                {" "}
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 298 298"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M148.707 0C66.7245 0 0 66.7245 0 148.707C0 230.689 66.7245 297.414 148.707 297.414C230.689 297.414 297.414 230.689 297.414 148.707C297.414 66.7245 230.689 0 148.707 0ZM294.707 148.707C294.707 188.275 278.843 224.204 253.18 250.567L244.688 242.074C268.158 217.904 282.669 184.962 282.669 148.707C282.669 112.452 267.878 78.8562 243.988 54.6394L252.48 46.1472C278.563 72.557 294.661 108.766 294.661 148.707H294.707ZM17.3576 148.707C17.3576 76.2899 76.2432 17.4043 148.66 17.4043C221.077 17.4043 279.963 76.2899 279.963 148.707C279.963 221.124 221.077 280.009 148.66 280.009C76.2432 280.009 17.3576 221.124 17.3576 148.707ZM250.52 44.2808L242.028 52.7731C217.858 29.2562 184.915 14.7447 148.614 14.7447C112.312 14.7447 79.416 29.2562 55.2458 52.7731L46.7536 44.2808C73.0701 18.6176 108.999 2.75295 148.567 2.75295C188.135 2.75295 224.11 18.6175 250.427 44.3275L250.52 44.2808ZM44.7939 46.1939L53.2862 54.6862C29.3494 78.9029 14.558 112.172 14.558 148.8C14.558 185.429 29.0693 218.044 52.5862 242.214L44.1406 250.707C18.4307 224.39 2.56627 188.415 2.56627 148.847C2.56627 109.279 18.7107 72.6504 44.7939 46.2872V46.1939ZM46.0539 252.667L54.5461 244.174C78.7629 268.064 111.985 282.856 148.614 282.856C185.242 282.856 218.511 268.064 242.728 244.128L251.22 252.62C224.857 278.703 188.602 294.848 148.66 294.848C108.719 294.848 72.5102 278.703 46.147 252.667"
                    fill="var(--background-color)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M207.722 57.7351C202.622 66.9627 200.922 78.8759 205.416 87.3302C214.54 104.491 230.158 114.314 239.894 131.851C273.15 191.781 209.011 201.72 174.784 209.915C155.428 214.537 143.812 230.506 158.492 258.688C214.436 253.98 258.39 207.07 258.39 149.903C258.39 111.144 238.177 77.1018 207.722 57.7266"
                    fill="var(--background-color)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M191.927 105.506C177.373 95.2564 162.525 82.7355 160.972 63.8935C160.307 55.8558 164.875 48.4915 171.632 43.0459C157.459 40.0907 142.84 39.9776 128.623 42.713C114.406 45.4483 100.873 50.9779 88.8086 58.9812C96.9207 91.2874 127.561 94.6763 155.629 103.143C195.722 115.235 214.322 153.656 158.871 159.818C81.4401 168.423 72.2954 187.545 127.861 256.987C133.447 258.095 139.112 258.763 144.803 258.984C114.222 212.039 131.18 198.9 188.95 180.965C245.166 163.485 224.646 128.565 191.925 105.51"
                    fill="var(--background-color)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M123.936 118.509C100.162 114.213 67.312 112.348 58.2119 97.5595C57.3549 96.166 56.5939 94.7157 55.9342 93.2188C45.5264 110.296 40.0342 129.915 40.0626 149.915C40.0626 197.952 71.1131 238.761 114.236 253.341C95.0693 233.567 63.4812 210.21 71.1047 172.043C76.204 146.547 105.038 150.269 127.675 148.404C160.298 145.722 156.82 124.463 123.927 118.509"
                    fill="var(--background-color)"
                  />
                </svg>
                SnusCo
              </LogoDiv>
              <List>
                <ListItem onClick={() => navigate(goToSupport())}>
                  {t("FOOTER.SUPPORT")}
                </ListItem>

                <ListItem onClick={() => navigate(goToSupport())}>
                  {t("FOOTER.FAQS")}
                </ListItem>
                <ListItem onClick={() => navigate(goToPrivacyPolicy())}>
                  {t("FOOTER.PRIVACY_POLICY")}
                </ListItem>
              </List>
            </GridItems>

            <GridItems>
              <CategoriesTitle>{t("FOOTER.CATEGORIES")}</CategoriesTitle>
              {categories && categories.length > 0 && (
                <List>
                  {categories.map((category, index) => (
                    <ListItem
                      key={index}
                      onClick={() => navigate(goToCategory(category.name))}
                    >
                      {category.name}
                    </ListItem>
                  ))}
                </List>
              )}
            </GridItems>

            <GridItems>
              <List style={{ display: "block" }}>
                <ListItem>info@snusco.com</ListItem>
                <ListItem>
                  21000 Love Braculjevica 128. Novi Sad, Vojvodina Srbija
                </ListItem>
              </List>
            </GridItems>
          </GridContainer>
          <Wraning>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1208_12314)">
                <path
                  d="M12 2C17.523 2 22 6.477 22 12C22.0021 14.6255 20.9715 17.1464 19.1309 19.0186C17.2902 20.8908 14.7872 21.9641 12.162 22.0066C9.53689 22.0491 7.00034 21.0576 5.1 19.246C3.19966 17.4344 2.08799 14.9482 2.005 12.324L2 12L2.004 11.72C2.152 6.327 6.57 2 12 2ZM12.01 15L11.883 15.007C11.64 15.0359 11.4159 15.153 11.2534 15.336C11.0909 15.519 11.0011 15.7552 11.0011 16C11.0011 16.2448 11.0909 16.481 11.2534 16.664C11.4159 16.847 11.64 16.9641 11.883 16.993L12 17L12.127 16.993C12.37 16.9641 12.5941 16.847 12.7566 16.664C12.9191 16.481 13.0089 16.2448 13.0089 16C13.0089 15.7552 12.9191 15.519 12.7566 15.336C12.5941 15.153 12.37 15.0359 12.127 15.007L12.01 15ZM12 7C11.7551 7.00003 11.5187 7.08996 11.3356 7.25272C11.1526 7.41547 11.0357 7.63975 11.007 7.883L11 8V12L11.007 12.117C11.0359 12.36 11.153 12.5841 11.336 12.7466C11.519 12.9091 11.7552 12.9989 12 12.9989C12.2448 12.9989 12.481 12.9091 12.664 12.7466C12.847 12.5841 12.9641 12.36 12.993 12.117L13 12V8L12.993 7.883C12.9643 7.63975 12.8474 7.41547 12.6644 7.25272C12.4813 7.08996 12.2449 7.00003 12 7Z"
                  fill="#7B7E82"
                />
              </g>
              <defs>
                <clipPath id="clip0_1208_12314">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {t("FOOTER.WARNING")}
          </Wraning>
          <div>
            <div
              class="trustpilot-widget"
              data-locale="en-US"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="66d39814fee7b0c8732e417b"
              data-style-height="52px"
              data-style-width="100%"
            >
              <a
                href="https://www.trustpilot.com/review/snusco.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div>
          </div>
        </Wrapper>
      </Container>
      <ContainerToolbar>
        <WrapperToolbar>
          <span>
            <Text>{t("FOOTER.COPYRIGHT")}</Text>
          </span>
          <IconContainer>
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3 9.6C17.6314 9.15817 17.5418 8.53137 17.1 8.2C16.6582 7.86863 16.0314 7.95817 15.7 8.4L13.3918 11.4776L11.2071 9.29289C11.0021 9.08791 10.7183 8.98197 10.4291 9.00252C10.1399 9.02307 9.87393 9.16809 9.7 9.4L6.7 13.4C6.36863 13.8418 6.45817 14.4686 6.9 14.8C7.34183 15.1314 7.96863 15.0418 8.3 14.6L10.6082 11.5224L12.7929 13.7071C12.9979 13.9121 13.2817 14.018 13.5709 13.9975C13.8601 13.9769 14.1261 13.8319 14.3 13.6L17.3 9.6Z"
                fill="#696969"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                fill="#696969"
              />
            </svg>

            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                fill="#696969"
              />
              <path
                d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                fill="#696969"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                fill="#696969"
              />
            </svg>
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z"
                fill="#696969"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                fill="#696969"
              />
            </svg>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_21757)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.0259 15.0564V22.3942C13.0259 22.4983 13.11 22.5864 13.2181 22.5864H15.9442C16.0483 22.5864 16.1364 22.5023 16.1364 22.3942V14.9363H18.114C18.214 14.9363 18.2941 14.8602 18.3061 14.7601L18.4943 12.5143C18.5023 12.4022 18.4142 12.3062 18.3021 12.3062H16.1364V10.7129C16.1364 10.3406 16.4406 10.0364 16.8129 10.0364H18.3341C18.4382 10.0364 18.5263 9.95229 18.5263 9.8442V7.5984C18.5263 7.49432 18.4422 7.40625 18.3341 7.40625H15.7601C14.2509 7.40625 13.0259 8.63123 13.0259 10.1404V12.3062H11.6648C11.5607 12.3062 11.4727 12.3902 11.4727 12.4983V14.7441C11.4727 14.8482 11.5567 14.9363 11.6648 14.9363H13.0259V15.0564Z"
                  fill="#696969"
                />
                <path
                  d="M15 0C6.72938 0 0 6.72938 0 15C0 23.2706 6.72938 30 15 30C23.2706 30 30 23.2706 30 15C30 6.72938 23.2706 0 15 0ZM28.0745 15C28.0745 22.2098 22.2098 28.0745 15 28.0745C7.79023 28.0745 1.92154 22.2098 1.92154 15C1.92154 7.79023 7.79023 1.92554 15 1.92554C22.2098 1.92554 28.0745 7.79023 28.0745 15.004V15Z"
                  fill="#696969"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_21757">
                  <rect width="30" height="30" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </IconContainer>
        </WrapperToolbar>
      </ContainerToolbar>
    </div>
  );
};

export default FooterMain;
