import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import HamburgerMenuData from "./HamburgerMenuData";
import ToggleSwitch from "../../../components/animations/ToggleSwitch";
import ChangePreferencesIcon from "../../../components/account/ChangePreferencesIcon";
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;
const slideOutRight = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;
const ContainerX = styled.div``;
const Container = styled.div`
  margin-top: var(--spacing-xxs);
  background-color: var(--transparent-color);
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--zindex-default);
  min-width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
`;
const Wrapper = styled.div`
  background-color: var(--background-color);

  width: 80%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  /* animation: ${(props) => (props.$isOpen ? slideInFromRight : slideOutRight)}
    0.5s ease-in forwards; */
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideInFromRight} 0.5s ease-out forwards; // Duže trajanje za slide-in
        `
      : css`
          animation: ${slideOutRight} 0.2s ease-in forwards; // Kraće trajanje za slide-out
        `}
`;
// Styled komponenta za hamburger linije
const HamburgerIcon = styled.div`
  width: var(--font-size-xlarge);
  height: 2px;
  background-color: var(--text-color);
  transition: all 0.5s ease-in-out;
  transform-origin: 1px;

  &:nth-child(1) {
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(40deg)" : "rotate(0)")};
  }

  &:nth-child(2) {
    transform: ${({ $isOpen }) =>
      $isOpen ? "translateX(100%)" : "translateX(0)"};
    opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
  }

  &:nth-child(3) {
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(-40deg)" : "rotate(0)")};
  }
`;
const HamburgerMidIcon = styled(HamburgerIcon)`
  width: calc(var(--font-size-xlarge) - 5px);
  height: 1.8px;
`;

// Styled komponenta za kontejner koji drži hamburger ikonu
const HamburgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 25px;
  cursor: pointer;
`;
const Span = styled.span`
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const XDiv = styled.div`
  padding: 0 var(--spacing-md);
  cursor: pointer;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--transparent-border-color);
`;
const MiddleDiv = styled.div``;

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeVisible, setShouldBeVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldBeVisible(true);
    } else {
      const timer = setTimeout(() => {
        setShouldBeVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ContainerX>
      <HamburgerContainer onClick={toggleMenu}>
        <HamburgerIcon key="1" $isOpen={isOpen} />
        <HamburgerMidIcon key="2" $isOpen={isOpen} />
        <HamburgerIcon key="3" $isOpen={isOpen} />
      </HamburgerContainer>
      <Span>Menu</Span>
      <Container
        onClick={toggleMenu}
        $isOpen={isOpen}
        $shouldBeVisible={shouldBeVisible}
      >
        <Wrapper
          $isOpen={isOpen}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <XDiv>
            <ChangePreferencesIcon />
            <ToggleSwitch />
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              viewBox="0 0 64 64"
              className="svg-icon"
              style={{
                width: "calc(var(--font-size-xxlarge) + 5px)",
                height: "calc(var(--font-size-xxlarge) + 5px)",
              }}
              onClick={toggleMenu}
            >
              <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
            </svg>
          </XDiv>
          <MiddleDiv>
            <HamburgerMenuData />{" "}
          </MiddleDiv>
        </Wrapper>
      </Container>
    </ContainerX>
  );
};

export default HamburgerMenu;
