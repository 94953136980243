import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ProductContext } from "../../../context/ProductContext";
import { freeShippingThreshold } from "../../../utils/global_const";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: ${(props) => (props.percentage == 0 ? "none" : "flex")};
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    #5693bf -20.06%,
    #000 49.64%,
    #5693bf 120.69%
  );
  box-shadow: 0 2px 4px rgba(251, 225, 225, 0.1);
  position: fixed;
  position: ${(props) => props.position};

  width: 100%;

  height: var(--navbar-mini);
  z-index: var(--zindex-fixed);
  font-size: var(--font-size-small);
  color: var(--background-color);

  top: calc(var(--navbar-height) * 2);
  @media (min-width: 768px) {
    top: ${(props) =>
      props.$isScrolled ? "var(--navbar-height-desktop) " : "initial"};
  }
`;

const DelieveryBar = ({ isScrolled, position }) => {
  const { t } = useTranslation();
  const cartItems = useSelector((state) => state.cart.itemsList);
  const { setActivateConffete } = useContext(ProductContext);

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );

  // Izračunavanje procenta napretka ka besplatnoj dostavi
  let percentage = (totalPrice / freeShippingThreshold) * 100;
  percentage = percentage > 100 ? 100 : percentage;

  useEffect(() => {
    const isConffeteActivated = localStorage.getItem("isConffeteActivated");

    if (percentage === 100 && isConffeteActivated !== "true") {
      setTimeout(() => {
        setActivateConffete(true);
      }, 300); // 300ms = 0.3 sekunde
      localStorage.setItem("isConffeteActivated", "true");
    }
    if (percentage < 100 && isConffeteActivated === "true") {
      localStorage.setItem("isConffeteActivated", "false");
    }
  }, [percentage, setActivateConffete]);

  return (
    <>
      {totalPrice != 0 && (
        <Container $isScrolled={isScrolled} position={position}>
          {/* Lokalizovani tekstovi */}
          <div>
            {totalPrice >= freeShippingThreshold ? (
              <span>{t("HEADER.FREE_SHIPPING_UNLOCKED")}</span>
            ) : (
              <span>
                {t("HEADER.SHOP_MORE_TO_REACH_SHIPPING", {
                  amount: `€${(freeShippingThreshold - totalPrice).toFixed(2)}`,
                })}
              </span>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default DelieveryBar;
