import styled from "styled-components";
import HeroBlog from "../../components/pages/HeroBlog";

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  min-height: auto;
  padding-bottom: 40px;
`;
const HeroTitle = styled.h1`
  color: var(--text-color);
  //font-family: Larken;
  font-size: var(--font-size-large);
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.8px;
  text-transform: uppercase;

  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
  @media (min-width: 1025px) {
    margin-top: 0;
    width: 50%;
    font-size: var(--font-size-xxlarge);
    line-height: 46px;
  }
`;
const BlogSubtitle = styled.h6`
  font-size: var(--font-size-base);
  color: var(--primary-color);
  font-weight: 400;
  padding: 12px 0;
`;
const BodyContainer = styled.div`
  padding: 24px 0;
  width: 90%;
  @media (min-width: 1025px) {
    width: 50%;
  }
`;
const Subtitle = styled.h2`
  font-family: "Satoshi";
`;
const Picture = styled.picture`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -24px 0 12px 0;
`;
const Image = styled.img`
  display: block;
  min-width: 100%;
  @media (min-width: 1025px) {
    min-width: 50%;
    max-width: 50%;
  }
  height: auto;
  margin: 0 auto;
`;

const BlogMain = ({ blog }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // Dodajte +1 jer getMonth() vraća mesec od 0 do 11
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Koristite `padStart(2, '0')` da biste osigurali da su dan i mesec uvek prikazani sa dve cifre
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };
  return (
    <>
      {blog && (
        <div>
          <Container className="container-wrapper">
            <BlogSubtitle>{formatDate(blog.published_date)}</BlogSubtitle>
            <HeroTitle>{blog.title}</HeroTitle>
            <HeroBlog />
            {blog.subtitles.map((subtitle, index) => (
              <BodyContainer key={index}>
                {index === Math.floor(blog.subtitles.length / 2) && (
                  <Picture>
                    <Image
                      src={require(`../../assets/images/blogs/blog${blog.id}.webp`)}
                      alt="Description of the image"
                    />
                  </Picture>
                )}
                <Subtitle>{subtitle}</Subtitle>
                <p
                  dangerouslySetInnerHTML={{ __html: blog.paragraphs[index] }}
                />
              </BodyContainer>
            ))}
          </Container>
        </div>
      )}
    </>
  );
};

export default BlogMain;
