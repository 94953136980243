import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIService from "../services/APIService"; // Pretpostavljajući da ovo postoji

import styled, { keyframes } from "styled-components";

import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useNavigation } from "../utils/navigation";
import ButtonLoading from "../components/animations/ButtonLoading";
import SimpleContext from "../context/SimpleContext";
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Container = styled.div`
  width: 100vw;
  min-height: calc(100vh - var(--navbar-height));
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--transparent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--zindex-fixed) + 2);
  @media (min-width: 768px) {
    min-height: 100vh;
    z-index: 99999;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  min-height: calc(100vh - var(--navbar-height) - 120px);
  background-color: #1a2c38;
  background-color: var(--background-color);
  position: relative;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;

  padding: 60px 0;
  @media (min-width: 768px) {
    width: 700px;
    min-height: auto;
  }
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //gap: 8px;
  margin: 10px 0;
`;

const Input = styled.input`
  outline: none;
  padding: var(--spacing-sm) 0 var(--spacing-sm) var(--spacing-xs);
  border: 1px solid #ccc;
  width: calc(100% - 8px);
  font-size: var(--font-size-small);
  color: var(--text-color);
  background-color: var(--background-color);
  margin: var(--spacing-xxs) 0 0 0;
  ::placeholder {
    font-size: var(--font-size-small);
    color: var(--p-color);
  }
`;
const Label = styled.label`
  font-size: var(--font-size-base);
  text-transform: capitalize;
  color: var(--text-color);
  text-align: left;
  min-width: 100%;
  font-family: "Larken";
`;

const FormWrapper = styled.div`
  min-width: 90%;
  max-width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-xs);
`;
const FormContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 500px;
  }
`;
const Title = styled.h1`
  font-size: var(--font-size-xxlarge);
  text-align: center;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: var(--text-color);
  text-transform: uppercase;
  margin-bottom: var(--spacing-sm);
`;
const Subtitle = styled.div`
  font-size: var(--font-size-small);
  color: var(--p2-color);
  text-align: center;

  margin-bottom: var(--spacing-xl);
`;
const SmallTitle = styled.div`
  font-size: 20px;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: var(--spacing-xl);
  font-size: var(--font-size-small);
  margin: 12px 0;
  color: var(--p-color);
  font-family: "Larken";
`;

const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 90%;
  text-align: center;
  margin: var(--spacing-xs) 0;
`;
const Button = styled.button`
  width: 100%;
  height: 44px;
  background-color: var(--text-color);
  text-transform: capitalize;
  border-radius: 0;
  border-color: var(--text-color);
  &:hover {
    background-color: var(--background-color);
    color: var(--text-color);
  }
`;
const PasswordRules = styled.div`
  /* Add password rules box styles here */
  margin-top: 10px;
  border-left: 5px solid red; /* Change color based on password strength */

  width: 100%;
`;

const Rule = styled.div`
  font-weight: 700;
  font-size: var(--font-size-small);
  color: ${(props) =>
    props.$isValid ? "var(--success-color)" : "var(--error-color)"};
  margin: 10px 0;
  display: flex;
  align-items: center;
  &::before {
    content: "${(props) => (props.$isValid ? "" : "❌")}";
    margin-right: 10px;
  }
  padding-top: 10px;
  padding-left: 10px;
`;
const StrengthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;
const StrengthText = styled.p`
  font-weight: bold;
  color: ${(props) => props.$strengthColor};
  margin: 0;
  padding: 0;
  font-size: var(--font-size-small);
`;

const EyeSVG = styled.svg`
  position: absolute;
  right: 40px;
  top: 0;
  width: var(--font-size-xlarge);
  height: var(--font-size-xlarge);
`;
const EyeSpan = styled.span`
  position: relative;
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--primary-color);
  font-weight: 100;
  text-transform: capitalize;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-lg);
`;

function PasswordReset() {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // const [passwordValidations, setPasswordValidations] = useState({
  //   minLength: false,
  //   uppercase: false,
  //   lowercase: false,
  //   number: false,
  //   specialChar: false,
  // });
  const { uid, token } = useContext(SimpleContext);
  const { goToHome } = useNavigation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [errorPasswordText2, setErrorPasswordText2] = useState("");

  const [isAnimating, setIsAnimating] = useState(false);

  // const validatePassword = (password) => {
  //   const validations = {
  //     minLength: password.length >= 8,
  //     uppercase: /[A-Z]/.test(password),
  //     lowercase: /[a-z]/.test(password),
  //     number: /[0-9]/.test(password),
  //     specialChar: /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/.test(password),
  //   };
  //   setPasswordValidations(validations);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ovde šaljete zahtev na backend sa novom lozinkom, UID-om i tokenom
    if (
      newPassword === confirmPassword
      //passwordValidations.minLength
      //atLeastThreeOtherRulesPassed()
    ) {
      setIsAnimating(true);
      try {
        const response = await APIService.ResetPassword(
          uid,
          token,
          newPassword
        );
        // Obrada uspešnog zahteva
        navigate(goToHome());
      } catch (error) {
        // Obrada grešaka
      }
    }
  };
  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    //validatePassword(password);
  };

  // const getPasswordStrengthText = (validations, t) => {
  //   //const { t } = useTranslation(); // Ensure you're using this hook at the top level of your component
  //   const passedRules = Object.values(validations).filter(Boolean).length;
  //   const totalRules = Object.keys(validations).length;
  //   const strength = passedRules / totalRules;

  //   if (strength === 1) {
  //     return "PASSWORD_STRENGTH_STRONG";
  //   } else if (strength >= 0.75) {
  //     return "PASSWORD_STRENGTH_GOOD";
  //   } else if (strength >= 0.5) {
  //     return "PASSWORD_STRENGTH_FAIR";
  //   } else {
  //     return "PASSWORD_STRENGTH_WEAK";
  //   }
  // };

  // const getPasswordStrengthColor = (strengthText, t) => {
  //   // Use the translated text to determine the color
  //   switch (strengthText) {
  //     case "PASSWORD_STRENGTH_STRONG":
  //       return "#4cae04";
  //     case "PASSWORD_STRENGTH_STRONG":
  //       return "#4cae04";
  //     case "PASSWORD_STRENGTH_FAIR":
  //       return "#991f39";
  //     default:
  //       return "#991f39";
  //   }
  // };

  // const atLeastThreeOtherRulesPassed = () => {
  //   const { uppercase, lowercase, number, specialChar } = passwordValidations;
  //   const validations = [uppercase, lowercase, number, specialChar];

  //   return validations.filter(Boolean).length >= 3;
  // };
  // useEffect(() => {
  //   if (confirmPassword !== newPassword) {
  //     setErrorPasswordText2(t("Register.PASSWORD_DO_NOT_MATCH"));
  //   } else {
  //     setErrorPasswordText2("");
  //   }
  // }, [confirmPassword, newPassword]);

  // // Inside your PasswordResetForm component
  // const strengthText = getPasswordStrengthText(passwordValidations, t);
  // const strengthColor = getPasswordStrengthColor(strengthText, t);

  return (
    <div>
      <Container>
        <Wrapper>
          <FormContainer>
            <Logo>
              <svg
                width="24"
                height="24"
                viewBox="0 0 298 298"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M148.707 0C66.7245 0 0 66.7245 0 148.707C0 230.689 66.7245 297.414 148.707 297.414C230.689 297.414 297.414 230.689 297.414 148.707C297.414 66.7245 230.689 0 148.707 0ZM294.707 148.707C294.707 188.275 278.843 224.204 253.18 250.567L244.688 242.074C268.158 217.904 282.669 184.962 282.669 148.707C282.669 112.452 267.878 78.8562 243.988 54.6394L252.48 46.1472C278.563 72.557 294.661 108.766 294.661 148.707H294.707ZM17.3576 148.707C17.3576 76.2899 76.2432 17.4043 148.66 17.4043C221.077 17.4043 279.963 76.2899 279.963 148.707C279.963 221.124 221.077 280.009 148.66 280.009C76.2432 280.009 17.3576 221.124 17.3576 148.707ZM250.52 44.2808L242.028 52.7731C217.858 29.2562 184.915 14.7447 148.614 14.7447C112.312 14.7447 79.416 29.2562 55.2458 52.7731L46.7536 44.2808C73.0701 18.6176 108.999 2.75295 148.567 2.75295C188.135 2.75295 224.11 18.6175 250.427 44.3275L250.52 44.2808ZM44.7939 46.1939L53.2862 54.6862C29.3494 78.9029 14.558 112.172 14.558 148.8C14.558 185.429 29.0693 218.044 52.5862 242.214L44.1406 250.707C18.4307 224.39 2.56627 188.415 2.56627 148.847C2.56627 109.279 18.7107 72.6504 44.7939 46.2872V46.1939ZM46.0539 252.667L54.5461 244.174C78.7629 268.064 111.985 282.856 148.614 282.856C185.242 282.856 218.511 268.064 242.728 244.128L251.22 252.62C224.857 278.703 188.602 294.848 148.66 294.848C108.719 294.848 72.5102 278.703 46.147 252.667"
                  fill="var(--primary-color)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M207.722 57.7351C202.622 66.9627 200.922 78.8759 205.416 87.3302C214.54 104.491 230.158 114.314 239.894 131.851C273.15 191.781 209.011 201.72 174.784 209.915C155.428 214.537 143.812 230.506 158.492 258.688C214.436 253.98 258.39 207.07 258.39 149.903C258.39 111.144 238.177 77.1018 207.722 57.7266"
                  fill="var(--primary-color)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M191.927 105.506C177.373 95.2564 162.525 82.7355 160.972 63.8935C160.307 55.8558 164.875 48.4915 171.632 43.0459C157.459 40.0907 142.84 39.9776 128.623 42.713C114.406 45.4483 100.873 50.9779 88.8086 58.9812C96.9207 91.2874 127.561 94.6763 155.629 103.143C195.722 115.235 214.322 153.656 158.871 159.818C81.4401 168.423 72.2954 187.545 127.861 256.987C133.447 258.095 139.112 258.763 144.803 258.984C114.222 212.039 131.18 198.9 188.95 180.965C245.166 163.485 224.646 128.565 191.925 105.51"
                  fill="var(--primary-color)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M123.936 118.509C100.162 114.213 67.312 112.348 58.2119 97.5595C57.3549 96.166 56.5939 94.7157 55.9342 93.2188C45.5264 110.296 40.0342 129.915 40.0626 149.915C40.0626 197.952 71.1131 238.761 114.236 253.341C95.0693 233.567 63.4812 210.21 71.1047 172.043C76.204 146.547 105.038 150.269 127.675 148.404C160.298 145.722 156.82 124.463 123.927 118.509"
                  fill="var(--primary-color)"
                />
              </svg>
              Snusplus
            </Logo>
            <Title>PASSWORD_RESET_TITLE</Title>
            <Subtitle>PASSWORD_INSTRUCTIONS</Subtitle>
            <Form onSubmit={handleSubmit}>
              <FormWrapper>
                <Label>New Password</Label>
                <EyeSpan>
                  <Input
                    type={isVisible ? "text" : "password"}
                    value={newPassword}
                    onChange={handlePasswordChange}
                    placeholder="Enter new password"
                    required
                  />
                  {isVisible ? (
                    <EyeSVG
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      <g id="style=fill">
                        <g id="eye-close">
                          <path
                            id="vector"
                            d="M15.6487 5.39465C14.4859 4.95229 13.2582 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C3.34474 14.9049 3.83895 15.4807 4.36664 16M19.3248 7.69629C19.9692 8.2894 20.5676 8.96401 21.11 9.71356C22.01 10.9532 22.01 13.0368 21.11 14.2764C18.82 17.4413 15.53 19.27 12 19.27C10.6143 19.27 9.26561 18.9882 7.99988 18.4544"
                            stroke="var(--text-color-hover)"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Subtract"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.742 5.57955C15.9962 4.53817 14.0413 3.96997 11.9998 3.96997C8.19594 3.96997 4.69275 5.94267 2.28258 9.27342C1.72076 10.0475 1.46484 11.0419 1.46484 11.995C1.46484 12.9478 1.72065 13.942 2.28222 14.716C3.02992 15.7494 3.88282 16.6521 4.81727 17.4057L8.72514 13.83C8.42248 13.2886 8.25 12.664 8.25 12C8.25 9.92657 9.92657 8.25 12 8.25C12.7834 8.25 13.5113 8.48997 14.1129 8.90023L17.742 5.57955ZM6.0677 18.2947C7.86114 19.4096 9.88397 20.02 11.9998 20.02C15.8037 20.02 19.3069 18.0473 21.7171 14.7165C22.2789 13.9424 22.5348 12.948 22.5348 11.995C22.5348 11.0421 22.279 10.0479 21.7175 9.27392C20.9248 8.17837 20.0139 7.22973 19.0129 6.44988L6.0677 18.2947ZM15 11.25C15.4142 11.25 15.75 11.5858 15.75 12C15.75 14.0734 14.0734 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15C11.25 14.5858 11.5858 14.25 12 14.25C13.245 14.25 14.25 13.245 14.25 12C14.25 11.5858 14.5858 11.25 15 11.25Z"
                            fill="var(--text-color-hover)"
                          />
                          <path
                            id="vector (Stroke)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M22.5533 2.19366C22.8329 2.49926 22.8119 2.97366 22.5063 3.25328L19.3012 6.18591C18.9956 6.46553 18.5212 6.44447 18.2416 6.13888C17.962 5.83329 17.9831 5.35888 18.2886 5.07926L21.4937 2.14663C21.7993 1.86701 22.2737 1.88807 22.5533 2.19366ZM5.73136 17.5857C6.01098 17.8913 5.98992 18.3657 5.68433 18.6454L2.5063 21.5533C2.2007 21.8329 1.7263 21.8118 1.44668 21.5062C1.16706 21.2006 1.18812 20.7262 1.49371 20.4466L4.67175 17.5387C4.97734 17.2591 5.45175 17.2801 5.73136 17.5857Z"
                            fill="var(--text-color-hover)"
                          />
                        </g>
                      </g>
                    </EyeSVG>
                  ) : (
                    <EyeSVG
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      <g id="style=fill">
                        <g id="eye-open">
                          <path
                            id="Subtract"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.0001 3.96997C8.19618 3.96997 4.69299 5.94267 2.28282 9.27342C1.721 10.0475 1.46509 11.0419 1.46509 11.995C1.46509 12.9478 1.7209 13.942 2.28246 14.716C4.69264 18.0471 8.19599 20.02 12.0001 20.02C15.804 20.02 19.3072 18.0473 21.7174 14.7165C22.2792 13.9424 22.5351 12.948 22.5351 11.995C22.5351 11.0421 22.2793 10.0479 21.7177 9.27392C19.3075 5.94286 15.8042 3.96997 12.0001 3.96997ZM9.75012 12C9.75012 10.755 10.7551 9.75 12.0001 9.75C13.2451 9.75 14.2501 10.755 14.2501 12C14.2501 13.245 13.2451 14.25 12.0001 14.25C10.7551 14.25 9.75012 13.245 9.75012 12ZM12.0001 8.25C9.92669 8.25 8.25012 9.92657 8.25012 12C8.25012 14.0734 9.92669 15.75 12.0001 15.75C14.0736 15.75 15.7501 14.0734 15.7501 12C15.7501 9.92657 14.0736 8.25 12.0001 8.25Z"
                            fill="var(--text-color-hover)"
                          />
                        </g>
                      </g>
                    </EyeSVG>
                  )}
                </EyeSpan>
              </FormWrapper>
              {/* <StrengthContainer>
                <SmallTitle $strengthColor="var(--text-color)">
                  Strenght
                </SmallTitle>
                <SmallTitle $strengthColor={strengthColor}>
                  {strengthText}
                </SmallTitle>
              </StrengthContainer>
              {newPassword &&
                (!passwordValidations.minLength ||
                  !atLeastThreeOtherRulesPassed()) && (
                  <PasswordRules>
                    <StrengthText $strengthColor="#1f1f1f">
                      {t("PasswordReset.RULES_MIN_LENGTH")}
                    </StrengthText>
                    <Rule $isValid={passwordValidations.minLength}>
                      {t("PasswordReset.RULES_MIN_LENGTH")}
                    </Rule>
                    <StrengthText $strengthColor="#1f1f1f">
                      {t("PasswordReset.RULES_OPTIONS")}
                    </StrengthText>
                    <Rule $isValid={passwordValidations.uppercase}>
                      {t("PasswordReset.RULES_UPPERCASE")}
                    </Rule>
                    <Rule $isValid={passwordValidations.lowercase}>
                      {t("PasswordReset.RULES_LOWERCASE")}
                    </Rule>
                    <Rule $isValid={passwordValidations.number}>
                      {t("PasswordReset.RULES_NUMBER")}
                    </Rule>
                    <Rule $isValid={passwordValidations.specialChar}>
                      {t("PasswordReset.RULES_SPECIAL_CHAR")}
                    </Rule>
                  </PasswordRules>
                )}
              <br /> */}
              <FormWrapper>
                <Label>Submit Password</Label>
                <EyeSpan>
                  <Input
                    type={isVisible2 ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    required
                  />
                  {isVisible2 ? (
                    <EyeSVG
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setIsVisible2(!isVisible2)}
                    >
                      <g id="style=fill">
                        <g id="eye-close">
                          <path
                            id="vector"
                            d="M15.6487 5.39465C14.4859 4.95229 13.2582 4.71997 12 4.71997C8.46997 4.71997 5.17997 6.54861 2.88997 9.71356C1.98997 10.9532 1.98997 13.0368 2.88997 14.2764C3.34474 14.9049 3.83895 15.4807 4.36664 16M19.3248 7.69629C19.9692 8.2894 20.5676 8.96401 21.11 9.71356C22.01 10.9532 22.01 13.0368 21.11 14.2764C18.82 17.4413 15.53 19.27 12 19.27C10.6143 19.27 9.26561 18.9882 7.99988 18.4544"
                            stroke="var(--text-color-hover)"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Subtract"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.742 5.57955C15.9962 4.53817 14.0413 3.96997 11.9998 3.96997C8.19594 3.96997 4.69275 5.94267 2.28258 9.27342C1.72076 10.0475 1.46484 11.0419 1.46484 11.995C1.46484 12.9478 1.72065 13.942 2.28222 14.716C3.02992 15.7494 3.88282 16.6521 4.81727 17.4057L8.72514 13.83C8.42248 13.2886 8.25 12.664 8.25 12C8.25 9.92657 9.92657 8.25 12 8.25C12.7834 8.25 13.5113 8.48997 14.1129 8.90023L17.742 5.57955ZM6.0677 18.2947C7.86114 19.4096 9.88397 20.02 11.9998 20.02C15.8037 20.02 19.3069 18.0473 21.7171 14.7165C22.2789 13.9424 22.5348 12.948 22.5348 11.995C22.5348 11.0421 22.279 10.0479 21.7175 9.27392C20.9248 8.17837 20.0139 7.22973 19.0129 6.44988L6.0677 18.2947ZM15 11.25C15.4142 11.25 15.75 11.5858 15.75 12C15.75 14.0734 14.0734 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15C11.25 14.5858 11.5858 14.25 12 14.25C13.245 14.25 14.25 13.245 14.25 12C14.25 11.5858 14.5858 11.25 15 11.25Z"
                            fill="var(--text-color-hover)"
                          />
                          <path
                            id="vector (Stroke)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M22.5533 2.19366C22.8329 2.49926 22.8119 2.97366 22.5063 3.25328L19.3012 6.18591C18.9956 6.46553 18.5212 6.44447 18.2416 6.13888C17.962 5.83329 17.9831 5.35888 18.2886 5.07926L21.4937 2.14663C21.7993 1.86701 22.2737 1.88807 22.5533 2.19366ZM5.73136 17.5857C6.01098 17.8913 5.98992 18.3657 5.68433 18.6454L2.5063 21.5533C2.2007 21.8329 1.7263 21.8118 1.44668 21.5062C1.16706 21.2006 1.18812 20.7262 1.49371 20.4466L4.67175 17.5387C4.97734 17.2591 5.45175 17.2801 5.73136 17.5857Z"
                            fill="var(--text-color-hover)"
                          />
                        </g>
                      </g>
                    </EyeSVG>
                  ) : (
                    <EyeSVG
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setIsVisible2(!isVisible2)}
                    >
                      <g id="style=fill">
                        <g id="eye-open">
                          <path
                            id="Subtract"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.0001 3.96997C8.19618 3.96997 4.69299 5.94267 2.28282 9.27342C1.721 10.0475 1.46509 11.0419 1.46509 11.995C1.46509 12.9478 1.7209 13.942 2.28246 14.716C4.69264 18.0471 8.19599 20.02 12.0001 20.02C15.804 20.02 19.3072 18.0473 21.7174 14.7165C22.2792 13.9424 22.5351 12.948 22.5351 11.995C22.5351 11.0421 22.2793 10.0479 21.7177 9.27392C19.3075 5.94286 15.8042 3.96997 12.0001 3.96997ZM9.75012 12C9.75012 10.755 10.7551 9.75 12.0001 9.75C13.2451 9.75 14.2501 10.755 14.2501 12C14.2501 13.245 13.2451 14.25 12.0001 14.25C10.7551 14.25 9.75012 13.245 9.75012 12ZM12.0001 8.25C9.92669 8.25 8.25012 9.92657 8.25012 12C8.25012 14.0734 9.92669 15.75 12.0001 15.75C14.0736 15.75 15.7501 14.0734 15.7501 12C15.7501 9.92657 14.0736 8.25 12.0001 8.25Z"
                            fill="var(--text-color-hover)"
                          />
                        </g>
                      </g>
                    </EyeSVG>
                  )}
                </EyeSpan>
                <motion.h3
                  className="input-required-text"
                  style={{ padding: "0", fontSize: "14px", fontWeight: "bold" }}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{
                    opacity: errorPasswordText2 !== "" ? 1 : 0,
                    y: errorPasswordText2 !== "" ? 0 : 10,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {errorPasswordText2}
                </motion.h3>
              </FormWrapper>
              <ButtonWrapper>
                <Button
                  type="submit"
                  // disabled={
                  //   !(
                  //     passwordValidations.minLength &&
                  //     atLeastThreeOtherRulesPassed()
                  //   )
                  // }
                >
                  Change Password
                </Button>
                {isAnimating && (
                  <ButtonLoading
                    isAnimating={isAnimating}
                    onComplete={handleAnimationComplete}
                  />
                )}
              </ButtonWrapper>
            </Form>
          </FormContainer>
        </Wrapper>
      </Container>
    </div>
  );
}

export default PasswordReset;
