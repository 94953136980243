import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../../context/ProductContext";
import OrderDetails from "./OrderDetails";

const Component = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 14px;

  @media only screen and (min-width: 768px) {
    margin: 0;
    justify-content: flex-start;
  }
`;

const SelectedSort = styled.h2`
  border: none;
  font-family: "Satoshi";
  font-size: var(--font-size-base);
  font-weight: 100;
  text-transform: capitalize;
  color: var(--text-color);
  display: flex;
  gap: 14px;
  cursor: pointer;
  white-space: nowrap;
  background-color: var(--background-color);
  border: 1px solid var(--transparent-border-color);
  padding: var(--spacing-sm) var(--spacing-md);
  @media (min-width: 768px) {
    background-color: var(--background-color-hover);
    border: none;
    position: relative;
  }
`;

const OptionsContainer = styled.div`
  background-color: var(--background-color-hover);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  @media (min-width: 768px) {
    /* border: 1px solid var(--border-color);
    display: flex;
    position: static;
    z-index: 1; */
    position: absolute;
    left: 0;
    top: 100%;
    width: fit-content;
  }
`;

const Option = styled.div`
  padding: var(--spacing-sm) var(--spacing-xs);
  text-transform: capitalize;
  white-space: nowrap;
  background-color: var(--background-color-hover);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  gap: var(--spacing-xxs);
`;
const OpenSortTitle = styled.div`
  text-transform: uppercase;
  padding: var(--spacing-md) var(--spacing-xs);
  border-bottom: 1px solid var(--transparent-border-color);

  @media (min-width: 768px) {
    display: none;
  }
`;
const MaskContainer = styled.div`
  background-color: var(--transparent-color);
  position: fixed;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  right: 0;
  top: 0;
  z-index: 1;
  min-width: 100%;
  height: 100vh;
  z-index: 9999;
  @media (min-width: 768px) {
    display: none;
  }
`;

const OrderHistoryFilter = ({ orders }) => {
  const { t } = useTranslation();
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isOpenYear, setIsOpenYear] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);

  useEffect(() => {
    let filtered = [...orders];

    // Filtriranje po godini
    if (selectedYears.length > 0) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.created_at);
        const orderYear = orderDate.getFullYear().toString();

        // Provera da li je porudžbina u poslednjih 30 dana
        if (selectedYears.includes(t("PROFILE.LAST_30_DAYS"))) {
          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
          return orderDate >= thirtyDaysAgo;
        }

        // Filtriranje po izabranoj godini
        return selectedYears.includes(orderYear);
      });
    }

    // Filtriranje po statusu
    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((order) =>
        selectedStatuses.includes(order.order_status)
      );
    }

    setFilteredOrders(filtered);
  }, [selectedYears, selectedStatuses, orders]);

  const toggleYearFilter = () => {
    setIsOpenYear(!isOpenYear);
  };

  const toggleStatusFilter = () => {
    setIsOpenStatus(!isOpenStatus);
  };

  const handleYearChange = (year) => {
    setSelectedYears((prevSelected) =>
      prevSelected.includes(year)
        ? prevSelected.filter((y) => y !== year)
        : [...prevSelected, year]
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevSelected) =>
      prevSelected.includes(status)
        ? prevSelected.filter((s) => s !== status)
        : [...prevSelected, status]
    );
  };
  const getLastThreeYears = () => {
    const currentYear = new Date().getFullYear();
    return [
      t("PROFILE.LAST_30_DAYS"),
      currentYear.toString(),
      (currentYear - 1).toString(),
      (currentYear - 2).toString(),
    ];
  };

  return (
    <>
      <Component>
        {/* Filter po godini */}
        <SelectedSort onClick={toggleYearFilter}>
          {t("PROFILE.FILTER_BY_YEAR")}
          <OptionsContainer $isOpen={isOpenYear}>
            <OpenSortTitle>{t("PROFILE.FILTER_BY_YEAR")}</OpenSortTitle>
            {getLastThreeYears().map((year) => (
              <Option
                key={year}
                selected={selectedYears.includes(year)}
                onClick={() => {
                  handleYearChange(year);
                  setIsOpenYear(false);
                }}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginRight: "4px",
                  }}
                >
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="var(--text-color)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="var(--background-color)"
                  />
                  {selectedYears.includes(year) && (
                    <path
                      d="M7.75 12L10.58 14.83L16.25 9.17004"
                      stroke="var(--text-color)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  )}
                </svg>
                {year}
              </Option>
            ))}
            <Option
              selected={selectedYears.includes("Older")}
              onClick={() => {
                handleYearChange("Older");
                setIsOpenYear(false);
              }}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginRight: "4px",
                }}
              >
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="var(--text-color)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="var(--background-color)"
                />
                {selectedYears.includes("Older") && (
                  <path
                    d="M7.75 12L10.58 14.83L16.25 9.17004"
                    stroke="var(--text-color)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>

              {t("PROFILE.OLDER")}
            </Option>
          </OptionsContainer>
        </SelectedSort>
        <MaskContainer $isOpen={isOpenYear} onClick={toggleYearFilter} />

        {/* Filter po statusu */}
        <SelectedSort onClick={toggleStatusFilter}>
          {t("PROFILE.FILTER_BY_STATUS")}
          <OptionsContainer $isOpen={isOpenStatus}>
            <OpenSortTitle>{t("PROFILE.FILTER_BY_STATUS")}</OpenSortTitle>
            {["Pending", "Delivered", "Cancelled", "Returned"].map((status) => (
              <Option
                key={status}
                selected={selectedStatuses.includes(status)}
                onClick={() => {
                  handleStatusChange(status);
                  setIsOpenStatus(false);
                }}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginRight: "4px",
                  }}
                >
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="var(--text-color)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="var(--background-color)"
                  />
                  {selectedStatuses.includes(status) && (
                    <path
                      d="M7.75 12L10.58 14.83L16.25 9.17004"
                      stroke="var(--text-color)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  )}
                </svg>
                {t(`ORDER_STATUS.${status.replace(/ /g, "_").toUpperCase()}`)}
              </Option>
            ))}
          </OptionsContainer>
        </SelectedSort>
        <MaskContainer $isOpen={isOpenStatus} onClick={toggleStatusFilter} />
      </Component>
      {filteredOrders.map((order) => (
        <OrderDetails key={order.id} order={order} />
      ))}
    </>
  );
};

export default OrderHistoryFilter;
