import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const WrapperSVG = styled.div`
  background-color: var(--background-color);
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SVG = styled.svg`
  padding: 8px;
  background-color: #fee4e2;
  border-radius: 50%;
  border: 6px solid #fef3f2;
`;
const Text = styled.div`
  padding: 0 12px;
  font-size: var(--font-size-small);
  font-weight: 500;
  color: var(--text-color);
  text-align: center;
`;

const Success = ({ text }) => {
  return (
    <Container>
      <WrapperSVG>
        <SVG
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1144_1508)">
            <path
              d="M10.0003 6.66663V9.99996M10.0003 13.3333H10.0087M18.3337 9.99996C18.3337 14.6023 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 14.6023 1.66699 9.99996C1.66699 5.39759 5.39795 1.66663 10.0003 1.66663C14.6027 1.66663 18.3337 5.39759 18.3337 9.99996Z"
              stroke="#D92D20"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1144_1508">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </SVG>
      </WrapperSVG>
      <div>
        <Text>{text}</Text>
      </div>
    </Container>
  );
};

export default Success;
