import styled from "styled-components";
import ProductAttributePie from "./ProductAttributePie";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { ProductContext } from "../../context/ProductContext";
import AddToCartAnim from "../animations/AddToCartAnim";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { calculatePrice } from "../../utils/discount";
import hoverBgImg from "../../assets/images/product_bg_hover.svg";
import { saveForLaterActions } from "../../store/save-slice";
import { useTranslation } from "react-i18next";

const Container = styled.figure`
  position: relative;
  background-color: var(--background-color);
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;

  justify-content: center;
  flex-direction: column;
  //padding: var(--spacing-xl) 0;
  border-radius: var(--border-width-thick);
  box-shadow: var(--shadow-default);
  @media (min-width: 768px) {
    //width: 100%;
    align-items: center;
  }
`;
const WrapperBottom = styled.div`
  width: calc(100% - var(--spacing-xl));
  min-height: 240px;
  margin-left: 5%;

  @media (min-width: 768px) {
    margin-left: 0;
  }
  padding: 0 0 var(--spacing-xl) 0;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;
const Picture = styled.picture`
  display: flex; // Omogućavanje flex layout-a za centriranje slike
  //height: 240px;
  cursor: pointer;
  width: 100%;
  min-height: 200px;
  position: relative;

  &:hover {
    background-image: url(${hoverBgImg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .primary-image {
      display: none;
    }

    .secondary-image {
      display: block;
    }
  }
`;

const Image = styled.img`
  height: 100%;
  //max-width: 200px;
  width: 200px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  &.secondary-image {
    display: none;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;
const PieContainer = styled(FlexDiv)`
  cursor: pointer;
  width: 100%;
  background: var(--background-color-hover);

  /* Background blur / lg */
  backdrop-filter: blur(12px);

  margin-bottom: var(--spacing-sm);
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const FlexDivPrice = styled(FlexDiv)`
  margin: var(--spacing-xxs) 0;
  justify-content: flex-start;
  cursor: pointer;
`;
const Title = styled.h5`
  font-weight: 500;
  font-size: var(--font-size-large);

  text-align: left;
  //min-width: 80%;
  width: 100%;
  min-height: calc(var(--font-size-small) * 2);

  cursor: pointer;
`;

const RowPrices = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  font-family: "Larken-Light";
`;
const Price = styled.span`
  font-size: var(--font-size-large);

  font-weight: 600;
`;
const DiscountedPrice = styled.span`
  font-size: var(--font-size-small);
  font-weight: 400;
  text-decoration: line-through;
  opacity: 0.5;
`;
const OffPercentage = styled.span`
  font-size: var(--font-size-base);
  color: var(--success-color);
  white-space: nowrap;
`;
const PricePiece = styled.span`
  font-size: var(--font-size-small);
  color: var(--text-color);
  font-weight: 700;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 4px;
`;

const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 100%;
  text-align: center;
`;
const Button = styled.button`
  width: 100%;
  border-radius: 0;
  background-color: var(--text-color);
  color: var(--background-color);
  &:hover {
    background-color: var(--background-color);
    color: var(--text-color);
  }
`;
const TransparentButton = styled(Button)`
  background-color: var(--background-color);
  padding: 15px;
  color: var(--text-color);
  &:hover {
    background-color: var(--text-color);
    color: var(--background-color);
  }
`;

const GridQuantity = styled.div`
  /* display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr; */
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
`;
const PackDiv = styled.div`
  border-left: 1px solid var(--p-color);
  padding-left: 8px;
  color: var(--p-color);
  font-family: "Larken";
  text-transform: capitalize;
`;
const Border = styled.div`
  height: 1px;
  background-color: var(--p-color);
  opacity: 0.4;
`;
const GridQuantityItem = styled.div`
  font-size: var(--font-size-small);
  border: 1px solid var(--p-color);
  //padding: 8px;

  color: var(--p-color);
  font-weight: 700;
  border-radius: 20px;
  text-align: center;
  text-transform: capitalize;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.5;
`;

const ProductCardPouches = ({ product, quantity }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setIsCartOpen, maxAttributes, currencyTag } =
    useContext(ProductContext);
  const { goToProduct, goToCart } = useNavigation();
  const navigate = useNavigate();

  const [isAnimating, setIsAnimating] = useState(false);

  const handleRemoveFromSaveForLater = () => {
    dispatch(saveForLaterActions.removeFromSaveForLater(product.id));
  };
  const handleAddToCart = () => {
    dispatch(
      cartActions.addToCart({
        product: product,
        quantity: quantity,
      })
    );
    handleRemoveFromSaveForLater();
  };

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  const discountedPrice = calculatePrice(product.price, quantity);

  // Calculate savings
  const savingsPercentage = Math.round(
    ((product.price - discountedPrice) / product.price) * 100
  );

  const nicotinePercentage =
    (parseFloat(product.nicotine) / parseFloat(maxAttributes.nicotine)) * 100;

  const primaryImage = product.images.find((img) => img.is_primary);
  const secondaryImage = product.images.find((img) => !img.is_primary);
  return (
    <Container>
      <Picture
        onClick={() =>
          navigate(goToProduct(product.category_name, product.name))
        }
      >
        {primaryImage && (
          <Image
            src={primaryImage.thumbnail}
            alt={`${product.category_name} ${product.name}`}
            srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
            sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
            loading="lazy"
            className="primary-image"
          />
        )}
        {secondaryImage && (
          <Image
            src={secondaryImage.thumbnail}
            alt={`${product.category_name} ${product.name}`}
            srcSet={`${secondaryImage.thumbnail} 320w, ${secondaryImage.large} 480w, ${secondaryImage.large} 800w`}
            sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
            loading="lazy"
            className="secondary-image"
          />
        )}
      </Picture>
      <PieContainer
        onClick={() =>
          navigate(goToProduct(product.category_name, product.name))
        }
      >
        <ProductAttributePie
          type="nicotine"
          percentage={nicotinePercentage}
          label={t("PRODUCT_CARD.NICOTINE")}
        />
        <ProductAttributePie
          type="intensity"
          percentage={90.67}
          label={t("PRODUCT_CARD.INTENSITY")}
        />
      </PieContainer>
      <WrapperBottom>
        <Title
          onClick={() =>
            navigate(goToProduct(product.category_name, product.name))
          }
        >
          {product.category_name} &nbsp;{product.name}
        </Title>
        <FlexDivPrice
          onClick={() =>
            navigate(goToProduct(product.category_name, product.name))
          }
        >
          <RowPrices>
            <Price>
              {currencyTag}
              {(product.price * quantity).toFixed(2)}
            </Price>
            <PricePiece>
              {currencyTag}
              {discountedPrice.toFixed(2)}/{t("PRODUCT_CARD.PRICE_PIECE")}
            </PricePiece>
            {savingsPercentage > 0 && (
              <>
                <OffPercentage>{savingsPercentage}% off</OffPercentage>
                <DiscountedPrice> ${product.price.toFixed(2)}</DiscountedPrice>
              </>
            )}
          </RowPrices>
        </FlexDivPrice>

        <Border />

        <GridQuantity>
          <GridQuantityItem>{quantity}</GridQuantityItem>
          <PackDiv>{t("PRODUCT_CARD.PACK")}</PackDiv>
        </GridQuantity>

        <ButtonContainer>
          <ButtonWrapper>
            <TransparentButton onClick={() => handleRemoveFromSaveForLater()}>
              {t("BUTTONS.REMOVE")}
            </TransparentButton>

            {isAnimating && (
              <AddToCartAnim
                isAnimating={isAnimating}
                onComplete={handleAnimationComplete}
              />
            )}
          </ButtonWrapper>

          <Button onClick={() => handleAddToCart()}>
            {t("BUTTONS.MOVE_TO_CART")}
          </Button>
        </ButtonContainer>
      </WrapperBottom>
    </Container>
  );
};

export default ProductCardPouches;
