import styled from "styled-components";
import LevelCardsSlider from "../../../components/account/LevelCardsSlider";
import LevelCards from "../../../components/account/LevelCards";
import StepsCards from "../../../components/account/StepsCards";
import StepsCardsSlider from "../../../components/account/StepsCardsSlider";
import PageLoading from "../../../components/animations/PageLoading";
import { useContext, useEffect } from "react";
import { AuthUserContext } from "../../../context/AuthUserContext";

const Container = styled.main``;
const Title = styled.h1`
  padding-top: 20px;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
  @media (min-width: 767px) {
    font-size: var(--font-size-xxlarge);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
const BorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BorderTop = styled.div`
  width: 100%;
  border-bottom: 2px solid var(--background-color-hover);
  padding-bottom: 14px;
  @media (min-width: 767px) {
    border-bottom: 4px solid var(--text-color);
    width: 40px;
  }
`;
const TitleNormal = styled.h3`
  padding-top: 20px;
  font-size: var(--font-size-large);
  font-weight: 700;
  text-align: center;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
`;
const SVGIcon = styled.svg`
  margin-bottom: 20px;
`;
const SmallText = styled.h6`
  display: block;
  font-size: var(--font-size-small);
`;
const SmallDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const SmallSVG = styled.svg`
  width: var(--font-size-base);
  height: var(--font-size-base);
`;

const DesktopVisible = styled.div`
  display: none;
  @media (min-width: 767px) {
    display: block;
  }
`;

const BottomContainer = styled.div`
  background-color: var(--background-color-hover-alt);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const BottomWrapper = styled.div`
  width: 80%;
  @media (min-width: 767px) {
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    gap: 40px;
  }
`;
const DivWidth = styled.div``;
const SmallSubtitle = styled(SmallText)`
  margin-top: 20px;
  font-size: var(--font-size-base);
  text-align: center;
`;

const Desc = styled(SmallText)`
  color: var(--text-color);
  text-align: center;
  font-weight: 400;
  letter-spacing: 1.2px;
  margin: 12px 0;
`;

const ButtonWrapper = styled.div`
  margin: 40px 0;
  display: flex;
  width: 100%;
  justify-content: center;
`;
const Button = styled.button`
  border-radius: 0;
  padding: 16px 24px;
  min-width: 200px;
`;

const AccountPoints = () => {
  const { fetchUserData, userProfile } = useContext(AuthUserContext);
  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <Container>
      {userProfile ? (
        <>
          <Title>My Balance</Title>
          <BorderWrapper>
            <BorderTop />
          </BorderWrapper>
          <Wrapper>
            <SVGIcon
              width="80px"
              height="80px"
              viewBox="0 0 76 76"
              version="1.1"
              baseProfile="full"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="38"
                cy="38"
                r="35"
                fill="none"
                stroke="var(--text-color)"
                strokeWidth="4"
              />
              <path
                fill="var(--text-color)"
                fillOpacity="1"
                strokeWidth="0.2"
                strokeLinejoin="round"
                d="M 26.455,50.6456L 20.4642,50.633L 13.1944,28.0567L 18.815,28.0567L 21.5748,37.5809L 22.6729,41.5697L 23.5942,45.6336L 23.6952,45.6336C 24.2785,42.9115 24.9629,40.2696 25.7482,37.708L 28.6426,28.0567L 34.0944,28.0567L 26.455,50.6456 Z M 36.8389,28.0567L 41.8633,28.0567L 41.8633,50.6456L 36.8389,50.6456L 36.8389,28.0567 Z M 46.6344,50.6456L 46.6344,28.1622C 48.1506,27.8878 50.4422,27.74 53.5091,27.7189C 56.6418,27.7189 58.942,28.2889 60.4098,29.4289C 61.1502,29.9743 61.7325,30.7025 62.1567,31.6138C 62.581,32.525 62.7972,33.5769 62.8056,34.7696C 62.8049,35.9653 62.6316,37.04 62.2857,37.9935C 61.9397,38.947 61.4254,39.7191 60.7426,40.31C 59.8692,41.0621 58.809,41.6219 57.5619,41.9895C 56.3148,42.3571 54.9303,42.5402 53.4082,42.5389L 51.6589,42.4133L 51.6589,50.6456L 46.6344,50.6456 Z M 51.6589,31.572L 51.6589,38.7218C 52.0626,38.8236 52.6345,38.8715 53.3746,38.8656C 54.7503,38.8551 55.8253,38.5078 56.5997,37.8236C 57.374,37.1395 57.7678,36.1814 57.7811,34.9493C 57.7748,33.8265 57.4259,32.9552 56.7342,32.3354C 56.0426,31.7156 55.0461,31.4012 53.7446,31.3922C 52.7298,31.3982 52.0345,31.4581 51.6589,31.572 Z "
              />
            </SVGIcon>
            <SmallDiv>
              <SmallText>{userProfile.vip_points} points</SmallText>
              <SmallSVG
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_429_11254)">
                  <path
                    d="M10 17L15 12"
                    stroke="#292929"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 12L10 7"
                    stroke="#292929"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_429_11254">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </SmallSVG>
            </SmallDiv>
            <LevelCardsSlider />

            <DesktopVisible>
              <LevelCards />
            </DesktopVisible>
            <TitleNormal>How it works</TitleNormal>
            <DesktopVisible>
              <StepsCards />
            </DesktopVisible>
            <StepsCardsSlider />
          </Wrapper>
          <BottomContainer>
            <TitleNormal>The important notes</TitleNormal>
            <BottomWrapper>
              <DivWidth>
                <SmallSubtitle>Receiving your points</SmallSubtitle>
                <Desc>
                  We're sure you're excited to start earning and saving up
                  points. Please note that the points will only be credited
                  after the return period has expired. And returned items,
                  postage and packing costs and redeemed vouchers will not count
                  towards your points.
                </Desc>
              </DivWidth>
              <DivWidth>
                <SmallSubtitle>Receiving your points</SmallSubtitle>
                <Desc>
                  We're sure you're excited to start earning and saving up
                  points. Please note that the points will only be credited
                  after the return period has expired. And returned items,
                  postage and packing costs and redeemed vouchers will not count
                  towards your points.
                </Desc>
              </DivWidth>
              <DivWidth>
                <SmallSubtitle>Receiving your points</SmallSubtitle>
                <Desc>
                  We're sure you're excited to start earning and saving up
                  points. Please note that the points will only be credited
                  after the return period has expired. And returned items,
                  postage and packing costs and redeemed vouchers will not count
                  towards your points.
                </Desc>
              </DivWidth>
              <DivWidth>
                <SmallSubtitle>Receiving your points</SmallSubtitle>
                <Desc>
                  We're sure you're excited to start earning and saving up
                  points. Please note that the points will only be credited
                  after the return period has expired. And returned items,
                  postage and packing costs and redeemed vouchers will not count
                  towards your points.
                </Desc>
              </DivWidth>
            </BottomWrapper>
            <ButtonWrapper>
              <Button>Read Our FAQ #todo</Button>
            </ButtonWrapper>
          </BottomContainer>
        </>
      ) : (
        <PageLoading />
      )}
    </Container>
  );
};

export default AccountPoints;
