import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../../layouts/header/PrimaryHeader";
import ShopMain from "../../layouts/main/product/ShopMain";
import { ProductContext } from "../../context/ProductContext";
import FooterMain from "../../layouts/footer/FooterMain";

const ShopVariants = ({ variant }) => {
  const { t, i18n } = useTranslation();
  const {
    bestSellers,
    newArrivals,
    loadNewArrivals,
    products,
    loadProducts,
    loadBestSellers,
    setCategory,
  } = useContext(ProductContext);

  // useEffect(() => {
  //   if (variant === "best-sellers") {
  //     console.log("variant", variant);
  //     loadBestSellers();
  //   } else if (variant === "new-arrivals") {
  //     loadNewArrivals();
  //     console.log("variant", variant);
  //   }
  // }, [variant]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCategory(null);
    if (products.length) {
      if (variant === "best-sellers") {
        loadBestSellers();
      } else if (variant === "new-arrivals") {
        loadNewArrivals();
      }
    } else {
      loadProducts();
    }
  }, [products, variant]);

  return (
    <div>
      <PrimaryHeader />
      {variant === "best-sellers" ? (
        <ShopMain sortedProducts={bestSellers} />
      ) : (
        <ShopMain sortedProducts={newArrivals} />
      )}
      <FooterMain />
    </div>
  );
};

export default ShopVariants;
