import { createContext, useState, useEffect } from "react";

const SimpleContext = createContext();

export default SimpleContext;

export const SimpleProvider = ({ children }) => {
  const [over18, setOver18] = useState("");
  const [showLogin, setShowLogin] = useState(false); // State za prikaz Login popup-a
  const [showRegister, setShowRegister] = useState(false); // State za prikaz Register popup-a
  const [showChat, setShowChat] = useState(false);
  const [showVerification, setShowVerification] = useState(false);

  const [showEditAddressBook, setShowEditAddressBook] = useState(false);
  const [showAddAddressBook, setShowAddAddressBook] = useState(false);

  //reset-password
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [uid, setUid] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/").filter(Boolean);
    const searchParams = new URLSearchParams(window.location.search);
    const referralCode = searchParams.get("referral"); // Uzimanje referal koda iz URL-a
    const authTokens = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;

    if (referralCode && !authTokens) {
      setShowRegister(true); // Prikazuje Register popup ako postoji referal kod i korisnik nije prijavljen
    }
    if (pathSegments.length >= 4 && pathSegments[1] === "reset-password") {
      const [, , extractedUid, extractedToken] = pathSegments;
      if (extractedUid && extractedToken) {
        setShowResetPassword(true); // Prikazuje Reset Password popup ako postoji uid i token
        setUid(extractedUid); // Setuje `uid` state na izvučeni uid iz URL-a
        setToken(extractedToken); // Setuje `token` state na izvučeni token iz URL-a
      }
    }
  }, []); // Prazan niz zavisnosti znači da će se efekat pokrenuti samo jednom, kada se komponenta montira

  // Funkcija za otvaranje Login popup-a
  const openLoginPopup = () => {
    setShowLogin(true);
    setShowRegister(false); // Uverite se da Register popup nije otvoren
    window.history.pushState(null, "", "?popup=login");
  };

  // Funkcija za zatvaranje Login popup-a
  const closeLoginPopup = () => {
    setShowLogin(false);
    const url = new URL(window.location);
    url.searchParams.delete("popup");
    window.history.replaceState(null, "", url.toString());
  };

  // Funkcija za otvaranje Register popup-a
  const openRegisterPopup = () => {
    setShowRegister(true);
    setShowLogin(false); // Uverite se da Login popup nije otvoren
    window.history.pushState(null, "", "?popup=register");
  };

  // Funkcija za zatvaranje Register popup-a
  const closeRegisterPopup = () => {
    setShowRegister(false);
    const url = new URL(window.location);
    url.searchParams.delete("popup");
    window.history.replaceState(null, "", url.toString());
  };

  const switchToRegister = () => {
    setShowLogin(false);
    setShowRegister(true);
    window.history.replaceState(null, "", "?popup=register");
  };

  // Funkcija za prebacivanje sa Register na Login popup
  const switchToLogin = () => {
    setShowRegister(false);
    setShowLogin(true);
    window.history.replaceState(null, "", "?popup=login");
  };

  const openChatPopup = () => {
    setShowChat(true);
  };
  const closeChatPopup = () => {
    setShowChat(false);
  };

  // Pakovanje vrednosti koje će biti dostupne kroz kontekst
  let values = {
    over18,
    setOver18,
    showLogin,
    showRegister,
    showVerification,
    setShowVerification,
    showChat,
    openLoginPopup,
    closeLoginPopup,
    openRegisterPopup,
    closeRegisterPopup,
    switchToRegister,
    switchToLogin,
    openChatPopup,
    closeChatPopup,

    showEditAddressBook,
    setShowEditAddressBook,
    showAddAddressBook,
    setShowAddAddressBook,

    //reset-password
    showResetPassword,
    uid,
    token,
  };

  return (
    <SimpleContext.Provider value={values}>{children}</SimpleContext.Provider>
  );
};
