import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import silverImage from "../../assets/images/STATUS_GROUP_SILVER.webp";
import goldImage from "../../assets/images/STATUS_GROUP_GOLD.webp";
import diamondImage from "../../assets/images/STATUS_GROUP_DIAMOND.webp";
import { AuthUserContext } from "../../context/AuthUserContext";

const SliderContainer = styled.div`
  padding: 30px 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  @media (min-width: 767px) {
    display: none;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease-out;
  margin-left: 15vw;
  transform: translateX(
    calc(-${(props) => props.currentindex} * (65vw + 30px))
  );
`;

//LEVEL CARDS
const CardContainerX = styled.div`
  flex: 0 0 calc(65vw + 25px); /* Širina kartice + razmak */
`;
const CardContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  gap: 20px;
  background-image: url(${(props) => props.backgroundimage});
  width: 65vw;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border-radius: 18px;
  padding: 70px 0;
`;

const DefaultLink = styled(Link)`
  color: inherit; /* Postavlja boju teksta linka na nasleđenu boju iz roditeljskog elementa */
  text-decoration: none; /* Uklanja podvlačenje */
  background-color: transparent; /* Uklanja pozadinsku boju */
  -webkit-text-decoration-skip: objects; /* Safari opcija koja preskače dekoraciju teksta preko objekata unutar linka */
  font-weight: inherit; /* Nasleđuje debljinu fonta od roditelja */
  border: none;
`;
const CardTitle = styled(Link)`
  color: #fff;
  text-decoration: none; /* Uklanja podvlačenje */
  background-color: transparent; /* Uklanja pozadinsku boju */
  -webkit-text-decoration-skip: objects; /* Safari opcija koja preskače dekoraciju teksta preko objekata unutar linka */
  font-weight: inherit; /* Nasleđuje debljinu fonta od roditelja */
  border: none;
  text-align: center;
  letter-spacing: 4px;
  font-weight: 300;
`;
const SVGContainer = styled.div`
  margin: 14px auto;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SVGIcon = styled.svg`
  width: 40px;
  height: 40px;
`;
const SmallText = styled.h6`
  text-align: center;
  font-size: var(--font-size-small);
  color: #fff;
`;

const SmallDiv = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const SmallSVG = styled.svg`
  width: var(--font-size-base);
  height: var(--font-size-base);
`;

const LevelCardsSlider = () => {
  const { userProfile } = useContext(AuthUserContext);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [items] = useState([
    "Item 1",
    "Item 2",
    "Item 3",
    // Ostali jedinstveni elementi...
  ]);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, getMaxIndex(items.length))
      ),
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getMaxIndex = (itemsLength) => {
    const scrollStep = 1; // Broj elemenata za skrolanje
    return Math.max(itemsLength - scrollStep, 0);
  };

  return (
    <SliderContainer {...handlers}>
      <SliderWrapper currentindex={currentIndex}>
        <CardContainerX>
          <CardContainer backgroundimage={silverImage}>
            <CardTitle>
              <strong>SILVER</strong>MEMBER
            </CardTitle>
            <SVGContainer>
              {userProfile.level >= 1 ? (
                <SVGIcon
                  width="40px"
                  height="40px"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 6.5V3.5C4.5 1.84315 5.84315 0.5 7.5 0.5C9.15685 0.5 10.5 1.84315 10.5 3.5V4M2.5 6.5H12.5C13.0523 6.5 13.5 6.94772 13.5 7.5V13.5C13.5 14.0523 13.0523 14.5 12.5 14.5H2.5C1.94772 14.5 1.5 14.0523 1.5 13.5V7.5C1.5 6.94772 1.94772 6.5 2.5 6.5Z"
                    stroke="var(--background-color)"
                  />
                </SVGIcon>
              ) : (
                <SVGIcon
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    stroke="var(--background-color)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 10.9999V6.99988C7 4.23845 9.23858 1.99988 12 1.99988V1.99988C14.7614 1.99988 17 4.23845 17 6.99988V10.9999"
                    stroke="var(--background-color)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </SVGIcon>
              )}
            </SVGContainer>
            <SmallText>You need 300pts</SmallText>
            <div>
              <SmallText> 1x Points Multiplier </SmallText>
              <SmallText>Referral Reward: 100 Points </SmallText>
              <SmallText> -</SmallText>
            </div>
          </CardContainer>
          <SmallDiv>
            <SmallText style={{ color: "var(--text-color)" }}>
              Silver benefits
            </SmallText>
          </SmallDiv>
        </CardContainerX>

        <CardContainerX>
          <CardContainer backgroundimage={goldImage}>
            <CardTitle>
              <strong>GOLD</strong>MEMBER
            </CardTitle>
            <SVGContainer>
              {userProfile.level >= 2 ? (
                <SVGIcon
                  width="40px"
                  height="40px"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 6.5V3.5C4.5 1.84315 5.84315 0.5 7.5 0.5C9.15685 0.5 10.5 1.84315 10.5 3.5V4M2.5 6.5H12.5C13.0523 6.5 13.5 6.94772 13.5 7.5V13.5C13.5 14.0523 13.0523 14.5 12.5 14.5H2.5C1.94772 14.5 1.5 14.0523 1.5 13.5V7.5C1.5 6.94772 1.94772 6.5 2.5 6.5Z"
                    stroke="var(--background-color)"
                  />
                </SVGIcon>
              ) : (
                <SVGIcon
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    stroke="var(--background-color)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 10.9999V6.99988C7 4.23845 9.23858 1.99988 12 1.99988V1.99988C14.7614 1.99988 17 4.23845 17 6.99988V10.9999"
                    stroke="var(--background-color)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </SVGIcon>
              )}
            </SVGContainer>
            <SmallText>You need 1500pts</SmallText>
            <div>
              <SmallText> 1.25x Points Multiplier </SmallText>
              <SmallText>Referral Reward: 100 Points </SmallText>
              <SmallText>50 Points Upon Entry </SmallText>
            </div>
          </CardContainer>
          <SmallDiv>
            <SmallText style={{ color: "var(--text-color)" }}>
              Gold benefits
            </SmallText>
          </SmallDiv>
        </CardContainerX>
        <CardContainerX>
          <CardContainer backgroundimage={diamondImage}>
            <CardTitle>
              <strong>DIAMOND</strong>MEMBER
            </CardTitle>
            <SVGContainer>
              {userProfile.level >= 3 ? (
                <SVGIcon
                  width="40px"
                  height="40px"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 6.5V3.5C4.5 1.84315 5.84315 0.5 7.5 0.5C9.15685 0.5 10.5 1.84315 10.5 3.5V4M2.5 6.5H12.5C13.0523 6.5 13.5 6.94772 13.5 7.5V13.5C13.5 14.0523 13.0523 14.5 12.5 14.5H2.5C1.94772 14.5 1.5 14.0523 1.5 13.5V7.5C1.5 6.94772 1.94772 6.5 2.5 6.5Z"
                    stroke="var(--background-color)"
                  />
                </SVGIcon>
              ) : (
                <SVGIcon
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    stroke="var(--background-color)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 10.9999V6.99988C7 4.23845 9.23858 1.99988 12 1.99988V1.99988C14.7614 1.99988 17 4.23845 17 6.99988V10.9999"
                    stroke="var(--background-color)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </SVGIcon>
              )}
            </SVGContainer>
            <SmallText>You need 2500pts</SmallText>
            <div>
              <SmallText> 1.50x Points Multiplier </SmallText>
              <SmallText>Referral Reward: 150 Points </SmallText>
              <SmallText>100 Points Upon Entry </SmallText>
            </div>
          </CardContainer>
          <SmallDiv>
            <SmallText style={{ color: "var(--text-color)" }}>
              Diamond benefits
            </SmallText>
          </SmallDiv>
        </CardContainerX>
      </SliderWrapper>
    </SliderContainer>
  );
};

export default LevelCardsSlider;
