import styled, { keyframes, css } from "styled-components";
import { useState, useEffect, useContext } from "react";
import { useNavigation } from "../../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../../../context/ProductContext";

const fadeInTop = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const fadeOutBottom = keyframes`
  from {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;

const Container = styled.div`
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}

  color: var(--text-color);
  padding: 0;
  border: 0;
  outline: none;
  position: fixed;

  top: ${(props) =>
    props.$isScrolled
      ? "var(--navbar-height)"
      : "calc(var(--navbar-height) + 20px)"};
  @media (min-width: 768px) {
    top: ${(props) =>
      props.$isScrolled
        ? "var(--navbar-height-desktop)"
        : "calc(var(--navbar-height-desktop) + 20px)"};
  }
  left: 0%;
  width: 100vw;
  height: 100vh;
  background-color: var(--transparent-color);
  z-index: 9;
  justify-content: center;
`;
const Box = styled.div`
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${fadeOutBottom} 0.5s ease-in-out both;
        `
      : css`
          animation: ${fadeInTop} 0.5s ease-in-out both;
        `}
  position:absolute;
  width: 100vw;
  height: 100vh;

  @media (min-width: 1024px) {
    width: var(--max-width-container);
    max-height: 70vh;
    height: auto;
  }
  background-color: var(--background-color-hover);
  box-shadow: var(--shadow-large);
  padding: 0px;
  top: 0;
`;
const BoxWrapper = styled.div`
  padding: 0;
  @media (min-width: 768px) {
    padding: 10px;
  }
`;
const ProductsContainer = styled.div`
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  max-height: 60vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }
`;
const ProductContainer = styled.div`
  display: flex;
  background-color: var(--background-color);
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid var(--background-color-hover-alt);
  &:hover {
    border: 1px solid var(--primary-color);
  }
  &:before {
    border: 1px solid transparent;
  }
  &hover:before {
    border-color: var(--primary-color);
  }
  img {
    transition: transform 0.3s ease-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
  cursor: pointer;
`;
const ProductImageContainer = styled.div`
  flex: 1;
`;
const ProductPicture = styled.picture`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ProductImage = styled.img`
  height: 65px;
`;
const ProductInfoContainer = styled.div`
  flex: 2;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const ProductTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: var(--font-size-small);
  padding: var(--spacing-xs) 0;
`;
const PriceFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: var(--spacing-sm);
`;
const ProductPrice = styled.h2`
  font-size: var(--font-size-small);
  color: var(--error-color);
  font-weight: bold;
`;
const DiscountedPrice = styled.span`
  font-size: var(--font-size-small);
  font-weight: 400;
  text-decoration: line-through;
`;
const BottomTitle = styled.h5`
  margin: var(--spacing-md) 0;
  font-weight: 400;
  font-size: var(--font-size-xlarge);
  padding: var(--spacing-xs) 0;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`;
const ButtonWrapper = styled.div`
  margin: var(--spacing-md) 0 var(--spacing-xxl) 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;
const Button = styled.button`
  font-weight: 500;
  font-size: var(--font-size-base);
  padding: 0;
  margin: var(--spacing-xs) 0;
  text-decoration: underline;
  background-color: transparent;
  color: var(--text-color);
  border: none;

  &:hover {
    background-color: transparent;
    scale: 1.1;
  }
`;

const SearchBody = ({ products, handleShowAll }) => {
  const { goToProduct, goToHome } = useNavigation();
  const { setProducts } = useContext(ProductContext);
  const navigate = useNavigate();
  const [isOpenContainer, setIsOpenContainer] = useState(true);
  const [isOpenBox, setIsOpenBox] = useState(true);

  const [isBoxAnimating, setIsBoxAnimating] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0); // Ažurira state ako korisnik skroluje nadole
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Očistite event listener kad se komponenta demontira
    };
  }, []);

  const [maxProductsToShow, setMaxProductsToShow] = useState(12);
  useEffect(() => {
    const updateProductDisplay = () => {
      if (window.innerWidth >= 1024) {
        setMaxProductsToShow(24); // Desktop
      } else if (window.innerWidth >= 768) {
        setMaxProductsToShow(15); // Tablet
      } else {
        setMaxProductsToShow(12); // Mobilni
      }
    };

    updateProductDisplay();

    window.addEventListener("resize", updateProductDisplay);

    return () => window.removeEventListener("resize", updateProductDisplay);
  }, []);

  useEffect(() => {
    if (products && products.length && isOpenBox && isOpenContainer) {
      toggleBox();
    } else if (
      (!products || products.length === 0) && // Provera da li nema proizvoda
      !isOpenBox &&
      !isOpenContainer
    ) {
      toggleBox();
    }
  }, [products]);

  const toggleBox = () => {
    if (isBoxAnimating) {
      return;
    }
    setIsOpenBox(!isOpenBox);
    setIsBoxAnimating(true);
    if (isOpenContainer === false) {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 500);
    } else {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 10);
    }
  };

  const handleProductCardClick = (category_name, name) => {
    toggleBox();
    navigate(goToProduct(category_name, name));
  };
  return (
    <Container
      onClick={() => {
        toggleBox();
      }}
      $isOpen={isOpenContainer}
      $isBoxAnimating={isBoxAnimating}
      $isScrolled={isScrolled}
    >
      <Box
        $isOpen={isOpenBox}
        $isBoxAnimating={isBoxAnimating}
        onClick={(event) => event.stopPropagation()}
      >
        <BoxWrapper>
          <BottomTitle>Products:</BottomTitle>
          <ProductsContainer>
            {products?.slice(0, maxProductsToShow).map((item) => (
              <ProductContainer
                key={item.id}
                onClick={() => {
                  handleProductCardClick(item.category_name, item.name);
                }}
              >
                <ProductImageContainer>
                  <ProductPicture>
                    {item.images.length > 0 &&
                      (() => {
                        const primaryImage = item.images.find(
                          (img) => img.is_primary
                        ); // Pronalazi primarnu sliku samo jednom
                        return (
                          primaryImage && (
                            <ProductImage
                              src={primaryImage.thumbnail} // Koristi 'thumbnail' verziju primarne slike
                              alt={item.name}
                              srcset={`
                        ${primaryImage.thumbnail} 320w,  // Koristi 'thumbnail' verziju za male ekrane
                        ${primaryImage.large} 480w,  // Koristi 'large' verziju za srednje ekrane
                        ${primaryImage.large} 800w`} // Ponovo koristi 'large' verziju za velike ekrane
                              sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                              loading="lazy"
                            />
                          )
                        );
                      })()}
                  </ProductPicture>
                </ProductImageContainer>
                <ProductInfoContainer>
                  <ProductTitle>
                    {item.category_name} {item.name}
                  </ProductTitle>
                  <PriceFlex>
                    <ProductPrice>${item.price}</ProductPrice>
                    {/* Ako postoji popust, prikažite DiscountedPrice, inače ne */}
                    {item.discountedPrice && (
                      <DiscountedPrice>
                        {item.discountedPrice} RSD
                      </DiscountedPrice>
                    )}
                  </PriceFlex>
                </ProductInfoContainer>
              </ProductContainer>
            ))}
          </ProductsContainer>

          <ButtonWrapper>
            <Button
              onClick={() => {
                handleShowAll();
              }}
            >
              Show all results ({products.length})
            </Button>
          </ButtonWrapper>
        </BoxWrapper>
      </Box>
    </Container>
  );
};

export default SearchBody;
