import { useContext, useState } from "react";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import AddressBookForm from "../account/AddressBookForm";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const FillAddressContainer = styled.div``;
const ActiveFillHeader = styled.div`
  padding: var(--spacing-xl) var(--spacing-lg);
  background-color: #c4d9e8;
  display: none;
  gap: var(--spacing-md);
  align-items: center;
  font-family: "Larken-Light";
  text-transform: uppercase;
  @media (min-width: 768px) {
    display: flex;
  }
`;
const FillHeader = styled(ActiveFillHeader)`
  background-color: var(--background-color);
  margin-top: 14px;
`;
const StepNumber = styled.div`
  border: 1px solid var(--text-color);
  padding: 4px 10px;
  border-radius: 50%;
`;

const AddNewAddress = styled.div`
  display: flex;
  justify-content: flex-start;
  color: var(--primary-color);
  font-weight: 700;
  gap: var(--spacing-md);
  padding: var(--spacing-md) var(--spacing-md);
  margin-bottom: var(--spacing-md);
  background-color: var(--background-color);
  font-size: var(--font-size-base);
  font-weight: 300;
  font-family: "Larken";
  border-top: 1px solid #d7e0e7;
  border-bottom: 1px solid #d7e0e7;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SelectAddressContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
`;
const SelectAddressWrapper = styled.div`
  padding: var(--spacing-xl) var(--spacing-md);
  margin: var(--spacing-xxs) 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
    padding: var(--spacing-xl);
    flex-direction: row;
  }
`;
const AddressTitle = styled.h3`
  font-size: var(--font-size-base);
  font-weight: normal;
`;
const AdressInfo = styled.p`
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--p-color);
`;
const RadioButton = styled.div`
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 20px;
  border: 1px solid var(--text-color-hover);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckedRadio = styled.div`
  background-color: var(--text-color);
  width: 8px;
  height: 8px;
  border-radius: 20px;
`;
const EditButton = styled.button`
  display: ${(props) => (props.$show ? "flex" : "none")};
  margin-left: calc(16px + var(--spacing-md));
  &:hover {
    background-color: transparent;
    color: var(--text-color);
  }
  @media (min-width: 768px) {
    display: block;
    border: none;
    padding: 0;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
  min-width: 60%;
`;
const OnlyMobile = styled.span`
  display: ${(props) => (props.$newAddress ? "none" : "inline")};
  @media (min-width: 768px) {
    display: inline;
  }
`;
const ConfirmButton = styled.div`
  font-family: "Larken";
  font-weight: bold;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--primary-color);
  text-align: center;
  color: var(--background-color);
  padding: var(--spacing-md) 0;
  display: block;
  cursor: pointer;
  @media (min-width: 768px) {
    display: none;
  }
`;
const AddressType = styled.p`
  display: inline;
  font-size: var(--font-size-small);
  font-family: "Larken";
  margin-top: 0;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: 20px;
  text-transform: capitalize;
  color: var(--text-color);
  background-color: #d7e0e7;
  margin-left: 14px;
`;
const FillAddress = ({
  setSelectedAddress,
  newAddress,
  setNewAddress,
  editAddress,
  setEditAddress,
}) => {
  const { t } = useTranslation();
  const [showRadio, setShowRadio] = useState(null);
  const { userProfile } = useContext(AuthUserContext);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleEditClick = (address) => {
    setEditAddress(address);
    setNewAddress(true);
  };
  const handleSelectClick = (address) => {
    setShowRadio(address.id);
    if (isMobile) {
      return;
    }
    setSelectedAddress(address);
  };
  const handleConfirmAddress = () => {
    const foundAddress = userProfile?.addresses.find(
      (address) => address.id === showRadio
    );
    setSelectedAddress(foundAddress);
  };
  return (
    <FillAddressContainer>
      <OnlyMobile $newAddress={newAddress}>
        <AddNewAddress onClick={() => setNewAddress(true)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_693_9651)">
              <path
                d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                stroke="#5693BF"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 10H12.5"
                stroke="#5693BF"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 7.5V12.5"
                stroke="#5693BF"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_693_9651">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {t("CHECKOUT.ADD_NEW_ADDRESS")}
        </AddNewAddress>
        <ActiveFillHeader>
          <StepNumber>2</StepNumber>
          {t("CHECKOUT.DELIVERY_ADDRESS")}
        </ActiveFillHeader>

        {userProfile &&
          userProfile?.addresses.map((address, index) => (
            <SelectAddressContainer key={index}>
              <SelectAddressWrapper>
                <FlexDiv onClick={() => handleSelectClick(address)}>
                  <RadioButton>
                    {address.id === showRadio && <CheckedRadio />}
                  </RadioButton>
                  <div>
                    <AddressTitle>
                      {t("CHECKOUT.DELIVER_TO")}
                      {address.country}, {address.id}
                      <AddressType>{address.type}</AddressType>
                    </AddressTitle>

                    <AdressInfo>
                      {address.street}, {address.city}, {address.postal_code} ..
                    </AdressInfo>
                  </div>
                </FlexDiv>

                <div>
                  <EditButton
                    className="button-transparent"
                    onClick={() => handleEditClick(address)}
                    $show={address.id === showRadio}
                  >
                    {t("BUTTONS.EDIT")}
                  </EditButton>
                </div>
              </SelectAddressWrapper>
            </SelectAddressContainer>
          ))}
      </OnlyMobile>
      {!newAddress && showRadio && (
        <ConfirmButton onClick={handleConfirmAddress}>
          {t("CHECKOUT.DELIVER_HERE")}
        </ConfirmButton>
      )}
      <OnlyMobile $newAddress={!newAddress}>
        <AddressBookForm
          editAddress={editAddress}
          setSelectedAddress={setSelectedAddress}
          setNewAddress={setNewAddress}
          setEditAddress={setEditAddress}
        />
      </OnlyMobile>

      <FillHeader>
        <StepNumber>3</StepNumber>
        {t("CHECKOUT.PAYMENT_OPTION")}
      </FillHeader>
    </FillAddressContainer>
  );
};

export default FillAddress;
