import { useEffect, useState } from "react";
import styled from "styled-components";

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px; /* Smanjena širina */
  height: 22px; /* Smanjena visina */
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--text-color);
  transition: 0.4s;
  border-radius: 22px; /* Promenjen radijus zaobljenja da odgovara visini */

  &:before {
    position: absolute;
    content: "";
    height: 18px; /* Smanjena visina thumb-a */
    width: 18px; /* Smanjena širina thumb-a */
    left: 2px; /* Manji levi margina */
    bottom: 2px; /* Manji donji margina */
    background-color: var(--background-color);
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: var(--text-color);
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px var(--text-color);
  }

  &:checked + ${Slider}:before {
    transform: translateX(18px); /* Ažurirano da odgovara smanjenoj širini */
  }
`;

const ToggleSwitch = () => {
  // Učitajte početnu temu iz localStorage-a ili postavite na 'light-theme' ako nije definisano
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "light-theme"
  );

  useEffect(() => {
    // Primena izabrane teme na element document
    document.documentElement.className = theme;
    // Čuvanje izabrane teme u localStorage
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((currentTheme) =>
      currentTheme === "light-theme" ? "dark-theme" : "light-theme"
    );
  };

  // Provera da li je trenutna tema 'dark-theme' za kontrolu stanja checkbox-a
  const isOn = theme === "dark-theme";

  return (
    <Switch>
      <Input type="checkbox" checked={isOn} onChange={toggleTheme} />
      <Slider />
    </Switch>
  );
};

export default ToggleSwitch;
