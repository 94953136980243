import { useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import debounce from "lodash.debounce";
import APIService from "../../services/APIService";
import SearchBody from "./components/SearchBody";
import { ProductContext } from "../../context/ProductContext";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../utils/navigation";
const expandAnimation = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;
const shrinkAnimation = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
`;
const ContainerX = styled.div``;
const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const Container = styled.div`
  display: ${(props) => (props.$shouldBeOpen ? "block" : "none")};
  background-color: var(--background-color);
  position: absolute;
  top: 0;
  //top: calc(var(--navbar-height));
  left: 0;
  z-index: var(--zindex-top);
  min-width: 100%;
  height: var(--navbar-height);
  box-sizing: border-box;
  overflow-x: hidden;

  @media (min-width: 768px) {
    min-height: var(--navbar-height-desktop);
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Button = styled.button`
  background-color: var(--background-color);
  border: none;
  &:hover {
    background-color: var(--background-color);
  }
`;

const Span = styled.span`
  display: none;
`;

const Input = styled.input`
  width: 0%;
  max-width: 90%;
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-medium);
  font-weight: 400;

  animation: ${(props) => (props.$isOpen ? expandAnimation : shrinkAnimation)}
    0.5s ease forwards;
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    max-width: var(--max-width-container);
  }
`;
const SearchHeader = () => {
  const { setSearchedProducts } = useContext(ProductContext);
  const navigate = useNavigate();
  const { goToSearch } = useNavigation();
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeOpen, setShouldBeOpen] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);

  const inputRef = useRef(null);
  // Debounce funkcija za pretragu kako bi se izbeglo preterano slanje zahteva
  const debouncedSearch = useRef(
    debounce(async (searchTerm) => {
      // Ovde dodajte logiku za slanje zahteva za pretragu
      console.log("Pretraga za:", searchTerm);
      try {
        const searchData = await APIService.SearchProducts(searchTerm);
        //console.log("searchData", searchData);
        setSearchProducts(searchData);
      } catch (error) {
        console.error("Greška pri pretrazi:", error);
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (searchValue) {
      debouncedSearch(searchValue);
    } else {
      setSearchProducts([]);
    }
  }, [searchValue, debouncedSearch]);

  useEffect(() => {
    if (isOpen) {
      //inputRef.current.focus();
      setShouldBeOpen(true);
    } else {
      const timer = setTimeout(() => {
        setShouldBeOpen(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus(); // Postavljanje fokusa na input polje
          inputRef.current.select(); // Selektovanje teksta unutar input polja
        }
      }, 0);

      return () => clearTimeout(timer);
    } else {
      setSearchProducts([]);
    }
  }, [isOpen]);

  const handleShowAll = () => {
    setSearchedProducts(searchProducts);
    navigate(goToSearch(searchValue));
    setSearchProducts([]);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleShowAll();
    }
  };

  return (
    <ContainerX>
      <SearchContainer>
        <svg
          aria-hidden="true"
          focusable="false"
          role="presentation"
          viewBox="0 0 64 64"
          className="svg-icon"
          onClick={() => setIsOpen(true)}
        >
          <path d="M47.16 28.58A18.58 18.58 0 1 1 28.58 10a18.58 18.58 0 0 1 18.58 18.58zM54 54L41.94 42"></path>
        </svg>
        <span>Search</span>
      </SearchContainer>
      <Container $shouldBeOpen={shouldBeOpen}>
        <Wrapper>
          <Form
            method="get"
            role="search"
            id="HeaderSearchForm"
            autoComplete="off"
            style={{ width: "90%" }}
          >
            <Button type="submit">
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                viewBox="0 0 64 64"
                className="svg-icon"
              >
                <path d="M47.16 28.58A18.58 18.58 0 1 1 28.58 10a18.58 18.58 0 0 1 18.58 18.58zM54 54L41.94 42"></path>
              </svg>
              <Span>Search our shop</Span>
            </Button>
            <Input
              type="search"
              placeholder="Search our store"
              aria-label="Search our store"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              $isOpen={isOpen}
              ref={inputRef}
              onKeyPress={handleKeyPress}
            />
          </Form>
          <Button type="button" onClick={() => setIsOpen(false)}>
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="svg-icon"
              viewBox="0 0 64 64"
            >
              <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
            </svg>
            <Span>"Close (Esc)"</Span>
          </Button>
        </Wrapper>
      </Container>
      <SearchBody products={searchProducts} handleShowAll={handleShowAll} />
    </ContainerX>
  );
};

export default SearchHeader;
