import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HearthA from "../../../assets/icons/search.png";

const Container = styled.div`
  margin-top: var(--spacing-xxs);
  background-color: var(--background-color);
  position: fixed;
  //top: calc(var(--navbar-height));
  left: 0;
  z-index: var(--zindex-default);
  min-width: 100%;
  height: calc(100vh - var(--navbar-height));
  box-sizing: border-box;
  overflow-x: hidden;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
`;
const Wrapper = styled.div`
  padding-top: 40px;
  padding: 40px 20px 0 20px;
  max-width: var(--max-width-container);
  margin: auto;
  display: flex;
  height: 50%;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;
const GridWrapper = styled.div`
  flex: 3;
  width: 100%;
  display: grid;
  grid-auto-flow: column; /* Elementi će se prvo popunjavati po kolonama */
  grid-template-rows: repeat(20, 1fr); /* Maksimalno 5 redova */
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Kolone će se popunjavati dok ima mesta, sa minimalnom širinom od 200px */

  height: 100%;
`;
const TopTitle = styled.div`
  white-space: nowrap;
`;

const GridItem = styled.div`
  padding: 10px; /* Dodajte padding oko svakog GridItem-a */
  margin: 5px; /* Dodajte malo prostora između GridItem-a */
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--text-color);
  font-weight: bold;

  &:hover {
    color: var(--primary-color);
  }
`;

const Picture = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1400px) {
    display: none;
  }
`;
const Image = styled.img`
  display: block;
  width: 100%;

  object-fit: cover;
  object-position: center;
  background-color: red;
`;

const MegaMenu = ({ megaMenuData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldBeVisible, setShouldBeVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setShouldBeVisible(true); // Odmah postavi na visible kad se otvara
    } else {
      const timer = setTimeout(() => {
        setShouldBeVisible(false); // Postavi na hidden nakon tranzicije
      }, 300); // Timeout treba da bude jednak trajanju tranzicije
      return () => clearTimeout(timer); // Očisti timer ako se stanje promeni pre završetka
    }
  }, [isVisible]); // Efekat se pokreće svaki put kada se isVisible promeni

  const onMouseEnter = () => {
    setIsVisible(true);
  };
  const onMouseLeave = () => {
    setIsVisible(false);
  };
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <TopTitle>{megaMenuData.name}</TopTitle>
      {megaMenuData.items.length ? (
        <Container $isVisible={isVisible} $shouldBeVisible={shouldBeVisible}>
          <Wrapper>
            <GridWrapper>
              {megaMenuData.items.map(
                (
                  item,
                  itemIndex // Mapiramo kroz stavke sekcije
                ) => (
                  <GridItem key={itemIndex}>
                    <StyledLink to={item.link}>{item.name}</StyledLink>
                  </GridItem>
                )
              )}
            </GridWrapper>
            <Picture>
              <Image src={HearthA} alt="cover-photo" />
            </Picture>
          </Wrapper>
        </Container>
      ) : null}
    </div>
  );
};

export default MegaMenu;
