import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const WrapperSVG = styled.div`
  background-color: var(--background-color-hover);
  width: 70px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Text = styled.div`
  padding: 0 12px;
  font-size: var(--font-size-small);
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--text-color);
  text-align: center;
`;
const TextSmall = styled.div`
  padding: 0 12px;
  font-size: var(--font-size-small);
  font-weight: 400;
  color: var(--text-color);
  text-align: center;
`;
const Error = ({ text, smalltext }) => {
  return (
    <Container>
      <WrapperSVG>
        <svg
          fill="var(--error-color)"
          width="40px"
          height="40px"
          viewBox="0 0 36 36"
          version="1.1"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>error-solid</title>
          <path d="M18,6A12,12,0,1,0,30,18,12,12,0,0,0,18,6Zm-1.49,6a1.49,1.49,0,0,1,3,0v6.89a1.49,1.49,0,1,1-3,0ZM18,25.5a1.72,1.72,0,1,1,1.72-1.72A1.72,1.72,0,0,1,18,25.5Z"></path>
          <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
        </svg>
      </WrapperSVG>
      <div>
        <Text>{text}</Text>
        <TextSmall>{smalltext}</TextSmall>
      </div>
    </Container>
  );
};

export default Error;
